
import React, { useState, useEffect } from "react";
import AppsDropDownComponent from "../PageTopComponents/AppDropDown";
import LocationsDropDownComponent from "../PageTopComponents/LocationDropDown";
import DateTimeField from '../DateTimeField/DateTimeField';
import { appData } from "../../apis/AppsAPI";
import { getAllLocations, getLocationCount, locationData } from "../../apis/LocationAPI";
import { errorHandler } from "../Utils/errorHandler";
import { toast, ToastContainer } from "react-toastify";
import SessionExpiredModal from "../SessionExpire/SessionExpiredModal";
import moment from "moment";
import axiosInstance from "../../services/AxiosInstance";
import Loading from "../Utils/Loading";
import { useLocation } from "react-router-dom";
import AnalyticsTopLoc from "./AnalyticsTopLoc";
import AnalyticsTopDate from "./AnalyticsTopDate";
import AnalyticsSummaryView from "./AnalyticsSummaryView";
import AnalyticsDropDown from "./AnalyticsDropDown";
import AnalyticsEmail from "./AnalyticsAddEmail";
import AnalyticsDate from "./AnalyticsDate";
import AnalyticsLoc from "./AnalyticsLoc";
import AnalyticsCSVDownload from "./AnalyticsCSVDownload";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faEnvelopeCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { faFileCsv } from '@fortawesome/free-solid-svg-icons';
import SortableAnalyticsTable from "./SortableAnalyticsTable";

const Analytics = () => {
    const search = useLocation().search;
    const queryParams = new URLSearchParams(search);
    const [applist, setApplist] = useState([]);
    const [location, setLocation] = useState([]);
    const [locationsPerPage, setLocationsPerPage] = useState(0);
    const [analyticsDataSummary, setAnalyticsDataSummary] = useState([]);
    const [analyticsDataDetailsLoc, setAnalyticsDataDetailsLoc] = useState([]);
    const [analyticsDataDetailsLocs, setAnalyticsDataDetailsLocs] = useState([]);
    const [analyticsDataDetailsDate, setAnalyticsDataDetailsDate] = useState([]);
    const [analyticsDataDetailsDates, setAnalyticsDataDetailsDates] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [sessionModal, setSessionModal] = useState(false);
    const [getDates, setGetDates] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [topAnalyticData, setTopAnalyticData] = useState([]);
    const [topAnalyticDataDate, setTopAnalyticDataDate] = useState([]);
    const [emails, setEmails] = useState([]);
    const [singleEmail, setSingleEmail] = useState('');
    const [isScrolling, setIsScrolling] = useState(false);
    const [showEmail, setShowEmail] = useState(false);
    const [locate, setLocate] = useState(null);
    const [dates, setDates] = useState(null);
    const [locLoading, setLocLoading] = useState(false);
    const [dateLoading, setDateLoading] = useState(false);
    const [locName, setLocName] = useState(null);
    const [summaryLoading, setSummaryLoading] = useState(false);
    const [insightDateLoading, setInsightDateLoading] = useState(false);
    const [insightLocLoading, setInsightLocLoading] = useState(false);
    const [pdfLoading, setPdfLoading] = useState(false);
    const [csvLoading, setCsvLoading] = useState(false);
    const user = JSON.parse(localStorage.getItem('user'));
    const role = user.role;
    const app = queryParams.get('app_id') ? parseInt(queryParams.get('app_id')) : null;
    const locs = queryParams.get('loc_id') ? parseInt(queryParams.get('loc_id')) : null;
    const stDate = queryParams.get('start') ? moment(queryParams.get('start')) : moment().startOf('month');
    const enDate = queryParams.get('end') ? moment(queryParams.get('end')) : moment();
    const mtric = queryParams.get('event') ? queryParams.get('event') : 'Downloads';

    const [apps, setApps] = useState(app);
    const [loc, setLoc] = useState(locs);
    const [startDate, setStartDate] = useState(stDate);
    const [endDate, setEndDate] = useState(enDate);
    const [metric, setMetric] = useState(mtric);


    // detect scrolling
    useEffect(() => {
        const handleScroll = () => {
            const isScrolling = window.scrollY > 0;
            setIsScrolling(isScrolling);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    // load all apps
    useEffect(() => {
        const apps = appData();
        if (apps.length === 1) {
            setApps(apps[0]?.id);
            setApplist(apps);
        }
        else if (apps.length > 1) {
            setApplist(apps);
        }
        else {
            setApplist([]);
        };
    }, []);



    // change url
    useEffect(() => {
        // Update the URL query params when the state of the hook changes

        const updateQueryParams = () => {
            // Check if startDate is greater than endDate
            if (startDate && endDate && startDate.isAfter(endDate)) {
                setStartDate(null);
                setEndDate(null);
            }

            const queryParams = new URLSearchParams();
            if (apps) queryParams.set('app_id', apps);
            if (loc) queryParams.set('loc_id', loc);
            if (startDate) queryParams.set('start', startDate.toISOString());
            if (endDate) queryParams.set('end', endDate.toISOString());
            if (metric) queryParams.set('event', metric);
            const queryString = queryParams.toString();
            const newUrl = `${window.location.pathname}?${queryString}`;
            window.history.pushState({ path: newUrl }, '', newUrl);
        };


        updateQueryParams();
    }, [apps, loc, startDate, endDate, metric, dates]);


    // load all locations by app
    useEffect(() => {
        if (apps) {

            locationData(apps).then((response) => {

                if (response.status === 200) {
                    setLocation(response.data);
                } else {
                    setLocation([]);
                    errorHandler("location fetch error "+response.status, setSessionModal, setShowToast, toast);

                };
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        }

        else if (role === 'administrator') {

            getLocationCount().then((response) => {
                if (response.status === 200) {
                    const count = response.data[0].countlocation;
                    setLocationsPerPage(count);

                } else {
                    setLocation([]);
                    window.location = '/error';
                };

            }).catch(err => {
                console.log(err);
                errorHandler(err, setSessionModal, setShowToast, toast, "/error");
            });

            if (locationsPerPage === 0) {
                return;
            }
            getAllLocations(locationsPerPage).then((response) => {
                if (response.status === 200) {
                    setLocation(response.data);
                } else {
                    setLocation([]);
                    window.location = '/error';
                };
            }).catch(err => {
                console.log(err);
                errorHandler(err, setSessionModal, setShowToast, toast, "/error");
            });


        }

        else {
            setLocation([]);
        };
    }, [apps, role, locationsPerPage]);

    // handle change of dropdowns
    const handleChange = async (event) => {

        const name = event.target.name;
        //console.log(name)
        let value;

        if (event.target.type === 'select-one') {
            let selectedIndex = event.target.selectedIndex;
            let tvalue = document.getElementById(name).value;
            //console.log(tvalue)

            if (selectedIndex === 0) {
                if (name === 'location_id') {
                    setLoc(null);
                    setLocate(null);
                }
                else if (name === 'app_id') {
                    setApps(null);
                    setLoc(null);
                    setLocate(null);
                    setLocation([]);
                }
                else if (name === 'metric') {
                    setMetric('Downloads');
                }
            }
            else {
                if (name === "location_id") {
                    value = +tvalue;
                    //console.log(value)
                    setLoc(value);
                    setLocate(value);
                }
                else if (name === "app_id") {
                    value = +tvalue;
                    //console.log(value)
                    setApps(value);
                    setLoc(null);
                    setLocate(null);
                }
                else if (name === 'metric') {
                    value = tvalue;
                    //console.log(value)
                    setMetric(value);
                }
            }
        }
    };





    useEffect(() => {

        // get analytics summary
        const getAnalyticsSummary = async () => {
            // get date range
            let startDates, endDates;
            if (startDate !== null && endDate !== null) {
                startDates = moment(startDate).format('YYYY-MM-DD');
                endDates = moment(endDate).format('YYYY-MM-DD');
            } else if (startDate !== null && endDate === null) {
                startDates = moment(startDate).format('YYYY-MM-DD');
                endDates = moment().format('YYYY-MM-DD');
            } else if (startDate === null && endDate !== null) {
                startDates = moment().startOf('month').format('YYYY-MM-DD');
                endDates = moment(endDate).format('YYYY-MM-DD');
            } else {
                startDates = moment().startOf('month').format('YYYY-MM-DD');
                endDates = moment().format('YYYY-MM-DD');
            }

            setGetDates([startDates, endDates]);

            let urlSubString = '';
            if (loc) {
                urlSubString = '&locationId=' + loc;
            }

            // get analytics summary
            setSummaryLoading(true);
            if (apps) {
                const url = '/v2/analytics/summary?appId=' + apps + '&startDate=' + startDates + '&endDate=' + endDates + urlSubString;
                axiosInstance.get(url).then((response) => {
                    if (response.status === 200) {
                        setAnalyticsDataSummary(response.data);
                        setTimeout(() => {
                            setSummaryLoading(false);
                            setIsLoading(false);

                        }, 500);
                    } else {
                        setAnalyticsDataSummary([]);
                        window.location = '/error';
                        setIsLoading(false);
                        setSummaryLoading(false);

                    }
                }).catch(err => {
                    setAnalyticsDataSummary([]);
                    errorHandler(err, setSessionModal, setShowToast, toast, "/error");
                    setIsLoading(false);
                    setSummaryLoading(false);


                });
            }
            else {
                setAnalyticsDataSummary([]);
                setIsLoading(false);
                setSummaryLoading(false);
            }
        };



        getAnalyticsSummary();
    }, [apps, startDate, endDate, metric, loc, locate]);


    // get analytics data by loc
    useEffect(() => {
        const getAnalyticsDataByLoc = async () => {
            // get date range
            let startDates, endDates, url;
            if (startDate !== null && endDate !== null) {
                startDates = moment(startDate).format('YYYY-MM-DD');
                endDates = moment(endDate).format('YYYY-MM-DD');
            } else if (startDate !== null && endDate === null) {
                startDates = moment(startDate).format('YYYY-MM-DD');
                endDates = moment().format('YYYY-MM-DD');
            } else if (startDate === null && endDate !== null) {
                startDates = moment().startOf('month').format('YYYY-MM-DD');
                endDates = moment(endDate).format('YYYY-MM-DD');
            } else {
                startDates = moment().startOf('month').format('YYYY-MM-DD');
                endDates = moment().format('YYYY-MM-DD');
            }

            if (apps) {
                url = '/v2/analytics/details-by-loc?appId=' + apps + '&startDate=' + startDates + '&endDate=' + endDates;
                console.log(url);
            }

            if (location.length === 0) {
                setIsLoading(false);

                return; // Exit early if location is not yet available
            }

            else {
                const locationIds = location.map(obj => obj.id);
                url = '/v2/analytics/details-by-loc?startDate=' + startDates + '&endDate=' + endDates + '&location=' + locationIds;
                console.log(url);
            }
            setIsLoading(false);
            setLocLoading(true);
            axiosInstance.get(url).then((response) => {
                if (response.status === 200) {
                    if (apps) {
                        setAnalyticsDataDetailsLoc(Array(response.data));

                    }
                    else {
                        setAnalyticsDataDetailsLocs(Array(response.data));
                        setTimeout(() => {
                            setLocLoading(false);
                        }, 500);
                    }
                    setLocLoading(false);
                } else {
                    apps ? setAnalyticsDataDetailsLoc([]) : setAnalyticsDataDetailsLocs([]);
                    window.location = '/error';
                    setLocLoading(false);
                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast, "/error");
                setLocLoading(false);

            });
        }
        getAnalyticsDataByLoc();
    }, [apps, startDate, endDate, dates, metric, location]);



    // get analytics data by loc
    useEffect(() => {
        const getAnalyticsDataByLocs = async () => {
            // get date range
            let startDates, endDates;
            if (startDate !== null && endDate !== null) {
                startDates = moment(startDate).format('YYYY-MM-DD');
                endDates = moment(endDate).format('YYYY-MM-DD');
            } else if (startDate !== null && endDate === null) {
                startDates = moment(startDate).format('YYYY-MM-DD');
                endDates = moment().format('YYYY-MM-DD');
            } else if (startDate === null && endDate !== null) {
                startDates = moment().startOf('month').format('YYYY-MM-DD');
                endDates = moment(endDate).format('YYYY-MM-DD');
            } else {
                startDates = moment().startOf('month').format('YYYY-MM-DD');
                endDates = moment().format('YYYY-MM-DD');
            }

            // const dts = moment(dates, 'DD-MM-YYYY').format('YYYY-MM-DD');

            // if (dates && (startDate.isAfter(dts) || endDate.isBefore(dts))){
            //     setDates(null);
            // };
            setIsLoading(false);

            if (apps) {
                let urlLoc;
                const dt = moment(dates, 'DD-MM-YYYY').format('YYYY-MM-DD');
                // console.log(dt, 'dt')
                if (dates) {
                    urlLoc = '/v2/analytics/details-by-loc-date?appId=' + apps + '&fixedDate=' + dt;
                } else {
                    urlLoc = '/v2/analytics/details-by-loc?appId=' + apps + '&startDate=' + startDates + '&endDate=' + endDates;
                }
                // console.log(urlLoc)
                setLocLoading(true);
                axiosInstance.get(urlLoc).then((response) => {
                    if (response.status === 200) {
                        setAnalyticsDataDetailsLocs(Array(response.data));
                        setIsLoading(false);
                        setTimeout(() => {
                            setLocLoading(false);
                        }, 500);
                    } else {
                        setAnalyticsDataDetailsLocs([]);
                        window.location = '/error';
                        setIsLoading(false);
                    };
                }).catch(err => {
                    errorHandler(err, setSessionModal, setShowToast, toast, "/error");
                });
            }
        };
        getAnalyticsDataByLocs();
    }, [apps, startDate, endDate, dates, metric]);



    // get analytics data by date
    useEffect(() => {
        const getAnalyticsDataByDate = async () => {
            // get date range
            let startDates, endDates;
            if (startDate !== null && endDate !== null) {
                startDates = moment(startDate).format('YYYY-MM-DD');
                endDates = moment(endDate).format('YYYY-MM-DD');
            } else if (startDate !== null && endDate === null) {
                startDates = moment(startDate).format('YYYY-MM-DD');
                endDates = moment().format('YYYY-MM-DD');
            } else if (startDate === null && endDate !== null) {
                startDates = moment().startOf('month').format('YYYY-MM-DD');
                endDates = moment(endDate).format('YYYY-MM-DD');
            } else {
                startDates = moment().startOf('month').format('YYYY-MM-DD');
                endDates = moment().format('YYYY-MM-DD');
            }

            let urlSubString = '';
            if (loc) {
                urlSubString = '&locationId=' + loc;
            }
            setIsLoading(false);

            if (apps) {
                const urlDate = '/v2/analytics/details-by-date?appId=' + apps + '&startDate=' + startDates + '&endDate=' + endDates + urlSubString;
                console.log(urlDate);
                axiosInstance.get(urlDate).then((response) => {
                    if (response.status === 200) {
                        setAnalyticsDataDetailsDate(Array(response.data));
                    } else {
                        setAnalyticsDataDetailsDate([]);
                        window.location = '/error';
                    };
                }).catch(err => {
                    errorHandler(err, setSessionModal, setShowToast, toast, "/error");
                });
            }
        };
        getAnalyticsDataByDate();
    }, [apps, startDate, endDate, loc, locate, metric, dates]);


    // get analytics data by date
    useEffect(() => {
        const getAnalyticsDataByDates = async () => {
            // get date range
            let startDates, endDates;
            if (startDate !== null && endDate !== null) {
                startDates = moment(startDate).format('YYYY-MM-DD');
                endDates = moment(endDate).format('YYYY-MM-DD');
            } else if (startDate !== null && endDate === null) {
                startDates = moment(startDate).format('YYYY-MM-DD');
                endDates = moment().format('YYYY-MM-DD');
            } else if (startDate === null && endDate !== null) {
                startDates = moment().startOf('month').format('YYYY-MM-DD');
                endDates = moment(endDate).format('YYYY-MM-DD');
            } else {
                startDates = moment().startOf('month').format('YYYY-MM-DD');
                endDates = moment().format('YYYY-MM-DD');
            }

            let urlSubString = '';
            if (locate !== '' && locate) {
                urlSubString = '&locationId=' + locate;
            } else if (loc) {
                urlSubString = '&locationId=' + loc;
            }
            setIsLoading(false);

            if (apps) {
                const urlDate = '/v2/analytics/details-by-date?appId=' + apps + '&startDate=' + startDates + '&endDate=' + endDates + urlSubString;
                console.log(urlDate)
                setDateLoading(true);
                axiosInstance.get(urlDate).then((response) => {
                    if (response.status === 200) {
                        setAnalyticsDataDetailsDates(Array(response.data));
                        setTimeout(() => {
                            setDateLoading(false);
                        }, 1000);
                    } else {
                        setAnalyticsDataDetailsDates([]);
                        setDateLoading(false);

                        window.location = '/error';
                    };
                }).catch(err => {
                    setDateLoading(false);

                    errorHandler(err, setSessionModal, setShowToast, toast, "/error");
                });
            }
        };
        getAnalyticsDataByDates();
    }, [apps, startDate, endDate, loc, locate, metric]);


    // logs out a user
    const handleLogout = async () => {
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location = "/";
    };

    // handle toast close
    const handleCloseToast = () => {
        setShowToast(false);
    };

    // handle start date reset
    const handleStartDateReset = () => {
        setStartDate(moment().startOf('month'));
        setEndDate(moment());
        setDates(null);
    };

    // handle end date reset
    const handleEndDateReset = () => {
        setEndDate(moment());
        setDates(null);
    };

    // handle show email
    const handleShowEmail = () => {
        setShowEmail(true);
    };

    // handle download pdf
    const handlePDFDownload = async () => {
        setPdfLoading(true);
        // get date range
        let startDates, endDates;
        if (startDate !== null && endDate !== null) {
            startDates = moment(startDate).format('YYYY-MM-DD');
            endDates = moment(endDate).format('YYYY-MM-DD');
        } else if (startDate !== null && endDate === null) {
            startDates = moment(startDate).format('YYYY-MM-DD');
            endDates = moment().format('YYYY-MM-DD');
        } else if (startDate === null && endDate !== null) {
            startDates = moment().startOf('month').format('YYYY-MM-DD');
            endDates = moment(endDate).format('YYYY-MM-DD');
        } else {
            startDates = moment().startOf('month').format('YYYY-MM-DD');
            endDates = moment().format('YYYY-MM-DD');
        }

        // get app name
        let applist = [];
        const userData = JSON.parse(localStorage.getItem('user'));
        if (userData.apps.length > 0) {
            applist = userData.apps;
        } else {
            applist = [];
        }

        if (apps) {
            const appData = applist.find(item => item.id === apps);
            const appName = appData.name;
            const apiUrl = '/v2/analytics/download-pdf?appId=' + apps + '&startDate=' + startDates + '&endDate=' + endDates;
            try {
                const response = await axiosInstance.get(apiUrl, { responseType: 'arraybuffer' });
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = 'analytics report for ' + String(appName) + ' from ' + String(startDates) + ' to ' + String(endDates) + '.pdf'
                link.click();
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Error downloading PDF:', error);
            }
        }
        setPdfLoading(false);
    };

    // handle clear date
    const handleDateClear = () => {
        setDates(null);
        //setLocate(null);
        setLocName(null);
    };

    // handle clear location
    const handleLocClear = () => {
        //setDates(null);
        setLocate(null);
        setLoc(null);
        setLocName(null);
    };




    const exportInternalAnalytics = () => {
        setCsvLoading(true);
        if (analyticsDataDetailsLocs.length === 0) {
            setCsvLoading(false);
            return;
        }
        let stDate = moment(startDate).format('YYYY-MM-DD');
        let enDate = moment(endDate).format('YYYY-MM-DD');
        const data = analyticsDataDetailsLocs[0];
        const headers = Object.keys(data[0]);
        let csv = headers.join(',') + '\n';

        // Iterating over each object in the array
        data.forEach(obj => {
            // Extracting values from each object based on the headers
            const values = headers.map(header => {
                // Handling cases where the value might contain commas
                let value = obj[header];
                if (typeof value === 'string' && value.includes(',')) {
                    value = `"${value}"`; // Wrapping value in double quotes to maintain CSV format
                }
                return value;
            });
            // Joining values to form a row and appending it to the CSV string
            csv += values.join(',') + '\n';
        });

        const url = window.URL.createObjectURL(new Blob([csv]));
        const link = document.createElement("a");
        link.href = url;
        const fileName = 'All Apps Data from ' + String(stDate) + ' to ' + String(enDate) + '.csv'
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        setCsvLoading(false);
    };


    return (
        <div> {location.length > 0 || applist.length > 1 ?

            <div>
                <a href="/analytics" className="btn btn-sm rounded-pill btn-secondary" style={{ marginLeft: '30px' }}>Analytics</a>
                <div className="container-fluid">
                    {showToast && <div className="toast-overlay"></div>}
                    <ToastContainer onClose={handleCloseToast} />
                    <div>
                        <SessionExpiredModal
                            show={sessionModal}
                            handleLogout={handleLogout}
                        />
                    </div>
                    <div className="card">
                        <div className="card-header">
                            <h3>Analytics</h3>
                        </div>
                        {showEmail &&
                            <AnalyticsEmail emails={emails} setEmails={setEmails} apps={apps} singleEmail={singleEmail} setSingleEmail={setSingleEmail} showEmail={showEmail} setShowEmail={setShowEmail} />
                        }
                        {apps ?
                            <div className="card-body">
                                <div className="row">
                                    <button className="btn btn-sm btn-primary rounded-pil" style={{ width: '150px' }} onClick={handleShowEmail}>Monthly Report  <FontAwesomeIcon icon={faEnvelopeCircleCheck} /></button>
                                    <AnalyticsCSVDownload appId={apps} startDate={startDate} endDate={endDate} csvLoading={csvLoading} setCsvLoading={setCsvLoading} />
                                    <button className="btn btn-sm btn-primary rounded-pil" style={{ width: '120px', marginLeft: '10px', border: "none" }} onClick={handlePDFDownload}>{pdfLoading ? <strong>Downloading...</strong> : <> Export PDF <FontAwesomeIcon icon={faFilePdf} /> </>}</button>
                                </div>
                            </div> : null
                        }
                    </div>
                    <div className="card" style={{ paddingLeft: '20px', position: 'sticky', top: '0', zIndex: '999', backgroundColor: isScrolling ? '#B3EAFF' : '#fff', height: '120px', marginTop: '15px' }}>
                        <div className="mb-3 row" style={{ marginTop: '10px' }}>
                            <div className='d-flex flex-wrap'>
                                <AppsDropDownComponent apps={apps} applist={applist} handleChange={handleChange} />
                                <LocationsDropDownComponent loc={loc} location={location} handleChange={handleChange} />
                                <div style={{ marginLeft: '20px' }}>
                                    <DateTimeField
                                        startDate={startDate}
                                        endDate={endDate}
                                        setStartDate={setStartDate}
                                        setEndDate={setEndDate}
                                    />
                                    <button onClick={handleStartDateReset} className="btn btn-sm btn-secondary" style={{ marginTop: '5px', marginLeft: '20px' }}>
                                        <i className='bx bx-reset'></i> Reset Start Date
                                    </button>
                                    <button onClick={handleEndDateReset} className="btn btn-sm btn-secondary" style={{ marginTop: '5px', marginLeft: '20px' }}>
                                        <i className='bx bx-reset'></i> Reset End Date
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {apps &&
                        <div className="card">
                            <div className="alert alert-primary" role="alert" style={{ position: 'sticky', top: '120px', zIndex: '1' }}>
                                <strong>Showing Analytics from {getDates[0]} to {getDates[1]}</strong>
                                {locate ?
                                    <button className="btn btn-sm rounded-pill btn-secondary" style={{ float: 'right', color: 'white', marginLeft: '5px', width: '100px' }} onClick={handleLocClear}><i className='bx bx-reset'></i> Location</button>
                                    : null}
                                {dates ?
                                    <button className="btn btn-sm rounded-pill btn-secondary" style={{ float: 'right', color: 'white', marginLeft: '5px', width: '100px' }} onClick={handleDateClear}><i className='bx bx-reset'></i> Date</button>
                                    : null}
                            </div>
                            <div className="card">
                                {isLoading && analyticsDataDetailsLoc.length === 0 && analyticsDataDetailsDate.length === 0 ?
                                    <Loading /> :
                                    <div className="row" style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>

                                        <div className="card-body" style={{ marginTop: '10px' }}>
                                            <AnalyticsSummaryView analyticsDataSummary={analyticsDataSummary} summaryLoading={summaryLoading} />
                                            <div className="card-header">
                                                <h3 style={{ color: '#1E2D47', textAlign: 'center' }}>Insight Summary</h3>
                                            </div>
                                            {loc ?
                                                <AnalyticsTopDate analyticsDataDetailsDate={analyticsDataDetailsDate} topAnalyticDataDate={topAnalyticDataDate} setTopAnalyticDataDate={setTopAnalyticDataDate} insightDateLoading={insightDateLoading} setInsightDateLoading={setInsightDateLoading} /> :
                                                <div>
                                                    <AnalyticsTopLoc analyticsDataDetailsLoc={analyticsDataDetailsLoc} topAnalyticData={topAnalyticData} setTopAnalyticData={setTopAnalyticData} locate={locate} setLocate={setLocate} insightLocLoading={insightLocLoading} setInsightLocLoading={setInsightLocLoading} />
                                                    <AnalyticsTopDate analyticsDataDetailsDate={analyticsDataDetailsDate} topAnalyticDataDate={topAnalyticDataDate} setTopAnalyticDataDate={setTopAnalyticDataDate} insightDateLoading={insightDateLoading} setInsightDateLoading={setInsightDateLoading} />
                                                </div>
                                            }
                                            <AnalyticsDropDown analyticsDataSummary={analyticsDataSummary} analyticsDataDetailsLoc={analyticsDataDetailsLoc} analyticsDataDetailsDate={analyticsDataDetailsDate} metric={metric} handleChange={handleChange} />
                                            <div className="card-header">
                                                <h5 className="alert alert-info" style={{ color: '#1E2D47', textAlign: 'center' }}>Select a date from graph to view it's associated data and select a location from table to view it's associated graph!</h5>
                                            </div>
                                            <div>
                                                {analyticsDataDetailsLoc.map((item) =>
                                                (Object.keys(item).map((key) => (
                                                    key === metric ?
                                                        <div className="card">
                                                            {item[key].length > 0 ?
                                                                null :
                                                                <div className="alert alert-primary" role="alert">
                                                                    <h5 className="alert alert-primary" role="alert" style={{ textAlign: 'center' }}>No data found for {metric} from {getDates[0]} to {getDates[1]}</h5>
                                                                </div>
                                                            }
                                                        </div> : null
                                                ))
                                                ))}
                                                {/* {
                                                    loc ?
                                                        <div className="card" style={{ display:'flex', flexDirection:'column',justifyContent:'center'}}>
                                                            <AnalyticsDate analyticsDataDetailsDates={analyticsDataDetailsDates} metric={metric} dates={dates} setDates={setDates} dateLoading={dateLoading} locate={locate} setLocate={setLocate} analyticsDataDetailsLocs={analyticsDataDetailsLocs} locName={locName} setLocName={setLocName} />
                                                        </div> :
                                                        <div className="card" style={{ display:'flex', flexDirection:'column',justifyContent:'center'}}>
                                                            <AnalyticsDate analyticsDataDetailsDates={analyticsDataDetailsDates} metric={metric} dates={dates} setDates={setDates} dateLoading={dateLoading} locate={locate} setLocate={setLocate} analyticsDataDetailsLocs={analyticsDataDetailsLocs} locName={locName} setLocName={setLocName} />
                                                            <AnalyticsLoc analyticsDataDetailsLocs={analyticsDataDetailsLocs} metric={metric} locate={locate} setLocate={setLocate} locLoading={locLoading} dates={dates} setDates={setDates} locName={locName} setLocName={setLocName} />
                                                        </div>
                                                    } */}
                                                <div className="card" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                                    <AnalyticsDate analyticsDataDetailsDates={analyticsDataDetailsDates} metric={metric} dates={dates} setDates={setDates} dateLoading={dateLoading} locate={locate} setLocate={setLocate} analyticsDataDetailsLocs={analyticsDataDetailsLocs} locName={locName} setLocName={setLocName} />
                                                    <AnalyticsLoc analyticsDataDetailsLocs={analyticsDataDetailsLocs} metric={metric} locate={locate} setLocate={setLocate} locLoading={locLoading} dates={dates} setDates={setDates} locName={locName} setLocName={setLocName} loc={loc} setLoc={setLoc} startDate={startDate} endDate={endDate} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>


                        </div>
                    }
                    {
                        !apps && role === 'administrator' && <div className="card" style={{ marginTop: '10px' }}>
                            {locLoading && analyticsDataDetailsLocs.length < 1 ?
                                <Loading /> :
                                <div className="table-responsive text-nowrap">
                                    <div className="card-header">
                                        <button className="btn-sm btn-rounded-pill" onClick={exportInternalAnalytics} style={{ width: '150px', height: '60px', marginLeft: '10px', border: "none", backgroundColor: 'grey', }}>
                                            {csvLoading ? <strong style={{ color: 'white' }}>Downloading...</strong> : (
                                                <>
                                                    <strong style={{ color: 'white' }}>Export</strong>
                                                    <FontAwesomeIcon
                                                        icon={faFileCsv}
                                                        color="white"
                                                    />
                                                </>
                                            )}
                                        </button>
                                    </div>
                                    <p>Click the column headers to sort</p>
                                    {analyticsDataDetailsLocs[0] && analyticsDataDetailsLocs[0].length > 0 && <SortableAnalyticsTable analyticsDataDetailsLocs={analyticsDataDetailsLocs} />}
                                </div>
                            }
                        </div>
                    }
                    {
                        !apps && role !== 'administrator' && <div className="card" style={{ marginTop: '10px' }}>
                            <div className="card-body alert">
                                <h3 style={{ textAlign: "center", color: '#000' }}><strong>Select an App to view Analytics</strong></h3>
                            </div>
                        </div>
                    }
                </div>
            </div> : < Loading />}
        </div>
    );
}


export default Analytics;
