import React from "react";
import { useEffect, useState } from 'react';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SessionExpiredModal from '../SessionExpire/SessionExpiredModal';
import { errorHandler } from "../Utils/errorHandler";
import { appData } from "../../apis/AppsAPI";
import { locationData } from "../../apis/LocationAPI";
import { useLocation } from "react-router-dom";
import { saveRewardList } from "../../apis/RewardsAPI";
import AddUpdateLoading from "../Utils/AddUpdateLoading";
import { v4 as uuidv4 } from 'uuid';


const AddRewards = () => {
    const search = useLocation().search;
    const queryParams = new URLSearchParams(search);
    const app = queryParams.get('app_id') ? parseInt(queryParams.get('app_id')) : null;
    const locs = queryParams.get('loc_id') ? parseInt(queryParams.get('loc_id')) : null;
    const [submitting, setSubmitting] = useState(false);
    const [applist, setApplist] = useState([]);
    const [location, setLocation] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [sessionModal, setSessionModal] = useState(false);
    const [backUrl] = useState("/rewards" + window.location.search);
    const [apps, setApps] = useState(app);
    const [loc, setLoc] = useState(locs);
    const [activateDmsMapping, setActivateDmsMapping] = useState('');
    const [points, setPoints] = useState(null);
    const [kind, setKind] = useState(null);
    const [dmsPointType, setDmsPointType] = useState(null);
    const [canOnlyRedeemOnce, setCanOnlyRedeemOnce] = useState(null);
    const [qrCodeValue, setQrCodeValue] = useState(null);
    const [expiresAt, setExpiresAt] = useState(null);
    const [rewardInputFile, setRewardInputFile] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [expiredNumberofDays, setExpiredNumberofDays] = useState(null);
    const [role, setRole] = useState(null);
    const [loading, setLoading] = useState(false);


    // load all apps
    useEffect(() => {
        const apps = appData();
        if (apps.length > 0) {
            setApplist(apps);
        }
        else {
            setApplist([]);
        }
    }, []);


    // load all locations by app
    useEffect(() => {
        if (apps) {
            locationData(apps).then((response) => {
                if (response.status === 200) {
                    setLocation(response.data);

                } else {
                    setLocation([]);
                    // window.location = '/error';
                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        } else {
            setLocation([]);
        }
    }, [apps]);



    // handle user input
    const handleChange = (event) => {
        const name = event.target.name;
        let value;
        let filen;
        if (event.target.type === 'select-one') {
            value = document.getElementById(name).value;
            const selectedIndex = event.target.selectedIndex;

            if (name === "app_id") {
                if (selectedIndex === 0) {
                    setLocation([]);
                }
                setApps(value);
            }
            if (name === "location_id") {
                if (selectedIndex === 0) {
                    setLoc(null);
                }
                setLoc(value);
            }
            if (name === "kind") {
                if (selectedIndex === 0) {
                    setKind(null);
                }
                setKind(value);
            }
            if (name === "can_only_redeem_once") {
                if (selectedIndex === 0) {
                    setCanOnlyRedeemOnce(null);
                }
                setCanOnlyRedeemOnce(parseInt(value));
            }
            if (name === "dms_point_type") {
                if (selectedIndex === 0) {
                    setDmsPointType(null);
                }
                setDmsPointType(value);
                if (parseInt(value) === 2 || value === '2') {
                    setPoints(null);
                }
            }
            if (name === "activate_dms_mapping") {
                if (selectedIndex === 0) {
                    setActivateDmsMapping(null);
                }
                setActivateDmsMapping(parseInt(value));
                if (parseInt(value) === 0 || value === '0') {
                    setExpiredNumberofDays(null);
                }
            }
        }
        else if (event.target.type === 'file') {
            filen = event.target.files[0];
            const maxSize = 1024 * 1024;
            const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg', 'svgz', 'tif', 'tiff', 'heif', 'cr3', 'arw', 'orf', 'pef', 'ref', 'sr2', 'dng'];




            if (filen.size > maxSize) {
                setShowToast(true);
                toast.error(`File size should be less than 1 MB`, { position: toast.POSITION.TOP_CENTER, autoClose: false, hideProgressBar: true });
                return false;
            } else if (!allowedExtensions.includes(filen.name.split('.').pop().toLowerCase())) {
                setShowToast(true);
                toast.error(`file type should be jpg, jpeg, png, gif, bmp, webp`, { position: toast.POSITION.TOP_CENTER, autoClose: false, hideProgressBar: true });
                return false;
            }
            else {
                setRewardInputFile(filen);
            }
        }
        else {
            value = event.target.value;
            if (name === "title") {
                setTitle(value);
            }
            if (name === "description") {
                setDescription(value);
            }
            if (name === "points") {
                if (dmsPointType === '2' || dmsPointType === 2) {
                    setPoints(null);
                } else {
                    setPoints(value);
                }
            }
            if (name === "expires_at") {
                setExpiresAt(value);
            }
            if (name === "expired_number_of_date") {
                if (activateDmsMapping === '1' || activateDmsMapping === 1) {
                    if (value === '') {
                        setExpiredNumberofDays(null);
                    } else if (parseInt(value) < 0) {
                        setExpiredNumberofDays(null);
                    } else if (typeof parseInt(value) !== 'number') {
                        setExpiredNumberofDays(null);
                    } else {
                        setExpiredNumberofDays(value);
                    }
                } else {
                    setExpiredNumberofDays(null);
                }
            }
        }
    };


    console.log(canOnlyRedeemOnce, 'canOnlyRedeemOnce');
    console.log(expiredNumberofDays, 'expiredNumberofDays');

    // load all apps
    useEffect(() => {
        const apps = appData();
        if (apps.length > 0) {
            setApplist(apps);
        }
        else {
            setApplist([]);
        }
    }, []);


    // generate qr code value
    const generateQRCode = () => {
        return new Promise((resolve) => {
            const qrCode = uuidv4();
            setQrCodeValue(qrCode);
            resolve(qrCode);
        });
    };


    // get user role
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            setRole(user.role);
        }
    }, []);

    console.log(role, 'role');
    console.log(apps, 'apps');
    console.log(loc, 'loc');
    console.log(location, 'location');

    console.log(window.location.search, 'window.location.search');
    // load all locations by app
    useEffect(() => {
        if (apps) {
            locationData(apps).then((response) => {
                if (response.status === 200) {
                    console.log(response.data);
                    let data = [];
                    response.data.map((loc, index) => {
                        return data.push({ value: loc.id, label: loc.name });
                    });
                    setLocation(data);
                } else {
                    setLocation([]);
                    // window.location = '/error';
                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        } else {
            setLocation([]);
        }
    }, [apps]);


    // handles form submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        const qrCode = await generateQRCode();
        setSubmitting(true);
        setLoading(true);
        const formData = new FormData();
        formData.append('location_id', loc);
        formData.append('kind', kind);
        formData.append('title', title);
        formData.append('description', description);
        formData.append('points', points);
        formData.append('can_only_redeem_once', canOnlyRedeemOnce);
        formData.append('dms_point_type', dmsPointType);
        formData.append('activate_dms_mapping', activateDmsMapping);
        formData.append('expires_at', expiresAt);
        formData.append('reward_input_file', rewardInputFile);
        formData.append('qr_code_value', qrCode);
        formData.append('expired_number_of_date', expiredNumberofDays);

        await saveRewardList(formData).then((res) => {
            if (res.status === 201 && res.data.affectedRows > 0) {
                setShowToast(true);
                toast.success(`Successfully Saved ${kind}`, {
                    position: toast.POSITION.TOP_CENTER, autoClose: 3000, hideProgressBar: false,
                    onClose: () => {
                        window.location.reload();
                    }
                });
            } else {
                setShowToast(true);
                toast.error(`Failed to save!`, { position: toast.POSITION.TOP_CENTER, autoClose: false, hideProgressBar: true });
            }
        }).catch(err => {
            errorHandler(err, setSessionModal, setShowToast, toast);
        });
        setSubmitting(false);
        setLoading(false);
    }


    // logs out a user
    const handleLogout = async () => {
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location = "/";
    };

    // handle toast close
    const handleCloseToast = () => {
        setShowToast(false);
    };


    console.log(qrCodeValue);

    return (
        <div className="col-xl-12">
            {submitting &&
                <div>Submtting Form...</div>
            }
            {showToast && <div className="toast-overlay"></div>}
            <ToastContainer onClose={handleCloseToast} />
            <a href={backUrl} className="btn btn-sm rounded-pill btn-secondary">Back</a>  <a href={backUrl} className="btn btn-sm rounded-pill btn-secondary">Rewards Home</a>
            <SessionExpiredModal
                show={sessionModal}
                handleLogout={handleLogout}
            />
            <form onSubmit={handleSubmit} >
                <div className="card mb-4">
                    <h5 className="card-header">Adding New Reward or Task</h5>
                    {loading ? <AddUpdateLoading show={loading} /> :
                        <div className="card-body">
                            <div className="mb-3 row">
                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">App<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                <div className="col-md-10">
                                    <select className="form-select" id="app_id" name="app_id" onChange={handleChange} value={apps || ''} >
                                        <option key='' value="">--Select Apps--</option>
                                        {applist && applist.length > 0 && applist.map((appObj) => (
                                            <option key={appObj.id} value={appObj.id}>{appObj.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Location<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                <div className="col-md-10">
                                    <select className="form-select" id="location_id" name="location_id" onChange={handleChange} value={loc || ''} required>
                                        <option key="" value="">--Select Location--</option>
                                        {location && location.length > 0 && location.map((locationObj) => (
                                            <option key={locationObj.value ? locationObj.value : locationObj.id} value={locationObj.value ? locationObj.value : locationObj.id}>{locationObj.label ? locationObj.label : locationObj.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Kind<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                <div className="col-md-10">
                                    <select className="form-select" id="kind" name="kind" onChange={handleChange} value={kind || ''} required>
                                        <option key="" value="">--Select Kind--</option>
                                        <option key="reward" value="reward">Reward</option>
                                        <option key="task" value="task">Task</option>
                                    </select>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Title<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                <div className="col-md-10">
                                    <input className="form-control" type="text" name="title" value={title || ''} onChange={handleChange} required />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Description<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                <div className="col-md-10">
                                    <input className="form-control" type="text" name="description" value={description || ''} onChange={handleChange} required />
                                </div>
                            </div>
                            {dmsPointType === '2' || dmsPointType === 2 ?
                                <div className="mb-3 row">
                                    <label htmlFor="html5-email-input" className="col-md-2 col-form-label">Points<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                    <div className="col-md-10">
                                        <input className="form-control" type="number" name="points" value={points || ''} onChange={handleChange} disabled />
                                    </div>
                                </div>
                                :
                                <div className="mb-3 row">
                                    <label htmlFor="html5-email-input" className="col-md-2 col-form-label">Points<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                    <div className="col-md-10">
                                        <input className="form-control" type="number" name="points" value={points || ''} onChange={handleChange} />
                                    </div>
                                </div>
                            }
                            <div className="mb-3 row">
                                <label htmlFor="html5-email-input" className="col-md-2 col-form-label">One Time<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                <div className="col-md-10">
                                    <select className="form-select" id="can_only_redeem_once" name="can_only_redeem_once" onChange={handleChange} value={String(canOnlyRedeemOnce) || ''} required>
                                        <option key="" value="">--Select One Time--</option>
                                        <option key="yes" value="1">Yes</option>
                                        <option key="no" value="0">No</option>
                                    </select>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-md-2 col-form-label">Preview</label>
                                <div className="col-md-10">
                                    <span >
                                        <img src={rewardInputFile ? URL.createObjectURL(rewardInputFile) : "no-image-found.gif"} height="200" width="200" alt="Reward/Task Image" />
                                    </span>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="html5-password-input" className="col-md-2 col-form-label">Image</label>
                                <div className="mb-3 col-md-10">
                                    <label htmlFor="formFile" className="form-label">Upload image</label>
                                    <input className="form-control" type="file" id="reward_input_file" name="reward_input_file" onChange={handleChange} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Expiration Date<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                <div className="col-md-10">
                                    <input className="form-control" type="datetime-local" name="expires_at" value={expiresAt || ''} onChange={handleChange} />
                                </div>
                            </div>

                            <div className="mb-3 row">
                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">DMS Point Type</label>
                                <div className="col-md-10">
                                    <select className="form-select" id="dms_point_type" name="dms_point_type" onChange={handleChange} value={dmsPointType || ''}>
                                        <option key="" value="">--Select DMS Point Type--</option>
                                        <option key="vehicle" value="1">Automated New Vehicle Points </option>
                                        <option key="service" value="2">Automated Service and Parts points</option>
                                    </select>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Activate DMS Mapping</label>
                                <div className="col-md-10">
                                    <select className="form-select" id="activate_dms_mapping" name="activate_dms_mapping" onChange={handleChange} value={String(activateDmsMapping) || ''}>
                                        <option key="" value="">--Activate DMS Mapping--</option>
                                        <option key="active" value="1">Active</option>
                                        <option key="inactive" value="0">Inactive</option>
                                    </select>
                                </div>
                            </div>
                            {
                                activateDmsMapping === '1' || activateDmsMapping === 1 ?
                                    <div className="mb-3 row">
                                        <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Expires In (Days)</label>
                                        <div className="col-md-10">
                                            <input className="form-control" type="number" name="expired_number_of_date" value={expiredNumberofDays || ''} onChange={handleChange} />
                                        </div>
                                    </div>
                                    : null
                            }

                        </div>
                    }
                    <div className="mb-3 row">
                        <div className="col-md-3">&nbsp;</div>
                        <div className="col-md-7"><input type="submit" className="btn btn-primary col-lg-10" value="Save" /></div>
                        <div className="col-md-2">&nbsp;</div>
                    </div>
                </div>
            </form>
        </div>
    );
}



export default AddRewards;
