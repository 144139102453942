import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { errorHandler } from "../Utils/errorHandler";
import { locationData } from "../../apis/LocationAPI";
import PaginationComponent from "../Pagination/Pagination";
import Loading from "../Utils/Loading";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { geoMessages, geoMessagesCount, deletePushMessage } from "../../apis/CommunicationAPI";
import ReadMore from "../Utils/ReadMore";
import ModuleHeader from "../Header/ModuleHeader";


const GeoPushMessages = () => {
    const userData = JSON.parse(localStorage.getItem('user'));
    let aplist = [];
    if (userData.apps) {
        aplist = userData.apps;
    }
    let endUserRole = userData?.role;

    const [numberOfPage, setNumberOfPage] = useState(0);
    const [numberOfRows, setNumberOfRows] = useState(0);
    const [del, setDel] = useState(false);
    const [location, setLocation] = useState([]);
    const [msgPerPage] = useState(30);
    const [isLoading, setIsLoading] = useState(true);
    const [sessionModal, setSessionModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [firstLoad, setFirstLoad] = useState(1);
    const [qryString, setQryString] = useState("");
    const search = useLocation().search;
    const queryParams = new URLSearchParams(search);
    const [timeoutid, setTimeoutid] = useState(null);
    const [userRole] = useState(endUserRole);

    const ap_id = queryParams.get('app_id');
    let app_id = null;
    if (ap_id) {
        app_id = parseInt(ap_id);
    } else {
        const clientUser = JSON.parse(localStorage.getItem('user'));
        if (clientUser?.apps?.length === 1) {
            app_id = parseInt(clientUser.apps[0]?.id);
        }
    }

    const locat_id = queryParams.get('loc_id');
    let loc_id = null;
    if (locat_id) {
        loc_id = parseInt(locat_id);
    }
    let searchstr = queryParams.get('searchkey');

    if (!searchstr) {
        searchstr = "";
    }

    const pag = queryParams.get('page');
    let pg = 1;

    if (pag) {
        pg = parseInt(pag);
    }

    const starDate = queryParams.get('start');
    console.log(starDate)
    let stDate = null;
    if (starDate) {
        stDate = moment(starDate);
    }

    const enDate = queryParams.get('end');
    let eDate = null;
    if (enDate) {
        eDate = moment(enDate);
    }

    const [apps, setApps] = useState(app_id);
    const [loc, setLoc] = useState(loc_id);
    const [searchkey, setSearchkey] = useState(searchstr);
    const [currentPage, setCurrentPage] = useState(pg);
    const [startDate, setStartDate] = useState(stDate);
    const [endDate, setEndDate] = useState(eDate);
    const [stMsg, setStMsg] = useState([]);
    const [applist, setApplist] = useState(aplist);

    console.log("aplist ", applist);

    let searchTitle = "Search for messages by subject";
    let buttonTitle = "Add New Geo Message";
    let buttonPath = `/communication/addgeopushmessage`;
    let dateSearchtitle = "Search for messages created within a date range";


    // watching search box
    function intervalexecute() {
        if (timeoutid) {
            clearTimeout(timeoutid);
        }
        const timeout = setTimeout(() => {
            //only first time current page will be what ever page user address pressed     
            let paramUpdate = true;
            setIsLoading(true);
            geoMessages(apps, loc, currentPage, searchkey, startDate, endDate, applist, msgPerPage, userRole).then((response) => {
                console.log("commonn load data");
                if (response.status === 200) {
                    setStMsg(response.data);
                    setIsLoading(false);
                } else {
                    setStMsg([]);
                    setIsLoading(false);
                    errorHandler("staff fetch " + response.status, setSessionModal, setShowToast, toast);
                }
            }).catch(err => {
                setIsLoading(false);
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
            // generate url in addressbar whenever any change take place
            if (paramUpdate) {
                updateQueryParams();
            }

            geoMessagesCount(apps, loc, searchkey, startDate, endDate, applist, userRole).then((responsex) => {
                if (responsex.status === 200) {
                    const rowNumberCount = responsex.data[0].count;
                    const pgCount = Math.ceil(rowNumberCount / msgPerPage);
                    setNumberOfPage(pgCount);
                    setNumberOfRows(rowNumberCount);
                } else {
                    setNumberOfPage(0);
                    setNumberOfRows(0);
                    errorHandler("staff count " + responsex.status, setSessionModal, setShowToast, toast);

                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        }, 2000);

        setTimeoutid(timeout);
    }

    useEffect(() => {
        if (firstLoad !== 1) { // first load not use search
            intervalexecute();
        } else {

            console.log("use effect searchkey");
        }

    }, [searchkey]);


    // load all locations by app
    useEffect(() => {
        if (apps) {
            // passing apps id to get location list
            locationData(apps).then((response) => {
                if (response.status === 200) {
                    setLocation(response.data);
                } else {
                    setLocation([]);
                    errorHandler("location " + response.status, setSessionModal, setShowToast, toast);

                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        } else {
            setLocation([]);
        }
    }, [apps]);

    // getting staff data count
    useEffect(() => {
        console.log("Staff count fired");
        geoMessagesCount(apps, loc, searchkey, startDate, endDate, applist, userRole).then((responsex) => {
            if (responsex.status === 200) {
                const rowNumberCount = responsex.data[0].count;
                const pgCount = Math.ceil(rowNumberCount / msgPerPage);
                setNumberOfPage(pgCount);
                // handle error  
                // if(currentPage>pgCount){
                //     setCurrentPage(1);
                // }
                setNumberOfRows(rowNumberCount);
            } else {
                setNumberOfPage(0);
                setNumberOfRows(0);
                errorHandler("staff count " + responsex.status, setSessionModal, setShowToast, toast);
            }
        }).catch(err => {
            errorHandler(err, setSessionModal, setShowToast, toast);
        });

    }, [apps, loc, startDate, endDate, applist, msgPerPage]);

    // getting staff data
    useEffect(() => {
        //only first time current page will be what ever page user address pressed     
        if (firstLoad === 1) {
            // not first time load each search set current page to 1
            setFirstLoad(0);
            // as it is firstload no need to do param update
            console.log("first time load");
        }
        setIsLoading(true);

        geoMessages(apps, loc, currentPage, searchkey, startDate, endDate, applist, msgPerPage, userRole).then((response) => {
            console.log("commonn load data");
            if (response.status === 200) {
                setStMsg(response.data);
                setIsLoading(false);
            } else {
                setStMsg([]);
                setIsLoading(false);
                errorHandler("staff fetch " + response.status, setSessionModal, setShowToast, toast);
            }
        }).catch(err => {
            setIsLoading(false);
            errorHandler(err, setSessionModal, setShowToast, toast);
        });
        // generate url in addressbar whenever any change take place
        updateQueryParams();

    }, [apps, loc, currentPage, startDate, endDate, applist, msgPerPage]);


    // Update the URL query params when the state of the hook changes
    const updateQueryParams = () => {
        console.log("addressbar update ..");
        const queryParams = new URLSearchParams();
        if (apps) queryParams.set('app_id', apps);
        if (loc) queryParams.set('loc_id', loc);
        if (searchkey) queryParams.set('searchkey', searchkey);
        if (startDate) queryParams.set('start', startDate.toISOString());
        if (endDate) queryParams.set('end', endDate.toISOString());
        if (currentPage) queryParams.set('page', currentPage);
        const queryString = queryParams.toString();
        const newUrl = `${window.location.pathname}?${queryString}`;
        window.history.pushState({ path: newUrl }, '', newUrl);
        // query string will be used for edit purpose
        setQryString(`?${queryString}`);
    };


    // handle inputs and change events
    const handleChange = async (event) => {
        console.log("handle fired");
        if (currentPage !== 1) {
            setCurrentPage(1);
        }
        let search = window.location.search;
        let params = new URLSearchParams(search);
        console.log(params);
        const name = event.target.name;
        let value;

        if (event.target.type === 'select-one') {
            let selectedIndex = event.target.selectedIndex;
            let tvalue = document.getElementById(name).value;
            // incase no value selected 
            if (selectedIndex === 0) {
                if (name === 'location_id') {
                    setLoc(null);
                } else if (name === 'app_id') {
                    setApps(null);
                    setLoc(null);
                    setLocation([]);
                }
            } else {
                // converting to number 
                if (name === 'location_id') {
                    value = +tvalue;
                    setLoc(value);
                } else if (name === 'app_id') {
                    value = +tvalue;
                    setApps(value);
                    locationData(value);
                    setLoc(null);
                }
            }
        } else {
            if (name === 'search_inputs') {
                setSearchkey(event.target.value);
            }
        }
    };


    // deleting a staff
    const deleteClicked = (e, stObj) => {
        e.preventDefault();
        if (window.confirm("Delete the message?")) {
            deletePushMessage(stObj.id).then((res) => {
                setDel(false);
                //    window.alert(res.status+" "+res.data.affectedRows );
                if (res.status === 201 && res.data.affectedRows > 0) {
                    setShowToast(true);
                    toast.success("Delete Successful", {
                        position: toast.POSITION.TOP_CENTER, hideProgressBar: false, autoClose: 1000,
                        onClose: () => {
                            window.location.reload();
                        }
                    });
                } else {
                    toast.error("Data not found!", { position: toast.POSITION.TOP_CENTER, hideProgressBar: false, autoClose: 1000 });
                }
            }).catch((err) => {
                setDel(false);
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        } else {
            console.log("You canceled!");
        }
    };



    // logs out a user
    const handleLogout = async () => {
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location = "/";
    };

    // handle toast close
    const handleCloseToast = () => {
        setShowToast(false);
    };


    return (
        <div>
            <a href={'/communication'} type="button" className="btn btn-sm  btn-secondary">Back</a><a href="/communication" className="btn btn-sm  btn-secondary">Communication Home</a><a href={'/communication/addgeopushmessage' + window.location.search} type="button" className="btn btn-sm btn-secondary">Add Geo Push Message</a>
            {del &&
                <div>Deleting Item...</div>
            }
            {showToast && <div className="toast-overlay"></div>}
            <ToastContainer onClose={handleCloseToast} />
            <ModuleHeader
                searchKey={searchkey}
                searchTitle={searchTitle}
                buttonTitle={buttonTitle}
                buttonPath={buttonPath}
                dateSearchTitle={dateSearchtitle}
                handleChange={handleChange}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                sessionModal={sessionModal}
                handleLogout={handleLogout}
                apps={apps}
                applist={applist}
                loc={loc}
                location={location}
                showDeptDropdown={false}
                showCreateNewButton={true} />

            <div className="alert alert-primary" role='alert'><strong>{numberOfRows} push messages found</strong></div>
            {isLoading ?
                <div>
                    <Loading />
                </div> : <div>
                    <div className="table-responsive text-nowrap">
                        <table className="table table-sm table-bordered">
                            <thead>
                                <tr>
                                    <th>App</th>
                                    <th>Label</th>
                                    <th>Subject</th>
                                    <th>Created</th>
                                    <th>Successful</th>
                                    <th>Expires</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody className="table-border-bottom-0">
                                {stMsg && stMsg.length > 0 && stMsg.map((spmObj) => (
                                    <tr key={spmObj.id}>
                                        <td>
                                            <i className="fab fa-angular fa-lg text-danger me-3"></i> <a href={`/communication/editgeopushmessage/${spmObj.id + window.location.search}`}> <strong>{spmObj.App ? spmObj.App : ''}</strong></a>
                                        </td>
                                        <td><a href={`/communication/editgeopushmessage/${spmObj.id + window.location.search}`}> {spmObj.Label ? spmObj.Label : ''} </a></td>
                                        <td><a href={`/communication/editgeopushmessage/${spmObj.id + window.location.search}`}><ReadMore>{spmObj.Subject ? spmObj.Subject : ''}</ReadMore></a></td>
                                        <td>{spmObj.Created ? spmObj.Created : ''}</td>
                                        <td>{spmObj.Sent ? spmObj.Sent : ''}</td>
                                        <td>{spmObj.Expires ? spmObj.Expires : ''}</td>
                                        <td>
                                            <div className="btn-group">
                                                <a href={`/communication/editgeopushmessage/${spmObj.id + window.location.search}`}>
                                                    <button className="btn btn-sm btn-outline-secondary"><i className='bx bxs-edit'></i></button>
                                                </a>
                                                <button className="btn btn-sm btn-outline-secondary" onClick={(e) => deleteClicked(e, spmObj)}><i className='bx bxs-trash'></i></button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="pagination-container" style={{ display: "flex", flexWrap: "wrap" }}>
                        <PaginationComponent
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            numberOfPage={numberOfPage}
                            setShowToast={setShowToast}
                            numVisiblePages={50}
                        />
                    </div>
                </div>}
        </div>
    );
};

export default GeoPushMessages;