import React from "react";

const ServerError = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <h1 style={{ fontSize: "5rem", margin: "2rem" }}>Something Went Wrong</h1>
      <h2 style={{ margin: "2rem" }}>Please Try Again</h2>
    </div>
  );
};

export default ServerError;