import { Button } from "react-bootstrap";
import * as XLSX from 'xlsx';

// utility for download sample format without dummy data
const DownloadDemoDataBlank = () => {
  const jsonData = [{
    "App": "",
    "Location": "",
    "First Name": "",
    "Last Name": "",
    "Department": "",
    "Title": "",
    "Email": "",
    "Phone": "",
    "Text Message Number": "",
    "Active Status": ""
  }];

  const data = JSON.parse(JSON.stringify(jsonData));

  const handleDownload = async () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "template.xlsx");
  };

  return (
    <div>
      <Button onClick={handleDownload}>Download Template</Button>
    </div>
  );
}


export default DownloadDemoDataBlank;