import React, { useState } from "react";

const ReadSubject = ({ children }) => {
    const text = JSON.stringify(children).replace(/['"]+/g, '');
    const [isReadMore, setIsReadMore] = useState(true);

    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    return (
        <>
            {text.length < 35 ? text :
                <p className="textR">
                    {isReadMore ? text.slice(0, 35) : text}
                    <span onClick={toggleReadMore} className="read-or-hide">
                        {isReadMore ? " ...show" : " ...less"}
                    </span>
                </p>
            }
        </>
    );
};


export default ReadSubject;