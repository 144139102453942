import React, { useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from "react";
import { useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { errorHandler } from "../Utils/errorHandler";
import { updateRewardForCustomer } from "../../apis/ProfilesAPI";
import AddUpdateLoading from "../Utils/AddUpdateLoading";


const EditRewardModal = ({ show, setShow, locationId, rewardId, rewardData }) => {
    const [inputs, setInputs] = useState([]);
    const { customerId } = useParams();
    const [loading, setLoading] = useState(false);


    const handleClose = () => {
        setShow(false);
    }


    // append customer id to inputs
    useEffect(() => {
        setInputs(inputs => ({ ...inputs, customer_id: customerId }));
    }, [customerId]);

    // append location id to inputs
    useEffect(() => {
        setInputs(inputs => ({ ...inputs, location_id: locationId }));
    }, [locationId]);


    // append reward id to inputs
    useEffect(() => {
        setInputs(inputs => ({ ...inputs, reward_id: rewardId }));
    }, [rewardId]);


    // append reward data to inputs
    useEffect(() => {
        if (rewardData && rewardId) {
            // get selected reward data by reward id
            const selectedReward = rewardData.filter((reward) => reward.id === rewardId);
            // console.log(selectedReward, 'selected reward');
            if (selectedReward.length > 0) {
                setInputs(inputs => ({ ...inputs, title: selectedReward[0].title }));
                setInputs(inputs => ({ ...inputs, description: selectedReward[0].description }));
                setInputs(inputs => ({ ...inputs, points: selectedReward[0].points }));
                setInputs(inputs => ({ ...inputs, loyalty_reward_or_task_id: "" }));
                setInputs(inputs => ({ ...inputs, expires: "" }));
            }
        }
    }, [rewardData, rewardId]);


    // console.log(rewardData, rewardId, 'inputs from edit reward modal');


    // handle change
    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }


    // update reward for customer
    const handleUpdateReward = (e) => {
        e.preventDefault();
        updateRewardForCustomer(inputs, rewardId)
            .then((res) => {
                if (res.status === 200 || res.status === 201) {
                    toast.success(`Reward updated successfully!`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        hideProgressBar: false,
                        onClose: () => {
                            setShow(false);
                            window.location.reload();
                        }
                    });
                }
            })
            .catch((err) => {
                errorHandler(err);
            });
    }


    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Edit Reward</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? <AddUpdateLoading /> :
                    <div className="row">
                        <div className="col-md-12">
                            <div>
                                <form onSubmit={handleUpdateReward}>
                                    <div className="form-group">
                                        <label htmlFor="title">Title</label>
                                        <input type="text" className="form-control" id="title" name="title" value={inputs.title || ''} onChange={handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="description">Description</label>
                                        <input type="text" className="form-control" id="description" name="description" value={inputs.description || ''} onChange={handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="points">Points</label>
                                        <input type="number" className="form-control" id="points" name="points" value={inputs.points || ''} onChange={handleChange} />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleUpdateReward}>
                    Update
                </Button>
            </Modal.Footer>
        </Modal>
    );
}


export default EditRewardModal;
