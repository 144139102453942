// export const errorHandler = (err, setSessionModal, setShowToast, toast) => {
//     if(err.response.data.message.message){
//           if(err.response.data.message.message === 'Invalid Token'){
//               setSessionModal(true);
//           }else{
//               setShowToast(true);
//               toast.error(`${err.response.data.message.message}`, { position: toast.POSITION.TOP_CENTER, autoClose:false, hideProgressBar:true});
//           }
//       }else{
//           if(err.response.data.message === 'Invalid Token'){
//               setSessionModal(true);
//           }else if(err.response.data.message){
//               setShowToast(true);
//               toast.error(`${err.response.data.message}`, { position: toast.POSITION.TOP_CENTER, autoClose:false, hideProgressBar:true});
//           } else if(err.response.data){
//             setShowToast(true);
//             toast.error(`${err.response.data}`, { position: toast.POSITION.TOP_CENTER, autoClose:false, hideProgressBar:true});

//           } else if(err.response){
//             setShowToast(true);
//             toast.error(`${err.response}`, { position: toast.POSITION.TOP_CENTER, autoClose:false, hideProgressBar:true});


//           } else if(err){
//             setShowToast(true);
//             toast.error(`${err}`, { position: toast.POSITION.TOP_CENTER, autoClose:false, hideProgressBar:true});
//           }

//       }
// };


export const errorHandler = (err, setSessionModal, setShowToast, toast) => {
    if (err) {
        if (err.response) {
            if (err.response.data) {
                if (err.response.data.message) {
                    if (err.response.data.message.message) {
                        if (err.response.data.message.message === 'Invalid Token') {
                            setSessionModal(true);
                        } else {
                            setShowToast(true);
                            toast.error(`${err.response.data.message.message}`, { position: toast.POSITION.TOP_CENTER, autoClose: false, hideProgressBar: true });
                        }
                    } else {
                        if (err.response.data.message === 'Invalid Token') {
                            setSessionModal(true);
                        } else if (err.response.data.message) {
                            setShowToast(true);
                            toast.error(`${err.response.data.message}`, { position: toast.POSITION.TOP_CENTER, autoClose: false, hideProgressBar: true });
                        } else if (err.response.data) {
                            setShowToast(true);
                            toast.error(`${err.response.data}`, { position: toast.POSITION.TOP_CENTER, autoClose: false, hideProgressBar: true });

                        } else if (err.response) {
                            setShowToast(true);
                            toast.error(`${err.response}`, { position: toast.POSITION.TOP_CENTER, autoClose: false, hideProgressBar: true });


                        } else if (err) {
                            setShowToast(true);
                            toast.error(`${err}`, { position: toast.POSITION.TOP_CENTER, autoClose: false, hideProgressBar: true });
                        }

                    }


                } else {
                    setShowToast(true);
                    toast.error(`${err.response}`, { position: toast.POSITION.TOP_CENTER, autoClose: false, hideProgressBar: true });


                }


            } else {

                setShowToast(true);
                toast.error(`${err.response}`, { position: toast.POSITION.TOP_CENTER, autoClose: false, hideProgressBar: true });



            }
        } else {

            setShowToast(true);
            toast.error(`${err}`, { position: toast.POSITION.TOP_CENTER, autoClose: false, hideProgressBar: true });

        }
    }



};