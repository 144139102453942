import React from "react";
import { useEffect, useState } from 'react';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SessionExpiredModal from '../SessionExpire/SessionExpiredModal';
import { errorHandler } from "../Utils/errorHandler";
import { appData } from "../../apis/AppsAPI";
import { departmentData } from "../../apis/DepartmentAPI";
import { locationData } from "../../apis/LocationAPI";
import { addStaff } from "../../apis/StaffAPI";
import { useLocation } from "react-router-dom";


const AddStaff = () => {

    const search = useLocation().search;
    const queryParams = new URLSearchParams(search);

    const ap_id = queryParams.get('app_id');
    let app_id = null;
    if (ap_id) {
        app_id = parseInt(ap_id);
    }

    const locat_id = queryParams.get('loc_id');
    let loc_multi_select = [];
    let loc_id = null;
    if (locat_id) {
        loc_id = parseInt(locat_id);
        loc_multi_select.push(loc_id);
    }
    // location multiselect defined 
    const [inputs, setInputs] = useState({ location_id: loc_multi_select });
    const [submitting, setSubmitting] = useState(false);
    // locationlist array
    const [location, setLocation] = useState([]);
    const [loc, setLoc] = useState(loc_id);
    const [applist, setApplist] = useState([]);
    const [apps, setApps] = useState(app_id);
    //setLoc hold value of location on change 


    const [departmentlist, setDepartmentlist] = useState([]);
    const [sessionModal, setSessionModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [backUrl] = useState("/staffs" + window.location.search);
    const [currentUrl] = useState(window.location.pathname.substring(1) + window.location.search);


    // handle user input
    const handleChange = (event) => {
        const name = event.target.name;
        let value;
        let filen;
        if (event.target.type === 'select') {

            value = document.getElementById(name).value;
            setInputs(values => ({ ...values, [name]: value }));
            if (name === "app_id") {
                setApps(value);
            }
        } else if (event.target.type === 'select-one') {

            value = document.getElementById(name).value;
            setInputs(values => ({ ...values, [name]: value }));
            const selectedIndex = event.target.selectedIndex;

            if (name === "app_id") {
                if (selectedIndex === 0) {
                    setLocation([]);
                }
                setApps(value);
            }
        } // when selected location
        else if (event.target.type === 'select-multiple') {

            let elements = document.getElementById(name).childNodes;

            let arrayOfSelecedIDs = [];

            let count = 0;
            for (let i = 0; i < elements.length; i++) {
                // if option is select then push it to object or array
                if (elements[i].selected && i !== 0) {
                    arrayOfSelecedIDs.push(elements[i].value);
                    count = count + 1;
                }
            }
            if (count > 0) {
                console.log("selected " + count);
            } else {
                setLoc(null);
            }
            setInputs(values => ({ ...values, [name]: arrayOfSelecedIDs }));
        }
        else if (event.target.type === 'file') {

            filen = event.target.files[0];
            setInputs(values => ({ ...values, [name]: filen }));
        }

        else {
            value = event.target.value;
            setInputs(values => ({ ...values, [name]: value }));
        }

    }
    //clicked option set as location accordingly department will be loaded
    const handleClick = (event, param) => {
        setLoc(param);
    };
    // handle submit event
    const handleSubmit = event => {
        event.preventDefault();
        if (inputs.first_name === "" || inputs.last_name === "") {
            setShowToast(true);
            toast.error("Validation Error: Both First Name & Last Name Required", { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
        }
        else if (inputs.title === "") {
            setShowToast(true);
            toast.error("Validation Error: Title Required", { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });


        } else if (!inputs.department) {
            setShowToast(true);
            toast.error("Validation Error: Department Required", { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });

        }
        else if (inputs.location_id.length > 0 && inputs.department) {
            setSubmitting(true);
            addStaff(inputs).then((res) => {
                setSubmitting(false);
                if (res.status === 201 && res.data.affectedRows > 0) {
                    setShowToast(true);
                    toast.success("Successfully Saved Staff Information ", {
                        position: toast.POSITION.TOP_CENTER, autoClose: 3000, hideProgressBar: false,
                        onClose: () => {
                            window.location.reload();
                        }
                    });
                } else {
                    setShowToast(true);
                    toast.error(`Failed to save staff!`, { position: toast.POSITION.TOP_CENTER, autoClose: false, hideProgressBar: true });
                }
            }).catch((err) => {
                setSubmitting(false);
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        } else {
            let validation_error = "";
            if (inputs.location_id.length < 1) {
                validation_error = " Location Required";
            }
            if (!inputs.department) {
                validation_error = validation_error + " Department Required";

            }
            setShowToast(true);
            toast.error(`${validation_error}`, { position: toast.POSITION.TOP_CENTER, autoClose: false, hideProgressBar: true });
        }
    };



    // load all apps
    useEffect(() => {
        const apps = appData();
        if (apps.length > 0) {
            setApplist(apps);
        }
        else {
            setApplist([]);
        }
    }, []);


    // load all locations by app
    useEffect(() => {
        if (apps) {
            locationData(apps).then((response) => {
                if (response.status === 200) {
                    setLocation(response.data);

                } else {
                    setLocation([]);
                    // window.location = '/error';
                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        } else {
            setLocation([]);
        }
    }, [apps]);




    //load all accessible departments
    useEffect(() => {
        departmentData(apps, loc, applist).then((response) => {

            if (response.status === 200) {
                //  incase select multi location
                let deptlist;
                let dlist = departmentlist;
                const objsArrStr = JSON.stringify(dlist);

                // convert the string again to array of objects
                // using the JSON.parse() method
                deptlist = JSON.parse(objsArrStr);
                // when multi location selected required to merge departments
                let selected_dept_available_deptlst = 0;
                let result;
                // for multi select
                if (inputs.location_id.length > 1) {
                    if (response.data.length > 0) {
                        let hasMatch = false;
                        //  merging department list
                        for (let k = 0; k < response.data.length; k++) {
                            for (let index = 0; index < deptlist.length; index++) { //  department drop down
                                let temp_dept = deptlist[index].department;
                                if (temp_dept === response.data[k].department) {
                                    hasMatch = true;
                                    break;
                                }
                            }
                            // if deparment not found added department on list
                            if (!hasMatch) {
                                deptlist.push(response.data[k]);
                            } else {
                                hasMatch = true;
                            }
                        }
                        setDepartmentlist(deptlist);
                        //incase selected deapartment exist 
                        deptlist.forEach((de) => {
                            if (de.department === inputs.department) {
                                selected_dept_available_deptlst = 1;
                            }
                        });
                    }
                } else {
                    //copy response.data to deptlist
                    deptlist = response.data;
                    setDepartmentlist(response.data);
                    if (inputs.location_id.length === 1) {
                        // check whether selected department in deparment list
                        result = response.data.find(({ department }) => department === inputs.department);
                        // if result found 
                        console.log(result);
                        if (result) {
                            selected_dept_available_deptlst = 1;
                        }
                    } else {
                        selected_dept_available_deptlst = 0;
                    }
                }
                // if no matching department found set selected department as null
                if (selected_dept_available_deptlst === 0) {
                    setInputs(values => ({ ...values, department: null }));
                }

            } else {
                console.log(response.status);
                setDepartmentlist([]);
                // window.location = '/error';
            }
        }).catch(err => {
            errorHandler(err, setSessionModal, setShowToast, toast);
        });
    }, [applist, apps, loc]);

    // handles toast closing
    const handleCloseToast = () => {
        setShowToast(false);
    }
    // logs out a user
    const handleLogout = async () => {
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location = "/";
    };




    return (
        <div className="col-xl-12">
            {submitting &&
                <div>Submtting Form...</div>
            }
            {showToast && <div className="toast-overlay"></div>}
            <ToastContainer onClose={handleCloseToast} />
            <a href={backUrl} className="btn btn-sm rounded-pill btn-secondary">Back</a>  <a href="/staffs" className="btn btn-sm rounded-pill btn-secondary">Staff Home</a> <a href={currentUrl} className="btn btn-sm rounded-pill btn-secondary">Add Staff</a>
            <SessionExpiredModal
                show={sessionModal}
                handleLogout={handleLogout}
            />
            <form onSubmit={handleSubmit} >
                <div className="card mb-4">
                    <h5 className="card-header">Adding New Staff</h5>
                    <div className="card-body">
                        <div className="mb-3 row">
                            <label className="col-md-2 col-form-label">Preview</label>
                            <div className="col-md-10">
                                <span >
                                    <img src={inputs.photo_file_name ? URL.createObjectURL(inputs.photo_file_name) : "no-image-found.gif"} height="200" width="200" alt="Staff Pic" />
                                </span>
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-password-input" className="col-md-2 col-form-label">Picture</label>
                            <div className="mb-3 col-md-10">
                                <label htmlFor="formFile" className="form-label">Upload image</label>
                                <input className="form-control" type="file" id="photo_file_name" name="photo_file_name" onChange={handleChange} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Apps<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-10">
                                <select className="form-select" id="app_id" name="app_id" onChange={handleChange} value={apps || ''} >
                                    <option key='' value="">--Select Apps--</option>
                                    {applist && applist.length > 0 && applist.map((appObj) => (
                                        <option key={appObj.id} value={appObj.id}>{appObj.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Location ID<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-10">
                                <select className="form-select" id="location_id" name="location_id" onChange={handleChange} value={inputs.location_id || []} multiple required>
                                    <option key="" value="">--Select Location--</option>
                                    {location && location.length > 0 && location.map((locationObj) => (
                                        <option key={locationObj.id} value={locationObj.id}
                                            onClick={event => handleClick(event, locationObj.id)} >{locationObj.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">First Name<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-10">
                                <input className="form-control" type="text" name="first_name" value={inputs.first_name || ''} onChange={handleChange} required />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Last Name<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-10">
                                <input className="form-control" type="text" name="last_name" value={inputs.last_name || ''} onChange={handleChange} required />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-email-input" className="col-md-2 col-form-label">Title<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-10">
                                <input className="form-control" type="text" name="title" value={inputs.title || ''} onChange={handleChange} required />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="form-label col-lg-2">Department<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-10 ">
                                <select className="form-select" id="department" name="department" onChange={handleChange} value={inputs.department || ''}>
                                    <option key='' value="">--Select Department--</option>
                                    {departmentlist && departmentlist.length > 0 && departmentlist.map((departmentObj) => (
                                        <option key={departmentObj.department} value={departmentObj.department}>{departmentObj.department}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Email</label>
                            <div className="col-md-10">
                                <input className="form-control" type="email" pattern="[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$" name="email" value={inputs.email || ''} onChange={handleChange} onInvalid={e => e.target.setCustomValidity('Please enter a valid email address')} onInput={e => e.target.setCustomValidity('')} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Phone</label>
                            <div className="col-md-10">
                                <input className="form-control" type="text" pattern="^(N\/A|\d{11}|\+?\d{1,2}\s?)?(\d{3}|\(\d{3}\)|\d{4})[\s.-]?\d{3}[\s.-]?\d{4}(\s*(ext|x|ext.|Ext|ext #|Ext #|x #|Ext.)\s*\d{1,5}))?$" name="phone" value={inputs.phone || ''} onChange={handleChange} onInvalid={e => e.target.setCustomValidity('Please enter a valid phone number')} onInput={e => e.target.setCustomValidity('')} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Text Message Number</label>
                            <div className="col-md-10">
                                <input className="form-control" type="text" pattern="^(N\/A|\d{11}|\+?\d{1,2}\s?)?(\d{3}|\(\d{3}\)|\d{4})[\s.-]?\d{3}[\s.-]?\d{4})?$" name="text_message_number" value={inputs.text_message_number || ''} onChange={handleChange} onInvalid={e => e.target.setCustomValidity('Please enter a valid text message number')} onInput={e => e.target.setCustomValidity('')} />
                            </div>
                        </div>
                    </div>
                    {/* <a href={"/addstaff"} type="button" className="btn btn-primary col-lg-12">Add New Staff</a> */}
                    <div className="mb-3 row">
                        <div className="col-md-3">&nbsp;</div>
                        <div className="col-md-7"><input type="submit" className="btn btn-primary col-lg-10" value="Save" /></div>
                        <div className="col-md-2">&nbsp;</div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default AddStaff;