import React, { useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from "react";
import { toast } from "react-toastify";
import { useParams } from 'react-router-dom';
import { errorHandler } from "../Utils/errorHandler";
import { addCustomerVehicle, getVehicleCountByLocation, getVehicleYearByLocation, getVehicleMakeByLocationAndYear, getVehicleModelByLocationAndYearAndMake, getCustomerVehicleDetailsByLocationAndVin } from "../../apis/ProfilesAPI";
import AddUpdateLoading from "../Utils/AddUpdateLoading";


const AddVehicleModal = ({ show, setShow, locationId }) => {
    // customer id from url
    const { customer_id } = useParams();
    const [vin, setVin] = useState('');
    const [acode, setAcode] = useState('');
    const [yearList, setYearList] = useState([]);
    const [year, setYear] = useState('');
    const [makeList, setMakeList] = useState([]);
    const [make, setMake] = useState('');
    const [modelList, setModelList] = useState([]);
    const [model, setModel] = useState('');
    const [odometer, setOdometer] = useState('');
    const [vehicles, setVehicles] = useState([]);
    const [hasInventory, setHasInventory] = useState(false);
    const [sessionModal, setSessionModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [loading, setLoading] = useState(false);
    const [findVehicle, setFindVehicle] = useState(false);

    // close modal
    const handleClose = () => setShow(false);


    // get vehicle details by location and vin
    useEffect(() => {
        if (vin !== '' && findVehicle) {
            getCustomerVehicleDetailsByLocationAndVin(locationId, vin).then((response) => {
                if (response.status === 200) {
                    // console.log(response.data);
                    setVehicles(response.data);
                    setYear(response.data[0].year);
                    setMake(response.data[0].make);
                    setModel(response.data[0].model);
                } else {
                    setVehicles([]);
                    setYear('');
                    setMake('');
                    setModel('');
                };
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        }
    }, [vin, findVehicle]);


    // find vehicle
    const handleFindVehicle = () => {
        setFindVehicle(true);
        // console.log(vin);
        // console.log(vehicles);
    };

    // console.log(vehicles, 'vehicles');

    // console.log(locationId, 'locationId');
    // get vehicle count by location
    useEffect(() => {
        if (locationId && locationId !== '' && locationId !== undefined && !vehicles.length) {
            getVehicleCountByLocation(locationId).then((response) => {
                if (response.status === 200) {
                    if (response.data[0].count > 0) {
                        setHasInventory(true);
                    } else {
                        setHasInventory(false);
                    };
                } else {
                    setShowToast(false);
                    window.location = '/error';
                };
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        }
    }, [locationId]);

    // console.log(hasInventory);


    // get vehicle year by location
    useEffect(() => {
        if (hasInventory) {
            getVehicleYearByLocation(locationId).then((response) => {
                if (response.status === 200) {
                    setYearList(response.data);
                } else {
                    setShowToast(false);
                    window.location = '/error';
                };
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        }
    }, [locationId, hasInventory]);

    // console.log(yearList, 'yearList');
    // console.log(year, 'year');

    // get vehicle make by location and year
    useEffect(() => {
        if (hasInventory && year !== '' && !vehicles.length) {
            getVehicleMakeByLocationAndYear(locationId, year).then((response) => {
                if (response.status === 200) {
                    setMakeList(response.data);
                } else {
                    setShowToast(false);
                    window.location = '/error';
                };
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        }
    }, [locationId, year, hasInventory]);

    // get vehicle model by location and year and make
    useEffect(() => {
        if (hasInventory && year !== '' && make !== '' && !vehicles.length) {
            getVehicleModelByLocationAndYearAndMake(locationId, year, make).then((response) => {
                if (response.status === 200) {
                    setModelList(response.data);
                } else {
                    setShowToast(false);
                    window.location = '/error';
                };
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        }
    }, [locationId, year, make, hasInventory]);



    // handle change
    const handleChange = (e) => {
        const name = e.target.name;
        let value;
        if (e.target.type === 'select-one' && hasInventory) {
            value = document.getElementById(name).value;
            const selectedIndex = e.target.selectedIndex;
            if (name === "year") {
                if (selectedIndex === 0) {
                    setMake('');
                    setModel('');
                }
                setYear(value);
            } else if (name === "make") {
                if (selectedIndex === 0) {
                    setModel('');
                }
                setMake(value);
            } else if (name === "model") {
                setModel(value);
            }
        }
        else {
            value = e.target.value;
            if (name === "vin") {
                setVin(value);
            }
            else if (name === "acode") {
                setAcode(value);
            }
            else if (name === "odometer") {
                setOdometer(value);
            }
            else if (name === "year") {
                setYear(value);
            }
            else if (name === "make") {
                setMake(value);
            }
            else if (name === "model") {
                setModel(value);
            }
        }
    }

    // handle submit
    const handleSubmit = (e) => {
        e.preventDefault();
        if (vin === '') {
            toast.error(`VIN is rquired!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: false,
            });
            return;
        }
        else if (year === '') {
            toast.error(`Year is rquired!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: false,
            });
            return;
        } else if (make === '') {
            toast.error(`Make is rquired!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: false,
            });
            return;
        } else if (model === '') {
            toast.error(`Model is rquired!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: false,
            });
            return;
        }

        setLoading(true);
        addCustomerVehicle(customer_id, vin, acode, year, make, model, odometer).then((response) => {
            if (response.status === 201) {
                toast.success(`Vehicle added successfully!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    onClose: () => {
                        setShow(false);
                        window.location.reload();
                    }
                });
            } else {
                setShowToast(false);
                window.location = '/error';
            };
        }).catch(err => {
            errorHandler(err, setSessionModal, setShowToast, toast);
        });
        setLoading(false);
    }

    // handle close toast
    const handleCloseToast = () => {
        setShowToast(false);
        window.location.reload();
    };


    // handle logout
    const handleLogout = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location = "/";
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="lg"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Add Vehicle</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? <AddUpdateLoading /> :
                    <>
                        <div className="row">
                            <div className="col-md-12">
                                <h3>Add Vehicle From Inventory</h3>
                                {hasInventory ?
                                    <div>
                                        <div className="form-group">
                                            <label htmlFor="vin">VIN<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                            <input type="text" className="form-control" id="vin" name="vin" value={vin} onChange={handleChange} required />
                                            <button type="button" className="btn btn-success mt-2" onClick={handleFindVehicle}>Find Vehicle</button>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="acode">Acode</label>
                                            <input type="text" className="form-control" id="acode" name="acode" value={acode} onChange={handleChange} />
                                        </div>
                                        {vehicles && vehicles.length > 0 ?
                                            <>
                                                <>
                                                    <div className="form-group">
                                                        <label htmlFor="year">Year<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                                        <input type="text" className="form-control" id="year" name="year" value={year} disabled />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="make">Make<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                                        <input type="text" className="form-control" id="make" name="make" value={make} disabled />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="model">Model<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                                        <input type="text" className="form-control" id="model" name="model" value={model} disabled />
                                                    </div>
                                                </>
                                            </>
                                            :
                                            <>
                                                <div className="form-group">
                                                    <label htmlFor="year">Year<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                                    <select className="form-select" id="year" name="year" onChange={handleChange} value={year || ''} >
                                                        <option key='' value="">--Select Year--</option>
                                                        {yearList && yearList.length > 0 && yearList.map((yearObj, index) => (
                                                            <option key={index} value={yearObj.year}>{yearObj.year}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="make">Make<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                                    <select className="form-select" id="make" name="make" onChange={handleChange} value={make || ''} >
                                                        <option key='' value="">--Select Make--</option>
                                                        {makeList && makeList.length > 0 && makeList.map((makeObj, index) => (
                                                            <option key={index} value={makeObj.make}>{makeObj.make}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="model">Model<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                                    <select className="form-select" id="model" name="model" onChange={handleChange} value={model || ''} >
                                                        <option key='' value="">--Select Model--</option>
                                                        {modelList && modelList.length > 0 && modelList.map((modelObj, index) => (
                                                            <option key={index} value={modelObj.model}>{modelObj.model}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </>
                                        }
                                        <div className="form-group">
                                            <label htmlFor="odometer">Odometer</label>
                                            <input type="text" className="form-control" id="odometer" name="odometer" value={odometer} onChange={handleChange} />
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <p className="alert alert-primary" style={{ textAlign: 'center' }}>No Inventory Found</p>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h3 className="mt-5">Add Vehicle Manually</h3>
                            </div>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="vin">VIN<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                <input type="text" className="form-control" id="vin" name="vin" value={vin} onChange={handleChange} required />
                                <button type="button" className="btn btn-success mt-2" onClick={handleFindVehicle}>Find Vehicle</button>
                            </div>
                            <div className="form-group">
                                <label htmlFor="acode">Acode</label>
                                <input type="text" className="form-control" id="acode" name="acode" value={acode} onChange={handleChange} />
                            </div>
                            {vehicles && vehicles.length > 0 ?
                                <>
                                    <div className="form-group">
                                        <label htmlFor="year">Year<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                        <input type="text" className="form-control" id="year" name="year" value={year} disabled />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="make">Make<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                        <input type="text" className="form-control" id="make" name="make" value={make} disabled />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="model">Model<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                        <input type="text" className="form-control" id="model" name="model" value={model} disabled />
                                    </div>
                                </>
                                :
                                <>
                                    <div className="form-group">
                                        <label htmlFor="year">Year<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                        <input type="text" className="form-control" id="year" name="year" value={year} onChange={handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="make">Make<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                        <input type="text" className="form-control" id="make" name="make" value={make} onChange={handleChange} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="model">Model<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                        <input type="text" className="form-control" id="model" name="model" value={model} onChange={handleChange} />
                                    </div>
                                </>
                            }
                            <div className="form-group">
                                <label htmlFor="odometer">Odometer</label>
                                <input type="text" className="form-control" id="odometer" name="odometer" value={odometer} onChange={handleChange} />
                            </div>
                        </form>
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type="submit" className="float-right" onClick={handleSubmit}>Add Vehicle</Button>
                <Button variant="secondary" onClick={handleClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}


export default AddVehicleModal;
