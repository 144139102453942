import React, { useState } from "react";
import axios from "axios";

const Login = () => {
  const [isErr, setIsErr] = useState(false);
  const [err, setErr] = useState([]);
  const [showCodeField, setShowCodeField] = useState(false);
  const [msg, setMsg] = useState("");
  const [inputs, setInputs] = useState({});
  const [twoFactorStatus, settwoFactorStatus] = useState(null);

  // handles login submit
  const handleSubmit = async (event) => {
    event.preventDefault();

    const uname = event.target.uname.value;
    const pass = event.target.pass.value;
    // following code asked for twice click
    //@floyd please fix
    if (twoFactorStatus === null) {
      await check2FAStatus(uname);
      return;
    }

    let code;
    if (event.target.code?.value) {
      code = event.target.code.value;
    }

    try {
      // api end for generate token
      await axios.post(process.env.REACT_APP_BACKEND_URL + "/v2/auth/login", { "username": uname, "password": pass, "code": code }).then((response) => {
        if (response.status === 200) {
          localStorage.setItem("accessToken", response.data.accessToken);
          localStorage.setItem("refreshToken", response.data.refreshToken);
          localStorage.setItem("user", JSON.stringify(response.data.other));
          window.location = "/";
        }
        else {
          window.location = '/login';
        }
      });
    } catch (err) {
      setIsErr(true);
      if (err.response.data.message.message) {
        setErr(err.response.data.message.message);
      } else {
        setErr(err.response.data.message);
      }
    }
  };

  const handleChange = (event) => {
    const name = event.target.name;
    let value;
    if (event.target.type === 'select') {
      value = document.getElementById(name).value;
      setInputs(values => ({ ...values, [name]: value }));
    }

    else {
      value = event.target.value;
      setInputs(values => ({ ...values, [name]: value }));
    }
  };

  const check2FAStatus = async (username) => {
    let message = "";
    try {
      await axios.post(process.env.REACT_APP_BACKEND_URL + "/v2/auth/sendcode", { "username": username }).then((response) => {
        if (response.status === 200) {
          if (response.data.info?.length) {
            if (response.data.info[0].is_2FA === 1) {
              settwoFactorStatus(true);
              setShowCodeField(true);
              if (response.data.info[0].contact_preference === "phone") {
                let mobile_number = "+" + response.data.info[0].country_code + response.data.info[0].phone_number;
                message = "A verification code sent to your mobile " + mobile_number.slice(0, 3) + mobile_number.slice(3).replace(/.(?=....)/g, '*') + " will be valid for next 2 minutes";
              } else {
                let email = response.data.info[0].email;
                message = "A verification code sent to your email address " + email.slice(0, 4) + email.slice(4).replace(/.(?=.........)/g, '*') + " will be valid for next 2 minutes";
              }
              setMsg(message);
            } else {
              settwoFactorStatus(false);
            }
          }
        }
      });
    } catch (err) {
      setIsErr(true);
      console.log(err);
    }
  }

  return (
    <div className="card" style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '30px',
      width: '800px'
    }}>
      <div className="form" style={{
      }}>

        <div className="row" style={{
          justifyContent: 'center',
          width: '800px'
        }}>
          <div className="col-lg">
            <form onSubmit={handleSubmit}>
              <div className="row mb-3">
                <label className="col-sm-2 col-form-label" htmlFor="basic-icon-default-fullname">UserName</label>
                <div className="col-sm-4">
                  <div className="input-group input-group-merge">
                    <span id="basic-icon-default-fullname2" className="input-group-text"><i className="bx bx-user"></i></span>
                    <input type="text" className="form-control" name="uname" required value={inputs.uname || ''} onChange={handleChange} />
                  </div>
                </div>
              </div>
              <div>
                <div className="row mb-3">
                  <label className="col-sm-2 col-form-label" htmlFor="basic-icon-default-company">Password</label>
                  <div className="col-sm-4">
                    <div className="input-group input-group-merge">
                      <span id="basic-icon-default-company2" className="input-group-text"><i className="bx bx-lock"></i></span>
                      <input type="password" id="basic-icon-default-company" className="form-control" name="pass" required />
                    </div>
                  </div>
                </div>
                {showCodeField &&
                  <div className="row mb-3">
                    <label className="col-sm-2 col-form-label" htmlFor="basic-icon-default-company">Code</label>
                    <div className="col-sm-4">
                      <div className="input-group input-group-merge">
                        <span id="basic-icon-default-company2" className="input-group-text"></span>
                        <input type="text" id="basic-icon-default-company" className="form-control" name="code" placeholder="verification code" />
                      </div>
                    </div>
                    <div className="col-sm-12" >
                      {msg && <p style={{ color: "green" }}>{msg}!</p>}
                    </div>
                  </div>
                }
                <div className="row justify-content-end">
                  <div className="col-sm-10">
                    <button type="submit" className="btn btn-primary">Login</button>
                  </div>
                </div>
              </div>
            </form>

            <div className="col-sm-5 pt-2" style={{ width: '100%', paddingLeft: "40%" }}>
              {isErr && <p style={{ color: "red" }}>{err}!</p>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;