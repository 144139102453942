import React from "react";
import Loading from "../Utils/Loading";
import moment from "moment";



const AnalyticsLoc = ({analyticsDataDetailsLocs, metric, locate, setLocate, locLoading, dates, setDates, locName, setLocName, loc, setLoc, startDate, endDate}) => {
    console.log(locLoading, 'locLoading from loc');
    console.log('dates from loc', dates);
    console.log(startDate, 'stDate from loc')
    
    if(dates){
        const dt = moment(dates, 'DD-MM-YYYY').format('YYYY-MM-DD');

        const dts = moment(dt);
        const stDate = moment(startDate);
        const enDate = moment(endDate);

        if(dts.isBefore(stDate) || dts.isAfter(enDate)){
            setDates(null);
        }
        console.log('dts', dts, 'stD', stDate, 'enD', enDate);
    }
    

    // handle click on location
    const handleLocClick = (elem) => {
        console.log('elem', elem)
        setLocate(elem);
        setLoc(elem);
    };
    console.log('locate', locate);
    console.log('analyticsDataDetailsLoc from analyticsloc', analyticsDataDetailsLocs)

    console.log('dates', dates)

    return (
        <div>
            {locLoading ?
                <Loading /> :
                <div className="row">
                    {analyticsDataDetailsLocs.map((item) => (
                        Object.keys(item).map((key) => (
                            key === metric ?
                                item[key].length > 0 && item[key][0].val ?
                                    <div key={item[key].length+20} className="container" style={{ width: "100%", height: '100%', marginTop: '10px', marginBottom:'20px'}}>
                                        <div key = {item[key].length+15} style={{marginTop: '10px'}}>
                                            <h5 style={{textAlign:'center', color:'#000000FF'}}>{key} By Location <span style={{color:'#2BAE66FF'}}>({dates ? dates: 'All Dates'})</span></h5>
                                            <table style={{borderCollapse: 'collapse', width: '100%', border:'2px solid black'}}>
                                                <thead style={{border: '2px solid black', backgroundColor:'teal'}}>
                                                    <tr>
                                                        <th key={item[key].length} style={{textAlign: 'left', color:'#000000FF', fontSize:'16px'}}>Location Name</th>
                                                        <th key={item[key].length+10} style={{textAlign: 'right', color:'#000000FF', fontSize:'16px'}}>{key}</th>
                                                    </tr>
                                                </thead>
                                                {item[key].map((elem, index) => (
                                                    <tbody key = {index+5} style={{border: '2px solid black'}}>
                                                        <tr key = {index+15} style={{backgroundColor: elem.location_id === locate ? '#2BAE66FF':'white'}}>
                                                            <th key={index} style={{textAlign: 'left', color:'#000000FF', cursor: 'pointer' }} onClick={() => handleLocClick(elem.location_id)}>{elem.name}</th>
                                                            <th key={index+10} style={{textAlign: 'right', color:'#000000FF'}}>{elem.val}</th>
                                                        </tr>
                                                    </tbody>
                                                ))}
                                            </table>
                                        </div>
                                    </div>:
                                null:
                            null
                        ))
                    ))}
                </div>
            }
        </div>
    );
};


export default AnalyticsLoc;