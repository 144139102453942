
// This is code will get app list from localstorage to get the list of apps for the user
import axiosInstance from "../services/AxiosInstance";

export const appData = () => {
    let applist = [];
    const userData = JSON.parse(localStorage.getItem('user'));
    if (userData.apps.length > 0) {
        applist = userData.apps;

    } else {
        applist = [];
    }
    return applist;
};

// add new app api call 
export const addApp = (inputs) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const role = user.role;
    const userid = user.id;
    if (role !== 'administrator') {
        return;
    }

    const formData = new FormData();
    formData.append("name", inputs.name);
    formData.append("sku", inputs.sku);
    formData.append("bundle_identifier", inputs.bundle_identifier);
    formData.append("client_id", inputs.client_id);
    formData.append("classification", inputs.classification);
    formData.append("country", inputs.country);
    formData.append("timezone", inputs.timezone);
    formData.append("splash_screen_backdrop", inputs.splash_screen_backdrop);
    formData.append("home_screen_title", inputs.home_screen_title);
    formData.append("choose_a_location_screen_title", inputs.choose_a_location_screen_title);
    formData.append("welcome_screen_title", inputs.welcome_screen_title);
    formData.append("welcome_screen_text", inputs.welcome_screen_text);
    formData.append("vehicle_request_flag", inputs.vehicle_request_flag);
    formData.append("loyalty_rewards_program_type", inputs.loyalty_rewards_program_type);
    formData.append("loyalty_rewards_terms_and_conditions", inputs.loyalty_rewards_terms_and_conditions);
    formData.append("extra_gan_account_ids", inputs.extra_gan_account_ids);
    formData.append("analytics_report_email", inputs.analytics_report_email);
    formData.append("advanced_push_enabled", inputs.advanced_push_enabled);
    formData.append("is_700_active", inputs.is_700_active);
    formData.append("reports_email_for_700_credit", inputs.reports_email_for_700_credit);
    formData.append("title_for_700_credit_screen", inputs.title_for_700_credit_screen);
    formData.append("prequalified_username_for_700_credit", inputs.prequalified_username_for_700_credit);
    formData.append("prequalified_password_for_700_credit", inputs.prequalified_password_for_700_credit);
    formData.append("terms_and_conditions_for_700_credit", inputs.terms_and_conditions_for_700_credit);
    formData.append("privacy_policy_for_700_credit", inputs.privacy_policy_for_700_credit);
    formData.append("ask_for_ssn_in_case_of_no_hit_for_700_credit", inputs.ask_for_ssn_in_case_of_no_hit_for_700_credit);
    formData.append("created_by", userid);
    formData.append("show_home_screen_pop_up_alert", inputs.show_home_screen_pop_up_alert);
    formData.append("home_screen_pop_up_alert_text", inputs.home_screen_pop_up_alert_text);
    formData.append("status", inputs.status);

    if (inputs.apn_prod_cert_file_name) {

        formData.append("apn_prod_cert_file_name", inputs.apn_prod_cert_file_name);
    }
    if (inputs.apn_dev_cert_file_name) {

        formData.append("apn_dev_cert_file_name", inputs.apn_dev_cert_file_name);
    }
    if (inputs.app_icon_file_name) {
        formData.append("app_icon_file_name", inputs.app_icon_file_name);

    }
    if (inputs.qr_code_file_name) {
        formData.append("qr_code_file_name", inputs.qr_code_file_name);

    }
    if (inputs.splash_screen_file_name) {
        formData.append("splash_screen_file_name", inputs.splash_screen_file_name);

    } if (inputs.translation_input_file) {

        formData.append("translation_input_file", inputs.translation_input_file);
    }




    return axiosInstance.post("/v2/apps/add", formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'userid': userid,
            'userrole': role
        }
    }
    );
};


export const updateApp = (inputs) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const role = user.role;

    if (role !== 'administrator') {
        return
    }
    const userid = user.id;
    const formData = new FormData();


    formData.append("id", inputs.id);

    formData.append("name", inputs.name);
    formData.append("sku", inputs.sku);
    formData.append("bundle_identifier", inputs.bundle_identifier);
    formData.append("client_id", inputs.client_id);
    formData.append("classification", inputs.classification);
    formData.append("country", inputs.country);
    formData.append("timezone", inputs.timezone);
    formData.append("splash_screen_backdrop", inputs.splash_screen_backdrop);
    formData.append("home_screen_title", inputs.home_screen_title);
    formData.append("choose_a_location_screen_title", inputs.choose_a_location_screen_title);
    formData.append("welcome_screen_title", inputs.welcome_screen_title);
    formData.append("welcome_screen_text", inputs.welcome_screen_text);
    formData.append("vehicle_request_flag", inputs.vehicle_request_flag);
    formData.append("loyalty_rewards_program_type", inputs.loyalty_rewards_program_type);
    formData.append("loyalty_rewards_terms_and_conditions", inputs.loyalty_rewards_terms_and_conditions);
    formData.append("extra_gan_account_ids", inputs.extra_gan_account_ids);
    formData.append("analytics_report_email", inputs.analytics_report_email);
    formData.append("advanced_push_enabled", inputs.advanced_push_enabled);
    formData.append("is_700_active", inputs.is_700_active);
    formData.append("reports_email_for_700_credit", inputs.reports_email_for_700_credit);
    formData.append("title_for_700_credit_screen", inputs.title_for_700_credit_screen);
    formData.append("prequalified_username_for_700_credit", inputs.prequalified_username_for_700_credit);
    formData.append("prequalified_password_for_700_credit", inputs.prequalified_password_for_700_credit);
    formData.append("terms_and_conditions_for_700_credit", inputs.terms_and_conditions_for_700_credit);
    formData.append("privacy_policy_for_700_credit", inputs.privacy_policy_for_700_credit);
    formData.append("ask_for_ssn_in_case_of_no_hit_for_700_credit", inputs.ask_for_ssn_in_case_of_no_hit_for_700_credit);
    formData.append("updated_by", userid);
    formData.append("app_icon_file_selected", inputs.appIconFileSelected);
    formData.append("splash_screen_selected", inputs.splashScreenSelected);
    formData.append("qr_code_file_name_selected", inputs.qrCodeFileNameSelected);
    formData.append("old_translation_url", inputs.old_translation_url);
    formData.append("translation_file_url", inputs.translation_file_url);
    formData.append("delete_translation_file", inputs.delete_translation_file);
    formData.append("show_home_screen_pop_up_alert", inputs.show_home_screen_pop_up_alert);
    formData.append("home_screen_pop_up_alert_text", inputs.home_screen_pop_up_alert_text);
    formData.append("status", inputs.status);
    formData.append("is_email_available", inputs.is_email_available);
    formData.append("is_sms_available", inputs.is_sms_available);




    if (inputs.translation_input_file) {

        formData.append("translation_input_file", inputs.translation_input_file);

    }




    if (inputs.apn_prod_cert_file_name) {

        formData.append("apn_prod_cert_file_name", inputs.apn_prod_cert_file_name);
    }
    if (inputs.apn_dev_cert_file_name) {

        formData.append("apn_dev_cert_file_name", inputs.apn_dev_cert_file_name);
    }
    if (inputs.app_icon_file_name) {
        formData.append("app_icon_file_name", inputs.app_icon_file_name);

    }
    if (inputs.qr_code_file_name) {
        formData.append("qr_code_file_name", inputs.qr_code_file_name);

    }
    if (inputs.splash_screen_file_name) {
        formData.append("splash_screen_file_name", inputs.splash_screen_file_name);

    }



    const url = "/v2/apps/update/" + inputs.id;
    console.log(url);


    return axiosInstance.patch(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'userid': userid,
            'userrole': role
        }
    });
};




// app searching 
export const appDetailData = (cpage, searchkey, appsPerPage, sort) => {

    let offset = (cpage - 1) * appsPerPage;
    let questionadded = false;
    let url = '/v2/apps/alldetail';
    url = url + '?offset=' + offset;
    questionadded = true;
    if (questionadded) {
        url = url + '&limit=' + appsPerPage;
        questionadded = true;
    } else {
        url = url + '?limit=' + appsPerPage;
        questionadded = true;
    }
    if (sort) {
        if (questionadded) {
            url = url + '&sort=' + sort;
        }
        else {
            url = url + '?sort=' + sort;
            questionadded = true;
        }
    }

    if (searchkey !== '' && searchkey) {
        let searchkeyencoded = encodeURIComponent(searchkey);
        if (questionadded) {
            url = url + '&searchkey=' + searchkeyencoded;
        } else {
            url = url + '?searchkey=' + searchkeyencoded;
            questionadded = true;

        }

    }
    const user = JSON.parse(localStorage.getItem('user'));
    const role = user.role;
    const userid = user.id;
    if (role !== 'administrator') {
        return;
    }


    console.log(url);
    // return axiosInstance.get(url);

    return axiosInstance.get(url, {
        headers: {
            'Content-Type': 'application/json',
            'userid': userid,
            'userrole': role
        }
    });

};
// count apps 
export const appDetailDataCount = (searchkey) => {
    let url = '/v2/apps/alldetailcount';
    let questionadded = false;

    if (searchkey !== '' && searchkey) {
        let searchkeyencoded = encodeURIComponent(searchkey);
        if (questionadded) {
            url = url + '&searchkey=' + searchkeyencoded;
        } else {
            url = url + '?searchkey=' + searchkeyencoded;
            questionadded = true;

        }

    }

    const user = JSON.parse(localStorage.getItem('user'));
    const role = user.role;
    const userid = user.id;
    if (role !== 'administrator') {
        return;
    }

    console.log(url);

    return axiosInstance.get(url, {
        headers: {
            'Content-Type': 'application/json',
            'userid': userid,
            'userrole': role
        }
    });



};


// get app by id
export const getAppById = (id) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const role = user.role;

    if (role !== 'administrator') {
        return
    }
    const userid = user.id;

    const url = "v2/apps/get/" + id;
    console.log(url);

    return axiosInstance.get(url, {
        headers: {
            'Content-Type': 'application/json',
            'userid': userid,
            'userrole': role
        }
    });
};

