import { Modal } from "react-bootstrap";
import React from "react";
import {toast} from "react-toastify";
import { useState } from "react";
import SessionExpiredModal from "../SessionExpire/SessionExpiredModal";
import { errorHandler } from "../Utils/errorHandler";
import { updateDepartmentByLoc, updateDepartmentName, getDepartments } from "../../apis/DepartmentAPI";


const EditDepartmentModal = ({show, setShow, item, order, apps, loc, setDepartments}) => {
    const [newDept, setNewDept] = useState(null);
    const [showWarning, setShowWarning] = useState(false);
    const [sessionModal, setSessionModal] = useState(false);
    const [showToast, setShowToast] = useState(false);

    // handle modal close
    const handleClose = () => setShow(false);

    // handle warning modal close
    const handleWarningClose = () => setShowWarning(false);

    // handle input change
    const handleChange = (e) => {
        e.preventDefault();
        if (e.target.type === 'text' && e.target.name === 'department_name') {
            const currentValue = e.target.value;
            setNewDept(currentValue);
        }
    };
    

    // handle backend call
    const handleEdit = (e) => {
        e.preventDefault();
        if(apps && loc){
            updateDepartmentByLoc(loc, item, newDept).then((res) => {
                console.log(res, res.status)
                if(res.status === 201){
                    if(newDept && item){
                        console.log(newDept, item, apps, loc)
                        updateDepartmentName(apps, loc, item, newDept).then((res) => {
                            if(res.status === 201){
                                toast.success("Department updated successfully!", { 
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    hideProgressBar: false,
                                    onClose: () => {
                                        setShowWarning(false);
                                        setShow(false);
                                        getDepartments(apps, loc, 30, undefined, 1).then((response) => {
                                            if(response.status === 200){
                                                setDepartments(response.data);
                                            }else{
                                                setDepartments([]);
                                                window.location = '/error';
                                            };
                                        }).catch(err => {
                                            errorHandler(err, setSessionModal, setShowToast, toast);
                                        });
                                    }
                                });
                            }else{
                                console.log(res, 'my res');
                                if(res.status === 200){
                                    toast.success("Department updated successfully!", {
                                        position: toast.POSITION.TOP_CENTER,
                                        autoClose: 2000,
                                        hideProgressBar: false,
                                        onClose: () => {
                                            setShowWarning(false);
                                            setShow(false);
                                            getDepartments(apps, loc, 30, undefined, 1).then((response) => {
                                                if(response.status === 200){
                                                    setDepartments(response.data);
                                                }else{
                                                    setDepartments([]);
                                                    window.location = '/error';
                                                };
                                            }).catch(err => {
                                                errorHandler(err, setSessionModal, setShowToast, toast);
                                            });
                                        }
                                    });
                                }else{
                                    toast.error("Error updating department!", {
                                        position: toast.POSITION.TOP_CENTER,
                                        autoClose: false,
                                        hideProgressBar: true
                                    });
                                };
                            };
                        }).catch((err) => {
                            errorHandler(err, setSessionModal, setShowToast, toast);
                        });
                    } else {
                        toast.error("Error updating department!", { 
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: false,
                            hideProgressBar: true
                        });
                    }; 
                } else {
                    toast.error("Error updating department!", { 
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: false,
                        hideProgressBar: true
                    });
                }
            }).catch((err) => {
                if(err.response.data.message.message){
                    if(err.response.data.message.message === "Data already exists!"){
                        toast.error(`A department with name ${newDept} already exists! Please choose another name`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: false,
                            hideProgressBar: true,
                            onClose: () => {
                                setShowWarning(false);
                            }
                        });
                    } else {
                        errorHandler(err, setSessionModal, setShowToast, toast);
                    };
                }
            });
        } else {
            toast.error("Error updating department!", { 
                position: toast.POSITION.TOP_CENTER,
                autoClose: false,
                hideProgressBar: true
            });
        };
    };

    // handle warning modal
    const handleEditWarning = (e) => {
        e.preventDefault();
        setShowWarning(true);
    };

    // logs out a user
    const handleLogout = async () => {
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location = "/";
    };

    return (
        <div>
            <Modal
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={showWarning}
                onHide={handleWarningClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" style={{color:"red"}}>
                        Warning!
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showToast && <div className="toast-overlay"></div>}
                    <SessionExpiredModal
                        show={sessionModal}
                        handleLogout={handleLogout}
                    />
                    {!newDept && <h5>Please enter a new department name.</h5>}
                    {item === newDept && <h5>Please change the department name.</h5>}
                    {apps && !loc && newDept && item !== newDept && <h5>Are you sure you want to edit this department name from {item} to {newDept}? This will update the department name for all locations associated with selected app.</h5>}
                    {apps && loc && newDept && item !== newDept && <h5>Are you sure you want to edit this department name from {item} to {newDept}?</h5>}
                </Modal.Body>
                <Modal.Footer>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={handleWarningClose}
                    >
                        Cancel
                    </button>
                        {!newDept && <p></p>}
                        {item === newDept && <p></p>}
                        {apps && !loc && newDept && item !== newDept && <button
                        type="button"
                        className="btn btn-danger"
                        onClick={handleEdit}
                    >
                        Continue
                    </button>}
                    {apps && loc && newDept && item !== newDept && <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleEdit}
                    >
                        Continue
                    </button>}
                </Modal.Footer>
            </Modal>
            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Department
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col mb-3">
                                <label htmlFor="nameWithTitle" className="form-label">Department Name</label>
                                <input type='text' id="nameWithTitle" name= 'department_name' className="form-control" defaultValue={item} onChange={handleChange} required></input>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col mb-3">
                                <label htmlFor="nameWithTitle" className="form-label">Order</label>
                                <input type='text' id="nameWithTitle" name= 'department_name' className="form-control" defaultValue={order} readOnly></input>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleClose}
                    >
                        Cancel
                    </button>
                    <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleEditWarning}
                    >
                        Update
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};


export default EditDepartmentModal;
