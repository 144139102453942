import React from "react";
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SessionExpiredModal from '../SessionExpire/SessionExpiredModal';
import { errorHandler } from "../Utils/errorHandler";
import { appData } from "../../apis/AppsAPI";
import { locationData } from "../../apis/LocationAPI";
import { updateAutomatedPushMessage, singleAutomatedMessage } from "../../apis/CommunicationAPI";
import { useLocation } from "react-router-dom";
import "react-datetime/css/react-datetime.css";
import AddUpdateLoading from "../Utils/AddUpdateLoading";


const EditAutomatedPushMessage = () => {
    const { push_id } = useParams();
    const search = useLocation().search;
    const queryParams = new URLSearchParams(search);
    const app = queryParams.get('app_id') ? parseInt(queryParams.get('app_id')) : null;

    const [inputs, setInputs] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [applist, setApplist] = useState([]);
    const [location, setLocation] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [sessionModal, setSessionModal] = useState(false);
    const [backUrl] = useState("/communication/automatedpushmessages" + window.location.search);
    const [currentUrl] = useState("/communication/addautomatedpushmessage" + window.location.search);
    const [loc, setLoc] = useState(null);
    const [messageType, setMessageType] = useState('');
    const [file, setFile] = useState(null);

    console.log(window.location.search)

    const [apps, setApps] = useState(app);

    // message types
    // message types
    const [messageTypes, setMessageTypes] = useState(
        [
            { id: 1, name: "Birthday", title: "Birthday" },
            { id: 2, name: "LeaseRenewal", title: "Lease Renewal" },
            { id: 3, name: "PurchaseAnniversary", title: "Purchase Anniversary" },
            { id: 4, name: "ScheduledServiceReminder", title: "Scheduled Service Reminder" },
            { id: 5, name: "ServiceThankYou", title: "Service Thank You" },
            { id: 6, name: "SetUpProfile", title: "Set Up Profile" },
            { id: 7, name: "mDocsReminder", title: "mDocs Reminder" },
            { id: 8, name: "EmptyGarage", title: "Empty Garage" },
            { id: 9, name: "PriceChange", title: "Price Change" },
            { id: 10, name: "MaintenanceScheduleReminder", title: "Maintenance Schedule Reminder" },
        ]
    );

    // load all apps
    useEffect(() => {
        const apps = appData();
        if (apps.length > 0) {
            setApplist(apps);
        }
        else {
            setApplist([]);
        }
        const userData = JSON.parse(localStorage.getItem('user'));
        console.log(userData, 'userData');
        if (userData) {
            setInputs(values => ({ ...values, updated_by: userData.id }));
        };
    }, []);


    // load all locations by app
    useEffect(() => {
        if (apps) {
            locationData(apps).then((response) => {
                if (response.status === 200) {
                    console.log(response.data);
                    let data = [];
                    response.data.map((loc, index) => {
                        data.push({ value: loc.id, label: loc.name });
                    });
                    setLocation(data);
                } else {
                    setLocation([]);
                    // window.location = '/error';
                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        } else {
            setLocation([]);
        }
    }, [apps]);

    // logs out a user
    const handleLogout = async () => {
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location = "/";
    };


    // handles toast closing
    const handleCloseToast = () => {
        setShowToast(false);
    };

    // handle user input
    const handleChange = (event) => {
        const name = event.target.name;
        let value;
        let filen;
        if (event.target.type === 'select') {
            value = document.getElementById(name).value;
            setInputs(values => ({ ...values, [name]: value }));
            if (name === "app_id") {
                setApps(value);
            }
        } else if (event.target.type === 'select-one') {
            value = document.getElementById(name).value;
            setInputs(values => ({ ...values, [name]: value }));
            const selectedIndex = event.target.selectedIndex;
            if (name === "app_id") {
                if (selectedIndex === 0) {
                    setLocation([]);
                }
                setApps(value);
            }
            else if (name === "message_type") {
                if (selectedIndex === 0) {
                    setMessageType(null);
                    setInputs(values => ({ ...values, [name]: null }));
                    // setInputs(values => ({ ...values, is_repeative_event: 1 }));
                    // setInputs(values => ({ ...values, repeat_event_gap: 365 }));
                    // setInputs(values => ({ ...values, interval_days: "" }));
                    // setInputs(values => ({ ...values, interval_days_2: "" }));
                    // setInputs(values => ({ ...values, interval_days_3: "" }));
                }
                else {
                    setMessageType(value);
                    setInputs(values => ({ ...values, [name]: value }));
                    if (value === "LeaseRenewal") {

                        setInputs(values => ({ ...values, is_repeative_event: 0 }));
                        setInputs(values => ({ ...values, repeat_event_gap: "" }));
                        setInputs(values => ({ ...values, interval_days: 180 }));
                        setInputs(values => ({ ...values, interval_days_2: 90 }));
                        setInputs(values => ({ ...values, interval_days_3: 30 }));
                    }

                    else if (value === "ScheduledServiceReminder") {

                        setInputs(values => ({ ...values, is_repeative_event: 0 }));
                        setInputs(values => ({ ...values, repeat_event_gap: "" }));
                        setInputs(values => ({ ...values, interval_days: "" }));
                        setInputs(values => ({ ...values, interval_days_2: "" }));
                        setInputs(values => ({ ...values, interval_days_3: "" }));


                    }
                    else if (value === "ServiceThankYou") {

                        setInputs(values => ({ ...values, is_repeative_event: 0 }));
                        setInputs(values => ({ ...values, repeat_event_gap: "" }));
                        setInputs(values => ({ ...values, interval_days: "-1" }));
                        setInputs(values => ({ ...values, interval_days_2: "" }));
                        setInputs(values => ({ ...values, interval_days_3: "" }));


                    }
                    else if (value === "SetUpProfile") {

                        setInputs(values => ({ ...values, is_repeative_event: 0 }));
                        setInputs(values => ({ ...values, repeat_event_gap: "" }));
                        setInputs(values => ({ ...values, interval_days: "-30" }));
                        setInputs(values => ({ ...values, interval_days_2: "" }));
                        setInputs(values => ({ ...values, interval_days_3: "" }));

                    }


                    else if (value === "mDocsReminder") {

                        setInputs(values => ({ ...values, is_repeative_event: 0 }));
                        setInputs(values => ({ ...values, repeat_event_gap: "" }));
                        setInputs(values => ({ ...values, interval_days: -60 }));
                        setInputs(values => ({ ...values, interval_days_2: "" }));
                        setInputs(values => ({ ...values, interval_days_3: "" }));


                    } else if (value === "EmptyGarage") {

                        setInputs(values => ({ ...values, is_repeative_event: 0 }));
                        setInputs(values => ({ ...values, repeat_event_gap: "" }));
                        setInputs(values => ({ ...values, interval_days: -30 }));
                        setInputs(values => ({ ...values, interval_days_2: "" }));
                        setInputs(values => ({ ...values, interval_days_3: "" }));


                    }
                    else if (value === "PriceChange") {

                        setInputs(values => ({ ...values, is_repeative_event: 0 }));
                        setInputs(values => ({ ...values, repeat_event_gap: "" }));
                        setInputs(values => ({ ...values, interval_days: -1 }));
                        setInputs(values => ({ ...values, interval_days_2: "" }));
                        setInputs(values => ({ ...values, interval_days_3: "" }));


                    } else if (value === "MaintenanceScheduleReminder") {

                        setInputs(values => ({ ...values, is_repeative_event: 0 }));
                        setInputs(values => ({ ...values, repeat_event_gap: "" }));
                        setInputs(values => ({ ...values, interval_days: -1 }));
                        setInputs(values => ({ ...values, interval_days_2: "" }));
                        setInputs(values => ({ ...values, interval_days_3: "" }));

                    }


                    else {
                        setInputs(values => ({ ...values, is_repeative_event: 1 }));
                        setInputs(values => ({ ...values, repeat_event_gap: 365 }));
                        setInputs(values => ({ ...values, interval_days: "" }));
                        setInputs(values => ({ ...values, interval_days_2: "" }));
                        setInputs(values => ({ ...values, interval_days_3: "" }));
                    }
                }





            }
            else if (name === "location_id") {
                setLoc(value);
                setInputs(values => ({ ...values, [name]: value }));
            }
        } else if (event.target.type === 'checkbox') {

            if (event.target.checked) {
                setInputs(values => ({ ...values, [name]: 1 }));
                console.log(inputs);
            } else {
                setInputs(values => ({ ...values, [name]: 0 }));
                console.log(inputs);
            }
        }
        else if (event.target.type === 'file') {
            filen = event.target.files[0];

            if (name === "attachment_file") {
                setInputs(values => ({ ...values, [name]: filen }));
                setInputs(values => ({ ...values, attachment_file_name: filen.name }));
                setInputs(values => ({ ...values, attachment_content_type: filen.type }));
                setInputs(values => ({ ...values, attachment_file_size: filen.size }));
                setFile(event.target.files[0]);
            } else if (name === "display_image_file") {
                setInputs(values => ({ ...values, [name]: filen }));
            }


        }
        else {
            value = event.target.value;
            setInputs(values => ({ ...values, [name]: value }));
        }
    }


    console.log(inputs);

    // handles form submit
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!inputs.app_id) {
            toast.error("Please select an App", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
            });
            return false;
        }
        if (!inputs.message_type) {
            toast.error("Please select a Message Type", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
            });
            return false;
        }
        setSubmitting(true);
        updateAutomatedPushMessage(inputs, push_id).then((response) => {
            if (response.status === 201 || response.status === 200) {
                setSubmitting(false);
                toast.success("Push Message updated successfully", {
                    position: toast.POSITION.TOP_CENTER,
                    onClose: () => {
                        window.location.reload();
                    }
                });
                window.location.reload();
            } else {
                setSubmitting(false);
                toast.error("Error updating Push Message", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000,
                });
            }
        }).catch(err => {
            errorHandler(err, setSessionModal, setShowToast, toast);
        });
    };


    console.log(push_id, 'push_id');

    // get single message
    useEffect(() => {
        if (push_id) {
            singleAutomatedMessage(push_id).then((response) => {
                if (response.status === 200) {
                    console.log(response.data);
                    const msg = response.data[0];
                    console.log(msg, 'msg');

                    setInputs(values => ({ ...values, message_validity: response.data[0].message_validity }));
                    setInputs(values => ({ ...values, interval_days: response.data[0].interval_days }));
                    setInputs(values => ({ ...values, interval_days_2: response.data[0].interval_days_2 }));
                    setInputs(values => ({ ...values, interval_days_3: response.data[0].interval_days_3 }));
                    setInputs(values => ({ ...values, repeat_event_gap: response.data[0].repeat_event_gap }));
                    setInputs(values => ({ ...values, is_repeative_event: response.data[0].is_repeative_event }));
                    setInputs(values => ({ ...values, is_automated: response.data[0].is_automated }));
                    setInputs(values => ({ ...values, is_published: response.data[0].is_published }));

                    if (msg.app_id) {
                        setApps(msg.app_id);
                        setInputs(values => ({ ...values, app_id: msg.app_id }));
                    }
                    if (msg.location_id) {
                        setLoc(msg.location_id);
                        setInputs(values => ({ ...values, location_id: msg.location_id }));
                    }
                    if (msg.message_type) {
                        setMessageType(msg.message_type);
                        setInputs(values => ({ ...values, message_type: msg.message_type }));
                    }
                    if (msg.footnote) {
                        setInputs(values => ({ ...values, foot_note: msg.footnote }));
                    }
                    if (msg.attachment_file_path) {
                        setInputs(values => ({ ...values, attachment_file_path: msg.attachment_file_path }));
                        setInputs(values => ({ ...values, old_attachment_path: msg.attachment_file_path }));
                    }
                    if (msg.display_image_path) {
                        setInputs(values => ({ ...values, display_image_path: msg.display_image_path }));
                        setInputs(values => ({ ...values, old_image_path: msg.display_image_path }));
                    }
                    if (msg.subject) {
                        setInputs(values => ({ ...values, subject: msg.subject }));
                    }
                    if (msg.body) {
                        setInputs(values => ({ ...values, body: msg.body }));
                    }
                    setInputs(values => ({ ...values, id: msg.id, is_automated: msg.is_automated }));

                } else {
                    setInputs([]);
                }

                console.log(inputs);

            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        }
    }, [push_id]);


    console.log(inputs, 'inputs');


    // file types
    const getFileTypeIcon = (fileType) => {
        console.log(fileType);
        const fileIcons = {
            pdf: 'pdf-logo.png',
            docx: 'docx-logo.png',
            txt: 'txt-logo.png',
            xlsx: 'xlsx-logo.png',
            csv: 'csv-logo.png',
            pptx: 'pptx-logo.png',
            png: 'image-logo.png',
            jpg: 'image-logo.png',
            jpeg: 'image-logo.png',
            PNG: 'image-logo.png',
            // Add more mappings as needed
        };

        const fileName = fileIcons[fileType] || 'general-logo.png';

        const file = require(`../../assets/img/${fileName}`);

        return file;
    };

    console.log(file, 'file global');
    console.log(messageType, 'messageType global', typeof messageType)


    return (
        <div className="col-xl-12">
            {submitting &&
                <div>Submtting Form...</div>
            }
            {showToast && <div className="toast-overlay"></div>}
            <ToastContainer onClose={handleCloseToast} />
            <a href={backUrl} className="btn btn-sm rounded-pill btn-secondary">Back</a>  <a href="/communication" className="btn btn-sm rounded-pill btn-secondary">Communication Home</a> <a href={currentUrl} className="btn btn-sm rounded-pill btn-secondary">Add</a>
            <SessionExpiredModal
                show={sessionModal}
                handleLogout={handleLogout}
            />
            {submitting && <AddUpdateLoading show={submitting} />}
            <form onSubmit={handleSubmit} >
                <div className="card mb-4">
                    <h4 className="card-header">Editing {inputs.is_automated === 1 ? 'Automated' : ''}  Push Message - {push_id}</h4>
                    <div className="card-body">
                        <h5>Message Info</h5>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Apps<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-10">
                                <select className="form-select" id="app_id" name="app_id" onChange={handleChange} value={inputs.app_id || ''} >
                                    <option key='' value="">--Select Apps--</option>
                                    {applist && applist.length > 0 && applist.map((appObj) => (
                                        <option key={appObj.id} value={appObj.id}>{appObj.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Location<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-10">
                                <select className="form-select" id="location_id" name="location_id" onChange={handleChange} value={inputs.location_id || ''} >
                                    <option key='' value="">--Select Location--</option>
                                    {location && location.length > 0 && location.map((locObj) => (
                                        <option key={locObj.value} value={locObj.value}>{locObj.label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Message Type<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-10">
                                <select className="form-select" id="message_type" name="message_type" onChange={handleChange} value={inputs.message_type || ''} >
                                    <option key='' value="">--Select Message Type--</option>
                                    {messageTypes && messageTypes.length > 0 && messageTypes.map((msgObj) => (
                                        <option key={msgObj.id} value={msgObj.name}>{msgObj.title}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="mb-3 row" style={{ height: '15px' }}>
                        </div>
                        <h5>Message Details</h5>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Subject<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-10">
                                <input className="form-control" type="text" id="subject" name="subject" onChange={handleChange} value={inputs.subject || ''} required />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Message<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-10">
                                <textarea className="form-control" type="text" id="body" name="body" onChange={handleChange} value={inputs.body || ''} required />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Foot Note</label>
                            <div className="col-md-10">
                                <textarea className="form-control" type="text" id="foot_note" name="foot_note" onChange={handleChange} value={inputs.foot_note || ''} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Number of Days Validity After Recieve<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-10">
                                <input className="" type="number" size="3" name="message_validity" value={inputs.message_validity || ''} onChange={handleChange} required />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label"> Days Number before/after Desired Date <sup style={{ color: "red", fontSize: 12 }}></sup></label>
                            <div className="col-md-3">
                                <input className="" type="text" size="3" name="interval_days" value={inputs.interval_days || ''} onChange={handleChange} placeholder="Interval 1" />
                            </div>
                            <div className="col-md-3">
                                <input className="" type="text" size="3" name="interval_days_2" value={inputs.interval_days_2 || ''} onChange={handleChange} placeholder="Interval 2" />
                            </div>
                            <div className="col-md-3">
                                <input className="" type="text" size="3" name="interval_days_3" value={inputs.interval_days_3 || ''} onChange={handleChange} placeholder="Interval 3" />
                            </div>
                        </div>


                        <div className="mb-3 row">
                            <label htmlFor="opt_status" className="col-md-3 col-form-label"> Repeated Event</label>
                            <div className="col-md-5 ">
                                <input type="checkbox" id="is_repeative_event" name="is_repeative_event" checked={inputs.is_repeative_event === 1} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Repeat Event Interval<sup style={{ color: "red", fontSize: 12 }}></sup></label>
                            <div className="col-md-3">
                                <input className="" type="text" size="3" name="repeat_event_gap" value={inputs.repeat_event_gap || ''} onChange={handleChange} placeholder="Repeat Frequency" />
                            </div>
                        </div>



                        <div className="mb-3 row">
                            <div className="col-md-10">

                                <img src={inputs.display_image_path ? inputs.display_image_path : "no-image-found.gif"} height="auto" width="250" />:
                            </div>
                            <input className="form-control" type="file" id="display_image_file" name="display_image_file" onChange={handleChange} />
                        </div>



                        {/* <div className="mb-3 row">
                            <label className="col-md-2 col-form-label">Preview</label>
                            <div className="col-md-10">
                                <span>
                                    {inputs.attachment_id ?
                                        <img src={file ? getFileTypeIcon(file.name.split('.').pop()) : (inputs.attachment_file_path ? getFileTypeIcon(inputs.attachment_file_path.split('.').pop()) : getFileTypeIcon(`http://cloud.dealerappvantage.com/api/Files/${inputs.attachment_id}`.split('.').pop()))} height="auto" width="150" alt='attachment' />
                                        :
                                        <img src={file ? getFileTypeIcon(file.name.split('.').pop()) : (inputs.attachment_file_path ? getFileTypeIcon(inputs.attachment_file_path.split('.').pop()) : 'no-image-found.gif')} height="auto" width="150" alt="" />
                                    }
                                </span>
                            </div>
                        </div> */}
                        <div className="mb-3 row">
                            <label htmlFor="html5-input" className="col-md-2 col-form-label">Attachment File</label>
                            <div className="mb-3 col-md-10">
                                <a href={inputs.attachment_file_path}>{inputs.attachment_file_path}</a>
                            </div>


                            <div className="mb-3 col-md-10">
                                <label htmlFor="formFile" className="form-label">Upload File</label>
                                <input className="form-control" type="file" id="attachment_file" name="attachment_file" onChange={handleChange} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <div className="col-md-3">&nbsp;</div>
                            <div className="col-md-7"><input type="submit" className="btn btn-primary col-lg-10" value="Save" /></div>
                            <div className="col-md-2">&nbsp;</div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};


export default EditAutomatedPushMessage;
