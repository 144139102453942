import React from "react";
import Modal from 'react-bootstrap/Modal';




const AddUpdateLoading = ({ show }) => {
    return (
        <Modal
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Submitting...
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex justify-content-center align-items-center' style={{ height: '120px' }}>
                    <div className='spinner-border spinner-border-lg text-primary' role='status'>
                        <span className='visually-hidden'>...</span>
                    </div>
                    <div className='spinner-border spinner-border-lg text-primary' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                    </div>
                    <div className='spinner-border spinner-border-lg text-primary' role='status'>
                        <span className='visually-hidden'>...</span>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};



export default AddUpdateLoading;