import React from "react";


const Marketing = () => {
    return (
        <div>
            <div className="card mb-3">
                <div className="col-md-12 col-xl-12">
                    <div className="card-body" style={{ paddingLeft: '15px' }}>
                        <div className="d-flex align-items-center">
                            <h2 style={{ paddingRight: '10px' }}><strong>Marketing</strong></h2>
                        </div>
                        <div>
                            <br />
                            <h3>App Launch Made Easy</h3>
                            Congratulations on having a branded app for your dealership(s).  Get ready to take the mobile experience to the next level for your customers.  The app contains everything they need to keep them coming back to you.  Here is a brief overview of how to launch and manage your app to maximize the benefits.
                            <br /><br />
                            <h3>Step 1 – AWARENESS</h3>
                            Staff -  Have everyone download it.  Search the app stores for your store or group name. Remind them that they can use the Live Inventory feature to help customers shop for a vehicle.  Also remind them that consumers can choose them as a Staff Member for future communication.
                            <br /><br />
                            Consumers -  Market the app to the world.  Set us up with your marketing person or company.  We will provide you with the digital assets for:
                            <ul class="list-unstyled">
                                <ul>
                                    <li>POS material – Examples: <a href="https://mlink-cloud-cms.dealerappvantage.com/uploads/poster_17ebc0fe8a.jpg" target="_blank">Posters</a>, <a href="https://mlink-cloud-cms.dealerappvantage.com/uploads/table_toppers_8c26127a2f.JPG" target="_blank">Table Toppers</a>, <a href="https://mlink-cloud-cms.dealerappvantage.com/uploads/video_display_403c840d56.jpg" target="_blank">Video Displays</a>, <a href="https://mlink-cloud-cms.dealerappvantage.com/uploads/window_cling_ba0795c186.JPG" target="_blank">Window Clings</a></li>
                                    <li>CRM email blast - Edit existing messages or create a blast to announce the app - <a href="https://mlink-cloud-cms.dealerappvantage.com/uploads/email_blast_8c555ee458.png" target="_blank">Example 1</a><a href="https://mlink-cloud-cms.dealerappvantage.com/uploads/email_blast2_7807429034.png" target="_blank">, Example 2</a></li>
                                    <li>Website – landing page</li>
                                    <li>Mobile site – mobile app redirect code -<a href="https://mlink-cloud-cms.dealerappvantage.com/uploads/mobile_pop_4035f2233e.jpg" target="_blank">Example</a></li>
                                    <li>Social media  - Include link in Facebook, Instagram, Twitter</li>
                                </ul>
                            </ul>
                            <br />
                            <h3>Step 2 – PUSH NOTIFICATIONS</h3>
                            Geofence - Using <a href="/communication/geofencepushmessages"> Geo-Fencing </a> , set up digital "fences" around your dealership to have your customers receive a welcome message automatically when they arrive to your dealership. Remember you can geofence literally any location on Google Maps you want so think outside the box and get creative with way you can market to your customer!
                            <br /><br />
                            Push Notification Strategy - Setup and implement a Push Notification plan. We recommend 2 Push Notifications per month advertising your specials and deals. Don't forget holiday sales events as well! Use the <a href="/communication"> Communication </a> module send those notifications to your loyal customers.
                            <br /> <br />
                            <h3>Step 3 – FEATURES & INTEGRATIONS</h3>
                            Revisit the list of features included with your app and determined what you will launch immediately an which you will launch over time. Setup your integrations (DMS, CRM, ETC.) Lastly don't forget to setup any of the additional features and integrations we've built for you. Take advantage of the power and customizable <a href="/rewards"> Loyalty Rewards</a> feature to keep your customers earning and coming back. Give them secure and convenient access to their important documents with mDocs. Stay connected with your customers with live video chat and more.
                            <br /> <br />
                            <h3>THAT'S IT!</h3>
                            It only takes a little bit of work to get thousands of downloads and app activity.  The benefits are endless. But what about 3, 6, 12 months from now?  Is it difficult to maintain the app?  With our help not at all.  It is as easy as repeat steps 1 – 3.
                            <br /><br />
                            *Setup an appointment with our support team to help with these steps and for web app portal walk through. For further details please review our Marketing Your App manual or contact your Product Specialist at support@dealerappvantage.com or 732-317-0089 Opt 3

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Marketing;