
import React, { useState, useEffect } from 'react';

const SortableAnalyticsTable = ({ analyticsDataDetailsLocs }) => {
    const [sortBy, setSortBy] = useState('downloads'); // Default sorting by 'downloads'
    const [sortOrder, setSortOrder] = useState('desc'); // Default sorting order

    const handleSort = (columnName) => {
        if (sortBy === columnName) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(columnName);
            setSortOrder('desc'); // Reset sorting order when changing column to sort by
        }
    };

    const sortedData = () => {
        if (!sortBy) return analyticsDataDetailsLocs[0];
        return [...analyticsDataDetailsLocs[0]].sort((a, b) => {
            const aValue = a[sortBy];
            const bValue = b[sortBy];
            if (sortOrder === 'desc') {
                if (aValue < bValue) return -1;
                if (aValue > bValue) return 1;
                return 0;
            } else {

                if (aValue > bValue) return -1;
                if (aValue < bValue) return 1;
                return 0;
            }
        });
    };

    useEffect(() => {
        // Default sorting behavior when component mounts
        handleSort('downloads');
    }, []); // Empty dependency array ensures this effect runs only once

    return (
        <table className="table table-sm">
            <thead style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#f2f2f2' }}>
                <tr>
                    <SortableHeader columnName="location_name" displayName="Location" sortBy={sortBy} sortOrder={sortOrder} handleSort={handleSort} />
                    <SortableHeader columnName="downloads" displayName="Downloads" sortBy={sortBy} sortOrder={sortOrder} handleSort={handleSort} />
                    <SortableHeader columnName="profiles" displayName="Profiles Created" sortBy={sortBy} sortOrder={sortOrder} handleSort={handleSort} />
                    <SortableHeader columnName="rewardPointsEarned" displayName="Rewards Earned" sortBy={sortBy} sortOrder={sortOrder} handleSort={handleSort} />
                    <SortableHeader columnName="rewardPointsRedeemed" displayName="Rewards Redeemed" sortBy={sortBy} sortOrder={sortOrder} handleSort={handleSort} />
                    <SortableHeader columnName="customerDocuments" displayName="Customer Documents" sortBy={sortBy} sortOrder={sortOrder} handleSort={handleSort} />
                </tr>
            </thead>
            <tbody className="table-border-bottom-0">
                {sortedData().map((analyticObj) => (
                    <tr key={analyticObj.id}>
                        <td>{analyticObj.location_name}</td>
                        <td>{analyticObj.downloads}</td>
                        <td>{analyticObj.profiles}</td>
                        <td>{analyticObj.rewardPointsEarned}</td>
                        <td>{analyticObj.rewardPointsRedeemed}</td>
                        <td>{analyticObj.customerDocuments}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

const SortableHeader = ({ columnName, displayName, sortBy, sortOrder, handleSort }) => {
    const handleClick = () => {
        handleSort(columnName);
    };

    return (
        <th style={{ backgroundColor: '#f2f2f2', color: '#333', fontWeight: 'bold', border: '2px solid #ccc', padding: '8px', cursor: 'pointer', position: 'sticky' }} onClick={handleClick}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{displayName}</span>
                <button onClick={handleClick} style={{ marginLeft: '4px', background: 'none', border: 'none', cursor: 'pointer', padding: '0' }}>
                    {sortBy === columnName && (
                        sortOrder === 'desc' ? <i className="bx bx-chevron-down bx-sm align-middle"></i> : <i className="bx bx-chevron-up bx-sm align-middle"></i>
                    )}
                </button>
            </div>
        </th>
    );
};

export default SortableAnalyticsTable;
