import React from "react";
import { useState } from "react";
import axiosInstance from "../../services/AxiosInstance";
import moment from "moment";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv, faCalendarDays, faLocationDot } from '@fortawesome/free-solid-svg-icons';

const AnalyticsCSVDownload = ({ appId, startDate, endDate, csvLoading, setCsvLoading }) => {
    const [csvData, setCsvData] = useState([]);

    const handleDownloadByDate = () => {
        setCsvLoading(true);
        // get app name
        let applist = [];
        const userData = JSON.parse(localStorage.getItem('user'));
        if (userData.apps.length > 0) {
            applist = userData.apps;
        } else {
            applist = [];
        }

        const appData = applist.find(item => item.id === appId);
        const appName = appData.name;

        // get date range
        let startDates, endDates;
        if (startDate !== null && endDate !== null) {
            startDates = moment(startDate).format('YYYY-MM-DD');
            endDates = moment(endDate).format('YYYY-MM-DD');
        } else if (startDate !== null && endDate === null) {
            startDates = moment(startDate).format('YYYY-MM-DD');
            endDates = moment().format('YYYY-MM-DD');
        } else if (startDate === null && endDate !== null) {
            startDates = moment().startOf('month').format('YYYY-MM-DD');
            endDates = moment(endDate).format('YYYY-MM-DD');
        } else {
            startDates = moment().startOf('month').format('YYYY-MM-DD');
            endDates = moment().format('YYYY-MM-DD');
        }
        axiosInstance.get('/v2/analytics/download-csv-date?appId=' + appId + '&startDate=' + startDates + '&endDate=' + endDates).then((response) => {
            if (response.status === 200) {
                setCsvData(response.data);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                const fileName = 'Analytics Data by Date for ' + String(appName) + ' from ' + String(startDates) + ' to ' + String(endDates) + '.csv'
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
            } else {
                setCsvData([]);
            }
        }).catch(err => {
            console.log(err);
        });
        setCsvLoading(false);
    }

    const handleDownloadByLoc = () => {
        setCsvLoading(true);
        // get app name
        let applist = [];
        const userData = JSON.parse(localStorage.getItem('user'));
        if (userData.apps.length > 0) {
            applist = userData.apps;
        } else {
            applist = [];
        }

        const appData = applist.find(item => item.id === appId);
        const appName = appData.name;


        // get date range
        let startDates, endDates;
        if (startDate !== null && endDate !== null) {
            startDates = moment(startDate).format('YYYY-MM-DD');
            endDates = moment(endDate).format('YYYY-MM-DD');
        } else if (startDate !== null && endDate === null) {
            startDates = moment(startDate).format('YYYY-MM-DD');
            endDates = moment().format('YYYY-MM-DD');
        } else if (startDate === null && endDate !== null) {
            startDates = moment().startOf('month').format('YYYY-MM-DD');
            endDates = moment(endDate).format('YYYY-MM-DD');
        } else {
            startDates = moment().startOf('month').format('YYYY-MM-DD');
            endDates = moment().format('YYYY-MM-DD');
        }
        axiosInstance.get('/v2/analytics/download-csv-loc?appId=' + appId + '&startDate=' + startDates + '&endDate=' + endDates).then((response) => {
            if (response.status === 200) {
                setCsvData(response.data);
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                const fileName = 'Analytics Data by Location for ' + String(appName) + ' from ' + String(startDates) + ' to ' + String(endDates) + '.csv';
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();
            } else {
                setCsvData([]);
            }
        }).catch(err => {
            console.log(err);
        });
        setCsvLoading(false);
    };

    console.log(csvData, 'csvData');

    return (
        <>
            <button className="dropdown-toggle hide-arrow btn btn-sm btn-primary rounded-pil" style={{ width: '120px', height: '30px', marginLeft: '10px', border: "none" }} data-bs-toggle="dropdown">
                {csvLoading ? <strong>Downloading...</strong> : <>Export CSV  <FontAwesomeIcon icon={faFileCsv} /></>}
            </button>
            <ul className="dropdown-menu dropdown-menu-end" style={{ width: '50px', height: '100px' }}>
                <button className="dropdown-item btn-sm" style={{ textAlign: 'center' }} onClick={handleDownloadByDate}><FontAwesomeIcon icon={faCalendarDays} /> Data</button>
                <div className="dropdown-divider"></div>
                <button className="dropdown-item btn-sm" style={{ textAlign: 'center' }} onClick={handleDownloadByLoc}><FontAwesomeIcon icon={faLocationDot} /> Data</button>
            </ul>
        </>
    )
};


export default AnalyticsCSVDownload;