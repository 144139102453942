import axiosInstance from "../services/AxiosInstance";


// utility function for format datetime
// const formatDateTime = (date) => {
//     if (date) {
//         const year = date.toDate().getFullYear();
//         const month = date.toDate().getMonth() + 1;
//         const day = date.toDate().getDate();
//         const hour = date.toDate().getHours();
//         const minute = date.toDate().getMinutes();
//         const second = date.toDate().getSeconds();
//         return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
//     }
// };

// get all staffs cpage is current page
export const clientData = (cpage, searchkey, clientsPerPage, sort) => {
    const offset = (cpage - 1) * clientsPerPage;
    let url = '/v2/admin/clients';
    let questionadded = false;


    if (cpage !== undefined && clientsPerPage !== undefined) {
        url = url + "?offset=" + offset + "&limit=" + clientsPerPage;
        questionadded = true; // tracking and added
    }
    const user = JSON.parse(localStorage.getItem('user'));
    const role = user.role;
    const userid = user.id;
    if (role !== 'administrator') {
        return;
    }




    if (searchkey !== '' && searchkey) {
        let searchkeyencoded = encodeURIComponent(searchkey);
        if (questionadded) {
            url = url + '&searchkey=' + searchkeyencoded;
        } else {
            url = url + '?searchkey=' + searchkeyencoded;
            questionadded = true;
        }
    }
    console.log(sort);
    if (sort) {
        if (questionadded) {
            url = url + '&sort=' + sort;
        }
        else {
            url = url + '?sort=' + sort;
            questionadded = true;
        }
    }





    console.log(url);
    return axiosInstance.get(url, {
        headers: {
            'Content-Type': 'application/json',
            'userid': userid,
            'userrole': role
        }
    });
}


// get all staffcount

export const clientCount = (searchkey) => {

    let url = '/v2/admin/clients/count';

    let questionadded = false;
    const user = JSON.parse(localStorage.getItem('user'));
    const role = user.role;
    if (role !== 'administrator') {
        return;
    }
    const userid = user.id;

    if (searchkey && searchkey !== "") {
        let searchkeyencoded = encodeURIComponent(searchkey);
        if (questionadded) {
            url = url + "&searchkey=" + searchkeyencoded;
        } else {
            questionadded = true;
            url = url + "?searchkey=" + searchkeyencoded;
        };
    }
    console.log(url);

    return axiosInstance.get(url, {
        headers: {
            'Content-Type': 'application/json',
            'userid': userid,
            'userrole': role
        }
    });
}


// get Client by id
export const getClientById = (id) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const role = user.role;

    if (role !== 'administrator') {
        return;
    }
    const userid = user.id;

    const url = "v2/admin/clients/" + id;
    console.log(url);

    return axiosInstance.get(url, {
        headers: {
            'Content-Type': 'application/json',
            'userid': userid,
            'userrole': role
        }
    });
};



// // add Client
export const addClient = (inputs) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const role = user.role;

    if (role !== 'administrator') {
        return;
    }
    const userid = user.id;
    const formData = new FormData();
    formData.append("name", inputs.client_name);
    formData.append("clientNumber", inputs.client_number);
    formData.append("apiKey", inputs.api_key);
    formData.append("isActive", inputs.is_active);
    formData.append("createdBy", userid);
    const url = "/v2/admin/clients";
    return axiosInstance.post(url, formData, {
        headers: {
            'Content-Type': 'application/json',
            'userid': userid,
            'userrole': role
        }
    }
    );
};


// // update client
export const updateClient = (inputs, locationChanged) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const role = user.role;

    if (role !== 'administrator') {
        return
    }
    const userid = user.id;
    const formData = new FormData();
    formData.append("id", inputs.id);
    formData.append("name", inputs.client_name);
    formData.append("clientNumber", inputs.client_number);
    formData.append("apiKey", inputs.api_key);
    formData.append("isActive", inputs.is_active);
    formData.append("updatedBy", userid);
    const url = "/v2/admin/clients/" + inputs.id;
    console.log(url);

    return axiosInstance.patch(url, formData, {
        headers: {
            'Content-Type': 'application/json',
            'userid': userid,
            'userrole': role
        }
    }
    );
};


// // delete client
// export const deleteClient = (e, staffObj, setDel) => {
//     e.preventDefault();
//     const id = e.target.getAttribute("data-key");
//     const imagePath = staffObj.image_path;
//     setDel(true);
//     return axiosInstance.delete("/v2/staff/del", { data: { id: id, image_path: imagePath } });
// };



