import React from 'react';

const LocationsDropDownComponent = ({ handleChange, loc, location }) => {
    let currentLoc = '';
    if (loc && loc !== 'null' && loc !== null && typeof loc === 'number') {
        console.log(loc + 'from locationsdropdowncomponent');
        const currenLoc = location.filter((location) => location.id === loc);
        //console.log(currenLoc);
        if (currenLoc && currenLoc.length > 0) {
            currentLoc = currenLoc[0].name;
        }
        //console.log(currentLoc);
    }

    return (
        <>
            <label className="mx-2 col-form-label" style={{ color: 'black' }}>Location </label>
            <div className="col-md-3">
                <select className="form-select" id="location_id" name="location_id" onChange={handleChange} value={loc || currentLoc} >
                    <option key='' value="">--Select Location--</option>
                    {location && location.length > 0 && location.map((locationObj) => (
                        <option key={locationObj.id} value={locationObj.id}>{locationObj.name}</option>

                    ))}
                </select>
            </div>
        </>
    );
};

export default LocationsDropDownComponent;
