import React, { useEffect } from "react";
import Modal from 'react-bootstrap/Modal';
import { useState } from "react";
import { toast } from "react-toastify";
import { errorHandler } from "../Utils/errorHandler";
import { addDocumentToCustomer, getCustomerVehicleVinByLocationAndCustomerId } from "../../apis/ProfilesAPI";
import { useParams } from 'react-router-dom';
import AddUpdateLoading from "../Utils/AddUpdateLoading";


const AddDocumentModal = ({ show, setShow, locationId }) => {
    const { customer_id } = useParams();
    const [inputs, setInputs] = useState([]);
    const [sessionModal, setSessionModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [loading, setLoading] = useState(false);
    const [vehicles, setVehicles] = useState([]);

    const handleClose = () => {
        setShow(false);
    }

    // append customer id to inputs
    useEffect(() => {
        setInputs(inputs => ({ ...inputs, customerId: customer_id }));
        setInputs(inputs => ({ ...inputs, uploadVia: 2 }));
    }, [customer_id]);

    // handle change
    const handleChange = (e) => {
        const name = e.target.name;
        let value;
        if (e.target.type === 'file') {
            value = e.target.files[0];
            setInputs(inputs => ({ ...inputs, filename: value.name }));
        }
        else if (e.target.type === 'select-one') {
            value = parseInt(e.target.value);
            setInputs(inputs => ({ ...inputs, vehicleId: value }));
            // console.log(value, 'value');
            // console.log(vehicles, 'vehicles');
            const vehicle = vehicles.find(vehicle => vehicle.id === value);
            // console.log(vehicle, 'vehicle');
            if (vehicle) {
                setInputs(inputs => ({ ...inputs, vin: vehicle.vin }));
            }
        }
        else {
            value = e.target.value;
        }
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }



    // get vehicle vin by location and customer id
    useEffect(() => {
        if (locationId && customer_id) {
            getCustomerVehicleVinByLocationAndCustomerId(locationId, customer_id)
                .then((res) => {
                    if (res.status === 200) {
                        setVehicles(res.data);
                    } else {
                        setVehicles([]);
                    }
                }).catch((err) => {
                    errorHandler(err, setSessionModal, setShowToast, toast);
                });
        }
    }, [locationId, customer_id]);


    // console.log(vehicles, 'vehicles from document modal');

    // add document to customer
    const handleAddDocument = (e) => {
        e.preventDefault();
        if (!inputs.name || !inputs.document_file_name) {
            toast.error(`Please fill all the fields!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: false,
                onClose: () => {
                    setShow(false);
                }
            });
            return;
        }

        setLoading(true);
        if (document) {
            addDocumentToCustomer(inputs)
                .then((res) => {
                    if (res.status === 201) {
                        toast.success(`Document added successfully!`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            hideProgressBar: false,
                            onClose: () => {
                                setShow(false);
                                window.location.reload();
                            }
                        });
                    }
                }).catch((err) => {
                    errorHandler(err, setSessionModal, setShowToast, toast);
                });
        }
        else {
            toast.error(`Please select a document to upload!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: false,
                onClose: () => {
                    setShow(false);
                }
            });
        }
        setLoading(false);
    }

    console.log(inputs, 'inputs');

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Document</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? <AddUpdateLoading /> :
                        <form onSubmit={handleAddDocument}>
                            <div className="form-group">
                                <label htmlFor="vehicleId">Vehicle VIN</label>
                                <select className="form-control" id="vehicleId" name="vehicleId" onChange={handleChange} >
                                    <option value="">--Select Vehicle--</option>
                                    {vehicles && vehicles.length > 0 && vehicles.map((vehicle, index) => (
                                        <option key={index} value={vehicle.id}>{vehicle.vin}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="name">Document Name</label>
                                <input type="text" className="form-control" id="name" name="name" onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="document">Document</label>
                                <input type="file" className="form-control" id="document" name="document_file_name" onChange={handleChange} />
                            </div>
                        </form>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button type="submit" className="btn btn-primary" onClick={handleAddDocument} >Add Document</button>
                    <button type="button" className="btn btn-secondary" onClick={handleClose}>Close</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AddDocumentModal;
