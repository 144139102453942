import React from "react";
import { useState, useEffect } from "react";
import { targetedMessages, targetedMessagesCount, deleteTargetedPushMessage } from "../../apis/CommunicationAPI";
import { appData } from "../../apis/AppsAPI";
import { locationData } from "../../apis/LocationAPI";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { errorHandler } from "../Utils/errorHandler";
import PaginationComponent from "../Pagination/Pagination";
import Loading from "../Utils/Loading";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ReadSubject from "../Utils/ReadSubject";
import ModuleHeader from "../Header/ModuleHeader";



const TargetedPushMessages = () => {
    const [targetedPushMessages, setTargetedPushMessages] = useState([]);
    const [applist, setApplist] = useState([]);
    const [location, setLocation] = useState([]);
    const [numberOfRows, setNumberOfRows] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [showSessionModal, setShowSessionModal] = useState(false);
    const [targetedMessagesPerPage] = useState(30);
    const [showToast, setShowToast] = useState(false);
    const [sessionModal, setSessionModal] = useState(false);



    const navigate = useNavigate();
    const search = useLocation().search;
    const queryParams = new URLSearchParams(search);
    const app = queryParams.get('app_id') ? parseInt(queryParams.get('app_id')) : null;
    const locs = queryParams.get('loc_id') ? parseInt(queryParams.get('loc_id')) : null;
    const searchstr = queryParams.get('searchkey') ? queryParams.get('searchkey') : null;
    const currentP = queryParams.get('page') ? parseInt(queryParams.get('page')) : 1;
    const stDate = queryParams.get('start') ? moment(queryParams.get('start')) : null;
    const enDate = queryParams.get('end') ? moment(queryParams.get('end')) : null;

    const userData = JSON.parse(localStorage.getItem('user'));
    const rol = userData.role;


    const [apps, setApps] = useState(app);
    const [loc, setLoc] = useState(locs);
    const [searchkey, setSearchkey] = useState(searchstr);
    const [currentPage, setCurrentPage] = useState(currentP);
    const [startDate, setStartDate] = useState(stDate);
    const [endDate, setEndDate] = useState(enDate);
    const [role, setRole] = useState(rol);

    let searchTitle = "Search for messages by subject";
    let buttonTitle = "Add New Targeted Message";
    let buttonPath = `/communication/addtargetedpushmessage`;
    let dateSearchtitle = "Search for messages created within a date range";


    // check if start date is not null
    useEffect(() => {
        if (startDate && !endDate) {
            setStartDate(moment(startDate).format('YYYY-MM-DD HH:mm:ss'));
            setEndDate(moment().format('YYYY-MM-DD HH:mm:ss'));
        }
        else if (!startDate && endDate) {
            setStartDate(moment('2000-01-01').format('YYYY-MM-DD HH:mm:ss'));
            setEndDate(moment(endDate).format('YYYY-MM-DD HH:mm:ss'));
        }
        else if (startDate && endDate) {
            setStartDate(moment(startDate).format('YYYY-MM-DD HH:mm:ss'));
            setEndDate(moment(endDate).format('YYYY-MM-DD HH:mm:ss'));
        }
        else {
            setStartDate(null);
            setEndDate(null);
        }
    }, [startDate, endDate]);



    // get all apps
    useEffect(() => {
        const apps = appData();
        if (apps.length === 1) {
            setApps(apps[0].id);
            setApplist(apps);
        }
        else if (apps.length > 1) {
            setApplist(apps);
        }
    }, []);



    // get all locations
    useEffect(() => {
        if (apps) {
            locationData(apps).then((res) => {
                if (res.status === 200) {
                    setLocation(res.data);
                }
                else {
                    setLocation([]);
                }
            }).catch((err) => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        }
    }, [apps]);


    // get all targeted messages count
    useEffect(() => {
        targetedMessagesCount(apps, loc, searchkey, startDate, endDate, applist, role).then((res) => {
            if (res.status === 200) {
                setNumberOfRows(res.data[0].count);
                setNumberOfPages(Math.ceil(res.data[0].count / targetedMessagesPerPage));
            }
            else {
                setNumberOfRows(0);
                setNumberOfPages(0);
            }
        }).catch((err) => {
            errorHandler(err, setSessionModal, setShowToast, toast);
        });
    }, [apps, loc, searchkey, startDate, endDate, applist, role]);


    // get all targeted messages
    useEffect(() => {
        targetedMessages(apps, loc, currentPage, searchkey, startDate, endDate, applist, targetedMessagesPerPage, role).then((res) => {
            if (res.status === 200) {
                setTargetedPushMessages(res.data);
                setTimeout(() => setIsLoading(false), 1000);
            }
            else {
                setTargetedPushMessages([]);
                setTimeout(() => setIsLoading(false), 1000);
            }
        }).catch((err) => {
            errorHandler(err, setSessionModal, setShowToast, toast);
        });
    }, [apps, loc, targetedMessagesPerPage, searchkey, currentPage, startDate, endDate, applist, role]);



    // delete targeted message
    const deleteTargetedMessage = (e, id) => {
        e.preventDefault();

        if (!window.confirm("Are you sure you want to delete this standard message?")) return;

        deleteTargetedPushMessage(id).then((res) => {
            if (res.status === 200 || res.status === 201) {
                toast.success("Message Deleted Successfully", { position: toast.POSITION.TOP_CENTER });
                targetedMessages(apps, loc, currentPage, searchkey, startDate, endDate, applist, targetedMessagesPerPage, role).then((res) => {
                    if (res.status === 200) {
                        setTargetedPushMessages(res.data);
                        setIsLoading(false);
                    }
                    else {
                        setTargetedPushMessages([]);
                        setIsLoading(false);
                    }
                }).catch((err) => {
                    errorHandler(err, showSessionModal, setShowToast, toast);
                });
            }
            else {
                toast.error("Message Deletion Failed", { position: toast.POSITION.TOP_CENTER });
            }
        }).catch((err) => {
            errorHandler(err, setSessionModal, setShowToast, toast);
        });
    };



    // update query params
    useEffect(() => {
        // Check if currentPage is greater than numberOfPage
        if (numberOfPages > 0 && currentPage > numberOfPages) {
            setCurrentPage(1);
        }

        // Update the URL query params when the state of the hook changes
        const updateQueryParams = () => {
            const queryParams = new URLSearchParams();
            if (apps) queryParams.set('app_id', apps);
            if (loc) queryParams.set('loc_id', loc);
            if (searchkey) queryParams.set('searchkey', searchkey);
            if (currentPage) queryParams.set('page', currentPage);
            if (startDate) queryParams.set('start', startDate);
            if (endDate) queryParams.set('end', endDate);
            const queryString = queryParams.toString();
            const newUrl = `${window.location.pathname}?${queryString}`;
            window.history.pushState({ path: newUrl }, '', newUrl);
        };
        updateQueryParams();
    }, [apps, loc, searchkey, currentPage, startDate, endDate, numberOfPages, navigate]);



    // handle change
    const handleChange = (e) => {
        setCurrentPage(1);

        const name = e.target.name;
        let value;

        if (e.target.type === 'select-one') {
            let selectedIndex = e.target.options.selectedIndex;
            let tvalue = document.getElementById(name).value;

            // if no value is selected, then set value to null
            if (selectedIndex === 0) {
                if (name === 'location_id') {
                    setLoc(null);
                }
                else if (name === 'app_id') {
                    setApps(null);
                    setLoc(null);
                    setLocation([]);
                }
            }
            else {
                if (name === 'location_id') {
                    value = + tvalue;
                    setLoc(value);
                }
                else if (name === 'app_id') {
                    value = + tvalue;
                    setApps(value);
                    setLoc(null);
                }
            }
        }
        else {
            if (name === 'search_inputs') {
                value = e.target.value;
                setSearchkey(value);
            }
        }
    };


    // close toast
    const handleCloseToast = () => {
        setShowToast(false);
    };

    // logs out a user
    const handleLogout = async () => {
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location = "/";
    };


    return (
        <div>
            <div>
                <a href={'/communication'} type="button" className="btn btn-sm rounded-pill btn-secondary">Back</a><a href="/communication" className="btn btn-sm rounded-pill btn-secondary">Communication Home</a><a href={'/communication/addtargetedpushmessage' + window.location.search} type="button" className="btn btn-sm rounded-pill btn-secondary">Add Targeted Push Message</a>
                {showToast && <div className="toast-overlay"></div>}
                <ToastContainer onClose={handleCloseToast} />
                <ModuleHeader
                    searchKey={searchkey}
                    searchTitle={searchTitle}
                    buttonTitle={buttonTitle}
                    buttonPath={buttonPath}
                    dateSearchTitle={dateSearchtitle}
                    handleChange={handleChange}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    sessionModal={sessionModal}
                    handleLogout={handleLogout}
                    apps={apps}
                    applist={applist}
                    loc={loc}
                    location={location}
                    showDeptDropdown={false}
                    showCreateNewButton={true} />
                <div className="alert alert-primary" role='alert'><strong>{numberOfRows} push messages found</strong></div>
                {isLoading ?
                    <div>
                        <Loading />
                    </div> : <div>
                        <div className="table-responsive text-nowrap">
                            <table className="table table-sm table-bordered">
                                <thead>
                                    <tr>
                                    <th>T. MID</th>

                                        <th>Customer</th>
                                        <th>App</th>
                                        <th>Location</th>
                                        <th>Subject</th>
                                        <th>R. Schedule(EST)</th>

                                        <th>Created</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody className="table-border-bottom-0">
                                    {targetedPushMessages && targetedPushMessages.length > 0 && targetedPushMessages.map((tpmObj) => (
                                        <tr key={tpmObj.id}>
                                          
                                            <td><a href={`/communication/edittargetedpushmessage/${tpmObj.id}`}>{tpmObj.id}</a></td>

                                            <td><a href={`/communication/edittargetedpushmessage/${tpmObj.id}`}>{tpmObj.Customer}</a></td>
                                            <td><a href={`/communication/edittargetedpushmessage/${tpmObj.id}`}>{tpmObj.App}</a></td>
                                            <td><a href={`/communication/edittargetedpushmessage/${tpmObj.id}`}>{tpmObj.Location}</a></td>
                                            <td><ReadSubject>{tpmObj.Subject}</ReadSubject></td>
                                            <td>{(tpmObj.release_time ) ? tpmObj.release_time:'-'}</td>
                                            
                                            
                                            <td>{(tpmObj.Created === '0000-00-00' || !tpmObj.Created) ? null : moment(tpmObj.Created).format('YYYY-MM-DD')}</td>
                                            <td>
                                                <div className="btn-group">
                                                    <a href={`/communication/edittargetedpushmessage/${tpmObj.id}`} className="btn btn-sm btn-outline-secondary"><i className='bx bxs-edit'></i></a>
                                                    <button className="btn btn-sm btn-outline-secondary" onClick={(e) => deleteTargetedMessage(e, tpmObj)}><i className='bx bxs-trash'></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="pagination-container" style={{ display: "flex", flexWrap: "wrap", paddingTop: "20px" }}>
                            <PaginationComponent
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                numberOfPage={numberOfPages}
                                setShowToast={setShowToast}
                                numVisiblePages={50}
                            />
                        </div>
                    </div>}

            </div>
        </div>
    );

}


export default TargetedPushMessages;