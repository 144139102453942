import React from "react";
import { useEffect } from "react";
import Loading from "../Utils/Loading";

const AnalyticsTopDate = ({analyticsDataDetailsDate, topAnalyticDataDate, setTopAnalyticDataDate, insightDateLoading, setInsightDateLoading}) => {
    // get top dates
    useEffect(() => {
        // get top analytics data by date
        const topAnalyticsDataDate = (analyticsDataDetailsDate) => {
            console.log('ok')
            let topAnalyticsData = [];
            setInsightDateLoading(true);
            analyticsDataDetailsDate.forEach(element => {
                console.log('elem', element)
                Object.keys(element).forEach(key => {
                    if (element[key].length > 0) {
                        let maxVal = Number.NEGATIVE_INFINITY;
                        let maxDate = null;
                        element[key].forEach(data => {
                        if (data.val > maxVal) {
                            maxVal = data.val;
                            maxDate = data.date;
                        }
                        });
                        if (maxVal !== Number.NEGATIVE_INFINITY){
                            topAnalyticsData.push({metric: key, date: maxDate, val: maxVal});
                        }
                    }
                });
            });
            console.log('top', topAnalyticsData);
            setTopAnalyticDataDate(topAnalyticsData);
            setTimeout(() => {
                setInsightDateLoading(false);
            } , 1000);
        };
        topAnalyticsDataDate(analyticsDataDetailsDate);
    }, [analyticsDataDetailsDate, setTopAnalyticDataDate, setInsightDateLoading]);


    return (
        <div>
            {insightDateLoading ?
                <Loading /> :
                <div className="card">
                    <div className="card-header">
                        <h4 style={{color:'#000000FF', textAlign:'center'}}>Top Dates</h4>
                    </div>
                    <div className="card-body">
                        <div className="card-row" style={{display:'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                            {topAnalyticDataDate.length > 0 && topAnalyticDataDate.map((item, index) => (
                                <div className="card-column" key={index} style={{flex: '1 1 calc(25% - 10px)', marginBottom: '5px', marginLeft: '5px'}}>
                                    <div className="card mb-4" style={{height:'200px'}}>
                                        <div className="card-header" style={{height:'70px'}}> 
                                            <h5 style={{textAlign:"center", color:'#000000FF'}}>Most {item.metric}</h5>
                                        </div>
                                        <div className="card-body">
                                            <h5 style={{ color: "#B1624EFF", textAlign:"center" }}>{item.date}</h5>
                                            <h5 style={{ color: "#343148FF", textAlign:"center" }}>{item.val}</h5>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default AnalyticsTopDate;