import React, { useState } from "react";

const ReadMore = ({ children }) => {
    const text = JSON.stringify(children).replace(/['"]+/g, '');
    const [isReadMore, setIsReadMore] = useState(true);

    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    return (
        <>
            {text.length < 50 ? text :
                <p className="textR">
                    {isReadMore ? text.slice(0, 50) : text}
                    <span onClick={toggleReadMore} className="read-or-hide">
                        {isReadMore ? " ...show more" : " ...show less"}
                    </span>
                </p>
            }
        </>
    );
};


export default ReadMore;