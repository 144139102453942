import React from "react";


const Help = () => {

    return (
        <div>
            <div className="card mb-3">
                <div className="col-md-12 col-xl-12">
                    <div className="card-body" style={{ paddingLeft: '15px' }}>
                        <div className="d-flex align-items-center">
                            <h2 style={{ paddingRight: '10px' }}><strong>Communications</strong></h2>
                        </div>
                        <div>The communications module is where you manage the powerful push notification system to communicate with your app users.
                            Push notifications are a very important tool for keeping your mobile app top of mind and your app users engaged. There are two categories of push notifications to be aware of, <b>Standard</b> and <b>Automated</b>.
                            <br /><br />
                            <h3>Standard</h3>
                            Standard notifications are push notifications that you generate manually. Within the standard notification category there are different types of messages that can be generated each with an specified intent to better align with your marketing strategy.
                            <br /><br />
                            <ul class="list-unstyled">
                                <ul>
                                    <li><a href="/communication/globalpushmessages">Global Messaging</a></li>
                                    <li><a href="/communication/targetedpushmessages">Targeted Messaging</a></li>
                                </ul>
                            </ul>
                            <u>Global Messaging</u>
                            <br />
                            As its name implies, global messaging allows you to send push notifications to your entire app customer base. These are the messages that you likely use most often and ones that you typically receive on your mobile devices from other apps. When a customer first installs the app, they select a location or a particular dealership rooftop as their default location or dealership. That mainly applies to mobile apps that have multiple rooftops within a single mobile app, so obviously if there is only one rooftop that’s the only location that’s managed. In the locations dropdown menu, whatever locations you select will send messages to customers of those specific locations. This is great for dealer groups who have a single mobile app but only want to message customers of a certain dealership.
                            <br /> <br />
                            Next is the type of message you want to send. We have general, sales, and service. All three allow you to put whatever text, media, and attachment you want in the message but here are the differences. With a sales message you can select which vehicles are related to a particular sale or special that you are informing your customers about. When they receive that push notification and tap on it, inside the message is an action button to take them right into the inventory of those specific vehicles related to that sale.
                            A Service message allows you to embed a coupon code in the message and when that message is received and opened, it contains an action button to take them right to the service scheduler. General messages do not have those additional action buttons and workflows.
                            <br /> <br />
                            <u>Targeted Messaging</u>
                            <br />
                            Targeted Messages allow you to target specific app customers. Here the message types are the same as global messages but a new field here is the customer dropdown. Here you can actually select specific customers that you want to send messages to. It’s important to note that only customers who actually register a profile in the app will appear here. Simply select the customers you want to target, compose your message like we reviewed in the global message section and send it immediately or schedule it for a future date.
                            <br />  <br />
                            <h3>Automated</h3>
                            Functionally automated push notifications are the same as standard notifications, with the main difference being they are triggered and fired automatically by our system without you having to compose or schedule the message each and every time it gets sent out. <a href="/communication/geofencepushmessages">Geofence Messages </a> are automated messages that you configure once and will automatically fire when a customer enters a geographic boundary that you set. geofencing portion the label field is a required field that serves a brief description of the kind of message this should be. The latitude and longitude fields are the geographical coordinates of the physical location you want to set a geofence around. You can easily get these coordinates by going to google maps and typing the address or name of the location you want the coordinates for. After clicking on the location, in the address bar of the browser you’ll see the latitude and longitude coordinates. The first number is latitude and the 2nd is longitude. Simply copy and paste those values in the appropriate fields. The range is how many feet you would like you to extend the fence borders from the latitude and longitude coordinates you set. It’s advised to stay within the recommended values of 60 - 150 feet. Again you can schedule and set the expiration of your message.
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mb-3">
                <div className="col-md-12 col-xl-12">
                    <div className="card-body" style={{ paddingLeft: '15px' }}>
                        <div className="d-flex align-items-center">
                            <h2 style={{ paddingRight: '10px' }}><strong>Analytics</strong></h2>
                        </div>
                        <div>View powerful insights on how your app is performing with your customers with the Analytics module and use this data to adapt to how your customers are interacting with you. By default you will see your analytics from the beginning of the current month until today’s date. To change the date range of the analytics you want to view, simply select a new start and end date to update the analytics for that date range.
                            <br /> <br />
                            The <b>Analytics Summary</b> and <b>Insight Summary</b> panes are great for a quick at a glance view of your overall app performance and engagement with your customers. If you are a multi rooftop application analytics can be view as a whole or for each location individually.
                            <br /> <br />
                            On the lower half of the page allows for more fine tuned and granular filtering of analytic data. Use the dropdown tool to select a specific metric you want to focus in on. Fine tuned data is broken out by location. Clicking on a location name in the table view below that will update the graph with more details for that given location. Use the reset buttons to go back to the default view.
                            <br /> <br />
                            <u>Monthly Reports</u>
                            <br />
                            An automated email is sent at the start of each month with a report of the previous month’s activity. Use the Monthly Report button to add additional email addresses that should be included in those reports. Reports can also be exported in CSV or PDF format using the buttons at the top. Be sure to select your desired date range first before exporting.
                        </div>

                    </div>
                </div>
            </div>
            <div className="card mb-3">
                <div className="col-md-12 col-xl-12">
                    <div className="card-body" style={{ paddingLeft: '15px' }}>
                        <div className="d-flex align-items-center">
                            <h2 style={{ paddingRight: '10px' }}><strong>Loyalty Rewards</strong></h2>
                        </div>
                        <div>With Loyalty Rewards you can build and manage your completely customized in app loyalty rewards program. First select the app and dealership location you want to manage rewards for. The system is point based and built around tasks and rewards. Tasks are what customers can do to earn points toward redeeming rewards. You create what the tasks are and the point values for them, you can make them be whatever you want them to be. Rewards are….well rewards. Things your customers can redeem the points they’ve accrued for.
                            <br /> <br />
                            <u>Program Types</u>
                            <br />
                            A program type of location locks any points earned and redeemed to that specific location only. Meaning, even if that location is part of a dealer group that has other locations within the same mobile app, the customer’s point balance will only be applied to that one location. A group program type means that points can be earned and redeemed at any location within the same app. For example, in this group configuration you could earn points from dealership location A and spend them at dealership location B, and vice versa. It’s important to determine which type of configuration you want before setting up your program as it’s highly recommended not to change the program type later on as this could negatively affect your customer’s point balances. <strong>The Program type is managed by DealerappVantage, please contact us for more information or to make changes.</strong>
                            <br /> <br />
                            <u>Creating Tasks/Rewards</u>
                            <br />
                            When creating Tasks and Rewards, select which locations in your group should have it available. Give the reward a Title, description, and assign a point value. The “One Time” toggle gives the option for any given reward to only be able to once. Some may use this as an app sign up bonus for example. Upload an image to display along with the Task/Reward. The Expiration date refers to how long a Task/Reward is available in the app. After the expiration date it will no longer be visible or scannable.
                            <br /> <br />
                            <u>Automated Rewards</u>
                            <br />
                            Our solution also allows for Loyalty Rewards to be connected with your DMS to automatically credit your customers based on Sales and Service work done. After we configure your point to dollar ratio in our system (email support@dealerappvantage.com for more information on this) use the DMS Point Type dropdown to select between Sales and Service reward automation. Activate DMS Mapping dropdown allow you to toggle the active/inactive status of the automation.
                            <br /><br />
                            <b>IMPORTANT: Automated Rewards will only work while the feature is in active mode. If the feature is deactivated and then reactivated at a later date, the system will only track earning from the date of reactivation, not the time period in between. The automation is not retroactive.</b>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mb-3">
                <div className="col-md-12 col-xl-12">
                    <div className="card-body" style={{ paddingLeft: '15px' }}>
                        <div className="d-flex align-items-center">
                            <h2 style={{ paddingRight: '10px' }}><strong>Customer Profiles</strong></h2>
                        </div>
                        The Customer Profiles module is your mini CRM with managing your app customers that have created a profile in the mobile app. Guest users are not included here. When customers onboard and create an account in the mobile app they chose a location (rooftop) to be associated with. If you are a multi location group you can select which specific location you would like to search for customer in. Click on a customer’s name to view and edit the details for that customer. This data is typically generated by the customer as they use the app, however here you can add or modify data in the customer’s profile on their behalf.
                        <div>
                            <br />
                            <ul class="list-unstyled">
                                <ul>
                                    <li><b>Customer Details</b> - Edit a customer’s name, contact preference, and zip code from this panel. Only the customers can update their mobile number or email because it has to be verified by them via 2 factor authentication.</li>
                                    <br />
                                    <li><b>Driver’s License</b> - Add a customer’s driver’s license information</li>
                                    <br />
                                    <li><b>Loyalty Rewards</b> - Manually add or deduct points from a customer’s reward balance. To deduct points make the point value negative (example: -30 to remove 30 points)</li>
                                    <br />
                                    <li><b>Vehicle</b>s - Add a vehicle to the customer’s digital garage by: entering the VIN, searching the connected live inventory, or full manual entry.</li>
                                    <br />
                                    <li><b>Favorited Staff</b> - Add a staff member to appear in the customer’s favorited staff list in the app for easy contact access</li>
                                    <br />
                                    <li><b>Messages</b> - A list of targeted messages that were sent to directly 1 to 1 for this specific customer</li>
                                </ul>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mb-3">
                <div className="col-md-12 col-xl-12">
                    <div className="card-body" style={{ paddingLeft: '15px' }}>
                        <div className="d-flex align-items-center">
                            <h2 style={{ paddingRight: '10px' }}><strong>Departments</strong></h2>
                        </div>
                        <div>Create departments to be displayed in the mobile app for staff members to be added to. Select the app and location, tap the ‘Add New Department’ button and give the department a name. Use the 'Order Departments' button to control the order from top to bottom of how the departments will be listed in the app. In the order departments screen, use grab and drag the department tiles in the desired order and save the changes.
                        </div>
                    </div>
                </div>
            </div>
            <div className="card mb-3">
                <div className="col-md-12 col-xl-12">
                    <div className="card-body" style={{ paddingLeft: '15px' }}>
                        <div className="d-flex align-items-center">
                            <h2 style={{ paddingRight: '10px' }}><strong>Staff Management</strong></h2>
                        </div>
                        <div>Manage the staff members of the dealership displayed in the app and ensure the current staff is always up to date. The staff added here appears in the app available for the customer to favorite and set and their preferred staff members, giving them that extra touch of personalization.
                            <br /> <br />
                            <u>Adding staff</u>
                            <br />
                            <b>IMPORTANT: Departments must be created first before adding any staff member to it, see above.</b> Staff members can be added by either using the ‘Add New Staff Member’ button or bulk imported using a Excel file. The ‘Import’ button provides a downloadable template for adding multiple staff members at once. The ‘Download’ button downloads the app’s current staff list as an Excel file. When adding a staff member using the ‘Add New Staff Member’ button, fill in all the appropriate information. In the 'Status' dropdown a staff member can be <u>Active</u> or <u>Inactive</u>. Active staff members are displayed in the staff list in the mobile app. Inactive staff members are hidden from the mobile app view but not deleted from your staff list and can be reactivated back at a later time. The 'Order' refers to their order in the staff listing for that given department.
                            <br />  <br /><b>NOTE: A staff member can be included in multiple locations by holding the (Ctrl - Windows or Command - Mac) button and clicking each location needed</b>.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Help;