import React from "react";
import moment from 'moment'
import * as XLSX from 'xlsx';
import axiosInstance from "../../services/AxiosInstance";
import { toast } from "react-toastify";



// download staff data in a file
const FileDownload = (props) => {

  // format date and time
  const formatDateTime = (date) => {
    if (date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss');
    }
  }

  // handle click event
  const handleClick = async () => {
    const { appId, locationId, dpt, stDate, enDate, skey } = props.allvalues;
    let appIds;
    if (!appId) {
      const userData = JSON.parse(localStorage.getItem('user'));
      const appsList = userData.apps;
      let appsId = [];
      if (appsList.length > 0) {
        for (const item of appsList) {
          appsId.push(item.id);
        }
      }
      appIds = appsId;
    } else {
      appIds = [appId];
    }

    // check if user is authorized to download staffs.
    if (appIds.length === 0) {
      toast.error('You do not have any app permision!', { position: toast.POSITION.TOP_CENTER, autoClose: false, hideProgressBar: true });
      return;
    }

    // get date range
    let startDates, endDates;
    if (stDate !== null && enDate !== null) {
      startDates = formatDateTime(stDate);
      endDates = formatDateTime(enDate);
    } else if (stDate !== null && enDate === null) {
      startDates = formatDateTime(stDate);
      endDates = moment().format('YYYY-MM-DD HH:mm:ss'); //formatDateTime(moment());
    } else if (stDate === null && enDate !== null) {
      startDates = moment('2000-01-01 00:00:00').format('YYYY-MM-DD HH:mm:ss') //formatDateTime(moment(`2000-01-01:00:00:00`, `YYYY-MM-DD HH:mm:ss`));
      endDates = formatDateTime(enDate);
    } else {
      startDates = "";
      endDates = "";
    };

    try {
      // make call
      const response = await axiosInstance.post('/v2/staff/download-data', {
        appId: appIds,
        locationId,
        startDate: startDates,
        endDate: endDates,
        searchKey: skey,
        deptName: dpt,
      });


      const { "App": appName, "Location": locationName, "Date Range": dateRange, "Staff Data": staffData } = response.data.data;
      const fileName = `staff_list.xlsx`;

      const appDataObject = { 'App': '', [appName]: '' };
      const locationDataObject = { 'Location': '', [locationName]: '' };
      const dateRangeObject = { 'Date Range': '', [dateRange]: '' };

      const appData = [appDataObject];
      const locationData = [locationDataObject];
      const dateRangeData = [dateRangeObject];

      const ws = XLSX.utils.json_to_sheet(appData);
      XLSX.utils.sheet_add_json(ws, locationData, { origin: 'A2' });
      XLSX.utils.sheet_add_json(ws, dateRangeData, { origin: 'A3' });

      for (let i = 0; i < 3; i++) {
        XLSX.utils.sheet_add_aoa(ws, [['', '', '']], { origin: 'A4' });
      };

      XLSX.utils.sheet_add_json(ws, staffData, { origin: 'A7' });

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      XLSX.writeFile(wb, fileName);
    } catch (error) {
      toast.error(`${error.response.data.message}`, { position: toast.POSITION.TOP_CENTER, autoClose: false, hideProgressBar: true });
    };
  };

  return (
    <div>
      <button onClick={handleClick} className="btn rounded-pill btn-primary me-1" style={{ fontSize: 10, height: '30px', backgroundColor: "#04AA6D", "border-color": "green" }}>
        Download
      </button>

    </div>
  );
}

export default FileDownload;