// All end with customer profile
import axiosInstance from "../services/AxiosInstance";

// get all profiles
export const profileData = (applist, selectedApp, loc, cpage, searchkey, profilesPerPage, role) => {
    // let userx = JSON.parse(localStorage.getItem('user'));

    let offset = (cpage - 1) * profilesPerPage;
    let url = "";
    let questionadded = false;
    //location and app selected
    if (selectedApp !== null && loc !== null) {

        url = url + 'v2/customer/get-customers-loc/' + offset + '/' + profilesPerPage + '?locationId=' + loc;
        questionadded = true;
    } // location selected only 
    else if (loc !== null) {

        url = url + 'v2/customer/get-customers-loc/' + offset + '/' + profilesPerPage + '?locationId=' + loc;
        questionadded = true;
    } // app selected only
    else if (selectedApp !== null && loc === null) {
        url = url + 'v2/customer/get-customers-app/' + offset + '/' + profilesPerPage + '?appId=' + selectedApp;
        questionadded = true;
    }

    else if (selectedApp === null && loc === null) {

        if (role === 'administrator') {
            url = url + 'v2/customer/get-customers/' + offset + '/' + profilesPerPage;
        }
        else if (applist && applist.length === 1) {
            url = url + 'v2/customer/get-customers-app/' + offset + '/' + profilesPerPage + '?appId=' + applist[0].id;
            questionadded = true;
        }
        else if (applist && applist.length > 1) {
            let appIds = [];
            for (let item of applist) {
                appIds.push(item.id);
            }
            let apid = encodeURIComponent(appIds);
            url = url + 'v2/customer/get-customers-multi-apps/' + offset + '/' + profilesPerPage + '?appIds=' + apid;
            questionadded = true;
        }
    }

    if (searchkey !== '' && searchkey) {
        let searchkeyencoded = encodeURIComponent(searchkey);
        if (questionadded) {
            url = url + '&searchKey=' + searchkeyencoded;
        } else {
            url = url + '?searchKey=' + searchkeyencoded;
            questionadded = true;

        }

    }

    console.log(url);
    return axiosInstance.get(url);
};
// count number of profiles
export const profileCount = (applist, selectedApp, loc, searchkey, role) => {
    let url = '';
    let questionadded = false;
    // location selected 
    if (selectedApp !== null && loc !== null) {
        url = url + 'v2/customer/get-customers-count-loc?locationId=' + loc;
        questionadded = true;
    }
    else if (loc !== null) {
        url = url + 'v2/customer/get-customers-count-loc?locationId=' + loc;
        questionadded = true;
    }
    // apps selected 
    else if (selectedApp !== null && loc === null) {
        url = url + 'v2/customer/get-customers-count-app?appId=' + selectedApp;
        questionadded = true;
    }// single app
    // nothing selected 
    else if (selectedApp === null && loc === null) {

        if (role === 'administrator') {
            url = url + 'v2/customer/get-customers-count';
            // /get-customers-count
        }

        // get all customer count for single location
        else if (applist && applist.length === 1) {
            url = url + 'v2/customer/get-customers-count-loc?appId=' + applist[0].id;
            questionadded = true;

        }
        // get all customer count for multi location

        else if (applist && applist.length > 1) {

            let appIds = [];
            for (let item of applist) {
                appIds.push(item.id);
            }


            let apid = encodeURIComponent(appIds);
            // url = url + '/v2/staff/all-data/' + offset + '/' + staffsPerPage + '?appId=[' + appIds + ']';


            url = url + 'v2/customer/get-customers-count-multi-apps?appIds=' + apid;
            questionadded = true;


        }
    }

    if (searchkey !== '' && searchkey) {
        let searchkeyencoded = encodeURIComponent(searchkey);
        if (questionadded) {
            url = url + '&searchKey=' + searchkeyencoded;
        } else {
            url = url + '?searchKey=' + searchkeyencoded;
            questionadded = true;

        }

    }

    console.log(url);
    return axiosInstance.get(url);
};

// get staff by id
export const getCustomerById = (id) => {
    let url = "/v2/customer/get/" + id;
    return axiosInstance.get(url);
};

// update 
export const updateCustomerVIP = (id) => {
    console.log(id);
    const formData = new FormData();
    let userx = JSON.parse(localStorage.getItem('user'));
    const role = userx.role;
    const userid = userx.id;
    formData.append("id", id);
    formData.append("updated_by", userx.id);

    const url = "/v2/customer/updateVip/" + id;

    // return axiosInstance.patch("/v2/customer/updateVip/" + id, formData);


    return axiosInstance.patch(url, formData, {
        headers: {
            'Content-Type': 'application/json',
            'userid': userid,
            'userrole': role
        }
    });
};

// delete staff
export const deleteCustomer = (e, staffObj, setDel) => {
    // e.preventDefault();
    let userx = JSON.parse(localStorage.getItem('user'));

    const id = e.target.getAttribute("data-key");
    setDel(true);
    return axiosInstance.delete("/v2/customer/del", { data: { id: id, updated_by: userx.id } });
};


// get rewards entries by customer id
export const getRewardsByCustomerId = (customer_id, cpage, searchkey, sort, kind, status) => {
    let url = '/v2/reward/all';
    // let offset = (cpage - 1) * 10;

    const numberOfItemsPerPage = 5;

    let offset = (cpage - 1) * numberOfItemsPerPage; //  you are working with 5 items

    url = url + '?limit=' + numberOfItemsPerPage + '&offset=' + offset;

    // location selected

    url = url + '&customer_id=' + customer_id;

    if (sort !== null) {
        url = url + '&sort=' + sort;
    }

    if (kind !== null) {
        url = url + '&kind=' + kind;
    }

    if (searchkey !== '' && searchkey) {
        let searchkeyencoded = encodeURIComponent(searchkey);
        url = url + '&searchkey=' + searchkeyencoded;
    }

    if (status !== null) {
        url = url + '&is_active=' + status;
    }

    console.log(url);
    return axiosInstance.get(url);
};



// get rewards entries count by customer id
export const getRewardsCountByCustomerId = (customer_id, searchkey, kind, status) => {
    let url = '/v2/reward/allcount';
    // location selected

    url = url + '?customer_id=' + customer_id;

    if (kind !== null) {
        url = url + '&kind=' + kind;
    }
    if (searchkey !== '' && searchkey) {
        let searchkeyencoded = encodeURIComponent(searchkey);
        url = url + '&searchkey=' + searchkeyencoded;
    }
    if (status !== null) {
        url = url + '&is_active=' + status;
    }
    console.log(url);
    return axiosInstance.get(url);
};


// sum reward entries
export const getRewardsSumCustomerId = (customer_id, searchkey, kind, status) => {
    let url = '/v2/reward/rewardentrysum';
    // location selected

    url = url + '?customer_id=' + customer_id;

    if (kind !== null) {
        url = url + '&kind=' + kind;

    }
    if (searchkey !== '' && searchkey) {
        let searchkeyencoded = encodeURIComponent(searchkey);
        url = url + '&searchkey=' + searchkeyencoded;
    }
    if (status !== null) {
        url = url + '&is_active=' + status;
    }
    console.log(url);
    return axiosInstance.get(url);
};

// get customer info basic by id
export const getCustomerInfoBasicById = (id) => {
    let url = "/v2/customer/get-customers-details-basic?customerId=" + id;
    console.log(url);
    return axiosInstance.get(url);
};
export const updateCustomerInfo = (inputs) => {
    
    const url ="/v2/customer/update/"+inputs.id;
    const formData = new FormData();
    formData.append("id", inputs.id);
    formData.append("first_name", inputs.customer_first_name);
    formData.append("last_name", inputs.customer_last_name);
    formData.append("contact_pref", inputs.contact_pref);
    formData.append("zip_code", inputs.zip_code);
    formData.append("phone_country_code", inputs.phone_country_code);
    console.log("Profile API Update Input");

   console.log(inputs);
   
   return axiosInstance.patch(url, formData);

  
};


// get customer info vehicle by id
export const getCustomerInfoVehicleById = (id, cpage, itemPerPage, searchKey) => {
    let offset = (cpage - 1) * itemPerPage;
    let url = "/v2/customer/get-customers-details-vehicle/" + offset + '/' + itemPerPage + '?customerId=' + id;
    if (searchKey !== '' && searchKey) {
        let searchKeyEncoded = encodeURIComponent(searchKey);
        url = url + '&searchKey=' + searchKeyEncoded;
    }
    console.log(url);
    return axiosInstance.get(url);
};


// get customer info vehicle by id count
export const getCustomerInfoVehicleByIdCount = (id, searchKey) => {
    let url = "/v2/customer/get-customers-details-vehicle-count?customerId=" + id;
    if (searchKey !== '' && searchKey) {
        let searchKeyEncoded = encodeURIComponent(searchKey);
        url = url + '&searchKey=' + searchKeyEncoded;
    }
    console.log(url);
    return axiosInstance.get(url);
};


// get customer info staff by id
export const getCustomerInfoStaffById = (id, cpage, itemPerPage, searchKey) => {
    let offset = (cpage - 1) * itemPerPage;
    let url = "/v2/customer/get-customers-details-staff/" + offset + '/' + itemPerPage + '?customerId=' + id;
    if (searchKey !== '' && searchKey) {
        let searchKeyEncoded = encodeURIComponent(searchKey);
        url = url + '&searchKey=' + searchKeyEncoded;
    }
    console.log(url);
    return axiosInstance.get(url);
};



// get customer info staff by id count
export const getCustomerInfoStaffByIdCount = (id, searchKey) => {
    let url = "/v2/customer/get-customers-details-staff-count?customerId=" + id;
    if (searchKey !== '' && searchKey) {
        let searchKeyEncoded = encodeURIComponent(searchKey);
        url = url + '&searchKey=' + searchKeyEncoded;
    }
    console.log(url);
    return axiosInstance.get(url);
};


// get customer info push messages by id
export const getCustomerInfoPushMessagesById = (id, cpage, itemPerPage, searchKey) => {
    let offset = (cpage - 1) * itemPerPage;
    let url = "/v2/customer/get-customers-details-push-messages/" + offset + '/' + itemPerPage + '?customerId=' + id;
    if (searchKey !== '' && searchKey) {
        let searchKeyEncoded = encodeURIComponent(searchKey);
        url = url + '&searchKey=' + searchKeyEncoded;
    }
    console.log(url);
    return axiosInstance.get(url);
};


// get customer info push messages by id count
export const getCustomerInfoPushMessagesByIdCount = (id, searchKey) => {
    let url = "/v2/customer/get-customers-details-push-messages-count?customerId=" + id;
    if (searchKey !== '' && searchKey) {
        let searchKeyEncoded = encodeURIComponent(searchKey);
        url = url + '&searchKey=' + searchKeyEncoded;
    }
    console.log(url);
    return axiosInstance.get(url);
};



// get customer info document by id
export const getCustomerInfoDocumentById = (id, cpage, itemPerPage, searchKey) => {
    let offset = (cpage - 1) * itemPerPage;
    let url = "/v2/customer/get-customers-details-documents/" + offset + '/' + itemPerPage + '?customerId=' + id;
    if (searchKey !== '' && searchKey) {
        let searchKeyEncoded = encodeURIComponent(searchKey);
        url = url + '&searchKey=' + searchKeyEncoded;
    }
    console.log(url);
    return axiosInstance.get(url);
};



// get customer info document by id count
export const getCustomerInfoDocumentByIdCount = (id, searchKey) => {
    let url = "/v2/customer/get-customers-details-documents-count?customerId=" + id;
    if (searchKey !== '' && searchKey) {
        let searchKeyEncoded = encodeURIComponent(searchKey);
        url = url + '&searchKey=' + searchKeyEncoded;
    }
    console.log(url);
    return axiosInstance.get(url);
};



// get customer reward balance by id
export const getCustomerRewardBalanceById = (id) => {
    let url = "/v2/customer/get/" + id + "/totalrewardpoint"
    return axiosInstance.get(url);
};


// add customer vehicle to customer
export const addCustomerVehicle = (customer_id, vin, acode, year, make, model, odometer) => {
    const formData = new FormData();
    formData.append("customerId", customer_id);
    formData.append("vin", vin);
    formData.append("acode", acode);
    formData.append("year", year);
    formData.append("make", make);
    formData.append("model", model);
    formData.append("odometer", odometer);

    // console.log(formData);
    const url = "/v2/customer/add-vehicle";
    console.log(url);
    return axiosInstance.post(url, formData);
};



// delete customer vehicle
export const deleteCustomerVehicle = (id) => {
    return axiosInstance.delete("/v2/customer/delete-vehicle/" + id);
};





// get vehicle count by location
export const getVehicleCountByLocation = (loc) => {
    // console.log(loc, 'loc');
    let url = '/v2/customer/get-vehicle-count/' + loc;
    console.log(url);
    return axiosInstance.get(url);
};


// get vehicle year by location
export const getVehicleYearByLocation = (loc) => {
    let url = '/v2/customer/get-vehicle-year/' + loc;
    console.log(url);
    return axiosInstance.get(url);
};


// get vehicle make by location and year
export const getVehicleMakeByLocationAndYear = (loc, year) => {
    let url = '/v2/customer/get-vehicle-make/' + loc + '/' + year;
    console.log(url);
    return axiosInstance.get(url);
};


// get vehicle model by location and year and make
export const getVehicleModelByLocationAndYearAndMake = (loc, year, make) => {
    let url = '/v2/customer/get-vehicle-model/' + loc + '/' + year + '/' + make;
    console.log(url);
    return axiosInstance.get(url);
};




// get staff by location and department
export const getStaffByLocationAndDepartment = (loc, department) => {
    let url = '/v2/staff/get-staff-by-location-department/' + loc + '/' + department;
    console.log(url);
    return axiosInstance.get(url);
};


// get department by location
export const getDepartmentByLocation = (loc) => {
    let url = '/v2/department/get-dept-by-location/' + loc;
    console.log(url);
    return axiosInstance.get(url);
};



// add staff to customer
export const addStaffToCustomer = (customer_id, staff_id) => {
    const formData = new FormData();
    formData.append("customerId", customer_id);
    formData.append("staffMemberId", staff_id);

    // console.log(formData);
    const url = "/v2/customer/add-staff";
    console.log(url);
    return axiosInstance.post(url, formData);
};


// delete staff from customer
export const deleteStaffFromCustomer = (customer_id, staff_id) => {
    const url = "/v2/customer/delete-staff/" + customer_id + '/' + staff_id;
    console.log(url);
    return axiosInstance.post(url);
};



// add document to customer
export const addDocumentToCustomer = (data) => {
    const url = "/v2/customer/add-document";
    console.log(url);
    console.log(data);
    return axiosInstance.post(url, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};


// delete document from customer
export const deleteDocumentFromCustomer = (id) => {
    const url = "/v2/customer/delete-document/" + id
    console.log(url);
    return axiosInstance.delete(url);
};



// add reward to customer
export const addRewardToCustomer = (data) => {
    const url = "/v2/reward/rewardentry";
    console.log(url);
    return axiosInstance.post(url, data);
};



// get rewards by location
export const getRewardsByLocation = (loc) => {
    let url = '/v2/reward/list?location_id=' + loc;
    console.log(url);
    return axiosInstance.get(url);
};


// update reward for customer
export const updateRewardForCustomer = (data, id) => {
    const url = "/v2/reward/rewardentry/" + id;
    // filter out id from data
    delete data.id;
    console.log(url);
    return axiosInstance.patch(url, data);
};


// delete reward for customer
export const deleteRewardForCustomer = (id) => {
    const url = "/v2/reward/rewardentry/" + id;
    console.log(url);
    return axiosInstance.delete(url);
};


// get customer vehicle details by location and vin
export const getCustomerVehicleDetailsByLocationAndVin = (loc, vin) => {
    let url = '/v2/customer/get-vehicle-details/' + loc + '/' + vin;
    console.log(url);
    return axiosInstance.get(url);
};


// get customer vehicle vin by location and customer id
export const getCustomerVehicleVinByLocationAndCustomerId = (loc, customer_id) => {
    let url = '/v2/customer/get-vehicle-vin/' + loc + '/' + customer_id;
    console.log(url);
    return axiosInstance.get(url);
};
