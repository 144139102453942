import React, { useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from "react";
import { toast } from "react-toastify";
import { errorHandler } from "../Utils/errorHandler";
import { locationData } from "../../apis/LocationAPI";
import { addDepartment, getDepartments } from "../../apis/DepartmentAPI";
import AppsDropDownComponent from "../PageTopComponents/AppDropDown";
import LocationsDropDownComponent from "../PageTopComponents/LocationDropDown";
import SessionExpiredModal from "../SessionExpire/SessionExpiredModal";

const AddDepartmentModal = ({ show, setShow, appslist, locs, app, setDepartments }) => {
    console.log(locs, app, 'from adddepartmentmodal');
    const [newDept, setNewDept] = useState(null);
    const [apps, setApps] = useState(null);
    const [loc, setLoc] = useState(null);
    const [locations, setLocations] = useState([]);
    const [sessionModal, setSessionModal] = useState(false);
    const [showToast, setShowToast] = useState(false);

    console.log(apps);


    // keep app name if it was selected
    useEffect(() => {
        setApps(app);
    }, [app]);

    // keep location name if it was selected
    useEffect(() => {
        setLoc(locs);
    }, [locs]);



    // load all locations by app
    useEffect(() => {
        if (apps) {
            locationData(apps).then((response) => {
                if (response.status === 200) {
                    setLocations(response.data);
                } else {
                    setLocations([]);
                    window.location = '/error';
                };
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        } else {
            setLocations([]);
        };
    }, [apps]);


    // close modal
    const handleClose = () => setShow(false);

    // handle change
    const handleChange = (e) => {
        e.preventDefault();
        if (e.target.type === 'text' && e.target.name === 'department_name') {
            const currentValue = e.target.value;
            setNewDept(currentValue);
        } else if (e.target.type === 'select-one' && e.target.name === 'app_id') {
            setApps(e.target.value);
        } else if (e.target.type === 'select-one' && e.target.name === 'location_id') {
            setLoc(e.target.value);
        }
    };

    // handle backend call
    const handleAdd = (e) => {
        e.preventDefault();
        addDepartment(newDept, loc).then((res) => {
            if (res.status === 201) {
                toast.success(`Department ${newDept} added successfully!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    onClose: () => {
                        setShow(false);
                        getDepartments(app, locs, 30, undefined, 1).then((response) => {
                            if (response.status === 200) {
                                setDepartments(response.data);
                                window.location.reload();
                            } else {
                                setDepartments([]);
                                window.location = '/error';
                            };
                        }).catch(err => {
                            errorHandler(err, setSessionModal, setShowToast, toast);
                        });
                    }
                });
            } else {
                toast.error("Error adding department!", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: false,
                    hideProgressBar: true
                });
            };
        }).catch((err) => {
            if (err.response.data.message.message) {
                if (err.response.data.message.message === "Data already exists!") {
                    toast.error(`Department name ${newDept} already exists! Please choose a different name!`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: false,
                        hideProgressBar: true
                    });
                } else {
                    errorHandler(err, setSessionModal, setShowToast, toast);
                };
            }
        });
    };

    // handle submit
    const handleSubmit = (e) => {
        e.preventDefault();
        if (loc && newDept && newDept !== '') {
            handleAdd(e);
        } else {
            toast.error("Please provide location and department name!", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: false,
                hideProgressBar: true
            });
        };
    };

    // logs out a user
    const handleLogout = async () => {
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location = "/";
    };


    return (
        <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Add Department
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {showToast && <div className="toast-overlay"></div>}
                <SessionExpiredModal
                    show={sessionModal}
                    handleLogout={handleLogout}
                />
                <div className="row g-2" style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <div className="d-flex flex-wrap" style={{ justifyContent: 'space-around' }}>
                        <AppsDropDownComponent
                            handleChange={handleChange}
                            apps={apps}
                            applist={appslist}
                        />
                        <LocationsDropDownComponent
                            handleChange={handleChange}
                            loc={loc}
                            location={locations}
                        />
                    </div>
                </div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col mb-3">
                            <label htmlFor="nameWithTitle" className="form-label">Department</label>
                            <input type='text' id="nameWithTitle" name='department_name' className="form-control" placeholder="Enter Department Name" onChange={handleChange} required></input>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button type="button" className="btn btn-secondary" onClick={handleClose}>Cancel</button>
                <Button onClick={handleSubmit} style={{ backgroundColor: "#04AA6D" }}>Submit</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default AddDepartmentModal;