import React, { useMemo } from "react";
import { useEffect, useState } from 'react';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SessionExpiredModal from '../SessionExpire/SessionExpiredModal';
import { errorHandler } from "../Utils/errorHandler";
import { appData } from "../../apis/AppsAPI";
import { locationData } from "../../apis/LocationAPI";
import { savePushMessage, vehicleYearByTypeAndLocation, vehicleMakeByYearAndTypeAndLocation, vehicleModelByMakeAndYearAndTypeAndLocation, vehicleCountByLocation } from "../../apis/CommunicationAPI";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { MultiSelect } from "react-multi-select-component";
import "react-datetime/css/react-datetime.css";
import Datetime from 'react-datetime';
import moment from 'moment';
import AddUpdateLoading from "../Utils/AddUpdateLoading";
import { Tooltip } from "react-bootstrap";

const AddPushMessage = () => {
    const navigate = useNavigate();
    const search = useLocation().search;
    const [inputs, setInputs] = useState([]);
    const queryParams = new URLSearchParams(search);
    const app = queryParams.get('app_id') ? parseInt(queryParams.get('app_id')) : null;

    const [submitting, setSubmitting] = useState(false);
    const [applist, setApplist] = useState([]);
    const [location, setLocation] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [sessionModal, setSessionModal] = useState(false);
    const [backUrl] = useState("/communication/globalpushmessages" + window.location.search);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [messageType, setMessageType] = useState('General');
    const [file, setFile] = useState(null);
    const [vehicleYear, setVehicleYear] = useState([]);
    const [vehicleMake, setVehicleMake] = useState([]);
    const [vehicleModel, setVehicleModel] = useState([]);
    const [selectedVehicleType, setSelectedVehicleType] = useState(null);
    const [selectedVehicleYear, setSelectedVehicleYear] = useState([]);
    const [selectedVehicleMake, setSelectedVehicleMake] = useState([]);
    const [selectedVehicleModel, setSelectedVehicleModel] = useState([]);
    const [hasInventory, setHasInventory] = useState(false);
    const [releaseDate, setReleaseDate] = useState(null);
    const [expiryDate, setExpiryDate] = useState(null);
    const [apps, setApps] = useState(app);


    // vehicle types
    const vehicleTypes = useMemo(() => [
        { id: 1, name: 'All', alias: 'all' },
        { id: 2, name: 'New', alias: 'new' },
        { id: 3, name: 'Used', alias: 'used' }
    ], []);


    // message types
    const messageTypes = [
        { id: 1, name: 'General', alias: 'general' },
        { id: 2, name: 'Sales', alias: 'sales' },
        { id: 3, name: 'Service', alias: 'service' },
    ];


    // set app id from query params
    useEffect(() => {
        if (app) {
            setInputs(values => ({ ...values, app_id: app }));
        }
    }, [app]);


    // load all apps
    useEffect(() => {
        const apps = appData();
        if (apps.length > 0) {
            setApplist(apps);
        }
        else {
            setApplist([]);
        }
    }, []);


    console.log(window.location.search, 'window.location.search');
    // load all locations by app
    useEffect(() => {
        if (apps) {
            locationData(apps).then((response) => {
                if (response.status === 200) {
                    console.log(response.data);
                    let data = [];
                    response.data.map((loc, index) => {
                        return data.push({ value: loc.id, label: loc.name });
                    });
                    setLocation(data);
                } else {
                    setLocation([]);
                    // window.location = '/error';
                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        } else {
            setLocation([]);
        }
    }, [apps]);



    // check to see if has inventory
    useEffect(() => {
        if (selectedLocations && selectedLocations.length > 0) {
            const selectedLocs = selectedLocations.map(sl => sl.value);
            console.log(selectedLocs, 'selectedLocs');
            vehicleCountByLocation(selectedLocs).then((response) => {
                if (response.status === 200) {
                    setHasInventory(true);
                } else {
                    setHasInventory(false);
                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        } else {
            setHasInventory(false);
        }
    }, [selectedLocations]);


    console.log(selectedLocations, 'selectedLocations');
    // load all vehicles by type & location
    useEffect(() => {
        if (selectedLocations.length > 0 && selectedVehicleType) {
            const selectedLocs = selectedLocations.map(sl => sl.value);
            const vType = vehicleTypes.find(vt => vt.id === parseInt(selectedVehicleType));
            const vAlias = vType.alias;
            vehicleYearByTypeAndLocation(selectedLocs, vAlias).then((response) => {
                if (response.status === 200) {
                    let data = []
                    response.data.map((year, index) => {
                        return data.push({ value: index, label: year.year });
                    });
                    setVehicleYear(data);
                } else {
                    setVehicleYear([]);
                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        } else {
            setVehicleYear([]);
        }
    }, [selectedLocations, selectedVehicleType, vehicleTypes]);



    // load all vehicles by type, year & location
    useEffect(() => {
        if (selectedLocations.length > 0 && selectedVehicleType && selectedVehicleYear.length > 0) {
            const selectedLocs = selectedLocations.map(sl => sl.value);
            const vType = vehicleTypes.find(vt => vt.id === parseInt(selectedVehicleType));
            const vAlias = vType.alias;
            const vYear = selectedVehicleYear.map(vy => vy.label);
            console.log(vYear, 'year');
            vehicleMakeByYearAndTypeAndLocation(selectedLocs, vAlias, vYear).then((response) => {
                if (response.status === 200) {
                    let data = []
                    response.data.map((make, index) => {
                        return data.push({ value: index, label: make.make });
                    });
                    setVehicleMake(data);
                } else {
                    setVehicleMake([]);
                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        } else {
            setVehicleMake([]);
        }
    }, [selectedLocations, selectedVehicleType, selectedVehicleYear, vehicleTypes]);




    // load all vehicles by type, year, make & location
    useEffect(() => {
        if (selectedLocations.length > 0 && selectedVehicleType && selectedVehicleYear.length > 0 && selectedVehicleMake.length > 0) {
            const selectedLocs = selectedLocations.map(sl => sl.value);
            const vType = vehicleTypes.find(vt => vt.id === parseInt(selectedVehicleType));
            const vAlias = vType.alias;
            const vYear = selectedVehicleYear.map(vy => vy.label);
            const vMake = selectedVehicleMake.map(vm => vm.label);
            vehicleModelByMakeAndYearAndTypeAndLocation(selectedLocs, vAlias, vYear, vMake).then((response) => {
                if (response.status === 200) {
                    let data = []
                    response.data.map((model, index) => {
                        return data.push({ value: index, label: model.model });
                    });
                    setVehicleModel(data);
                } else {
                    setVehicleModel([]);
                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        } else {
            setVehicleModel([]);
        }
    }, [selectedLocations, selectedVehicleType, selectedVehicleYear, selectedVehicleMake, vehicleTypes]);


    // logs out a user
    const handleLogout = async () => {
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location = "/";
    };


    // handles toast closing
    const handleCloseToast = () => {
        setShowToast(false);
    };

    // handle user input
    const handleChange = (event) => {
        const name = event.target.name;
        let value;
        let filen;
        if (event.target.type === 'select') {
            value = document.getElementById(name).value;
            setInputs(values => ({ ...values, [name]: value }));
            if (name === "app_id") {
                setApps(value);
            }
        } else if (event.target.type === 'select-one') {
            value = document.getElementById(name).value;
            setInputs(values => ({ ...values, [name]: value }));
            const selectedIndex = event.target.selectedIndex;
            if (name === "app_id") {
                if (selectedIndex === 0) {
                    setLocation([]);
                }
                setApps(value);
            }
            else if (name === "message_type") {
                if (selectedIndex === 0) {
                    setMessageType(null);
                }
                else {
                    const mType = messageTypes.find(mt => mt.id === parseInt(value));
                    setMessageType(value);
                    setInputs(values => ({ ...values, [name]: mType.alias }));
                }
            }
            else if (name === "vehicle_type") {
                if (selectedIndex === 0) {
                    setSelectedVehicleType(null);
                }
                setSelectedVehicleType(value);
                const vType = vehicleTypes.find(vt => vt.id === parseInt(value));
                setInputs(values => ({ ...values, [name]: vType.alias }));
                console.log(vType.alias);
            }
        }
        else if (event.target.type === 'file') {
            if (name === "display_image_file") {
                filen = event.target.files[0];
                setInputs(values => ({ ...values, [name]: filen }));
                setInputs(values => ({ ...values, display_image_file_name: filen.name }));
                setInputs(values => ({ ...values, display_image_content_type: filen.type }));
                setInputs(values => ({ ...values, display_image_file_size: filen.size }));
            }
            else if (name === "attachment_file") {
                filen = event.target.files[0];
                setInputs(values => ({ ...values, [name]: filen }));
                setInputs(values => ({ ...values, attachment_file_name: filen.name }));
                setInputs(values => ({ ...values, attachment_content_type: filen.type }));
                setInputs(values => ({ ...values, attachment_file_size: filen.size }));
                setFile(event.target.files[0]);
            }
        }
        else {
            value = event.target.value;
            setInputs(values => ({ ...values, [name]: value }));
        }
    }


    console.log(inputs);

    // on selecting location
    const onSelectedLocation = (data) => {
        setSelectedLocations(data);
        setInputs(values => ({ ...values, location_id: data.map(c => c.value) }));
    }


    // on selecting year
    const onSelectedYear = (data) => {
        setSelectedVehicleYear(data);
        setInputs(values => ({ ...values, vehicle_year: data }));
    }


    // on selecting make
    const onSelectedMake = (data) => {
        setSelectedVehicleMake(data);
        setInputs(values => ({ ...values, vehicle_make: data }));
    }

    // on selecting model
    const onSelectedModel = (data) => {
        setSelectedVehicleModel(data);
        setInputs(values => ({ ...values, vehicle_model: data }));
    }


    // set release date
    const setReleaseDateFunc = (releaseDate) => {
        console.log(releaseDate);
        setReleaseDate(releaseDate)
        setInputs(values => ({ ...values, release_date: releaseDate.format('YYYY-MM-DD') }))
        setInputs(values => ({ ...values, release_time: releaseDate.format('YYYY-MM-DD hh:mm:ss') }))
    };


    // set expiry date
    const setExpireDateFunc = (expiryDate) => {
        console.log(expiryDate);
        setExpiryDate(expiryDate)
        setInputs(values => ({ ...values, expiry_date: expiryDate.format('YYYY-MM-DD') }))
    };




    // handles form submit
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!apps) {
            toast.error("Please select an App", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
            });
            return false;
        }
        if (!selectedLocations || selectedLocations.length === 0) {
            toast.error("Please select a Location", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
            });
            return false;
        }
        if (!inputs.message_type) {
            toast.error("Please select a Message Type", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
            });
            return false;
        }
        setSubmitting(true);
        savePushMessage(inputs).then((response) => {
            console.log(response.status, 'response')
            if (response.status === 201 || response.status === 200) {
                setSubmitting(false);
                toast.success("Push Message saved successfully", {
                    position: toast.POSITION.TOP_CENTER,
                    onClose: () => {
                        window.location.reload();
                    }
                });
            } else {
                setSubmitting(false);
                toast.error("Error saving Push Message", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000,
                });
            }
        }).catch(err => {
            errorHandler(err, setSessionModal, setShowToast, toast);
        });
    };

    // file types
    const getFileTypeIcon = (fileType) => {
        console.log(fileType);
        const fileIcons = {
            pdf: 'pdf-logo.png',
            docx: 'docx-logo.png',
            txt: 'txt-logo.png',
            xlsx: 'xlsx-logo.png',
            csv: 'csv-logo.png',
            pptx: 'pptx-logo.png',
            png: 'image-logo.png',
            jpg: 'image-logo.png',
            jpeg: 'image-logo.png',
            PNG: 'image-logo.png',
            // Add more mappings as needed
        };

        const fileName = fileIcons[fileType] || 'general-logo.png';

        return fileName;
    };

    console.log(file, 'file global');
    console.log(messageType, 'messageType global', typeof messageType)

    useEffect(() => {
        // Initialize Bootstrap tooltips when the component mounts
        const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
        tooltipTriggerList.forEach(tooltipTriggerEl => {
            new Tooltip(tooltipTriggerEl); // Create a Tooltip instance for each element
        });
    }, []);



    return (
        <div className="col-xl-12">
            {submitting &&
                <div>Submtting Form...</div>
            }
            {showToast && <div className="toast-overlay"></div>}
            <ToastContainer onClose={handleCloseToast} />
            <a href={backUrl} className="btn btn-sm rounded-pill btn-secondary">Back</a>  <a href="/communication" className="btn btn-sm rounded-pill btn-secondary">Communication Home</a>
            <SessionExpiredModal
                show={sessionModal}
                handleLogout={handleLogout}
            />
            {submitting ?
                <AddUpdateLoading show={submitting} /> :
                <form onSubmit={handleSubmit} >
                    <div className="card mb-4">
                        <h4 className="card-header">Adding New Push Message</h4>
                        <div className="card-body">
                            <h5>Message Info</h5>
                            <div className="mb-3 row">
                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Apps<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                <div className="col-md-10">
                                    <select className="form-select" id="app_id" name="app_id" onChange={handleChange} value={apps || ''} >
                                        <option key='' value="">--Select Apps--</option>
                                        {applist && applist.length > 0 && applist.map((appObj) => (
                                            <option key={appObj.id} value={appObj.id}>{appObj.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Location<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                <div className="col-md-10">
                                    <MultiSelect
                                        options={location}
                                        value={selectedLocations}
                                        onChange={onSelectedLocation}
                                        labelledBy={"Select Location"}
                                    />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Message Type<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                <div className="col-md-10">
                                    <select className="form-select" id="message_type" name="message_type" onChange={handleChange} value={messageType || ''} >
                                        <option key='' value="">--Select Message Type--</option>
                                        {messageTypes && messageTypes.length > 0 && messageTypes.map((msgObj) => (
                                            <option key={msgObj.id} value={msgObj.id}>{msgObj.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="mb-3 row" style={{ height: '15px' }}>
                            </div>
                            <h5>Message Details</h5>
                            <div className="mb-3 row">
                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Subject<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                <div className="col-md-10">
                                    <input className="form-control" type="text" id="subject" name="subject" onChange={handleChange} value={inputs.subject || ''} required />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Message<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                <div className="col-md-10">
                                    <textarea className="form-control" type="text" id="message" name="message" onChange={handleChange} value={inputs.message || ''} required />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Terms And Conditions</label>
                                <div className="col-md-10">
                                    <textarea className="form-control" type="text" id="terms_and_conditions" name="terms_and_conditions" onChange={handleChange} value={inputs.terms_and_conditions || ''} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-md-2 col-form-label">Preview</label>
                                <div className="col-md-10">
                                    <span >
                                        <img src={inputs.display_image_file ? URL.createObjectURL(inputs.display_image_file) : "no-image-found.gif"} height={inputs.display_image_file ? '200' : '0'} width={inputs.display_image_file ? '200' : '0'} alt="display image" />
                                    </span>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="html5-password-input" className="col-md-2 col-form-label">Display Image</label>
                                <div className="mb-3 col-md-10">
                                    <label htmlFor="formFile" className="form-label">Upload Image</label>
                                    <input className="form-control" type="file" id="display_image_file" name="display_image_file" onChange={handleChange} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-md-2 col-form-label">Preview</label>
                                <div className="col-md-10">
                                    <span>
                                        {file ? (
                                            <a href={URL.createObjectURL(file)} download>
                                                <img
                                                    src={require('../../assets/img/' + getFileTypeIcon(file.name.split('.').pop()))}
                                                    alt=""
                                                    width="100"
                                                    height="100"
                                                />
                                            </a>
                                        ) : (
                                            <p></p>
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="html5-password-input" className="col-md-2 col-form-label">Attachment File</label>
                                <div className="mb-3 col-md-10">
                                    <label htmlFor="formFile" className="form-label">Upload File</label>
                                    <input className="form-control" type="file" id="attachment_file" name="attachment_file" onChange={handleChange} />
                                </div>
                            </div>
                            <div className="mb-3 row" style={{ height: '15px' }}>
                            </div>
                            {messageType === '2' ?
                                <div>
                                    <h5>Sales Message Options</h5>
                                    {hasInventory ?
                                        <div>
                                            <div className="mb-3 row">
                                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Vehicle Type</label>
                                                <div className="col-md-10">
                                                    <select className="form-select" id="vehicle_type" name="vehicle_type" onChange={handleChange} value={selectedVehicleType || ''} >
                                                        <option key='' value="">--Select Vehicle Type--</option>
                                                        {vehicleTypes && vehicleTypes.length > 0 && vehicleTypes.map((vehicleObj) => (
                                                            <option key={vehicleObj.id} value={vehicleObj.id}>{vehicleObj.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Vehicle Year</label>
                                                <div className="col-md-10">
                                                    <MultiSelect
                                                        options={vehicleYear}
                                                        value={selectedVehicleYear}
                                                        onChange={onSelectedYear}
                                                        labelledBy={"Select Year"}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Vehicle Make</label>
                                                <div className="col-md-10">
                                                    <MultiSelect
                                                        options={vehicleMake}
                                                        value={selectedVehicleMake}
                                                        onChange={onSelectedMake}
                                                        labelledBy={"Select Make"}
                                                    />
                                                </div>
                                            </div>
                                            <div className="mb-3 row">
                                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Vehicle Model</label>
                                                <div className="col-md-10">
                                                    <MultiSelect
                                                        options={vehicleModel}
                                                        value={selectedVehicleModel}
                                                        onChange={onSelectedModel}
                                                        labelledBy={"Select Model"}
                                                    />
                                                </div>
                                            </div>
                                        </div> : null}
                                    <div className="mb-3 row">
                                        <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Vehicle VINs</label>
                                        <div className="col-md-10">
                                            <input className="form-control" type="text" id="vehicle_vins" name="vehicle_vins" onChange={handleChange} value={inputs.vehicle_vins || ''} />
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Vehicle Stock Numbers</label>
                                        <div className="col-md-10">
                                            <input className="form-control" type="text" id="vehicle_stock_numbers" name="vehicle_stock_numbers" onChange={handleChange} value={inputs.vehicle_stock_numbers || ''} />
                                        </div>
                                    </div>
                                </div> : null}
                            <div className="mb-3 row" style={{ height: '15px' }}>
                            </div>
                            {messageType === '3' ?
                                <div>
                                    <h5>Service Message Options</h5>
                                    <div className="mb-3 row">
                                        <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Coupon Code</label>
                                        <div className="col-md-10">
                                            <input className="form-control" type="text" id="coupon_code" name="coupon_code" onChange={handleChange} value={inputs.coupon_code || ''} />
                                        </div>
                                    </div>
                                </div> : null}
                            <div className="mb-3 row" style={{ height: '15px' }}>
                            </div>
                            <h5>Message Schedule</h5>
                            <div className="mb-3 row">
                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Release Date</label>
                                <div className="col-md-10">
                                    <Datetime
                                        dateFormat="MM/DD/YYYY"
                                        timeFormat={true}
                                        onChange={setReleaseDateFunc}
                                        value={moment(releaseDate) || ''}
                                        inputProps={{ placeholder: 'Select a date to send the message' }}
                                        name="release_date"
                                        id="release_date"
                                    />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Expire</label>
                                <div className="col-md-10">
                                    <Datetime
                                        data-bs-toggle="tooltip" // Enable Bootstrap tooltip
                                        data-bs-placement="top"   // Position the tooltip above
                                        title="Select a date to expire the message"  // Tooltip text
                                        dateFormat="MM/DD/YYYY"
                                        timeFormat={true}
                                        onChange={setExpireDateFunc}
                                        value={moment(expiryDate) || ''}
                                        inputProps={{ placeholder: 'Select a date to expire the message' }}
                                        name="expiry_date"
                                        id="expiry_date"
                                    />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <div className="col-md-3">&nbsp;</div>
                                <div className="col-md-7"><input type="submit" className="btn btn-primary col-lg-10" value="Save" /></div>
                                <div className="col-md-2">&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </form>}
        </div>
    );
};


export default AddPushMessage;