import React, { useEffect, useState } from "react";

import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { errorHandler } from "../Utils/errorHandler";
import { clientData, clientCount } from "../../apis/ClientAPI";
// import { clientData } from "../../apis/ClientAPI";

import PaginationComponent from "../Pagination/Pagination";
import Loading from "../Utils/Loading";
import SessionExpiredModal from '../SessionExpire/SessionExpiredModal';

const Clients = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const [numberOfPage, setNumberOfPage] = useState(0);
    const [numberOfRows, setNumberOfRows] = useState(0);
    const [del, setDel] = useState(false);
    const [clientlist, setClientlist] = useState([]);
    const [clientsPerPage] = useState(50);
    const [searchkey, setSearchkey] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [showToast, setShowToast] = useState(false);
    const [sessionModal, setSessionModal] = useState(false);
    const [sort, setSort] = useState(0);
    const [timeoutid, setTimeoutid] = useState(null);
    const [firstLoad, setFirstLoad] = useState(1);

    // initial loading all clients and count clients
    useEffect(() => {
        setIsLoading(true);

        clientData(currentPage, searchkey, clientsPerPage, sort).then((response) => {
            if (response.status === 200) {
                setClientlist(response.data);
                setIsLoading(false);

            } else {
                setClientlist([]);
                window.location = '/error';
                setIsLoading(false);

            };
        }).catch(err => {
            setIsLoading(false);
            errorHandler(err, setSessionModal, setShowToast, toast);
        });
        clientCount(searchkey).then((responsex) => {
            if (responsex.status === 200) {
                console.log(responsex);
                const rowNumberCount = responsex.data[0].number_of_rows;
                const pgCount = Math.ceil(rowNumberCount / clientsPerPage);
                setNumberOfPage(pgCount);
                setNumberOfRows(rowNumberCount);
            } else {
                setNumberOfPage(0);
                setNumberOfRows(0);
                setIsLoading(true);
                errorHandler("search error" + responsex.status, setSessionModal, setShowToast, toast);

            }
        }).catch(err => {
            errorHandler(err, setSessionModal, setShowToast, toast);
        });
        setFirstLoad(0);
    }, []);

    // function fetching row count and result set based on timer 
    function intervalexecute() {
        if (timeoutid) {
            console.log("need to clear timeoutid"); // same time another key stroke
            clearTimeout(timeoutid);
        }

        const timeout = setTimeout(() => {
            setIsLoading(true);
            clientData(currentPage, searchkey, clientsPerPage, sort).then((response) => {
                if (response.status === 200) {
                    setClientlist(response.data);
                } else {
                    setClientlist([]);
                    errorHandler("search have issue " + response.status, setSessionModal, setShowToast, toast);

                }
                setIsLoading(false);

            }).catch(err => {
                setIsLoading(false);
                errorHandler(err, setSessionModal, setShowToast, toast);
            });

            clientCount(searchkey).then((responsex) => {
                if (responsex.status === 200) {
                    const rowNumberCount = responsex.data[0].number_of_rows;
                    const pgCount = Math.ceil(rowNumberCount / clientsPerPage);
                    setNumberOfPage(pgCount);
                    setNumberOfRows(rowNumberCount);
                } else {
                    setNumberOfPage(0);
                    setNumberOfRows(0);
                    setIsLoading(true);
                    errorHandler("search result count issue" + responsex.status, setSessionModal, setShowToast, toast);

                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });

        }, 2000);

        setTimeoutid(timeout);

    }
    // getting staff data and count data when search key changed 
    useEffect(() => {

        if (firstLoad !== 1) { // first load not use search
            intervalexecute();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchkey]);

    // getting staff data when current page or sort changed
    useEffect(() => {
        setIsLoading(true);

        clientData(currentPage, searchkey, clientsPerPage, sort).then((response) => {
            console.log(response.data)
            if (response.status === 200) {
                setClientlist(response.data);
                setIsLoading(false);
            } else {
                setClientlist([]);
                setIsLoading(false);
                errorHandler(response.status, setSessionModal, setShowToast, toast);

            };
        }).catch(err => {
            errorHandler(err, setSessionModal, setShowToast, toast);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, sort]);
    // handle inputs on change event
    const handleChange = async (event) => {
        setCurrentPage(1);
        const name = event.target.name;
        if (name === 'search_inputs') {
            setSearchkey(event.target.value);
        } else if (name === 'sort') {

            let value = document.getElementById(name).value;
            setSort(value);
        }

    };


    // // deleting a staff
    // const deleteClicked = (e, staffObj) => { deleteStaff(e, staffObj, setDel).then((res) =>{ 
    //         setDel(false);
    //         if(res.status===201 && res.data.affectedRows>0) {
    //             setShowToast(true);
    //             toast.success("Delete Successful",{ position: toast.POSITION.TOP_CENTER, hideProgressBar: false, autoClose:3000,
    //                 onClose: () => {
    //                 window.location.href = "/staffs"} 
    //             });
    //         }else{
    //             toast.error("Data not found!",{ position: toast.POSITION.TOP_CENTER, hideProgressBar: false, autoClose:3000});
    //         };
    //     }).catch((err)=>{
    //         setDel(false);
    //         errorHandler(err, setSessionModal, setShowToast, toast, "/staffs");
    //     });
    // };



    // logs out a user
    const handleLogout = async () => {
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location = "/";
    };

    // handle toast close
    const handleCloseToast = () => {
        setShowToast(false);
    };


    return (
        <div>
            <a href="/clients" className="btn btn-sm rounded-pill btn-secondary">Client Home</a>
            {del &&
                <div>Deleting Item...</div>
            }
            {showToast && <div className="toast-overlay"></div>}
            <ToastContainer onClose={handleCloseToast} />
            <div className="card">
                <h5 className="card-header">Client Management</h5>
                <div className="mb-3 row">
                    <div className="col-md-6">
                        <div style={{ paddingLeft: "10px" }}>
                            <a href={"/addclient"} target="_blank" type="button" className="btn btn-primary col-md-3" rel="noreferrer">Add New Client</a>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <input className="form-control" type="search" id="search_input" name="search_inputs" value={searchkey || ''} onChange={handleChange} placeholder="search ..." />
                    </div>
                </div>
                <div className="mb-3 row" style={{ paddingLeft: "20px" }}>
                    <label className="col-md-1 col-form-label">Sort </label>
                    <div className="col-md-3">
                        <select className="form-select" id="sort" name="sort" onChange={handleChange} value={sort} >
                            <option value="">--Order--</option>
                            <option value="0">By Name</option>
                            <option value="3">Most Recent Entry</option>
                            <option value="5">Most Recent Update</option>
                            <option value="1">By Name DESC</option>
                            <option value="2">Most Oldest Entry</option>
                            <option value="4">Most Oldest Update</option>


                        </select>
                    </div>
                </div>

                <div>
                    <SessionExpiredModal
                        show={sessionModal}
                        handleLogout={handleLogout}
                    />
                </div>


            </div>
            <div className="alert alert-primary" role='alert'><strong>{numberOfRows} Clients found</strong></div>
            {isLoading ?
                <div>
                    <Loading />
                </div> : <div>
                    <div className="table-responsive text-nowrap">
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th>Action</th>
                                    <th>Name</th>
                                    <th>Client Number</th>
                                    <th>API Key</th>
                                    <th>Apps</th>
                                    {/* <th>Bundle</th> */}
                                </tr>
                            </thead>
                            <tbody className="table-border-bottom-0">
                                {clientlist && clientlist.length > 0 && clientlist.map((clientObj) => (
                                    <tr key={clientObj.id}>
                                        <td>
                                            <div className="dropdown">
                                                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="bx bx-dots-vertical-rounded"></i>
                                                </button>
                                                <div className="dropdown-menu" >
                                                    <a className="dropdown-item" href={"/client/edit/" + clientObj.id}><i className="bx bx-edit-alt me-1"></i> Edit</a>
                                                    {/* <a href="/#" data-key={clientObj.id} className="dropdown-item" onClick={(event) => deleteClicked(event, clientObj)} ><i className="bx bx-trash me-1"></i> Delete</a> */}
                                                </div>
                                            </div>
                                            <div>
                                            </div>
                                        </td>

                                        <td>
                                            <i className="fab fa-angular fa-lg text-danger me-3"></i> <a href={"/client/edit/" + clientObj.id} target="_blank" rel="noreferrer"> <strong>{clientObj.name ? clientObj.name : ''}</strong></a>
                                        </td>
                                        <td><a href={"/client/edit/" + clientObj.id} target="_blank" rel="noreferrer"> {clientObj.client_number ? clientObj.client_number : ''} </a></td>
                                        <td><a href={clientObj.id ? "/client/edit/" + clientObj.id : ''} target="_blank" rel="noreferrer" >{clientObj.api_key ? clientObj.api_key : ''}</a></td>
                                        <td>  {clientObj.apps_name}
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="pagination-container" style={{ display: "flex", flexWrap: "wrap" }}>
                        <PaginationComponent
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            numberOfPage={numberOfPage}
                            setShowToast={setShowToast}
                            numVisiblePages={50}
                        />
                    </div>
                </div>}
        </div>
    );
};

export default Clients;