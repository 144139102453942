import React from "react";
import { useEffect } from "react";
import Loading from "../Utils/Loading";


const AnalyticsTopLoc = ({analyticsDataDetailsLoc, topAnalyticData, setTopAnalyticData, insightLocLoading, setInsightLocLoading}) => {
    // get top locations
    useEffect(() => {
        // get top analytics data
        const topAnalyticsData = (analyticsDataDetailsLoc) => {
            console.log('ok')
            setInsightLocLoading(true);
            let topAnalyticsData = [];
            analyticsDataDetailsLoc.forEach(element => {
                console.log('elem', element)
                Object.keys(element).forEach(key => {
                    console.log('key', element[key])
                    if(element[key].length > 0){
                        console.log('ok', element[key][0].name, element[key][0].val);
                        topAnalyticsData.push({metric: key, name: element[key][0].name, val: element[key][0].val});
                    }
                })
            });
            console.log('top', topAnalyticsData);
            setTopAnalyticData(topAnalyticsData);
            setTimeout(() => {
                setInsightLocLoading(false);
            }, 1000);
        };
        topAnalyticsData(analyticsDataDetailsLoc);
    }, [analyticsDataDetailsLoc, setTopAnalyticData, setInsightLocLoading]);


    return (
        <div>
            {insightLocLoading ? 
                <Loading />:
                <div className="card">
                    <div className="card-header">
                        <h4 style={{color:'#000000FF', textAlign:'center'}}>Top Locations</h4>
                    </div>
                    <div className="card-body">
                        <div className="card-row" style={{display:'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                            {topAnalyticData.length > 0 && topAnalyticData.map((item, index) => (
                                <div className="card-column" key={index} style={{flex: '1 1 calc(25% - 10px)', marginBottom: '5px', marginLeft: '5px'}}>
                                    <div className="card mb-4" style={{height:'200px'}}>
                                        <div className="card-header" style={{height:'70px'}}> 
                                            <h5 style={{textAlign:"center", color:'#000000FF'}}>Most {item.metric}</h5>
                                        </div>
                                        <div className="card-body">
                                            <h5 style={{ color: "#B1624EFF", textAlign:"center" }}>{item.name}</h5>
                                            <h5 style={{ color: "#343148FF", textAlign:"center" }}>{item.val}</h5>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};


export default AnalyticsTopLoc;