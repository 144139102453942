import React from 'react';

const Loading = () => {
  return (
    <div className='d-flex justify-content-center align-items-center' style={{ height: '300px' }}>
      <div className='spinner-border spinner-border-lg text-primary' role='status'>
        <span className='visually-hidden'>...</span>
      </div>
      <div className='spinner-border spinner-border-lg text-primary' role='status'>
        <span className='visually-hidden'>Loading...</span>
      </div>
      <div className='spinner-border spinner-border-lg text-primary' role='status'>
        <span className='visually-hidden'>...</span>
      </div>
    </div>
  );
};

export default Loading;
