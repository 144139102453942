import React from "react";
import { useEffect, useState } from 'react';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SessionExpiredModal from '../SessionExpire/SessionExpiredModal';
import { errorHandler } from "../Utils/errorHandler";
import { getAppById, updateApp } from "../../apis/AppsAPI";
import { clientData } from "../../apis/ClientAPI";
import { useParams } from 'react-router-dom';

const EditApp = () => {
    const [inputs, setInputs] = useState({});
    const [submitting, setSubmitting] = useState(false);
    const [clientlist, setClientlist] = useState([]);
    const [errorMsg, setErrorMsg] = useState("");
    const { app_id } = useParams();
    const [appIconFileSelected, setAppIconFileSelected] = useState(false);
    const [splashScreenSelected, setSplashScreenSelected] = useState(false);

    const [sessionModal, setSessionModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [backUrl] = useState("/app_management_center" + window.location.search);
    const [currentUrl] = useState(window.location.pathname.substring(1) + window.location.search);
    const toastErrOptions = {
        autoClose: 1000,
        position: toast.POSITION.TOP_CENTER,
    };
    // file validation
    const validateSelectedFile = async (selectedFile, ftype) => {
        let message = "";
        let return_json = { "status": false };
        if (!selectedFile) {
            message = "No file Selected";
            setErrorMsg(message);
            return_json.msg = message;
            return return_json;
        }

        setErrorMsg("");

        const MIN_FILE_SIZE = 100; // 100 Byte
        let MAX_FILE_SIZE = 1024; // 1MB
        let fileName = selectedFile.name;
        let ext = fileName.substr(fileName.lastIndexOf('.') + 1);
        console.log(ftype);
        if (ftype === "certificate") {
            MAX_FILE_SIZE = 1024; // 3MB
            // setErrorMsg(selectedFile);
            if (ext === "pem" || ext === "ppk" || ext === "crt" || ext === "pub") {
                console.log("ok extension")
            } else {
                message = "File type should be .pem";
                setErrorMsg(message);
                return_json.msg = message;
                return return_json;
            }
        } else if (ftype === "plist") {
            MAX_FILE_SIZE = 1024; // 2MB
            if (ext === "plist") {
                console.log("ok extension")
            } else {
                message = "File type should be .plist";
                setErrorMsg(message);
                return_json.msg = message;
                return return_json;
            }
        } else if (ftype === "json") {
            MAX_FILE_SIZE = 1024; // 2MB
            if (ext === "json") {
                console.log("json extension")
            } else {
                message = "File type should be .json";
                setErrorMsg(message);
                return_json.msg = message;
                return return_json;
            }
        }
        else if (ftype === "image") {

            if (ext === "png" || ext === "jpg" || ext === "jpeg" || ext === "gif" || ext === "bmp" || ext === "ico") {
                MAX_FILE_SIZE = 1024; // 12MB


            } else {
                message = "File type should be image";
                setErrorMsg(message);
                return_json.msg = message;
                return return_json;
            }
        }
        else {
            message = "File type has unexpected issue";
            setErrorMsg(message);
            return_json.msg = message;
            return return_json;
        }

        const fileSizeKiloBytes = selectedFile.size / 1024;
        const fileSizeBytes = selectedFile.size;
        // MIN_FILE_SIZE in bytes
        if (fileSizeBytes < MIN_FILE_SIZE) {
            message = "File Size too small(" + fileSizeKiloBytes + "). Should be bigger than " + MIN_FILE_SIZE + " kb";

            setErrorMsg(message);
            return_json.msg = message;
            return return_json;
        }
        if (fileSizeKiloBytes > MAX_FILE_SIZE) {
            message = "File Size too big (" + fileSizeKiloBytes + "). Should be smaller than " + MAX_FILE_SIZE + " kb";
            setErrorMsg(message);
            return_json.msg = message;
            return return_json;
        }
        return_json = { "msg": "", "status": true }
        return return_json;

    };

    // remove url will only change the flag delete translation file
    const removeUrl = () => {
        setInputs(values => ({ ...values, delete_translation_file: 1, translation_file_url: "" }));
    };
    // fired when something changed 
    const handleChange = (event) => {
        const name = event.target.name;
        let value;
        let filen;
        if (event.target.type === 'select') {

            value = document.getElementById(name).value;
            setInputs(values => ({ ...values, [name]: value }));
        }
        else if (event.target.type === 'checkbox') {

            if (event.target.checked) {

                setInputs(values => ({ ...values, [name]: 1 }));
                console.log(inputs);
            } else {

                setInputs(values => ({ ...values, [name]: 0 }));
                console.log(inputs);

            }
        } else if (event.target.type === 'file') {
            // read the file    
            filen = event.target.files[0];

            // UI control after selection
            if (name === "app_icon_file_name") {
                setAppIconFileSelected(true);
                setInputs(values => ({ ...values, ["appIconFileSelected"]: true }));

            }
            else if (name === "splash_screen_file_name") {
                setSplashScreenSelected(true);
                setInputs(values => ({ ...values, ["splashScreenSelected"]: true }));


            }
            else if (name === "qr_code_file_name") {
                setSplashScreenSelected(true);
                setInputs(values => ({ ...values, ["qrCodeFileNameSelected"]: true }));

            }
            // if no file selected no validation required                

            if (!filen) {
                if (inputs[name]) {
                    setInputs(values => ({ ...values, [name]: null }));
                }
                return;
            }

            let file_type = "";
            if (name === "apn_prod_cert_file_name" || name === "apn_dev_cert_file_name") {

                file_type = "certificate";
            } else if (name === "main_menu_override_file_name") {

                file_type = "plist";
            } else if (name === "translation_input_file") {

                file_type = "json";

            }
            else {
                file_type = "image";
            }

            let filevalidation = false;
            validateSelectedFile(filen, file_type).then((response) => {
                filevalidation = response;
                if (filevalidation.status) {
                    setInputs(values => ({ ...values, [name]: filen }));
                    // we will delete if old translation file is there new file selected 
                   
                }
                else if (!filevalidation.status) {

                    // if validation failed then clear the field
                    document.getElementById(name).value = "";
                    if (inputs[name]) {
                        setInputs(values => ({ ...values, [name]: null }));
                    }
                    toast.error("Validation Error:" + filevalidation?.msg, toastErrOptions);


                } else {
                    // some unusual thing happen so set input null and through error
                    if (inputs[name]) {
                        setInputs(values => ({ ...values, [name]: null }));

                    }
                    toast.error("Unexpected Error:" + filevalidation?.msg, toastErrOptions);

                }
                console.log(inputs);
            }).catch(error => {
                setErrorMsg(error);
                toast.error(error, toastErrOptions);

                // document.getElementById(name).value = "";
                console.log(error);
                if (inputs[name]) {
                    setInputs(values => ({ ...values, [name]: null }));
                }

            });


        }

        else {
            value = event.target.value;

            setInputs(values => ({ ...values, [name]: value }));
    
        }
        console.log(inputs);

    }


    // handle submit event
    const handleSubmit = event => {
        event.preventDefault();
        if (inputs.name === "") {
            // setShowToast(true);
            toast.error("Validation Error: APP Name Required", toastErrOptions);
        }
        else if (inputs.sku === "") {
            // setShowToast(true);
            toast.error("Validation Error: SKU Required", toastErrOptions);


        }
        else if (inputs.bundle_identifier === "") {
            // setShowToast(true);
            toast.error("Validation Error: Bundle Identifier KEY Required", toastErrOptions);

        }
        else {

            console.log("validation is perfect");
            setSubmitting(true);
            updateApp(inputs).then((res) => {
                setSubmitting(false);
                console.log(res.data);
                if (res.status === 201 && res.data.affectedRows > 0) {
                    // setShowToast(true);
                    toast.success("Successfully Saved Apps Information ", {
                        position: toast.POSITION.TOP_CENTER, autoClose: 2000, hideProgressBar: false,
                        onClose: () => {
                            window.location.reload();
                        }

                    });
                } else {
                    setShowToast(true);
                    toast.error(`Failed to save Apps!`, { position: toast.POSITION.TOP_CENTER, autoClose: false, hideProgressBar: true });
                }
            }).catch((err) => {
                setSubmitting(false);
                console.log(err);
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        }
    }



    useEffect(() => {
        const a_id = Number(app_id);
        getAppById(a_id).then((response) => {
            if (response.status === 200 && response.data.length > 0) {
                console.log(response.data[0]);
                setAppIconFileSelected(false);
                setSplashScreenSelected(false);
                setInputs(values => ({
                    ...values,
                    "id": a_id,
                    "name": response.data[0].name,
                    "bundle_identifier": response.data[0].bundle_identifier,
                    "client_id": response.data[0].client_id,
                    "sku": response.data[0].sku,
                    "timezone": response.data[0].timezone,
                    "country": response.data[0].country,
                    "classification": response.data[0].classification,
                    "is_700_active": response.data[0].is_700_active,
                    "vehicle_request_flag": response.data[0].vehicle_request_flag,
                    "ask_for_ssn_in_case_of_no_hit_for_700_credit": response.data[0].ask_for_ssn_in_case_of_no_hit_for_700_credit,
                    "advanced_push_enabled": response.data[0].advanced_push_enabled,
                    "welcome_screen_title": response.data[0].welcome_screen_title,
                    "welcome_screen_text": response.data[0].welcome_screen_text,
                    "loyalty_rewards_program_type": response.data[0].loyalty_rewards_program_type,
                    "loyalty_rewards_terms_and_conditions": response.data[0].loyalty_rewards_terms_and_conditions,
                    "extra_gan_account_ids": response.data[0].extra_gan_account_ids,
                    "analytics_report_email": response.data[0].analytics_report_email,
                    "reports_email_for_700_credit": response.data[0].reports_email_for_700_credit,
                    "title_for_700_credit_screen": response.data[0].title_for_700_credit_screen,
                    "home_screen_title": response.data[0].home_screen_title,
                    "choose_a_location_screen_title": response.data[0].choose_a_location_screen_title,
                    "splash_screen_backdrop": response.data[0].splash_screen_backdrop,
                    "prequalified_username_for_700_credit": response.data[0].prequalified_username_for_700_credit,
                    "prequalified_password_for_700_credit": response.data[0].prequalified_password_for_700_credit,
                    "terms_and_conditions_for_700_credit": response.data[0].terms_and_conditions_for_700_credit,
                    "privacy_policy_for_700_credit": response.data[0].privacy_policy_for_700_credit,
                    "apn_dev_cert_file_name": response.data[0].apn_dev_cert_file_name,
                    "apn_prod_cert_file_name": response.data[0].bundle_identifier + "_prod.pem",
                    "app_icon_file_name": response.data[0].app_icon_file_name,
                    "qr_code_file_name": response.data[0].qr_code_file_name,
                    "splash_screen_file_name": response.data[0].splash_screen_file_name,
                    "show_home_screen_pop_up_alert": response.data[0].show_home_screen_pop_up_alert,
                    "home_screen_pop_up_alert_text": response.data[0].home_screen_pop_up_alert_text,
                    "status":  response.data[0].status,
                    "is_email_available":  response.data[0].is_email_available,
                    "is_sms_available":  response.data[0].is_sms_available,
                    "translation_file_url":  response.data[0].translation_file_url,
                    "appIconFileSelected": false,
                    "splashScreenSelected": false,
                    "qrCodeFileNameSelected": false,
                    "old_translation_url":  response.data[0].translation_file_url,
                    "delete_translation_file":0
                }));
                
                // old translation file be the existing url if any 
                //"apn_prod_cert_file_name": response.data[0].apn_prod_cert_file_name,
            }
            else {
                toast.error(`Data not found!`, {
                    position: toast.POSITION.TOP_CENTER, autoClose: false
                });
            };
        }
        ).catch(err => {
            errorHandler(err, setSessionModal, setShowToast, toast);
        });

        clientData(1, "", 5000, 0).then((response) => {
            if (response.status === 200) {
                setClientlist(response.data);

            } else {
                setClientlist([]);

            };
        }).catch(err => {

            errorHandler(err, setSessionModal, setShowToast, toast, "/addapp");
        });


    }, [app_id]);


    // logs out a user
    const handleLogout = async () => {
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location = "/";
    };


    // handles toast closing
    const handleCloseToast = () => {
        setShowToast(false);
    };

    return (
        <div className="col-xl-12">
            {submitting &&
                <div>Submtting Form...</div>
            }
            {showToast && <div className="toast-overlay"></div>}
            <ToastContainer onClose={handleCloseToast} />



            <a href={backUrl} className="btn btn-sm rounded-pill btn-secondary">Back</a>  <a href="/app_management_center" className="btn btn-sm rounded-pill btn-secondary">App Home</a> <a href={currentUrl} className="btn btn-sm rounded-pill btn-secondary">Add App</a>

            <SessionExpiredModal
                show={sessionModal}
                handleLogout={handleLogout}
            />


            <form onSubmit={handleSubmit} >
                <div className="card mb-4">
                    <h5 className="card-header">Editing App</h5>
                    <div className="card-body">

                        {errorMsg === "" ?
                            <div></div> : <div className="mb-3 row"> <div className="col-md-5"><sup style={{ color: "red", fontSize: 16 }}>{errorMsg}</sup></div></div>
                        }

                        <div className="mb-3 row">
                            <label className="form-label col-lg-3"> Status<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-5">
                                <select className="form-select" id="status" name="status" onChange={handleChange} value={inputs.status} required>
                                    <option value="1">Active</option>
                                    <option value="0">Inactive</option>
                                    <option value="CA">Cancel</option>
                                </select>
                            </div>
                        </div>
                        
                        
                        
                        <div className="mb-3 row">
                            <label className="form-label col-lg-3"> Client<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-5">
                                <select className="form-select" id="client_id" name="client_id" onChange={handleChange} value={inputs.client_id} required>
                                    <option key='' value="">--Select Client--</option>
                                    {clientlist && clientlist.length > 0 && clientlist.map((clientObj) => (
                                        <option key={clientObj.id} value={clientObj.id}>{clientObj.name}</option>

                                    ))}

                                </select>
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="form-label col-lg-3"> Classification<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-5">
                                <select className="form-select" id="classification" name="classification" onChange={handleChange} value={inputs.classification} required>
                                    <option value="">Select</option>
                                    <option value="auto_dealers">Auto Dealers</option>
                                    <option value="body_shops">Body Shops</option>
                                    <option value="motorcycle_dealers">Motorcycle Dealers</option>
                                    <option value="powersports_dealers">Powersports Dealers</option>
                                    <option value="scooter_dealers">Scooter Dealers</option>

                                </select>
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="form-label col-lg-3"> Country<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-5">
                                <select className="form-select" id="country" name="country" onChange={handleChange} value={inputs.country} required>
                                    <option value="AU">Australia</option>
                                    <option value="BD">Bangladesh</option>
                                    <option value="CA">Canada</option>
                                    <option value="IR">Ireland</option>
                                    <option value="AE">UAE</option>
                                    <option value="UK">United Kingdom</option>

                                    <option value="US">United State</option>


                                </select>
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="form-label col-lg-3"> Timezone<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-5">

                                <select className="form-select" id="timezone" name="timezone" onChange={handleChange} value={inputs.timezone} required >
                                    <option value="">--Select Timezone--</option>

                                    <option value="Hawaii">(GMT-10:00) Hawaii</option>
                                    <option value="Alaska">(GMT-09:00) Alaska</option>
                                    <option value="Pacific Time (US &amp; Canada)">(GMT-08:00) Pacific Time (US &amp; Canada)</option>
                                    <option value="Arizona">(GMT-07:00) Arizona</option>
                                    <option value="Mountain Time (US &amp; Canada)">(GMT-07:00) Mountain Time (US &amp; Canada)</option>
                                    <option value="Central Time (US &amp; Canada)">(GMT-06:00) Central Time (US &amp; Canada)</option>
                                    <option value="Eastern Time (US &amp; Canada)">(GMT-05:00) Eastern Time (US &amp; Canada)</option>
                                    <option value="Indiana (East)">(GMT-05:00) Indiana (East)</option><option value="" disabled="disabled">-------------</option>
                                    <option value="International Date Line West">(GMT-11:00) International Date Line West</option>
                                    <option value="Midway Island">(GMT-11:00) Midway Island</option>
                                    <option value="Samoa">(GMT-11:00) Samoa</option>
                                    <option value="Tijuana">(GMT-08:00) Tijuana</option>
                                    <option value="Chihuahua">(GMT-07:00) Chihuahua</option>
                                    <option value="Mazatlan">(GMT-07:00) Mazatlan</option>
                                    <option value="Central America">(GMT-06:00) Central America</option>
                                    <option value="Guadalajara">(GMT-06:00) Guadalajara</option>
                                    <option value="Mexico City">(GMT-06:00) Mexico City</option>
                                    <option value="Monterrey">(GMT-06:00) Monterrey</option>
                                    <option value="Saskatchewan">(GMT-06:00) Saskatchewan</option>
                                    <option value="Bogota">(GMT-05:00) Bogota</option>
                                    <option value="Lima">(GMT-05:00) Lima</option>
                                    <option value="Quito">(GMT-05:00) Quito</option>
                                    <option value="Caracas">(GMT-04:30) Caracas</option>
                                    <option value="Atlantic Time (Canada)">(GMT-04:00) Atlantic Time (Canada)</option>
                                    <option value="La Paz">(GMT-04:00) La Paz</option>
                                    <option value="Santiago">(GMT-04:00) Santiago</option>
                                    <option value="Newfoundland">(GMT-03:30) Newfoundland</option>
                                    <option value="Brasilia">(GMT-03:00) Brasilia</option>
                                    <option value="Buenos Aires">(GMT-03:00) Buenos Aires</option>
                                    <option value="Georgetown">(GMT-03:00) Georgetown</option>
                                    <option value="Greenland">(GMT-03:00) Greenland</option>
                                    <option value="Mid-Atlantic">(GMT-02:00) Mid-Atlantic</option>
                                    <option value="Azores">(GMT-01:00) Azores</option>
                                    <option value="Cape Verde Is.">(GMT-01:00) Cape Verde Is.</option>
                                    <option value="Casablanca">(GMT+00:00) Casablanca</option>
                                    <option value="Dublin">(GMT+00:00) Dublin</option>
                                    <option value="Edinburgh">(GMT+00:00) Edinburgh</option>
                                    <option value="Lisbon">(GMT+00:00) Lisbon</option>
                                    <option value="London">(GMT+00:00) London</option>
                                    <option value="Monrovia">(GMT+00:00) Monrovia</option>
                                    <option value="UTC">(GMT+00:00) UTC</option>
                                    <option value="Amsterdam">(GMT+01:00) Amsterdam</option>
                                    <option value="Belgrade">(GMT+01:00) Belgrade</option>
                                    <option value="Berlin">(GMT+01:00) Berlin</option>
                                    <option value="Bern">(GMT+01:00) Bern</option>
                                    <option value="Bratislava">(GMT+01:00) Bratislava</option>
                                    <option value="Brussels">(GMT+01:00) Brussels</option>
                                    <option value="Budapest">(GMT+01:00) Budapest</option>
                                    <option value="Copenhagen">(GMT+01:00) Copenhagen</option>
                                    <option value="Ljubljana">(GMT+01:00) Ljubljana</option>
                                    <option value="Madrid">(GMT+01:00) Madrid</option>
                                    <option value="Paris">(GMT+01:00) Paris</option>
                                    <option value="Prague">(GMT+01:00) Prague</option>
                                    <option value="Rome">(GMT+01:00) Rome</option>
                                    <option value="Sarajevo">(GMT+01:00) Sarajevo</option>
                                    <option value="Skopje">(GMT+01:00) Skopje</option>
                                    <option value="Stockholm">(GMT+01:00) Stockholm</option>
                                    <option value="Vienna">(GMT+01:00) Vienna</option>
                                    <option value="Warsaw">(GMT+01:00) Warsaw</option>
                                    <option value="West Central Africa">(GMT+01:00) West Central Africa</option>
                                    <option value="Zagreb">(GMT+01:00) Zagreb</option>
                                    <option value="Athens">(GMT+02:00) Athens</option>
                                    <option value="Bucharest">(GMT+02:00) Bucharest</option>
                                    <option value="Cairo">(GMT+02:00) Cairo</option>
                                    <option value="Harare">(GMT+02:00) Harare</option>
                                    <option value="Helsinki">(GMT+02:00) Helsinki</option>
                                    <option value="Istanbul">(GMT+02:00) Istanbul</option>
                                    <option value="Jerusalem">(GMT+02:00) Jerusalem</option>
                                    <option value="Kyev">(GMT+02:00) Kyev</option>
                                    <option value="Minsk">(GMT+02:00) Minsk</option>
                                    <option value="Pretoria">(GMT+02:00) Pretoria</option>
                                    <option value="Riga">(GMT+02:00) Riga</option>
                                    <option value="Sofia">(GMT+02:00) Sofia</option>
                                    <option value="Tallinn">(GMT+02:00) Tallinn</option>
                                    <option value="Vilnius">(GMT+02:00) Vilnius</option>
                                    <option value="Baghdad">(GMT+03:00) Baghdad</option>
                                    <option value="Kuwait">(GMT+03:00) Kuwait</option>
                                    <option value="Moscow">(GMT+03:00) Moscow</option>
                                    <option value="Nairobi">(GMT+03:00) Nairobi</option>
                                    <option value="Riyadh">(GMT+03:00) Riyadh</option>
                                    <option value="St. Petersburg">(GMT+03:00) St. Petersburg</option>
                                    <option value="Volgograd">(GMT+03:00) Volgograd</option>
                                    <option value="Tehran">(GMT+03:30) Tehran</option>
                                    <option value="Abu Dhabi">(GMT+04:00) Abu Dhabi</option>
                                    <option value="Baku">(GMT+04:00) Baku</option>
                                    <option value="Muscat">(GMT+04:00) Muscat</option>
                                    <option value="Tbilisi">(GMT+04:00) Tbilisi</option>
                                    <option value="Yerevan">(GMT+04:00) Yerevan</option>
                                    <option value="Kabul">(GMT+04:30) Kabul</option>
                                    <option value="Ekaterinburg">(GMT+05:00) Ekaterinburg</option>
                                    <option value="Islamabad">(GMT+05:00) Islamabad</option>
                                    <option value="Karachi">(GMT+05:00) Karachi</option>
                                    <option value="Tashkent">(GMT+05:00) Tashkent</option>
                                    <option value="Chennai">(GMT+05:30) Chennai</option>
                                    <option value="Kolkata">(GMT+05:30) Kolkata</option>
                                    <option value="Mumbai">(GMT+05:30) Mumbai</option>
                                    <option value="New Delhi">(GMT+05:30) New Delhi</option>
                                    <option value="Sri Jayawardenepura">(GMT+05:30) Sri Jayawardenepura</option>
                                    <option value="Kathmandu">(GMT+05:45) Kathmandu</option>
                                    <option value="Almaty">(GMT+06:00) Almaty</option>
                                    <option value="Astana">(GMT+06:00) Astana</option>
                                    <option value="Dhaka">(GMT+06:00) Dhaka</option>
                                    <option value="Novosibirsk">(GMT+06:00) Novosibirsk</option>
                                    <option value="Rangoon">(GMT+06:30) Rangoon</option>
                                    <option value="Bangkok">(GMT+07:00) Bangkok</option>
                                    <option value="Hanoi">(GMT+07:00) Hanoi</option>
                                    <option value="Jakarta">(GMT+07:00) Jakarta</option>
                                    <option value="Krasnoyarsk">(GMT+07:00) Krasnoyarsk</option>
                                    <option value="Beijing">(GMT+08:00) Beijing</option>
                                    <option value="Chongqing">(GMT+08:00) Chongqing</option>
                                    <option value="Hong Kong">(GMT+08:00) Hong Kong</option>
                                    <option value="Irkutsk">(GMT+08:00) Irkutsk</option>
                                    <option value="Kuala Lumpur">(GMT+08:00) Kuala Lumpur</option>
                                    <option value="Perth">(GMT+08:00) Perth</option>
                                    <option value="Singapore">(GMT+08:00) Singapore</option>
                                    <option value="Taipei">(GMT+08:00) Taipei</option>
                                    <option value="Ulaan Bataar">(GMT+08:00) Ulaan Bataar</option>
                                    <option value="Urumqi">(GMT+08:00) Urumqi</option>
                                    <option value="Osaka">(GMT+09:00) Osaka</option>
                                    <option value="Sapporo">(GMT+09:00) Sapporo</option>
                                    <option value="Seoul">(GMT+09:00) Seoul</option>
                                    <option value="Tokyo">(GMT+09:00) Tokyo</option>
                                    <option value="Yakutsk">(GMT+09:00) Yakutsk</option>
                                    <option value="Adelaide">(GMT+09:30) Adelaide</option>
                                    <option value="Darwin">(GMT+09:30) Darwin</option>
                                    <option value="Brisbane">(GMT+10:00) Brisbane</option>
                                    <option value="Canberra">(GMT+10:00) Canberra</option>
                                    <option value="Guam">(GMT+10:00) Guam</option>
                                    <option value="Hobart">(GMT+10:00) Hobart</option>
                                    <option value="Melbourne">(GMT+10:00) Melbourne</option>
                                    <option value="Port Moresby">(GMT+10:00) Port Moresby</option>
                                    <option value="Sydney">(GMT+10:00) Sydney</option>
                                    <option value="Vladivostok">(GMT+10:00) Vladivostok</option>
                                    <option value="Magadan">(GMT+11:00) Magadan</option>
                                    <option value="New Caledonia">(GMT+11:00) New Caledonia</option>
                                    <option value="Solomon Is.">(GMT+11:00) Solomon Is.</option>
                                    <option value="Auckland">(GMT+12:00) Auckland</option>
                                    <option value="Fiji">(GMT+12:00) Fiji</option>
                                    <option value="Kamchatka">(GMT+12:00) Kamchatka</option>
                                    <option value="Marshall Is.">(GMT+12:00) Marshall Is.</option>
                                    <option value="Wellington">(GMT+12:00) Wellington</option>
                                    <option value="Nuku'alofa">(GMT+13:00) Nuku'alofa</option></select>

                            </div>
                        </div>


                        <div className="mb-3 row">
                            <label className="col-md-3 col-form-label"> Name<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-5">
                                <input className="form-control" type="text" name="name" id="aname" value={inputs.name || ''} onChange={handleChange} required />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="col-md-3 col-form-label">SKU<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-5">
                                <input className="form-control" type="text" name="sku" id="sku" value={inputs.sku || ''} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="col-md-3 col-form-label">Bundle Identifier<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-5">
                                <input className="form-control" type="text" name="bundle_identifier" id="bundle_identifier" value={inputs.bundle_identifier || ''} onChange={handleChange} required />
                            </div>
                        </div>
                        {/* <div className="mb-3 row">
                            <label className="col-md-3 col-form-label">Beacon UUID</label>
                            <div className="col-md-5">
                                <input className="form-control" type="text" maxLength="36" name="beacon_uuid" id="beacon_uuid" value={inputs.beacon_uuid || ''} onChange={handleChange} />
                            </div>
                        </div> */}
                        {/* <div className="mb-3 row">
                            <label className="col-md-3 col-form-label">App store App ID</label>
                            <div className="col-md-5">
                                <input className="form-control" type="text" name="app_store_app_id" id="app_store_app_id" value={inputs.app_store_app_id || ''} onChange={handleChange} />
                            </div>
                        </div> */}
                        {/* <div className="mb-3 row">
                            <label className="col-md-3 col-form-label">App Store Icon Url</label>
                            <div className="col-md-9">
                                <input className="form-control" type="text" name="app_store_icon_url" id="app_store_icon_url" value={inputs.app_store_icon_url || ''} onChange={handleChange} />
                            </div>
                        </div> */}
                        <div className="mb-3 row">
                            <label className="col-md-3 col-form-label">App Icon</label>
                            <div className="col-md-5">

                                {inputs.app_icon_file_name && !appIconFileSelected && <span >
                                    <img src={process.env.REACT_APP_ASSET_URL + "/assets/app_icons/" + inputs.id + "/original/" + inputs.app_icon_file_name} height="250" width="200" alt="Splash screen" border="1" onError={(e) => { e.target.onerror = null; e.target.src = process.env.REACT_APP_ASSET_URL + "/assets/app_icons/" + inputs.id + "/original/Icon." + inputs.app_icon_file_name.split('.').pop() }} />
                                </span>}


                            </div>
                            <div className="col-md-3">

                                <input className="form-control" type="file" id="app_icon_file_name" name="app_icon_file_name" onChange={handleChange} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="col-md-3 col-form-label">Apn Certificate</label>
                            <div className="col-md-9">
                                <div className="mb-3 row">
                                    <div className="col-md-6">
                                        <label className="col-md-6 col-form-label">Development Cert    <sup style={{ color: "black", fontSize: 10 }}>.pem</sup></label>
                                        <input className="form-control" type="file" accept=".pem" id="apn_dev_cert_file_name" name="apn_dev_cert_file_name" onChange={handleChange} />
                                        {inputs.apn_dev_cert_file_name ?
                                            <a href={process.env.REACT_APP_ASSET_URL + "/apn_certs/" + inputs.apn_dev_cert_file_name} target="_blank" rel="noreferrer">
                                                <img src={process.env.REACT_APP_BASE_URL + "/cert_on_file_clip.png"} alt={inputs.apn_dev_cert_file_name} className="rounded-circle" />
                                            </a>
                                            :
                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                            <a>
                                                -
                                            </a>
                                        }


                                    </div>

                                    <div className="col-md-6">
                                        <label className="col-md-6 col-form-label">Production Cert             <sup style={{ color: "black", fontSize: 10 }}>.pem</sup></label>
                                        <input className="form-control" type="file" accept=".pem" id="apn_prod_cert_file_name" name="apn_prod_cert_file_name" onChange={handleChange} />

                                        {inputs.apn_prod_cert_file_name ?
                                            <a href={process.env.REACT_APP_ASSET_URL + "/apn_certs/" + inputs.apn_prod_cert_file_name} target="_blank" rel="noreferrer">

                                                <img src={process.env.REACT_APP_BASE_URL + "/cert_on_file_clip.png"} alt={inputs.apn_prod_cert_file_name} className="rounded-circle" />


                                            </a>
                                            :
                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                            <a>
                                                -
                                            </a>
                                        }

                                    </div>
                                </div>

                            </div>
                        </div>
                        {/* <div className="mb-3 row">
                            <label className="col-md-3 col-form-label">Main Menu Override</label>
                            <div className="col-md-7">

                                <input className="form-control" type="file" accept=".plist" id="main_menu_override_file_name" name="main_menu_override_file_name" onChange={handleChange} />
                            </div>
                            <div className="col-md-2">
                                <sup style={{ color: "black", fontSize: 12 }}>.plist</sup>
                            </div>
                        </div> */}
                        <div className="mb-3 row">
                            <label className="col-md-3 col-form-label">QR Code</label>
                            <div className="col-md-7">

                                <input className="form-control" type="file" id="qr_code_file_name" name="qr_code_file_name" onChange={handleChange} />
                                {inputs.qr_code_file_name ?
                                    <a href={process.env.REACT_APP_ASSET_URL + "/assets/qr_codes/" + inputs.id + "/original/" + inputs.qr_code_file_name} target="_blank" rel="noreferrer">
                                        Download QR Code

                                    </a>
                                    :
                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                    <a>
                                        -
                                    </a>
                                }
                            </div>

                        </div>


                        <h2>Screen</h2>
                        <div className="mb-3 row">
                            <label className="col-md-3 col-form-label">Splash Screen Backdrop</label>
                            <div className="col-md-5">
                                <select className="form-select" id="splash_screen_backdrop" name="splash_screen_backdrop" onChange={handleChange} value={inputs.splash_screen_backdrop} >
                                    <option value="">--Select--</option>

                                    <option value="white">White</option>
                                    <option value="black">Black</option>
                                    <option value="grey">Grey</option>
                                    <option value="blue_gradient">Blue Gradient</option>
                                </select>

                            </div>
                        </div>


                        <div className="mb-3 row">
                            <label className="col-md-3 col-form-label">Splash Screen</label>
                            <div className="col-md-9">
                                {/* <span >
                                    <img src={inputs.splash_screen_file_name ? URL.createObjectURL(inputs.splash_screen_file_name) : "no-image-splash.png"} height="250" width="200" alt="Splash screen" border="1" onError={(e) => { e.target.onerror = null; e.target.src = process.env.REACT_APP_BASE_URL + "/app_no_image.jpg" }} />
                                </span> */}
                                {inputs.splash_screen_file_name && !splashScreenSelected &&
                                    <span >
                                        <img src={inputs.splash_screen_file_name ? process.env.REACT_APP_ASSET_URL + "/assets/splash_screens/" + inputs.id + "/original/" + inputs.splash_screen_file_name : "app_file_not_exist.jpg"} height="250" width="200" alt="Splash screen" border="1" onError={(e) => { e.target.onerror = null; e.target.src = process.env.REACT_APP_ASSET_URL + "/assets/splash_screens/" + inputs.id + "/original/splash_screen." + inputs.app_icon_file_name.split('.').pop() }} />


                                    </span>
                                }
                                <input className="form-control" type="file" id="splash_screen_file_name" name="splash_screen_file_name" onChange={handleChange} />
                            </div>
                        </div>

                        {/* <div className="mb-3 row">
                            <label className="col-md-3 col-form-label">BlackBerry</label>
                            <div className="col-md-9">
                                <div className="mb-3 row">
                                    <div className="col-md-6">
                                        <span >
                                            <img src={inputs.bb_splash_file_name ? URL.createObjectURL(inputs.bb_splash_file_name) : "no-image-splash.png"} height="250" width="200" alt="black berry flash screen" border="1" />
                                        </span>
                                        <label className="col-md-6 col-form-label">Splash Screen </label>

                                        <input className="form-control" type="file" id="bb_splash_file_name" name="bb_splash_file_name" onChange={handleChange} />
                                    </div>
                                    <div className="col-md-6">
                                        <span >
                                            <img src={inputs.bb_featured_file_name ? URL.createObjectURL(inputs.bb_featured_file_name) : "no-image-splash.png"} height="250" width="200" alt="black berry Featured screen" border="1" />
                                        </span>
                                        <label className="col-md-6 col-form-label">Featured Screen</label>

                                        <input className="form-control" type="file" id="bb_featured_file_name" name="bb_featured_file_name" onChange={handleChange} />
                                    </div>


                                </div>


                            </div>
                        </div> */}

                        {/* <div className="mb-3 row">
                            <label className="col-md-3 col-form-label">Custom Style Background</label>
                            <div className="col-md-9">
                                <span >
                                    <img src={inputs.custom_style_bg_file_name ? URL.createObjectURL(inputs.custom_style_bg_file_name) : "no-image-splash.png"} height="250" width="200" alt="custom style background" border="1" />
                                </span>
                                <input className="form-control" type="file" id="custom_style_bg_file_name" name="custom_style_bg_file_name" onChange={handleChange} />
                            </div>
                        </div> */}
                        <div className="mb-5 row">
                            <label className="col-md-3 col-form-label">Home Screen Title</label>
                            <div className="col-md-9 ">

                                <input className="form-control" type="text" name="home_screen_title" id="home_screen_title" value={inputs.home_screen_title || ''} onChange={handleChange} />

                            </div>
                        </div>
                        <div className="mb-5 row">
                            <label className="col-md-3 col-form-label">Location Screen Title</label>
                            <div className="col-md-9 ">

                                <input className="form-control" type="text" name="choose_a_location_screen_title" id="choose_a_location_screen_title" value={inputs.choose_a_location_screen_title || ''} onChange={handleChange} />

                            </div>
                        </div>




                        <div className="mb-5 row">
                            <label className="col-md-3 col-form-label">Welcome Screen Title</label>
                            <div className="col-md-9 ">
                                <textarea
                                    id="welcome_screen_title" name="welcome_screen_title"
                                    value={inputs.welcome_screen_title || ''}
                                    onChange={handleChange} rows="4" cols="60"
                                />
                            </div>
                        </div>



                        <div className="mb-5 row">
                            <label className="col-md-3 col-form-label">Welcome Screen Text</label>
                            <div className="col-md-9">
                                <textarea
                                    id="welcome_screen_text" name="welcome_screen_text"
                                    value={inputs.welcome_screen_text || ''}
                                    onChange={handleChange} rows="4" cols="60"
                                />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="opt_show_home_screen_pop_up_alert" className="col-md-3 col-form-label"> Show Home Screen Pop Up Alert</label>
                            <div className="col-md-5 ">
                                <input type="checkbox" id="opt_show_home_screen_pop_up_alert" name="show_home_screen_pop_up_alert" checked={inputs.show_home_screen_pop_up_alert === 1} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="mb-5 row">
                            <label className="col-md-3 col-form-label">Home Screen Pop Up Text </label>
                            <div className="col-md-9">
                                <textarea
                                    id="home_screen_pop_up_alert_text" name="home_screen_pop_up_alert_text"
                                    value={inputs.home_screen_pop_up_alert_text || ''}
                                    onChange={handleChange} rows="15" cols="30"
                                />
                            </div>
                        </div>



                        <div className="mb-3 row">
                            <label className="col-md-2 col-form-label">Translation Json File</label>
                            {inputs.translation_file_url && <span >
                                    <a href={inputs.translation_file_url}  > {inputs.translation_file_url}   </a>
                                  <input type="button" className="btn btn-secondary col-md-3" value="Remove" onClick={removeUrl} />  </span>  }
                            

                            
                            <div className="mb-3 col-md-10">
                                <label className="form-label">Upload Json File</label>
                                <input className="form-control" type="file" id="translation_input_file" name="translation_input_file" onChange={handleChange} />
                            </div>
                        </div>





                        <div className="mb-5 row">
                            <label className="col-md-3 col-form-label">Vehicle_request_flag</label>
                            <div className="col-md-5">
                                <select className="form-select" id="vehicle_request_flag" name="vehicle_request_flag" onChange={handleChange} value={inputs.vehicle_request_flag}>
                                    <option value="">--Select --</option>
                                    <option value="none">None</option>
                                    <option value="loaner">Loaner</option>
                                    <option value="rental">Rental</option>

                                </select>
                            </div>
                        </div>



                        <div className="mb-5 row">
                            <label className="col-md-3 col-form-label">Loyalty Rewards Program Type</label>
                            <div className="col-md-5">
                                <select className="form-select" id="loyalty_rewards_program_type" name="loyalty_rewards_program_type" onChange={handleChange} value={inputs.loyalty_rewards_program_type} >
                                    <option value="">--Select --</option>
                                    <option value="group">Group</option>
                                    <option value="location">Location</option>

                                </select>
                            </div>
                        </div>
                        <div className="mb-5 row">
                            <label className="col-md-3 col-form-label">Loyalty Rewards terms & Conditions</label>
                            <div className="col-md-9">
                                <textarea
                                    id="loyalty_rewards_terms_and_conditions" name="loyalty_rewards_terms_and_conditions"
                                    value={inputs.loyalty_rewards_terms_and_conditions || ''}
                                    onChange={handleChange} rows="20" cols="90"
                                />
                            </div>
                        </div>
                        {/* <div className="mb-3 row">
                            <label className="col-md-3 col-form-label">Facebook App ID</label>
                            <div className="col-md-9">
                                <input className="form-control" type="text" name="facebook_app_id" id="facebook_app_id" value={inputs.facebook_app_id || ''} onChange={handleChange} />
                            </div>
                        </div> */}
                        {/* <div className="mb-3 row">
                            <label className="col-md-3 col-form-label">Twitter  </label>
                            <div className="col-md-9">
                                <div className="mb-3 row">
                                    <div className="col-md-5">
                                        <label className="col-md-6 col-form-label">OAuth Consumer Key</label>

                                        <input className="form-control" type="text" name="twitter_consumer_key" value={inputs.twitter_consumer_key || ''} onChange={handleChange} />
                                    </div>
                                    <div className="col-md-7">
                                        <label className="col-md-6 col-form-label">OAuth Consumer Secret</label>

                                        <input className="form-control" type="text" name="twitter_consumer_secret" value={inputs.twitter_consumer_secret || ''} onChange={handleChange} />
                                    </div>
                                </div>


                            </div>
                        </div> */}




                        <div className="mb-3 row">
                            <label className="col-md-3 col-form-label">Extra Google Analytics Tracker  ID <sup style={{ color: "black", fontSize: 12 }}>comma separated</sup></label>
                            <div className="col-md-9">
                                <input className="form-control" type="text" name="extra_gan_account_ids" id="extra_gan_account_ids" value={inputs.extra_gan_account_ids || ''} onChange={handleChange} />
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <label className="col-md-3 col-form-label">Analytics Report Email To</label>
                            <div className="col-md-9">
                                <input className="form-control" type="text" id="analytics_report_email" name="analytics_report_email" value={inputs.analytics_report_email || ''} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="advanced_push_enabled" className="col-md-3 col-form-label"> Enable Advance Push Notification</label>
                            <div className="col-md-5 ">
                                <input type="checkbox" id="advanced_push_enabled" name="advanced_push_enabled" checked={inputs.advanced_push_enabled === 1} onChange={handleChange} />
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <label htmlFor="is_email_available" className="col-md-3 col-form-label"> Email Registration Available</label>
                            <div className="col-md-5 ">
                                <input type="checkbox" id="is_email_available" name="is_email_available" checked={inputs.is_email_available === 1} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="is_sms_available" className="col-md-3 col-form-label"> SMS Registration Available </label>
                            <div className="col-md-5 ">
                                <input type="checkbox" id="is_sms_available" name="is_sms_available" checked={inputs.is_sms_available === 1} onChange={handleChange} />
                            </div>
                        </div>

                        



                        <h2>700 Credits</h2>
                        <div className="mb-3 row">
                            <label htmlFor="opt_is_700_active" className="col-md-3 col-form-label"> Enabled 700 Credit</label>
                            <div className="col-md-5 ">


                                <input type="checkbox" id="opt_is_700_active" name="is_700_active" checked={inputs.is_700_active === 1} onChange={handleChange} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="opt_ask_ssn" className="col-md-3 col-form-label"> Ask For SSN In Case No Hit</label>
                            <div className="col-md-5 ">
                                <input type="checkbox" id="opt_ask_ssn" name="ask_for_ssn_in_case_of_no_hit_for_700_credit" checked={inputs.ask_for_ssn_in_case_of_no_hit_for_700_credit === 1} onChange={handleChange} />

                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="col-md-3 col-form-label">700 Credit Report Email To</label>
                            <div className="col-md-9">
                                <input className="form-control" type="text" name="reports_email_for_700_credit" value={inputs.reports_email_for_700_credit || ''} onChange={handleChange} />
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <label className="col-md-3 col-form-label">700 Credit Screen Title</label>
                            <div className="col-md-5">
                                <input className="form-control" type="text" name="title_for_700_credit_screen" id="title_for_700_credit_screen" value={inputs.title_for_700_credit_screen || ''} onChange={handleChange} />
                            </div>
                        </div>




                        <div className="mb-3 row">
                            <label className="col-md-3 col-form-label">700 Credit Prequalified </label>
                            <div className="col-md-9">
                                <div className="mb-3 row">
                                    <div className="col-md-6">
                                        <label className="col-md-6 col-form-label">Username</label>

                                        <input className="form-control" type="text" name="prequalified_username_for_700_credit" id="prequalified_username_for_700_credit" value={inputs.prequalified_username_for_700_credit || ''} onChange={handleChange} />
                                    </div>
                                    <div className="col-md-6">
                                        <label className="col-md-6 col-form-label">Password</label>

                                        <input className="form-control" type="text" name="prequalified_password_for_700_credit" id="prequalified_password_for_700_credit" value={inputs.prequalified_password_for_700_credit || ''} onChange={handleChange} />
                                    </div>
                                </div>


                            </div>
                        </div>


                        <div className="mb-5 row">
                            <label className="col-md-3 col-form-label">700 Credit Terms & Conditions</label>
                            <div className="col-md-9 ">
                                <textarea
                                    id="terms_and_conditions_for_700_credit" name="terms_and_conditions_for_700_credit"
                                    value={inputs.terms_and_conditions_for_700_credit || ''}
                                    onChange={handleChange} rows="20" cols="90"
                                />
                            </div>
                        </div>
                        <div className="mb-5 row">
                            <label className="col-md-3 col-form-label">700 Credit Privacy Policy</label>
                            <div className="col-md-9">
                                <textarea
                                    id="privacy_policy_for_700_credit" name="privacy_policy_for_700_credit"
                                    value={inputs.privacy_policy_for_700_credit || ''}
                                    onChange={handleChange} rows="20" cols="90"
                                />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <div className="col-md-3">&nbsp;</div>
                            <div className="col-md-7"><input type="submit" className="btn btn-primary col-lg-10" value="Save" /></div>
                            <div className="col-md-2">&nbsp;</div>
                        </div>


                    </div>

                </div>
            </form>
        </div>
    );
};

export default EditApp;