import React from 'react';

const AppsDropDownComponent = ({ handleChange, apps, applist }) => {
    // console.log(apps, 'from appsdropdowncomponent2');
    let currentApp = '';
    if (apps && apps !== 'null' && apps !== null && typeof apps === 'number') {
        // console.log(apps+'from appsdropdowncomponent');
        const currenApp = applist.filter((app) => app.id === apps);
        if (currenApp && currenApp.length > 0) {
            currentApp = currenApp[0].name;
        }
        //console.log(currentApp);
    }
    return (
        <>
            <label htmlFor="html5-text-input" className="mx-2 col-form-label" style={{ color: 'black' }}>Apps </label>
            <div className="col-md-3">
                <select className="form-select" id="app_id" name="app_id" onChange={handleChange} value={apps || currentApp} >
                    <option key='' value="">--Select Apps--</option>
                    {applist && applist.length > 0 && applist.map((appObj) => (
                        <option key={appObj.id} value={appObj.id}>{appObj.name}</option>
                    ))}
                </select>
            </div>
        </>
    );
};

export default AppsDropDownComponent;
