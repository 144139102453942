
import './App.css';
import './assets/vendor/css/core.css';
import './assets/vendor/css/theme-default.css';
import './assets/css/demo.css';
import './assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css';
import './assets/vendor/fonts/boxicons.css';
import './assets/vendor/css/core.css';


import Layout from './components/Layout/Layout';
import Dashboard from './components/Dashboard/Dashboard';

//import Header from './components/Header/Header';
// import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login/Login';
import GetStarted from './components/GetStarted/GetStarted';
import AppManagementCenter from './components/Apps/Apps';
import AddApp from './components/Apps/AddApp';
import EditApp from './components/Apps/EditApp';

import Communication from './components/Communication/Communication';
import Customization from './components/Customization/Customization';
import Marketing from './components/Marketing/Marketing';
import Analytics from './components/Analytics/Analytics';
import Features from './components/Features/Features';
import Staffs from './components/Staffs/Staffs';
import AddStaff from './components/Staffs/AddStaff';
import EditStaff from './components/Staffs/EditStaff';
import Department from './components/Departments/Department';
import Clients from './components/Clients/Clients';
import AddClient from './components/Clients/AddClient';
import EditClient from './components/Clients/EditClient';
import StandardPushMessages from './components/Communication/StandardPushMessages';
import GeoPushMessages from './components/Communication/GeoPushMessages';
import ScheduledPushMessages from './components/Communication/ScheduledPushMessages';
import ExpiredPushMessages from './components/Communication/ExpiredPushMessages';
import TargetedPushMessages from './components/Communication/TargetedPushMessages';
import AddPushMessage from './components/Communication/AddPushMessage';
import AddGeoPushMessage from './components/Communication/AddGeoPushMessage';
import AddTargetedPushMessage from './components/Communication/AddTargetedPushMessage';
import EditPushMessage from './components/Communication/EditPushMessage';
import EditGeoPushMessage from './components/Communication/EditGeoPushMessage';
import EditTargetedPushMessage from './components/Communication/EditTargetedPushMessage';
import AutomatedPushMessages from './components/Communication/AutomatedPushMessages';
import AddAutomatedPushMessage from './components/Communication/AddAutomatedPushMessage';
import EditAutomatedPushMessage from './components/Communication/EditAutomatedPushMessage';
import PushMessages from './components/Communication/PushMessages';

import CustomerProfiles from './components/CustomerProfiles/CustomerProfiles';
import EditCustomer from './components/CustomerProfiles/EditCustomer';
import Rewards from './components/Rewards/Rewards';
import AddRewards from './components/Rewards/AddRewards';
import EditRewards from './components/Rewards/EditReward';

// import React from "react";
import PrivateRoute from './components/Utils/PrivateRoute';
import NotFound from './components/Errors/NotFound';
import ServerError from './components/Errors/ServerError';
import Help from './components/Help/help';




const App = () => {


  return (
    <div className="App">
      <Layout>
        <Dashboard>
          <Routes>
            <Route path="/" element={<GetStarted />} />
            <Route path="/login" element={<Login />} />
            <Route path="/start" element={<GetStarted />} />

            <Route path="/features" element={<PrivateRoute> <Features /> </PrivateRoute>} />
            <Route path="/app_management_center" element={<PrivateRoute> <AppManagementCenter /> </PrivateRoute>} />
            <Route path="/addapp" element={<PrivateRoute> <AddApp /> </PrivateRoute>} />
            <Route path="/app/edit/:app_id" element={<PrivateRoute> <EditApp /> </PrivateRoute>} />
            <Route path="/customer_profiles" element={<CustomerProfiles />} />
            <Route path="/customer/edit/:customer_id" element={<EditCustomer />} />

            <Route path="/app_management_center" element={<PrivateRoute> <AppManagementCenter /> </PrivateRoute>} />
            <Route path="/addapp" element={<PrivateRoute> <AddApp /> </PrivateRoute>} />
            <Route path="/communication" element={<PrivateRoute> <Communication /> </PrivateRoute>} />
            <Route path="/departments" element={<PrivateRoute> <Department /> </PrivateRoute>} />
            <Route path="/customization" element={<PrivateRoute> <Customization /> </PrivateRoute>} />
            <Route path="/analytics" element={<PrivateRoute> <Analytics /> </PrivateRoute>} />
            <Route path="/marketing" element={<PrivateRoute> <Marketing /> </PrivateRoute>} />
            <Route path="/staffs" element={<PrivateRoute> <Staffs /> </PrivateRoute>} />
            <Route path="/addstaff" element={<PrivateRoute> <AddStaff /> </PrivateRoute>} />
            <Route path="/staff/edit/:staff_id" element={<EditStaff />} />
            <Route path="/clients" element={<PrivateRoute> <Clients /> </PrivateRoute>} />
            <Route path="/addclient" element={<PrivateRoute> <AddClient /> </PrivateRoute>} />
            <Route path="/client/edit/:client_id" element={<PrivateRoute> <EditClient /> </PrivateRoute>} />
            <Route path="/communication/globalpushmessages" element={<PrivateRoute> <StandardPushMessages /> </PrivateRoute>} />
            <Route path="/communication/geofencepushmessages" element={<PrivateRoute> <GeoPushMessages /> </PrivateRoute>} />
            <Route path="/communication/scheduledpushmessages" element={<PrivateRoute> <ScheduledPushMessages /> </PrivateRoute>} />
            <Route path="/communication/expiredpushmessages" element={<PrivateRoute> <ExpiredPushMessages /> </PrivateRoute>} />
            <Route path="/communication/targetedpushmessages" element={<PrivateRoute> <TargetedPushMessages /> </PrivateRoute>} />
            <Route path="/communication/addpushmessage" element={<PrivateRoute> <AddPushMessage /> </PrivateRoute>} />
            <Route path="/communication/addgeopushmessage" element={<PrivateRoute> <AddGeoPushMessage /> </PrivateRoute>} />
            <Route path="/communication/addtargetedpushmessage" element={<PrivateRoute> <AddTargetedPushMessage /> </PrivateRoute>} />
            <Route path="/communication/editpushmessage/:push_id" element={<PrivateRoute> <EditPushMessage /> </PrivateRoute>} />
            <Route path="/communication/editgeopushmessage/:push_id" element={<PrivateRoute> <EditGeoPushMessage /> </PrivateRoute>} />
            <Route path="/communication/edittargetedpushmessage/:push_id" element={<PrivateRoute> <EditTargetedPushMessage /> </PrivateRoute>} />
            <Route path="/communication/pushmessages" element={<PrivateRoute> <PushMessages /> </PrivateRoute>} />
            <Route path="/communication/automatedpushmessages" element={<PrivateRoute> <AutomatedPushMessages /> </PrivateRoute>} />
            <Route path="/communication/addautomatedpushmessage" element={<PrivateRoute> <AddAutomatedPushMessage /> </PrivateRoute>} />
            <Route path="/communication/editautomatedpushmessage/:push_id" element={<PrivateRoute> <EditAutomatedPushMessage /> </PrivateRoute>} />
            <Route path="/rewards" element={<PrivateRoute> <Rewards /> </PrivateRoute>} />
            <Route path="/rewards/add" element={<PrivateRoute> <AddRewards /> </PrivateRoute>} />
            <Route path="/rewards/edit/:reward_id" element={<PrivateRoute> <EditRewards /> </PrivateRoute>} />
            <Route path="/help" element={<PrivateRoute> <Help /> </PrivateRoute>} />
            <Route path="/notfound" element={<NotFound />} />
            <Route path="/error" element={<ServerError />} />
            <Route path='/*' element={<Navigate to='/notfound' />} />
          </Routes>

        </Dashboard>
      </Layout>
    </div>




  );
}

export default App;
