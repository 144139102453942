import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SessionExpiredModal from '../SessionExpire/SessionExpiredModal';
import { errorHandler } from "../Utils/errorHandler";
import { locationData } from "../../apis/LocationAPI";
import PaginationComponent from "../Pagination/Pagination";
import DateTimeField from "../DateTimeField/DateTimeField";
import AppsDropDownComponent from "../PageTopComponents/AppDropDown";
import LocationsDropDownComponent from "../PageTopComponents/LocationDropDown";
import Loading from "../Utils/Loading";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { automatedMessages, automatedMessagesCount, deleteAutomatedPushMessage } from "../../apis/CommunicationAPI";
import ReadMore from "../Utils/ReadMore";


const PushMessages = () => {
    const userData = JSON.parse(localStorage.getItem('user'));
    let tailLink=window.location.search;
    const isAutomatedPN =0;
 

    
    if(tailLink.indexOf('?')>-1){
        tailLink =tailLink+'&automated='+isAutomatedPN;
    }

    // let aplist = userData.apps;
    let aplist = [];
    if (userData.apps) {
        aplist = userData.apps;
    }
    console.log(aplist, "aplist");
    let endUserRole = userData?.role;
    const [numberOfPage, setNumberOfPage] = useState(0);
    const [numberOfRows, setNumberOfRows] = useState(0);
    const [del, setDel] = useState(false);
    const [location, setLocation] = useState([]);
    const [msgPerPage] = useState(30);
    const [isLoading, setIsLoading] = useState(true);
    const [sessionModal, setSessionModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [firstLoad, setFirstLoad] = useState(1);
    const [qryString, setQryString] = useState("");

    const search = useLocation().search;
    const queryParams = new URLSearchParams(search);
    const [timeoutid, setTimeoutid] = useState(null);
    const [userRole] = useState(endUserRole);

    const ap_id = queryParams.get('app_id');
    let app_id = null;
    if (ap_id) {
        app_id = parseInt(ap_id);
    } else {
        const clientUser = JSON.parse(localStorage.getItem('user'));
        if (clientUser?.apps?.length === 1) {
            app_id = parseInt(clientUser.apps[0]?.id);
        }
    }

    const locat_id = queryParams.get('loc_id');
    let loc_id = null;
    if (locat_id) {
        loc_id = parseInt(locat_id);
    }
    let searchstr = queryParams.get('searchkey');

    if (!searchstr) {
        searchstr = "";
    }

    const pag = queryParams.get('page');
    let pg = 1;

    if (pag) {
        pg = parseInt(pag);
    }

    const starDate = queryParams.get('start');
    console.log(starDate)
    let stDate = null;
    if (starDate) {
        stDate = moment(starDate);
    }

    const enDate = queryParams.get('end');
    let eDate = null;
    if (enDate) {
        eDate = moment(enDate);
    }

    const [apps, setApps] = useState(app_id);
    const [loc, setLoc] = useState(loc_id);
    const [searchkey, setSearchkey] = useState(searchstr);
    const [currentPage, setCurrentPage] = useState(pg);
    const [startDate, setStartDate] = useState(stDate);
    const [endDate, setEndDate] = useState(eDate);
    const [atMsg, setatMsg] = useState([]);
    const [applist, setApplist] = useState(aplist);
    const [messageTypes, setMessageTypes] = useState(
        [
            { id: 1, name: "Birthday" },
            { id: 2, name: "Lease Renewal" },
            { id: 3, name: "Purchase Anniversary" },
            { id: 4, name: "Scheduled Service Reminder" },
            { id: 5, name: "Service Thank You" },
            { id: 6, name: "Set Up Profile" },
            { id: 7, name: "mDocs Reminder" },
            { id: 8, name: "Empty Garage" },
            { id: 9, name: "Price Change" },
            { id: 10, name: "Maintenance Schedule Reminder" },
        ]
    );
    const [message_type, setMessageType] = useState('');

    console.log("aplist ", applist);

    // watching search box
    function intervalexecute() {
        if (timeoutid) {
            clearTimeout(timeoutid);
        }
        const timeout = setTimeout(() => {
            //only first time current page will be what ever page user address pressed     
            let paramUpdate = true;
            setIsLoading(true);
            automatedMessages(apps, loc, currentPage, message_type, searchkey, startDate, endDate, applist, msgPerPage, userRole,isAutomatedPN).then((response) => {
                console.log("commonn load data");
                if (response.status === 200) {
                    setatMsg(response.data);
                    setIsLoading(false);
                } else {
                    setatMsg([]);
                    setIsLoading(false);
                    errorHandler("Communication fetch " + response.status, setSessionModal, setShowToast, toast);
                }
            }).catch(err => {
                setIsLoading(false);
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
            // generate url in addressbar whenever any change take place
            if (paramUpdate) {
                updateQueryParams();
            }
            automatedMessagesCount(apps, loc, message_type, searchkey, startDate, endDate, applist, userRole,isAutomatedPN).then((responsex) => {
                if (responsex.status === 200) {
                    const rowNumberCount = responsex.data[0].count;
                    const pgCount = Math.ceil(rowNumberCount / msgPerPage);
                    setNumberOfPage(pgCount);
                    setNumberOfRows(rowNumberCount);
                } else {
                    setNumberOfPage(0);
                    setNumberOfRows(0);
                    errorHandler("staff count " + responsex.status, setSessionModal, setShowToast, toast);

                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        }, 2000);

        setTimeoutid(timeout);
    }

    useEffect(() => {
        if (firstLoad !== 1) { // first load not use search
            intervalexecute();
        } else {

            console.log("use effect searchkey");
        }

    }, [searchkey]);


    // load all locations by app
    useEffect(() => {
        if (apps) {
            // passing apps id to get location list
            locationData(apps).then((response) => {
                if (response.status === 200) {
                    setLocation(response.data);
                } else {
                    setLocation([]);
                    errorHandler("location " + response.status, setSessionModal, setShowToast, toast);

                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        } else {
            setLocation([]);
        }
    }, [apps]);

    // getting staff data count
    useEffect(() => {
        automatedMessagesCount(apps, loc, message_type, searchkey, startDate, endDate, applist, userRole,isAutomatedPN).then((responsex) => {
            if (responsex.status === 200) {
                const rowNumberCount = responsex.data[0].count;
                const pgCount = Math.ceil(rowNumberCount / msgPerPage);
                setNumberOfPage(pgCount);
                // handle error  
                // if(currentPage>pgCount){
                //     setCurrentPage(1);
                // }
                setNumberOfRows(rowNumberCount);
            } else {
                setNumberOfPage(0);
                setNumberOfRows(0);
                errorHandler("staff count " + responsex.status, setSessionModal, setShowToast, toast);
            }
        }).catch(err => {
            errorHandler(err, setSessionModal, setShowToast, toast);
        });

    }, [apps, loc, message_type, startDate, endDate, applist, msgPerPage]);

    // getting staff data
    useEffect(() => {
        //only first time current page will be what ever page user address pressed     
        if (firstLoad === 1) {
            // not first time load each search set current page to 1
            setFirstLoad(0);
            // as it is firstload no need to do param update
            console.log("first time load");
        }
        setIsLoading(true);

        automatedMessages(apps, loc, currentPage, message_type, searchkey, startDate, endDate, applist, msgPerPage, userRole,isAutomatedPN).then((response) => {
            console.log("commonn load data");
            if (response.status === 200) {
                setatMsg(response.data);
                setIsLoading(false);
            } else {
                setatMsg([]);
                setIsLoading(false);
                errorHandler("staff fetch " + response.status, setSessionModal, setShowToast, toast);
            }
        }).catch(err => {
            setIsLoading(false);
            errorHandler(err, setSessionModal, setShowToast, toast);
        });
        // generate url in addressbar whenever any change take place
        updateQueryParams();

    }, [apps, loc, currentPage, message_type, startDate, endDate, applist, msgPerPage]);


    // Update the URL query params when the state of the hook changes
    const updateQueryParams = () => {
        console.log("addressbar update ..");
        const queryParams = new URLSearchParams();
        if (apps) queryParams.set('app_id', apps);
        if (loc) queryParams.set('loc_id', loc);
        if (searchkey) queryParams.set('searchkey', searchkey);
        if (startDate) queryParams.set('start', startDate.toISOString());
        if (endDate) queryParams.set('end', endDate.toISOString());
        if (currentPage) queryParams.set('page', currentPage);
        const queryString = queryParams.toString();
        const newUrl = `${window.location.pathname}?${queryString}`;
        window.history.pushState({ path: newUrl }, '', newUrl);
        // query string will be used for edit purpose
        setQryString(`?${queryString}`);
    };


    // handle inputs and change events
    const handleChange = async (event) => {
        console.log("handle fired");
        if (currentPage !== 1) {
            setCurrentPage(1);
        }
        let search = window.location.search;
        let params = new URLSearchParams(search);
        console.log(params);
        const name = event.target.name;
        let value;

        if (event.target.type === 'select-one') {
            let selectedIndex = event.target.selectedIndex;
            let tvalue = document.getElementById(name).value;
            // incase no value selected 
            if (selectedIndex === 0) {
                if (name === 'location_id') {
                    setLoc(null);
                } else if (name === 'app_id') {
                    setApps(null);
                    setLoc(null);
                    setLocation([]);
                } else if (name === 'message_type') {
                    setMessageType(0);
                }
            } else {
                // converting to number 
                if (name === 'location_id') {
                    value = +tvalue;
                    setLoc(value);
                } else if (name === 'app_id') {
                    value = +tvalue;
                    setApps(value);
                    locationData(value);
                    setLoc(null);
                } else if (name === 'message_type') {
                    value = tvalue;
                    setMessageType(value);
                }
            }
        } else {
            if (name === 'search_inputs') {
                setSearchkey(event.target.value);
            }
        }
    };


    // deleting a staff
    const deleteClicked = (e, id) => {
        e.preventDefault();
        if (window.confirm("Delete the PN?")) {
            deleteAutomatedPushMessage(id).then((res) => {
                setDel(false);
                //    window.alert(res.status+" "+res.data.affectedRows );
                if (res.status === 201 && res.data.affectedRows > 0) {
                    setShowToast(true);
                    toast.success("Delete Successful", {
                        position: toast.POSITION.TOP_CENTER, hideProgressBar: false, autoClose: 1000,
                        onClose: () => {
                            window.location.reload();
                        }
                    });
                } else {
                    toast.error("Data not found!", { position: toast.POSITION.TOP_CENTER, hideProgressBar: false, autoClose: 1000 });
                }
            }).catch((err) => {
                setDel(false);
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        } else {
            console.log("You canceled!");
        }
    };



    // logs out a user
    const handleLogout = async () => {
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location = "/";
    };

    // handle toast close
    const handleCloseToast = () => {
        setShowToast(false);
    };


    return (
        <div>
            <a href={'/communication'} type="button" className="btn btn-sm rounded-pill btn-secondary">Back</a><a href="/communication" className="btn btn-sm rounded-pill btn-secondary">Communication Home</a><a href={'/communication/addautomatedpushmessage' +tailLink} type="button" className="btn btn-sm rounded-pill btn-secondary">Add</a>
            {del &&
                <div>Deleting Item...</div>
            }
            {showToast && <div className="toast-overlay"></div>}
            <ToastContainer onClose={handleCloseToast} />
            <div className="card">
                <h5 className="card-header"> Push Messages</h5>
                <div className="mb-3 row">
                    <div className="col-md-6">
                        <div style={{ paddingLeft: "10px" }}>
                            <a href={'/communication/addautomatedpushmessage' + tailLink} type="button" className="btn btn-primary col-md-3">Add New</a>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <input className="form-control" type="search" id="search_input" name="search_inputs" value={searchkey || ''} onChange={handleChange} placeholder="search ..." />
                    </div>
                </div>
                <div>
                    <SessionExpiredModal
                        show={sessionModal}
                        handleLogout={handleLogout}
                    />
                </div>
                <div>
                    <div style={{ display: "flex", marginBottom: '1rem', marginLeft: '.1rem', paddingLeft: '10px' }} >
                        <DateTimeField
                            startDate={startDate}
                            endDate={endDate}
                            setStartDate={setStartDate}
                            setEndDate={setEndDate}
                        />
                    </div>
                    <div style={{ display: "flex", marginBottom: '1rem', marginLeft: '.1rem', paddingLeft: '10px' }} >
                        <button onClick={() => setStartDate(null)} className="btn btn-sm rounded-pill btn-secondary" style={{ marginTop: '5px', marginLeft: '20px' }}>
                            <i className='bx bx-reset'></i> Start Date
                        </button>
                        <button onClick={() => setEndDate(null)} className="btn btn-sm rounded-pill btn-secondary" style={{ marginTop: '5px', marginLeft: '20px' }}>
                            <i className='bx bx-reset'></i> End Date
                        </button>
                    </div>
                </div>
                <div className="mb-4 row" style={{ paddingLeft: "20px" }}>
                    <div className="d-flex flex-wrap">
                        <AppsDropDownComponent
                            apps={apps}
                            handleChange={handleChange}
                            applist={applist}
                            className="me-1" // Add me-1 class to add margin between components
                        />
                        <LocationsDropDownComponent
                            loc={loc}
                            handleChange={handleChange}
                            location={location}
                            className="me-1" // Add me-1 class to add margin between components
                        />
                        <>
                            <label htmlFor="html5-text-input" className="mx-2 col-form-label" style={{ color: 'black' }}>Message Type </label>
                            <div className="col-md-3">
                                <select className="form-select" id="message_type" name="message_type" onChange={handleChange} value={message_type || ''} >
                                    <option key='' value="">--Select Message Type--</option>
                                    {messageTypes && messageTypes.length > 0 && messageTypes.map((msgObj) => (
                                        <option key={msgObj.id} value={msgObj.name}>{msgObj.name}</option>
                                    ))}
                                </select>
                            </div>
                        </>
                    </div>
                </div>
            </div>
            <div className="alert alert-primary" role='alert'><strong>{numberOfRows}  push messages found</strong></div>
            {isLoading ?
                <div>
                    <Loading />
                </div> : <div>
                    <div className="table-responsive text-nowrap">
                        <table className="table table-sm table-bordered">
                            <thead>
                                <tr>
                                    <th>App</th>
                                    <th>Location</th>
                                    <th>Subject</th>
                                    <th>Body</th>
                                    <th>Message Type</th>
                                    <th>Created</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody className="table-border-bottom-0">
                                {atMsg && atMsg.length > 0 && atMsg.map((apmObj) => (
                                    <tr key={apmObj.id}>
                                        <td>
                                            <i className="fab fa-angular fa-lg text-danger me-3"></i> <a href={`/communication/editautomatedpushmessage/${apmObj.id + tailLink}`}> <strong>{apmObj.app_name ? apmObj.app_name : ''}</strong></a>
                                        </td>
                                        <td><a href={`/communication/editautomatedpushmessage/${apmObj.id + window.location.search}`}> {apmObj.location_name ? apmObj.location_name : ''} </a></td>
                                        <td><ReadMore>{apmObj.subject ? apmObj.subject : ''}</ReadMore></td>
                                        <td><ReadMore>{apmObj.body ? apmObj.body : ''}</ReadMore></td>
                                        <td>{apmObj.message_type ? apmObj.message_type : ''}</td>
                                        <td>{apmObj.created_at ? moment(apmObj.created_at).format('YYYY-MM-DD') : ''}</td>
                                        <td>
                                            <div className="btn-group">
                                                <a href={`/communication/editautomatedpushmessage/${apmObj.id + tailLink}`}>
                                                    <button className="btn btn-sm btn-outline-secondary"><i className='bx bxs-edit'></i></button>
                                                </a>
                                                <button className="btn btn-sm btn-outline-secondary" onClick={(e) => deleteClicked(e, apmObj.id)}><i className='bx bxs-trash'></i></button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="pagination-container" style={{ display: "flex", flexWrap: "wrap" }}>
                        <PaginationComponent
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            numberOfPage={numberOfPage}
                            setShowToast={setShowToast}
                            numVisiblePages={50}
                        />
                    </div>
                </div>}
        </div>
    );
};

export default PushMessages;