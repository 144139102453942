import React, { useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from "react";
import { toast } from "react-toastify";
import { useParams } from 'react-router-dom';
import { errorHandler } from "../Utils/errorHandler";
import { saveCustomerLicense,updateCustomerLicense } from "../../apis/LicenseAPI";
import moment from "moment";







const AddLicenseModal = ({ show, setShow, customerInfo,customerId,licenseInfo,supdate }) => {
    // customer id from url
    console.log("I am here");

    const customer_id  = useParams();

    const [sessionModal, setSessionModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [licenseSaving, setLicenseSaving] = useState(false);

    

    const [license, setLicense]= useState(licenseInfo);



    console.log(license);




    // showing update button
    const [showUpdate, setshowUpdate] = useState(supdate);

    
    // close modal
    const handleClose = () =>{ 
   
    setShow(false);
   }





    const handleChangeLicense = (event) => {
        const name = event.target.name;
        let value;
        let filen;
        if (event.target.type === 'select') {

            value = document.getElementById(name).value;
            setLicense(values => ({ ...values, [name]: value }));
            // if (name === "app_id") {
            //     setApps(value);
            // }
        } else if (event.target.type === 'select-one') {

            value = document.getElementById(name).value;
            setLicense(values => ({ ...values, [name]: value }));

        
        } // when selected location
       
       

        else {
            value = event.target.value;
            console.log(value);
            
            setLicense(values => ({ ...values, [name]: value }));
        }

    }


    // handle submit
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!license.license_number) {
            toast.error(`License Number is rquired!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: false,
            });
            return;
        }
        else if (!license.expiry_date) {
            toast.error(`Expiry Date is rquired!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: false,
            });
            return;
        } else if (!license.issue_date) {
            toast.error(`Issue Date is rquired!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: false,
            });
            return;
        } else if (!license.birth_date) {
            toast.error(`Birth Date is rquired!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: false,
            });
            return;
        } else if (license.gender=="" ||license.gender==undefined ) {
            toast.error(`Gender required !`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: false,
            });
            return;
        }
        else if (!(license.country)) {
            toast.error(`Country required !`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: false,
            });
            return;
        }
        else if (!(license.city)) {
            toast.error(`city required !`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: false,
            });
            return;
        }
        else if (!(license.street)) {
            toast.error(`Street required !`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: false,
            });
            return;
        }
        else if (!(license.zip)) {
            toast.error(`zip required !`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: false,
            });
            return;
        }
        else if (!(license.document_type)) {
            toast.error(`document type required !`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: false,
            });
            return;
        }



        setLicenseSaving(true);
        saveCustomerLicense(license).then((response) => {
            if (response.status === 201) {
                toast.success(`License added successfully!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    onClose: () => {
                        setShow(false);
                        window.location.reload();
                    }
                });
            } else {
             
                toast.error("error "+response.data?.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: false, autoClose:3000});

                setShowToast(false);
           }
        }).catch(err => {
            console.log(err);
            errorHandler(err, setSessionModal, setShowToast, toast);

        });
        setLicenseSaving(false);
    }

   // each lieneseinfo change incase different license 
    useEffect(() => {
        if (licenseInfo && !isNaN(licenseInfo.id)) {
            // get selected reward data by reward id
            // console.log(selectedReward, 'selected reward');
              
            
            console.log(licenseInfo.expiry_date);
             let localBirthDate =moment(licenseInfo.birth_date).format('YYYY-MM-DD');
             let localIssueDate =moment(licenseInfo.issue_date).format('YYYY-MM-DD');
             let localExpiryDate =moment(licenseInfo.expiry_date).format('YYYY-MM-DD');

             
             
             setLicense(inputs => ({ ...inputs, id: licenseInfo.id }));

               setLicense(inputs => ({ ...inputs, license_number: licenseInfo.license_number }));
                setLicense(inputs => ({ ...inputs, first_name: licenseInfo.first_name }));
                setLicense(inputs => ({ ...inputs, last_name: licenseInfo.last_name }));
                setLicense(inputs => ({ ...inputs, middle_name:licenseInfo.middle_name }));
                setLicense(inputs => ({ ...inputs, gender:licenseInfo.gender }));
                setLicense(inputs => ({ ...inputs, birth_date:localBirthDate  }));

                setLicense(inputs => ({ ...inputs, issue_date:localIssueDate  }));

                setLicense(inputs => ({ ...inputs, expiry_date:localExpiryDate  }));

               setLicense(inputs => ({ ...inputs, app_id: licenseInfo.app_id }));


                setLicense(inputs => ({ ...inputs, document_type: licenseInfo.document_type }));
                setLicense(inputs => ({ ...inputs, customerId: customerId }));
                setLicense(inputs => ({ ...inputs, street: licenseInfo.street }));
                setLicense(inputs => ({ ...inputs, zip: licenseInfo.zip }));
                setLicense(inputs => ({ ...inputs, city: licenseInfo.city }));
                setLicense(inputs => ({ ...inputs, state: licenseInfo.state }));

                setLicense(inputs => ({ ...inputs, country: licenseInfo.country }));



        }
       
    }, [licenseInfo]);


    useEffect(() => {
        if (licenseInfo.id===undefined) {
 
            setLicense(inputs => ({ ...inputs, license_number: "" }));
            setLicense(inputs => ({ ...inputs, first_name: "" }));
            setLicense(inputs => ({ ...inputs, last_name: "" }));
            setLicense(inputs => ({ ...inputs, middle_name:"" }));
            setLicense(inputs => ({ ...inputs, expires:""  }));
            setLicense(inputs => ({ ...inputs, birth_date:"" }));
            setLicense(inputs => ({ ...inputs, issue_date:"" }));
            setLicense(inputs => ({ ...inputs, gender:"" }));


            setLicense(inputs => ({ ...inputs, document_type: "" }));
            setLicense(inputs => ({ ...inputs, customerId: customerId }));
            setLicense(inputs => ({ ...inputs, street: "" }));
            setLicense(inputs => ({ ...inputs, zip: ""}));
            setLicense(inputs => ({ ...inputs, city: ""}));
            setLicense(inputs => ({ ...inputs, state: "" }));
            setLicense(inputs => ({ ...inputs, app_id: customerInfo[0].app_id }));

            setLicense(inputs => ({ ...inputs, country: "" }));

        }
    }, [supdate]);




 // handle submit
 const handleUpdate = (e) => {
    console.log(license);
     e.preventDefault();
     
     
     if (!license.license_number) {
         toast.error(`License Number is rquired!`, {
             position: toast.POSITION.TOP_CENTER,
             autoClose: 2000,
             hideProgressBar: false,
         });
         return;
     }
     else if (license.gender=="" ||license.gender==undefined ) {
        toast.error(`Gender required !`, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
            hideProgressBar: false,
        });
        return;
    }
     
     else if (!license.expiry_date) {
         toast.error(`Expiry Date is rquired!`, {
             position: toast.POSITION.TOP_CENTER,
             autoClose: 2000,
             hideProgressBar: false,
         });
         return;
     } else if (!license.issue_date) {
         toast.error(`Issue Date is rquired!`, {
             position: toast.POSITION.TOP_CENTER,
             autoClose: 2000,
             hideProgressBar: false,
         });
         return;
     } else if (!license.birth_date) {
         toast.error(`Birth Date is rquired!`, {
             position: toast.POSITION.TOP_CENTER,
             autoClose: 2000,
             hideProgressBar: false,
         });
         return;
     }  
     else if (!(license.country)) {
         toast.error(`Country required !`, {
             position: toast.POSITION.TOP_CENTER,
             autoClose: 2000,
             hideProgressBar: false,
         });
         return;
     }
     else if (!(license.city)) {
         toast.error(`city required !`, {
             position: toast.POSITION.TOP_CENTER,
             autoClose: 2000,
             hideProgressBar: false,
         });
         return;
     }
     else if (!(license.street)) {
         toast.error(`Street required !`, {
             position: toast.POSITION.TOP_CENTER,
             autoClose: 2000,
             hideProgressBar: false,
         });
         return;
     }
     else if (!(license.zip)) {
         toast.error(`zip required !`, {
             position: toast.POSITION.TOP_CENTER,
             autoClose: 2000,
             hideProgressBar: false,
         });
         return;
     }
     else if (!(license.document_type)) {
         toast.error(`document type required !`, {
             position: toast.POSITION.TOP_CENTER,
             autoClose: 2000,
             hideProgressBar: false,
         });
         return;
     }



     setLicenseSaving(true);
     updateCustomerLicense(license).then((response) => {
         if (response.status === 201) {
             toast.success(`License updated successfully!`, {
                 position: toast.POSITION.TOP_CENTER,
                 autoClose: 2000,
                 hideProgressBar: false,
                 onClose: () => {
                     setShow(false);
                     window.location.reload();
                 }
             });
         } else {
          
             toast.error("error "+response.data?.message, { position: toast.POSITION.TOP_CENTER, hideProgressBar: false, autoClose:3000});

             setShowToast(false);
        }
     }).catch(err => {
         console.log(err);
         errorHandler(err, setSessionModal, setShowToast, toast);

     });
     setLicenseSaving(false);
 }



    // handle close toast
    const handleCloseToast = () => {
        setShowToast(false);
        window.location.reload();
    };


    // handle logout
    const handleLogout = () => {
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location = "/";
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="lg"
            centered
        >
            <Modal.Header closeButton>
            {!supdate &&   <Modal.Title>Add   License</Modal.Title> }
            {supdate &&  <Modal.Title> Update   License</Modal.Title> }

            </Modal.Header>
            <Modal.Body>
                      
                {licenseSaving &&
                    <div>Saving New License ...</div>
                }

                       

                        <form onSubmit={handleSubmit}>
                            
                            <div className="form-group">
                                 <label>Customer ID:  {license.customerId} </label>
                            </div>

                            
                            <div className="form-group">
                                <label >Apps ID:  {license.app_id}</label>
                            </div>


                            <div className="form-group">
                                <label><strong>License Number</strong><sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            
                                {supdate &&
                                 <div><label > {license.license_number}</label></div>

                                    } 

                                {!supdate &&
                                <input type="text" className="form-control" id="license_number" name="license_number" value={license.license_number|| ''} onChange={handleChangeLicense} required />

                                 }

                            
                            </div>
                            <div className="form-group">
                                <label htmlFor="document_type">Document Type</label>
                                <input type="text" className="form-control" id="document_type" name="document_type" value={license.document_type||''} onChange={handleChangeLicense} />
                            </div>

                           
                           
                            <div className="form-group">
                                <label htmlFor="first_name">First Name</label>
                                <input type="text" className="form-control" id="first_name" name="first_name" value={license.first_name||''} onChange={handleChangeLicense} />
                            </div>
                            <div className="form-group">
                                <label htmlFor="middle_name">Middle Name</label>
                                <input type="text" className="form-control" id="middle_name" name="middle_name" value={license.middle_name||''} onChange={handleChangeLicense} />
                            </div>

                            <div className="form-group">
                                <label htmlFor="last_name">Last Name</label>
                                <input type="text" className="form-control" id="last_name" name="last_name" value={license.last_name||''} onChange={handleChangeLicense} />
                            </div>
                            <div className="form-group">
                            <label htmlFor="gender">Gender</label>
                            <select className="form-control" id="gender" name="gender" onChange={handleChangeLicense} value={license.gender}>
                                <option value="">Select Gender</option>
                                    <option value="1">Male</option>
                                    <option  value="2">Female</option>

                              
                            </select>
                        </div>
                            
                            <div className="form-group">
                                <label htmlFor="street">Street</label>
                                <input type="text" className="form-control" id="street" name="street" value={license.street||''} onChange={handleChangeLicense} />
                            </div>

                            <div className="form-group">
                                <label htmlFor="zip">Zip</label>
                                <input type="text" className="form-control" id="zip" name="zip" value={license.zip||''} onChange={handleChangeLicense} />
                            </div>

                            <div className="form-group">
                                <label htmlFor="city">City</label>
                                <input type="text" className="form-control" id="city" name="city" value={license.city||''} onChange={handleChangeLicense} />
                            </div>

                            <div className="form-group">
                                <label htmlFor="state">State</label>
                                <input type="text" className="form-control" id="state" name="state" value={license.state||''} onChange={handleChangeLicense} />
                            </div>

                            <div className="form-group">
                                <label htmlFor="country">Country</label>
                            
                                <select className="form-select" id="country" name="country" onChange={handleChangeLicense} value={license.country} required>
                                    <option value="">Select</option>

                                    <option value="Australia">Australia</option>
                                    <option value="Bangladesh">Bangladesh</option>
                                    <option value="Canada">Canada</option>
                                    <option value="UK">United Kingdom</option>

                                    <option value="USA">United State</option>


                                </select>
                            
                            
                            </div>
                            <div className="form-group">
                                <label htmlFor="birth_date">Birth Date</label>
                                <input type="date" className="form-control" id="birth_date" name="birth_date" value={license.birth_date} onChange={handleChangeLicense} />
                            </div>


                            <div className="form-group">
                                <label htmlFor="issue_date">Issue Date</label>
                                <input type="date" className="form-control" id="issue_date" name="issue_date" value={license.issue_date} onChange={handleChangeLicense} />
                            </div><div className="form-group">
                                <label htmlFor="birth_date">Expiry Date</label>
                                <input type="date" className="form-control" id="expiry_date" name="expiry_date" value={license.expiry_date} onChange={handleChangeLicense} />
                            </div>




                       
                        </form>
                
            </Modal.Body>
            <Modal.Footer>
                {!supdate &&
                <Button variant="primary" type="submit" className="float-right" onClick={handleSubmit}>Add License</Button>

                }
              
                {supdate &&
                   <Button variant="primary" type="submit" className="float-right" onClick={handleUpdate}>Update License</Button>

                }

              
                <Button variant="secondary" onClick={handleClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}


export default AddLicenseModal;
