import React, { useState } from "react";
import { Modal, Button, Toast } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import axiosInstance from "../../services/AxiosInstance";
import { toast } from "react-toastify";
import { errorHandler } from "../Utils/errorHandler";
import SessionExpiredModal from "../SessionExpire/SessionExpiredModal";
import DownloadDemoDataBlank from "./DownloadEmptyTemplate";
import DownloadDemoData from "./DownloadDataTemplate";



// upload staff data using file
const FileUploadModal = (props) => {
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [toastVariant, setToastVariant] = useState('success');
  const [sessionModal, setSessionModal] = useState(false);

  const handleExcelUpload = async (file) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { defval: '' });


      // check if user is authorized to upload staffs for the selected apps
      const distinctApps = [...new Set(jsonData.map(item => item.App))];
      let userApps = [];
      const userData = JSON.parse(localStorage.getItem('user'));
      if (userData.apps.length > 0) {
        userApps = userData.apps.map((app) => app.name);
      }


      if (!userApps || userApps.length < 1) {
        setToastMessage(`You are not authorized to upload staffs!`);
        setShowToast(true);
        setToastVariant('danger');
        return;
      } else if (!distinctApps.every((app) => userApps.includes(app))) {
        setToastMessage(`You are not authorized to upload staffs for ${distinctApps.filter((app) => !userApps.includes(app))}!`);
        setShowToast(true);
        setToastVariant('danger');
        return;
      }

      // upload staffs
      const userId = userData.id;
      jsonData.forEach((item) => {
        item.created_by = userId;
      });
      try {
        const response = await axiosInstance.post('/v2/staff/upload-data', { jsonData });
        console.log(response.status);
        if (response.status === 201) {
          const count = response.data.counts;
          setToastMessage(`${count} Staffs Imported Successfully! Failed rows are: ${response.data.failedRows}`);
          setShowToast(true);
          setToastVariant('success');
        } else {
          setToastMessage(`could not save staffs from file!`);
          setShowToast(true);
          setToastVariant('danger');
        }
      } catch (err) {
        console.log("request error");

        console.log(err);
        if (err.response.data.message === 'Invalid Token') {
          const refreshToken = localStorage.getItem('refreshToken');
          await axiosInstance.post("/v2/auth/logout", { refreshToken }).then((response) => {
            if (response.status === 200) {
              localStorage.removeItem("user");
              localStorage.removeItem("accessToken");
              localStorage.removeItem("refreshToken");
              window.location.reload();
            } else {
              // toast.error(`Something went wrong. Please try again!`, { position: toast.POSITION.TOP_CENTER, autoClose: 2000, hideProgressBar: false });
              errorHandler(response.status, setSessionModal, setShowToast, toast);

              localStorage.removeItem("user");
              localStorage.removeItem("accessToken");
              localStorage.removeItem("refreshToken");
              window.location.reload();
            }
          }).catch(er => {
            //  errorHandler(err, setSessionModal, setShowToast, toast, "/staffs");
            errorHandler(er, setSessionModal, setShowToast, toast);

          });
        } else {
          errorHandler(err, setSessionModal, setShowToast, toast);

          // setToastMessage(`${err.response.data.message}`);
          setShowToast(true);
          setToastVariant('danger');
        }
      };
    };
    reader.readAsBinaryString(file);
  };

  // handling notfication close
  const handleCloseToast = () => {
    setShowToast(false);
    window.location.reload();
  };

  // handling logout
  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    window.location = "/";
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <SessionExpiredModal
        show={sessionModal}
        handleLogout={handleLogout}
      />
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Upload File
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ display: 'flex', paddingBottom: '2rem', justifyContent: 'center' }}>
          <div style={{ display: 'flex', float: 'left', paddingRight: '2rem', width: '50%' }}>
            <DownloadDemoDataBlank />
          </div>
          <div style={{ display: 'flex', float: 'right' }}>
            <DownloadDemoData />
          </div>
        </div>
        <div style={{ display: 'flex', paddingTop: '4rem', justifyContent: 'center' }}>
          <div>
            <input
              type="file"
              onChange={(e) => {
                const file = e.target.files[0];
                handleExcelUpload(file);
              }}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleExcelUpload} style={{ backgroundColor: '#04AA6D' }}>Submit</Button>
      </Modal.Footer>
      {showToast && (
        <Toast onClose={handleCloseToast} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <Toast.Header closeButton={false} style={{ backgroundColor: toastVariant === 'success' ? '#28A745' : '#DC3545', color: '#fff', fontWeight: 'bold' }}>
            <strong className="mr-auto">Notification</strong>
          </Toast.Header>
          <Toast.Body style={{ color: toastVariant === 'success' ? '#28A745' : '#DC3545', fontWeight: 'bold' }}>
            {toastMessage}
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
              <Button style={{ backgroundColor: toastVariant === 'success' ? '#28A745' : '#DC3545', color: '#fff', fontWeight: 'bold' }} onClick={handleCloseToast}>Close</Button>
            </div>
          </Toast.Body>
        </Toast>
      )}
    </Modal>
  );
}

export default FileUploadModal;
