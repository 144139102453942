import React from "react";
import QRCode from "react-qr-code";



const QRCodeGenerator = ({ value, background, foreground, level, size }) => {
    return (
        <QRCode value={value} bgColor={background} fgColor={foreground} level={level} size={size} />
    );
};

export default QRCodeGenerator;