import React from "react";
import Login from "../Login/Login";

const GetStarted = () => {
    let firstName;
    let user;
    let p = localStorage.getItem('user');
    if (p) {
        user = JSON.parse(p);
        if (user) {
            firstName = user.first_name;
        }
    }

    return (
        <div className="row">
            <div className="col-md-12 col-xl-12">
                <div className="card shadow-none bg-transparent">
                    <div className="card-body">
                        {!user ?
                            <div>
                                <h2 className="card-title" style={{ marginBottom: '-1px' }}>Welcome to the</h2>
                                <h1 className="card-title" style={{ marginTop: '5px' }}>App Management Center</h1>
                                <br></br>
                                <Login />
                            </div>
                            :
                            <div>
                                <h1 className="card-title">{firstName}'s App Dashboard</h1>
                                <div className='row mb-1' style={{ marginRight: '15px', marginTop: '50px' }}>
                                    <a href='/analytics' className='col-md-6 col-lg-4'>
                                        <div className='card mb-3'>
                                            <div className='card-body' style={{ textAlign: 'center' }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="60px" height="60px" viewBox="0 0 24 24">
                                                    <path d="M6 21H3a1 1 0 0 1-1-1v-8a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1zm7 0h-3a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v17a1 1 0 0 1-1 1zm7 0h-3a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1h3a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1z" fill="currentColor"></path></svg>
                                                <h5 style={{ marginTop: '15px', color: '#000000' }}><strong>Analytics</strong></h5>
                                                <p style={{ color: '#000000ff' }}>Check how your app is performing with your customers</p>
                                            </div>
                                        </div>
                                    </a>
                                    <a href='/communication' className='col-md-6 col-lg-4'>
                                        <div className='card mb-3'>
                                            <div className='card-body' style={{ textAlign: 'center' }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="60px" height="60px" viewBox="0 0 24 24"><circle cx="9.5" cy="9.5" r="1.5" fill="currentColor" /><circle cx="14.5" cy="9.5" r="1.5" fill="currentColor" /><path fill="currentColor" d="M12 2C6.486 2 2 5.589 2 10c0 2.908 1.897 5.515 5 6.934V22l5.34-4.004C17.697 17.852 22 14.32 22 10c0-4.411-4.486-8-10-8m0 14h-.333L9 18v-2.417l-.641-.247C5.671 14.301 4 12.256 4 10c0-3.309 3.589-6 8-6s8 2.691 8 6s-3.589 6-8 6" /></svg>
                                                <h5 style={{ marginTop: '15px', color: '#000000' }}><strong>Communications</strong></h5>
                                                <p style={{ color: '#000000ff' }}>Communicate with your customers using Push Notifications</p>
                                            </div>
                                        </div>
                                    </a>
                                    <a href='/rewards' className='col-md-6 col-lg-4'>
                                        <div className='card mb-3'>
                                            <div className='card-body' style={{ textAlign: 'center' }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24"><path fill="currentColor" d="M20 7h-1.209A4.92 4.92 0 0 0 19 5.5C19 3.57 17.43 2 15.5 2c-1.622 0-2.705 1.482-3.404 3.085C11.407 3.57 10.269 2 8.5 2C6.57 2 5 3.57 5 5.5c0 .596.079 1.089.209 1.5H4c-1.103 0-2 .897-2 2v2c0 1.103.897 2 2 2v7c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-7c1.103 0 2-.897 2-2V9c0-1.103-.897-2-2-2m-4.5-3c.827 0 1.5.673 1.5 1.5C17 7 16.374 7 16 7h-2.478c.511-1.576 1.253-3 1.978-3M7 5.5C7 4.673 7.673 4 8.5 4c.888 0 1.714 1.525 2.198 3H8c-.374 0-1 0-1-1.5M4 9h7v2H4zm2 11v-7h5v7zm12 0h-5v-7h5zm-5-9V9.085L13.017 9H20l.001 2z" /></svg>
                                                <h5 style={{ marginTop: '15px', color: '#000000' }}><strong>Loyalty Rewards</strong></h5>
                                                <p style={{ color: '#000000ff' }}>Manage your customized in app loyalty rewards program</p>
                                            </div>
                                        </div>
                                    </a>
                                    <a href='/customer_profiles' className='col-md-6 col-lg-4'>
                                        <div className='card mb-3'>
                                            <div className='card-body' style={{ textAlign: 'center' }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="60px" height="60px" viewBox="0 0 24 24"><path fill="currentColor" d="M5 22h14c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2h-2a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1H5c-1.103 0-2 .897-2 2v15c0 1.103.897 2 2 2M5 5h2v2h10V5h2v15H5z" /><path fill="currentColor" d="m11 13.586l-1.793-1.793l-1.414 1.414L11 16.414l5.207-5.207l-1.414-1.414z" /></svg>
                                                <h5 style={{ marginTop: '15px', color: '#000000' }}><strong>Customer Profiles</strong></h5>
                                                <p style={{ color: '#000000ff' }}>Your built in customer management tool for your app customers</p>
                                            </div>
                                        </div>
                                    </a>
                                    <a href='/staffs' className='col-md-6 col-lg-4'>
                                        <div className='card mb-3'>
                                            <div className='card-body' style={{ textAlign: 'center' }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="60px" height="60px" viewBox="0 0 24 24"><path fill="currentColor" d="M12 2A10.13 10.13 0 0 0 2 12a10 10 0 0 0 4 7.92V20h.1a9.7 9.7 0 0 0 11.8 0h.1v-.08A10 10 0 0 0 22 12A10.13 10.13 0 0 0 12 2M8.07 18.93A3 3 0 0 1 11 16.57h2a3 3 0 0 1 2.93 2.36a7.75 7.75 0 0 1-7.86 0m9.54-1.29A5 5 0 0 0 13 14.57h-2a5 5 0 0 0-4.61 3.07A8 8 0 0 1 4 12a8.1 8.1 0 0 1 8-8a8.1 8.1 0 0 1 8 8a8 8 0 0 1-2.39 5.64" /><path fill="currentColor" d="M12 6a3.91 3.91 0 0 0-4 4a3.91 3.91 0 0 0 4 4a3.91 3.91 0 0 0 4-4a3.91 3.91 0 0 0-4-4m0 6a1.91 1.91 0 0 1-2-2a1.91 1.91 0 0 1 2-2a1.91 1.91 0 0 1 2 2a1.91 1.91 0 0 1-2 2" /></svg>
                                                <h5 style={{ marginTop: '15px', color: '#000000' }}><strong>Staff Management</strong></h5>
                                                <p style={{ color: '#000000ff' }}>Manage and organize your dealership staff members in your app</p>
                                            </div>
                                        </div>
                                    </a>
                                    <a href='/departments' className='col-md-6 col-lg-4'>
                                        <div className='card mb-3'>
                                            <div className='card-body' style={{ textAlign: 'center' }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="60px" height="60px" viewBox="0 0 24 24"><path fill="currentColor" d="M22 7.999a1 1 0 0 0-.516-.874l-9.022-5a1.003 1.003 0 0 0-.968 0l-8.978 4.96a1 1 0 0 0-.003 1.748l9.022 5.04a.995.995 0 0 0 .973.001l8.978-5A1 1 0 0 0 22 7.999m-9.977 3.855L5.06 7.965l6.917-3.822l6.964 3.859z" /><path fill="currentColor" d="M20.515 11.126L12 15.856l-8.515-4.73l-.971 1.748l9 5a1 1 0 0 0 .971 0l9-5z" /><path fill="currentColor" d="M20.515 15.126L12 19.856l-8.515-4.73l-.971 1.748l9 5a1 1 0 0 0 .971 0l9-5z" /></svg>
                                                <h5 style={{ marginTop: '15px', color: '#000000' }}><strong>Departments</strong></h5>
                                                <p style={{ color: '#000000ff' }}>Add and remove dealership departments from your app</p>
                                            </div>
                                        </div>
                                    </a>
                                    <a href='/help' className='col-md-6 col-lg-4'>
                                        <div className='card mb-3'>
                                            <div className='card-body' style={{ textAlign: 'center' }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="60px" height="60px" viewBox="0 0 24 24"><path fill="currentColor" d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10s10-4.486 10-10S17.514 2 12 2m1 16h-2v-2h2zm.976-4.885c-.196.158-.385.309-.535.459c-.408.407-.44.777-.441.793v.133h-2v-.167c0-.118.029-1.177 1.026-2.174c.195-.195.437-.393.691-.599c.734-.595 1.216-1.029 1.216-1.627a1.934 1.934 0 0 0-3.867.001h-2C8.066 7.765 9.831 6 12 6s3.934 1.765 3.934 3.934c0 1.597-1.179 2.55-1.958 3.181" /></svg>
                                                <h5 style={{ marginTop: '15px', color: '#000000' }}><strong>Help</strong></h5>
                                                <p style={{ color: '#000000ff' }}>Knowledge Base and F.A.Q about using the App Management Center</p>
                                            </div>
                                        </div>
                                    </a>
                                    <a href='/marketing' className='col-md-6 col-lg-4'>
                                        <div className='card mb-3'>
                                            <div className='card-body' style={{ textAlign: 'center' }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24"><path fill="currentColor" d="M11.219 3.375L8 7.399L4.781 3.375A1.002 1.002 0 0 0 3 4v15c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2V4a1.002 1.002 0 0 0-1.781-.625L16 7.399l-3.219-4.024c-.381-.474-1.181-.474-1.562 0M5 19v-2h14.001v2zm10.219-9.375c.381.475 1.182.475 1.563 0L19 6.851L19.001 15H5V6.851l2.219 2.774c.381.475 1.182.475 1.563 0L12 5.601z" /></svg>
                                                <h5 style={{ marginTop: '15px', color: '#000000' }}><strong>Marketing</strong></h5>
                                                <p style={{ color: '#000000ff' }}>Learn how to properly market your mobile app for maximum engagement</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        </div>
    );

}


export default GetStarted;