import React from "react";
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SessionExpiredModal from '../SessionExpire/SessionExpiredModal';
import { errorHandler } from "../Utils/errorHandler";
import { appData } from "../../apis/AppsAPI";
import { locationData } from "../../apis/LocationAPI";
import { updateTargetedPushMessage, singleTargetedMessage, customersByLocation, vehicleYearByTypeAndLocation, vehicleMakeByYearAndTypeAndLocation, vehicleModelByMakeAndYearAndTypeAndLocation, vehicleCountByLocation } from "../../apis/CommunicationAPI";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { MultiSelect } from 'react-multi-select-component';
import "react-datetime/css/react-datetime.css";
import Datetime from 'react-datetime';
import moment from 'moment';
import AddUpdateLoading from "../Utils/AddUpdateLoading";


const EditTargetedPushMessage = () => {
    const { push_id } = useParams();
    const navigate = useNavigate();
    const search = useLocation().search;
    const queryParams = new URLSearchParams(search);
    const app = queryParams.get('app_id') ? parseInt(queryParams.get('app_id')) : null;

    const [inputs, setInputs] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [applist, setApplist] = useState([]);
    const [location, setLocation] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [sessionModal, setSessionModal] = useState(false);
    const [backUrl] = useState("/communication/targetedpushmessages" + window.location.search);
    const [currentUrl] = useState("/communication/addtargetedpushmessage" + window.location.search);
    const [messageType, setMessageType] = useState('General');
    const [file, setFile] = useState(null);
    const [vehicleYear, setVehicleYear] = useState([]);
    const [vehicleMake, setVehicleMake] = useState([]);
    const [vehicleModel, setVehicleModel] = useState([]);
    const [selectedVehicleType, setSelectedVehicleType] = useState(null);
    const [selectedVehicleYear, setSelectedVehicleYear] = useState([]);
    const [selectedVehicleMake, setSelectedVehicleMake] = useState([]);
    const [selectedVehicleModel, setSelectedVehicleModel] = useState([]);
    const [hasInventory, setHasInventory] = useState(false);
    const [releaseDate, setReleaseDate] = useState(null);
    const [expiryDate, setExpiryDate] = useState(null);
    const [customerType, setCustomerType] = useState(null);
    const [customers, setCustomers] = useState([]);
    const [selectedCustomers, setSelectedCustomers] = useState([]);


    const [apps, setApps] = useState(app);
    const [loc, setLoc] = useState(null);



    // message types
    const messageTypes = [
        { id: 1, name: 'General', alias: 'general' },
        { id: 2, name: 'Sales', alias: 'sales' },
        { id: 3, name: 'Service', alias: 'service' },
    ];


    // vehicle types
    const vehicleTypes = [
        { id: 1, name: 'All', alias: 'all' },
        { id: 2, name: 'New', alias: 'new' },
        { id: 3, name: 'Used', alias: 'used' }
    ];


    // load all apps
    useEffect(() => {
        const apps = appData();
        if (apps.length > 0) {
            setApplist(apps);
        }
        else {
            setApplist([]);
        }
    }, []);


    // load all locations by app
    useEffect(() => {
        if (apps) {
            locationData(apps).then((response) => {
                if (response.status === 200) {
                    console.log(response.data);
                    setLocation(response.data);
                } else {
                    setLocation([]);
                    // window.location = '/error';
                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        } else {
            setLocation([]);
        }
    }, [apps]);



    // get targeted push message by id
    useEffect(() => {
       
            singleTargetedMessage(push_id).then((response) => {
                if (response.status === 200) {
                    console.log(response.data);
                    const msg = response.data[0];
                    console.log(msg, 'msg');
                 
                    if (msg.messageType) {
                        const mType = messageTypes.find(mt => mt.alias === msg.messageType || mt.name === msg.messageType);

                        console.log(mType,"mType");
                        setMessageType(mType.id);
                        setInputs(values => ({ ...values, message_type: mType.alias }));
                    
                    }
                    if (msg.vehicle_type) {
                        console.log(msg.vehicle_type, 'msg.vehicle_type');
                        const vType = vehicleTypes.find(vt => vt.alias === msg.vehicle_type || vt.name === msg.vehicle_type);
                        console.log(vType, 'vType');
                        setSelectedVehicleType(vType.id);
                        setInputs(values => ({ ...values, vehicle_type: vType.alias }));
                    }
                    if (msg.vehicle_year) {
                        const vYear = msg.vehicle_year.split(',');
                        const vYears = vYear.map((vy, index) => ({ value: index, label: vy }));
                        setSelectedVehicleYear(vYears);
                        setInputs(values => ({ ...values, vehicle_year: vYear }));
                    }
                    if (msg.vehicle_make) {
                        const vMake = msg.vehicle_make.split(',');
                        const vMakes = vMake.map((vm, index) => ({ value: index, label: vm }));
                        setSelectedVehicleMake(vMakes);
                        setInputs(values => ({ ...values, vehicle_make: vMake }));
                    }
                    if (msg.vehicle_model) {
                        const vModel = msg.vehicle_model.split(',');
                        const vModels = vModel.map((vm, index) => ({ value: index, label: vm }));
                        setSelectedVehicleModel(vModels);
                        setInputs(values => ({ ...values, vehicle_model: vModel }));
                    }
                    if (msg.display_image_path) {
                        setInputs(values => ({ ...values, display_image_path: msg.display_image_path }));
                        setInputs(values => ({ ...values, old_image_path: msg.display_image_path }));
                    }
                    if (msg.attachment_file_path) {
                        setInputs(values => ({ ...values, attachment_file_path: msg.attachment_file_path }));
                        setInputs(values => ({ ...values, old_attachment_path: msg.attachment_file_path }));
                    }

                    setInputs(values => ({ ...values, id: msg.id }));
                    setInputs(values => ({ ...values, customer_id: msg.customer_id }));
                    setInputs(values => ({ ...values, subject: msg.subject }));
                    setInputs(values => ({ ...values, message: msg.message }));
                    setInputs(values => ({ ...values, terms_and_conditions: msg.terms_and_conditions }));
                    setInputs(values => ({ ...values, coupon_code: msg.coupon_code }));
                    setInputs(values => ({ ...values, vehicle_vins: msg.vehicle_vins }));
                    setInputs(values => ({ ...values, vehicle_stock_numbers: msg.vehicle_stock_numbers }));
                    setInputs(values => ({ ...values, release_time: msg.release_time }));
                    setInputs(values => ({ ...values, expiry_time: msg.expiry_time }));
                    console.log(inputs);

                }
                
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        
    }, [push_id]);





    // check to see if has inventory
    useEffect(() => {
        if (loc) {
            vehicleCountByLocation(loc).then((response) => {
                if (response.status === 200) {
                    setHasInventory(true);
                } else {
                    setHasInventory(false);
                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        } else {
            setHasInventory(false);
        }
    }, [loc]);


    // load all vehicles by type & location
    useEffect(() => {
        if (loc && selectedVehicleType) {
            const vType = vehicleTypes.find(vt => vt.id === parseInt(selectedVehicleType));
            const vAlias = vType.alias;
            vehicleYearByTypeAndLocation(loc, vAlias).then((response) => {
                if (response.status === 200) {
                    let data = []
                    response.data.map((year, index) => {
                        data.push({ value: index, label: year.year });
                    });
                    setVehicleYear(data);
                } else {
                    setVehicleYear([]);
                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        } else {
            setVehicleYear([]);
        }
    }, [loc, selectedVehicleType]);



    // load all vehicles by type, year & location
    useEffect(() => {
        if (loc && selectedVehicleType && selectedVehicleYear.length > 0) {
            const vType = vehicleTypes.find(vt => vt.id === parseInt(selectedVehicleType));
            const vAlias = vType.alias;
            const vYear = selectedVehicleYear.map(vy => vy.label);
            console.log(vYear, 'year');
            vehicleMakeByYearAndTypeAndLocation(loc, vAlias, vYear).then((response) => {
                if (response.status === 200) {
                    let data = []
                    response.data.map((make, index) => {
                        data.push({ value: index, label: make.make });
                    });
                    setVehicleMake(data);
                } else {
                    setVehicleMake([]);
                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        } else {
            setVehicleMake([]);
        }
    }, [loc, selectedVehicleType, selectedVehicleYear]);




    // load all vehicles by type, year, make & location
    useEffect(() => {
        if (loc && selectedVehicleType && selectedVehicleYear.length > 0 && selectedVehicleMake.length > 0) {
            const vType = vehicleTypes.find(vt => vt.id === parseInt(selectedVehicleType));
            const vAlias = vType.alias;
            const vYear = selectedVehicleYear.map(vy => vy.label);
            const vMake = selectedVehicleMake.map(vm => vm.label);
            vehicleModelByMakeAndYearAndTypeAndLocation(loc, vAlias, vYear, vMake).then((response) => {
                if (response.status === 200) {
                    let data = []
                    response.data.map((model, index) => {
                        data.push({ value: index, label: model.model });
                    });
                    setVehicleModel(data);
                } else {
                    setVehicleModel([]);
                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        } else {
            setVehicleModel([]);
        }
    }, [loc, selectedVehicleType, selectedVehicleYear, selectedVehicleMake]);


    // logs out a user
    const handleLogout = async () => {
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location = "/";
    };


    // handles toast closing
    const handleCloseToast = () => {
        setShowToast(false);
    };

    // handle user input
    const handleChange = (event) => {
        const name = event.target.name;
        let value;
        let filen;
        if (event.target.type === 'select') {
          
            value = document.getElementById(name).value;
            setInputs(values => ({ ...values, [name]: value }));
            if (name === "app_id") {
                setApps(value);
            }
        } else if (event.target.type === 'select-one') {
            console.log("I am here");
            value = document.getElementById(name).value;
            setInputs(values => ({ ...values, [name]: value }));
            const selectedIndex = event.target.selectedIndex;
            if (name === "app_id") {
                if (selectedIndex === 0) {
                    setLoc(null);
                }
                setApps(value);
            }
            else if (name === "message_type") {
              console.log(selectedIndex);
              
                if (selectedIndex === 0) {
                    setMessageType(null);
                }
                else {
                    const mType = messageTypes.find(mt => mt.id === parseInt(value));
                    setMessageType(parseInt(value));
                    setInputs(values => ({ ...values, [name]: mType.alias }));
                }
            }
            else if (name === "vehicle_type") {
                if (selectedIndex === 0) {
                    setSelectedVehicleType(null);
                }
                setSelectedVehicleType(value);
                const vType = vehicleTypes.find(vt => vt.id === parseInt(value));
                setInputs(values => ({ ...values, [name]: vType.alias }));
                console.log(vType.alias);
            }
            else if (name === "location_id") {
                if (selectedIndex === 0) {
                    setLoc(null);
                }
                setLoc(value);
            }
            else if (name === "customer_type") {
                if (selectedIndex === 0) {
                    setCustomerType(null);
                }
                setCustomerType(value);
            }
        } // when selected location
        else if (event.target.type === 'select-multiple') {

            let elements = document.getElementById(name).childNodes;
            console.log(elements, name)

            let arrayOfSelecedIDs = [];

            let count = 0;
            for (let i = 0; i < elements.length; i++) {
                // if option is select then push it to object or array
                if (elements[i].selected && i !== 0) {
                    arrayOfSelecedIDs.push(elements[i].value);
                    count = count + 1;
                }
            }
            if (count > 0) {
                console.log("selected " + count);
            }
            setInputs(values => ({ ...values, [name]: arrayOfSelecedIDs }));
        }
        else if (event.target.type === 'file') {
            if (name === "display_image_file") {
                filen = event.target.files[0];
                setInputs(values => ({ ...values, [name]: filen }));
            }
            else if (name === "attachment_file") {
                filen = event.target.files[0];
                setInputs(values => ({ ...values, [name]: filen }));
                setFile(event.target.files[0]);
            }
        }
        else {
            value = event.target.value;
            setInputs(values => ({ ...values, [name]: value }));
        }
    }




    const customerTypes = [
        { id: 1, name: 'All', alias: null },
        { id: 2, name: 'Rewards Only', alias: 1 },
        { id: 3, name: 'Excluding Rewards', alias: 0 }
    ];



    // get customers by location
    useEffect(() => {
        if (loc && customerType) {
            const cType = customerTypes.find(ct => ct.id === parseInt(customerType));
            const cAlias = cType.alias;
            customersByLocation(loc, cAlias).then((response) => {
                if (response.status === 200) {
                    let data = []
                    response.data.map((customer, index) => {
                        data.push({ value: customer.id, label: customer.Customers });
                    });
                    setCustomers(data);
                } else {
                    setCustomers([]);
                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        } else {
            setCustomers([]);
        }
    }, [loc, customerType]);




    // on selecting customers
    const onSelectedCustomers = (data) => {
        setSelectedCustomers(data);
        setInputs(values => ({ ...values, customer_id: data.map(c => c.value) }));
    }


    // on selecting year
    const onSelectedYear = (data) => {
        setSelectedVehicleYear(data);
        setInputs(values => ({ ...values, vehicle_year: data }));
    }


    // on selecting make
    const onSelectedMake = (data) => {
        setSelectedVehicleMake(data);
        setInputs(values => ({ ...values, vehicle_make: data }));
    }

    // on selecting model
    const onSelectedModel = (data) => {
        setSelectedVehicleModel(data);
        setInputs(values => ({ ...values, vehicle_model: data }));
    }


    // set release date
    const setReleaseDateFunc = (releaseDate) => {
        console.log(releaseDate);
        setReleaseDate(releaseDate)
        setInputs(values => ({ ...values, release_date: releaseDate.format('YYYY-MM-DD') }));
        setInputs(values => ({ ...values, release_time: releaseDate.format('YYYY-MM-DD hh:mm:ss') }));
    };


    // set expiry date
    const setExpireDateFunc = (expiryDate) => {
        console.log(expiryDate);
        setExpiryDate(expiryDate)
        setInputs(values => ({ ...values, expiry_time: expiryDate.format('YYYY-MM-DD hh:mm:ss') }))
    };




    // handles form submit
    const handleSubmit = (e) => {
        e.preventDefault();

        if (!inputs.message_type) {
            toast.error("Please select Message Type", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
            });
            return false;
        }
        setSubmitting(true);
        updateTargetedPushMessage(inputs, push_id).then((response) => {
            if (response.status === 201) {
                setSubmitting(false);
                toast.success("Push Message updated successfully", {
                    position: toast.POSITION.TOP_CENTER,
                    onClose: () => {
                        window.location.reload();
                    }
                });
            } else {
                setSubmitting(false);
                toast.error("Error updating Push Message", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000,
                });
            }
        }).catch(err => {
            errorHandler(err, setSessionModal, setShowToast, toast);
        });
    };


    // file types
    const getFileTypeIcon = (fileType) => {
        console.log(fileType);
        const fileIcons = {
            pdf: 'pdf-logo.png',
            docx: 'docx-logo.png',
            txt: 'txt-logo.png',
            xlsx: 'xlsx-logo.png',
            csv: 'csv-logo.png',
            pptx: 'pptx-logo.png',
            png: 'image-logo.png',
            jpg: 'image-logo.png',
            jpeg: 'image-logo.png',
            PNG: 'image-logo.png',
            // Add more mappings as needed
        };

        const fileName = fileIcons[fileType] || 'general-logo.png';


        const fileIcon = require('../../assets/img/' + fileName);

        return fileIcon;
    };




    return (
        <div className="col-xl-12">
            {submitting &&
                <div>Submtting Form...</div>
            }
            {showToast && <div className="toast-overlay"></div>}
            <ToastContainer onClose={handleCloseToast} />
            <a href={backUrl} className="btn btn-sm rounded-pill btn-secondary">Back</a>  <a href="/communication" className="btn btn-sm rounded-pill btn-secondary">Communication Home</a> <a href={currentUrl} className="btn btn-sm rounded-pill btn-secondary">Add Push Message</a>
            <SessionExpiredModal
                show={sessionModal}
                handleLogout={handleLogout}
            />
            {submitting && <AddUpdateLoading show={submitting} />}
            <form onSubmit={handleSubmit} >
                <div className="card mb-4">
                    <h4 className="card-header">Editing Push Message</h4>
                    <div className="card-body">
                        <h5>Message Info</h5>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Message Type<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-10">
                                <select className="form-select" id="message_type" name="message_type" onChange={handleChange} value={messageType || ''} >
                                    <option key='' value="">--Select Message Type--</option>
                                    {messageTypes && messageTypes.length > 0 && messageTypes.map((msgObj) => (
                                        <option key={msgObj.id} value={msgObj.id}>{msgObj.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="mb-3 row" style={{ height: '15px' }}>
                        </div>
                        <h5>Message Details</h5>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Subject<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-10">
                                <input className="form-control" type="text" id="subject" name="subject" onChange={handleChange} value={inputs.subject || ''} required />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Message<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-10">
                                <textarea className="form-control" type="text" id="message" name="message" onChange={handleChange} value={inputs.message || ''} required />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Terms And Conditions</label>
                            <div className="col-md-10">
                                <textarea className="form-control" type="text" id="terms_and_conditions" name="terms_and_conditions" onChange={handleChange} value={inputs.terms_and_conditions || ''} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="col-md-2 col-form-label">Preview</label>
                            <div className="col-md-10">
                                <span >
                                    {inputs.image_id ?
                                        <img src={inputs.display_image_file ? URL.createObjectURL(inputs.display_image_file) : (inputs.display_image_path ? inputs.display_image_path : `http://cloud.dealerappvantage.com/api/Images/${inputs.image_id}`)} height="auto" width="150" alt='med1' />
                                        :
                                        <img src={inputs.display_image_file ? URL.createObjectURL(inputs.display_image_file) : inputs.display_image_path} height="auto" width="150" alt="med1" />
                                    }
                                </span>
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-password-input" className="col-md-2 col-form-label">Display Image</label>
                            <div className="mb-3 col-md-10">
                                <label htmlFor="formFile" className="form-label">Upload Image</label>
                                <input className="form-control" type="file" id="display_image_file" name="display_image_file" onChange={handleChange} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="col-md-2 col-form-label">Preview</label>
                            <div className="col-md-10">
                                <span>
                                    {inputs.attachment_id ?
                                        <img src={file ? getFileTypeIcon(file.name.split('.').pop()) : (inputs.attachment_file_path ? getFileTypeIcon(inputs.attachment_file_path.split('.').pop()) : getFileTypeIcon(`http://cloud.dealerappvantage.com/api/Files/${inputs.attachment_id}`.split('.').pop()))} height="auto" width="150" alt='attachment' />
                                        :
                                        <img src={file ? getFileTypeIcon(file.name.split('.').pop()) : (inputs.attachment_file_path ? getFileTypeIcon(inputs.attachment_file_path.split('.').pop()) : 'no-image-found.gif')} height="auto" width="150" alt="" />
                                    }
                                </span>
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-password-input" className="col-md-2 col-form-label">Attachment File</label>
                            <div className="mb-3 col-md-10">
                                <label htmlFor="formFile" className="form-label">Upload File</label>
                                <input className="form-control" type="file" id="attachment_file" name="attachment_file" onChange={handleChange} />
                            </div>
                        </div>
                        <div className="mb-3 row" style={{ height: '15px' }}>
                        </div>
                        {messageType === 2 ?
                            <div>
                                <h5>Sales Message Options</h5>
                                {hasInventory ?
                                    <div>
                                        <div className="mb-3 row">
                                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Vehicle Type</label>
                                            <div className="col-md-10">
                                                <select className="form-select" id="vehicle_type" name="vehicle_type" onChange={handleChange} value={selectedVehicleType || ''} >
                                                    <option key='' value="">--Select Vehicle Type--</option>
                                                    {vehicleTypes && vehicleTypes.length > 0 && vehicleTypes.map((vehicleObj) => (
                                                        <option key={vehicleObj.id} value={vehicleObj.id}>{vehicleObj.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Vehicle Year</label>
                                            <div className="col-md-10">
                                                <MultiSelect
                                                    options={vehicleYear}
                                                    value={selectedVehicleYear}
                                                    onChange={onSelectedYear}
                                                    labelledBy={"Select Year"}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Vehicle Make</label>
                                            <div className="col-md-10">
                                                <MultiSelect
                                                    options={vehicleMake}
                                                    value={selectedVehicleMake}
                                                    onChange={onSelectedMake}
                                                    labelledBy={"Select Make"}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Vehicle Model</label>
                                            <div className="col-md-10">
                                                <MultiSelect
                                                    options={vehicleModel}
                                                    value={selectedVehicleModel}
                                                    onChange={onSelectedModel}
                                                    labelledBy={"Select Model"}
                                                />
                                            </div>
                                        </div>
                                    </div> : null}
                                <div className="mb-3 row">
                                    <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Vehicle VINs</label>
                                    <div className="col-md-10">
                                        <input className="form-control" type="text" id="vehicle_vins" name="vehicle_vins" onChange={handleChange} value={inputs.vehicle_vins || ''} />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Vehicle Stock Numbers</label>
                                    <div className="col-md-10">
                                        <input className="form-control" type="text" id="vehicle_stock_numbers" name="vehicle_stock_numbers" onChange={handleChange} value={inputs.vehicle_stock_numbers || ''} />
                                    </div>
                                </div>
                            </div> : null}
                        <div className="mb-3 row" style={{ height: '15px' }}>
                        </div>
                        {messageType === 3 ?
                            <div>
                                <h5>Service Message Options</h5>
                                <div className="mb-3 row">
                                    <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Coupon Code</label>
                                    <div className="col-md-10">
                                        <input className="form-control" type="text" id="coupon_code" name="coupon_code" onChange={handleChange} value={inputs.coupon_code || ''} />
                                    </div>
                                </div>
                            </div> : null}
                        <div className="mb-3 row" style={{ height: '15px' }}>
                        </div>
                        <h5>Message Schedule</h5>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Release Date</label>
                            <div className="col-md-10">
                                <Datetime
                                    dateFormat="MM/DD/YYYY"
                                    timeFormat={true}
                                    onChange={setReleaseDateFunc}
                                    value={inputs.release_time ? moment(inputs.release_time) : ''}
                                    inputProps={{ placeholder: 'MM/DD/YYYY' }}
                                    name="release_date"
                                    id="release_date"
                                    required
                                    
                                />


                                
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Expire</label>
                            <div className="col-md-10">
                                <Datetime
                                    dateFormat="MM/DD/YYYY"
                                    timeFormat={true}
                                    onChange={setExpireDateFunc}
                                    value={inputs.expiry_time ? moment(inputs.expiry_time) : ''}
                                    inputProps={{ placeholder: 'MM/DD/YYYY' }}
                                    name="expiry_date"
                                    id="expiry_date"
                                    required
                                />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <div className="col-md-3">&nbsp;</div>
                            <div className="col-md-7"><input type="submit" className="btn btn-primary col-lg-10" value="Save" /></div>
                            <div className="col-md-2">&nbsp;</div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};


export default EditTargetedPushMessage;