import axiosInstance from "../services/AxiosInstance";


// count number of profiles

// delete customer License
export const deleteCustomerLicense = (id,customerId) => {

    let formData = new FormData();
    formData.append("customerId", customerId);
    return axiosInstance.delete("/v2/drivinglicense/del/" + id+"?customerId="+customerId);
};



// Save customer License
export const saveCustomerLicense = async(data) => {
    
    // let url ="/v2/drivinglicense/add";

    // validation is important 
    let formData = new FormData();
    formData.append("customerId", data.customerId);
    formData.append("license_number", data.license_number);
    formData.append("first_name", data.first_name);
    formData.append("middle_name", data.middle_name);
    formData.append("last_name", data.last_name);
    formData.append("street", data.street);
    formData.append("zip", data.zip);
    formData.append("city",data.city);
    formData.append("state", data.state);
    formData.append("country", data.country);
    formData.append("birth_date", data.birth_date);
    formData.append("document_type", data.document_type);
    formData.append("issue_date", data.issue_date);
    formData.append("expiry_date", data.expiry_date);
    formData.append("gender", data.gender);
    formData.append("app_id", data.app_id);
    console.log("Print form data");




    const url = "/v2/drivinglicense/add";
    console.log(url);
    return axiosInstance.post(url, formData);


};


// delete customer License
export const updateCustomerLicense = async(data) => {
    
    // let url ="/v2/drivinglicense/add";

    // validation is important 
    let formData = new FormData();
    formData.append("customerId", data.customerId);
    formData.append("first_name", data.first_name);
    formData.append("middle_name", data.middle_name);
    formData.append("last_name", data.last_name);
    formData.append("street", data.street);
    formData.append("zip", data.zip);
    formData.append("city",data.city);
    formData.append("state", data.state);
    formData.append("country", data.country);
    formData.append("birth_date", data.birth_date);
    formData.append("document_type", data.document_type);
    formData.append("issue_date", data.issue_date);
    formData.append("expiry_date", data.expiry_date);
    formData.append("gender", data.gender);
    console.log("Print form data");



    const url = "/v2/drivinglicense/update/"+data.id;
    console.log(url);
    return axiosInstance.patch(url, formData);


};



// get license of a customer 
export const getLicenseByCustomerId = (customer_id,licnese_number) => {
    let url = "/v2/drivinglicense/getlicenseinfo?customerId="+customer_id
    if(licnese_number){
        let searchkeyencoded = encodeURIComponent(licnese_number);

        url = url+"&licenseno="+searchkeyencoded;
    }

    // console.log("get license api");

    console.log(url);
    return axiosInstance.get(url);
};




