import React from "react";

const NotFound = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <h1 style={{ fontSize: "5rem", margin: "2rem" }}>404</h1>
      <h2 style={{ margin: "2rem" }}>Page Not Found</h2>
    </div>
  );
};

export default NotFound;