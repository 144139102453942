import React from "react";
import { Button } from "react-bootstrap";
import * as XLSX from 'xlsx';

// utility for download sample format with dummy data
const DownloadDemoData = () => {
  const jsonData = [{
    "App": "App Name",
    "Location": "Location Name",
    "First Name": "Ross",
    "Last Name": "Geller",
    "Department": "Services",
    "Title": "Manager",
    "Email": "ross@mail.com",
    "Phone": "(123)456-789",
    "Text Message Number": "",
    "Active Status": "Active"
  },
  {
    "App": "App Name",
    "Location": "Location Name",
    "First Name": "Monica",
    "Last Name": "Geller",
    "Department": "Finance",
    "Title": "Sales",
    "Email": "mon@mail.com",
    "Phone": "(123)456-7890",
    "Text Message Number": "",
    "Active Status": "Inactive"
  },
  {
    "App": "App Name",
    "Location": "Location Name",
    "First Name": "Joey",
    "Last Name": "Tribbiani",
    "Department": "Foods",
    "Title": "Administration",
    "Email": "joey@mail.com",
    "Phone": "(123)456-7890",
    "Text Message Number": "(123)456-7890",
    "Active Status": "Active"
  },
  {
    "App": "App Name",
    "Location": "Location Name",
    "First Name": "Rachel",
    "Last Name": "Green",
    "Department": "After Sales",
    "Title": "Manager",
    "Email": "rachel@mail.com",
    "Phone": "(123)456-7890",
    "Text Message Number": "(123)456-7890",
    "Active Status": "Active"
  },
  {
    "App": "App Name",
    "Location": "Location Name",
    "First Name": "Chandler",
    "Last Name": "Bing",
    "Department": "Finance",
    "Title": "Manager",
    "Email": "bing@mail.com",
    "Phone": "(123)456-789",
    "Text Message Number": "",
    "Active Status": "Active"
  },
  {
    "App": "App Name",
    "Location": "Location Name",
    "First Name": "Phoebe",
    "Last Name": "Buffay",
    "Department": "Delivery",
    "Title": "Care Taker",
    "Email": "phoebe@mail.com",
    "Phone": "(123)456-789",
    "Text Message Number": "",
    "Active Status": "Active"
  }];

  const data = JSON.parse(JSON.stringify(jsonData));

  const handleDownload = async () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "template.xlsx");
  };

  return (
    <div>
      <Button onClick={handleDownload}>Download Template with Data</Button>
    </div>
  );
};

export default DownloadDemoData;