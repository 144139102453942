import React from "react";
import { Modal, Button } from "react-bootstrap";
import QRCodeGenerator from "./QRCode";


const QRCodeModal = ({ show, handleClose, qrCodeValue }) => {
    console.log(qrCodeValue);
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <QRCodeGenerator value={qrCodeValue} background={'#ffffff'} foreground={'#000000'} level={'M'} size={512} />
            </Modal.Body>
        </Modal>
    );
}

export default QRCodeModal;