import React from 'react';
import DateTimeField from '../DateTimeField/DateTimeField';
import SessionExpiredModal from '../SessionExpire/SessionExpiredModal';
import AppsDropDownComponent from '../PageTopComponents/AppDropDown';
import LocationsDropDownComponent from '../PageTopComponents/LocationDropDown';
import DepartmentsDropDownComponent from "../PageTopComponents/DepartmentDropDown";
import PropTypes from 'prop-types';

function ModuleHeader({
    searchKey,
    handleChange,
    buttonTitle,
    buttonPath,
    dateSearchTitle,
    searchTitle,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    sessionModal,
    handleLogout,
    apps,
    applist,
    depart,
    departmentlist,
    loc,
    location,
    showDeptDropdown,
    showCreateNewButton
}) {
    // ... your existing state and functions ...

    return (
        <div>
            <div className="card px-3 mt-3">
                <div className="mb-3 row mt-3">
                    <div className="col-md-6">
                        <input
                            className="form-control"
                            type="search"
                            id="search_input"
                            name="search_inputs"
                            value={searchKey || ''}
                            onChange={handleChange}
                            placeholder={searchTitle}
                        />
                    </div>
                    {showCreateNewButton && <div className="col-md-6">
                        <a href={`${buttonPath}` + window.location.search} type="button" className="btn btn-primary">{buttonTitle}</a>
                    </div>}
                </div>
            </div>
            <div className="card px-3 pt-2 mb-5 mt-3">
                <h5>{dateSearchTitle}</h5>
                <SessionExpiredModal show={sessionModal} handleLogout={handleLogout} />
                <div style={{ display: 'flex', marginLeft: '.1rem', marginTop: '.5rem' }}>
                    <DateTimeField
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                    />
                    <div style={{ marginLeft: 'auto', marginBottom: '20px' }}>
                        <button onClick={() => setStartDate(null)} className="btn btn-sm btn-secondary" style={{ marginLeft: '0.1rem', marginRight: '10px' }}>
                            <i className='bx bx-reset'></i> Reset Start Date
                        </button>
                        <button onClick={() => setEndDate(null)} className="btn btn-sm btn-secondary" style={{ marginLeft: '0.1rem' }}>
                            <i className='bx bx-reset'></i> Reset End Date
                        </button>
                    </div>
                </div>
            </div>
            <div className="mb-4 row">
                <div className="d-flex flex-wrap">
                    <AppsDropDownComponent
                        apps={apps}
                        handleChange={handleChange}
                        applist={applist}
                        className="me-1"
                    />
                    <LocationsDropDownComponent
                        loc={loc}
                        handleChange={handleChange}
                        location={location}
                        className="me-1"
                    />
                    {showDeptDropdown &&
                        <DepartmentsDropDownComponent
                            depart={depart}
                            handleChange={handleChange}
                            departmentlist={departmentlist}
                        />
                    }
                </div>
            </div>
        </div>
    );
}
ModuleHeader.propTypes = {
    searchKey: PropTypes.string,
    handleChange: PropTypes.func,
    buttonTitle: PropTypes.string,
    buttonPath: PropTypes.string,
    dateSearchTitle: PropTypes.string,
    searchTitle: PropTypes.string,
    startDate: PropTypes.instanceOf(Date),
    setStartDate: PropTypes.func,
    endDate: PropTypes.instanceOf(Date),
    setEndDate: PropTypes.func,
    sessionModal: PropTypes.bool,
    handleLogout: PropTypes.func,
    apps: PropTypes.array,
    applist: PropTypes.array,
    loc: PropTypes.string,
    location: PropTypes.string,
    showDeptDropdown: PropTypes.bool,
    showCreateNewButton: PropTypes.bool,
};
export default ModuleHeader;
