import React from "react";
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SessionExpiredModal from '../SessionExpire/SessionExpiredModal';
import { errorHandler } from "../Utils/errorHandler";
import { getStaffById, updateStaff } from "../../apis/StaffAPI";
import { departmentData } from "../../apis/DepartmentAPI";
import { locationData } from "../../apis/LocationAPI";
const EditStaff = () => {
    const inputRef = React.useRef();
    const { staff_id } = useParams();

    // inputs is json a jso user all selection
    const [inputs, setInputs] = useState({});
    const [loclist, setLoclist] = useState([]);

    // location hold locations list
    const [location, setLocation] = useState([]);
    // on clicking location department would be loaded 
    const [loc, setLoc] = useState(null);

    const [apps, setApps] = useState(null);
    const [departmentlist, setDepartmentlist] = useState([]);
    const [mainlocation, setMainlocation] = useState(null);

    const [sessionModal, setSessionModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [applist] = useState([]);
    const [submitting, setSubmitting] = useState(false);

    const [staffName, setStaffName] = useState("");
    // define back page url
    const [backUrl] = useState("/staffs" + window.location.search);
    const [addUrl] = useState("/addstaff" + window.location.search);

    //clicked option set as location accordingly department will be loaded

    // handle user inputs
    const handleChange = (event) => {
        const name = event.target.name;
        let value;
        let filen;
        if (event.target.type === 'select') {
            value = document.getElementById(name).value;
            setInputs(values => ({ ...values, [name]: value }));
        } else if (event.target.type === 'select-multiple') {
            let elements = document.getElementById(name).childNodes;
            let arrayOfSelecedIDs = [];
            let count = 0;
            for (let i = 0; i < elements.length; i++) {
                if (elements[i].selected && i !== 0) {
                    arrayOfSelecedIDs.push(elements[i].value);
                    count = count + 1;

                }
            }

            if (count > 0) {
                console.log("selected " + count);
                // i need to check previous any selected input missed in this array    
            } else {
                // if no location selected no location change occur
                setLoc(null);  // none selected so get department  trigger not need to fire 
            }

            setInputs(values => ({ ...values, [name]: arrayOfSelecedIDs }));

            //to do if same location clicked again location change should not fire
        }
        else if (event.target.type === 'file') {
            filen = event.target.files[0];
            setInputs(values => ({ ...values, [name]: filen }));
        }

        else {
            value = event.target.value;
            setInputs(values => ({ ...values, [name]: value }));
        }
    };

    /* Clicking on specific location fire depart ment list fetching trigger */
    const handleClick = (event, param) => {
        setLoc(param);
    };

    // handle submit event
    const handleSubmit = event => {
        event.preventDefault();
        let lc_changed = 0;
        // main location with order initiate as it is
        let mlocation = mainlocation;
        let location_number = inputs.location_id.map(i => Number(i));

        if (inputs.first_name === "" || inputs.last_name === "") {
            setShowToast(true);
            toast.error(`Validation Error: First Name & Last Name Required`, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });

        }
        else if (inputs.title === "") {
            setShowToast(true);
            toast.error(`Validation Error: Title Required`, { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });

        }  // it will work to set location change
        else if (!inputs.department) {
            setShowToast(true);
            toast.error("Validation Error: Department Required", { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });

        }
        if (location_number.length > 0) {
            if (location_number.indexOf(mainlocation) < 0) {
                mlocation = location_number[0];
                lc_changed = 1;
            } else {
                lc_changed = 0;

            }
        } else {
            mlocation = null;
        }
        setSubmitting(true);

        console.log(mlocation);
        updateStaff(inputs, lc_changed, mlocation).then((res) => {
            setSubmitting(false);
            if (res.status === 201 && res.data.affectedRows > 0) {
                setShowToast(true);
                toast.success(`Updated Successfully`, {
                    position: toast.POSITION.TOP_CENTER, autoClose: 3000, hideProgressBar: false, onClose: () => {
                        window.location.reload();
                    }
                });
                // window.alert("success");
            } else {
                setSubmitting(false);
                setShowToast(true);
                toast.error(`Could not update staff!`, { position: toast.POSITION.TOP_CENTER, autoClose: false, hideProgressBar: true });
            }
        }).catch((err) => {
            setSubmitting(false);
            errorHandler(err, setSessionModal, setShowToast, toast);
        });

        // }
        // else {
        //     setShowToast(true);
        //     toast.error(`Validation Error: Location Required`, { position: toast.POSITION.TOP_CENTER, autoClose: false, hideProgressBar: true });
        // };
    };


    /** Remove url */

    const removeUrl = () => {
        setInputs(values => ({ ...values, image_path: null, photo_file_name: null, image_id: null }));
        inputRef.current.value = "";
    };


    // load all locations by app
    useEffect(() => {
        if (apps) {
            locationData(apps).then((response) => {
                if (response.status === 200) {
                    setLocation(response.data);
                } else {
                    console.log(response.status);
                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        } else {
            setLocation([]);
        }
    }, [apps]);


    //load all accessible departments
    useEffect(() => {
        departmentData(apps, loc, applist).then((response) => {
            if (response.status === 200) {
                //clone departmentlist to deplist 
                let deptlist;
                let dlist = departmentlist;
                const objsArrStr = JSON.stringify(dlist);

                // convert the string again to array of objects
                // using the JSON.parse() method
                deptlist = JSON.parse(objsArrStr);
                // when multi location selected required to merge departments
                let selected_dept_available_deptlst = 0;
                let result;
                if (inputs.location_id.length > 1) {
                    if (response.data.length > 0) {
                        let hasMatch = false;
                        //  mergin department list
                        for (let k = 0; k < response.data.length; k++) {
                            for (let index = 0; index < deptlist.length; index++) {
                                let temp_dept = deptlist[index].department;
                                if (temp_dept === response.data[k].department) {
                                    hasMatch = true;
                                    break;
                                }
                            }
                            if (!hasMatch) {
                                deptlist.push(response.data[k]);
                            } else {
                                hasMatch = true;
                            }
                        }
                        setDepartmentlist(deptlist);
                        console.log(deptlist);
                        //incase selected deapartment exist 
                        deptlist.forEach((de) => {
                            if (de.department === inputs.department) {
                                selected_dept_available_deptlst = 1;
                            }
                        });

                    } else {
                        selected_dept_available_deptlst = 1;
                        console.log("no response thing will be as it is");
                    }

                } else {
                    //copy response.data to deptlist
                    deptlist = response.data;
                    setDepartmentlist(response.data);
                    if (inputs.location_id.length === 1) {
                        // check whether selected department in list
                        result = response.data.find(({ department }) => department === inputs.department);
                        console.log(result);
                        if (result) {
                            selected_dept_available_deptlst = 1;

                        }
                    } else {

                        selected_dept_available_deptlst = 0;
                    }

                }

                // if no matching department found set selected department as null
                if (selected_dept_available_deptlst === 0) {
                    setInputs(values => ({ ...values, department: null }));
                }

            } else {
                console.log(response.status);
                setDepartmentlist([]);
                // window.location = '/error';
            }


        }).catch(err => {
            //  errorHandler(err, setSessionModal, setShowToast, toast, "/staffs");
            console.log(err);
        });
    }, [applist, apps, loc]);


    // get user data that is to be edited
    useEffect(() => {
        const s_id = Number(staff_id);
        getStaffById(s_id).then((response) => {
            if (response.status === 200 && response.data.length > 0) {
                let arr_loc = [];
                // comma separated location id
                if (response.data[0].loc_id) {
                    arr_loc = response.data[0].loc_id.split(',').map(Number);
                } else {  // to support old system
                    arr_loc[0] = response.data[0].location_id;
                }
                // this variable used for regenerate order
                setMainlocation(response.data[0].location_id);
                setApps(response.data[0].app_id);
                //departmentData(response.data[0].app_id);
                //locationData(response.data[0].app_id);
                setInputs(values => ({
                    ...values, "id": response.data[0].id, "first_name": response.data[0].first_name,
                    "last_name": response.data[0].last_name, "department": response.data[0].department,
                    "title": response.data[0].title, "phone": response.data[0].phone, "email": response.data[0].email,
                    "text_message_number": response.data[0].text_message_number, "order": response.data[0].order,
                    "location_id": arr_loc, "image_path": response.data[0].image_path, "old_image_path": response.data[0].image_path,
                    "is_active": response.data[0].is_active, "image_id": response.data[0].image_id
                }));
                setStaffName(response.data[0].first_name + " " + response.data[0].last_name);
                setLoclist(arr_loc);
            }
            else {
                toast.error(`Data not found!`, {
                    position: toast.POSITION.TOP_CENTER, autoClose: false,
                    onClose: () => {
                        window.location.href = "/staffs"
                    }
                });
            }
        }
        ).catch(err => {
            console.log(err);
            errorHandler(err, setSessionModal, setShowToast, toast, "/staffs");
        });
    }, [staff_id]);




    // handle toast notification close
    const handleCloseToast = () => {
        setShowToast(false);
    };
    // logs out a user
    const handleLogout = async () => {
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location = "/";
    };


    return (
        <div className="col-xl-12">
            {submitting &&
                <div>Submtting Form...</div>
            }
            {showToast && <div className="toast-overlay"></div>}
            <ToastContainer onClose={handleCloseToast} />
            <SessionExpiredModal
                show={sessionModal}
                handleLogout={handleLogout}
            />
            <a href={backUrl} type="button" className="btn btn-sm rounded-pill btn-secondary">Back</a>  <a href="/staffs" type="button" className="btn btn-sm rounded-pill btn-secondary">Staff Home</a> <a href={addUrl} type="button" className="btn btn-sm rounded-pill btn-secondary">Add Staff</a>
            <form onSubmit={handleSubmit} >
                <div className="card mb-4">
                    <h5 className="card-header"> ID  {staff_id} - {staffName} </h5>
                    <div className="card-body">
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Preview</label>
                            <div className="col-md-10">
                                <span >
                                    {inputs.image_id ?
                                        <img src={inputs.photo_file_name ? URL.createObjectURL(inputs.photo_file_name) : (inputs.image_path ? inputs.image_path : `http://cloud.dealerappvantage.com/api/Images/${inputs.image_id}`)} height="auto" width="150" alt='med1' />
                                        :
                                        <img src={inputs.photo_file_name ? URL.createObjectURL(inputs.photo_file_name) : inputs.image_path} height="auto" width="150" alt="med1" />
                                    }
                                </span>
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <div className="col-md-2">
                            </div>
                            <div className="col-md-8">
                                <input type="button" className="btn btn-secondary col-md-3" value="Remove Image" onClick={removeUrl} />
                            </div>
                            <div className="col-md-2">
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-password-input" className="col-md-2 col-form-label">Picture</label>
                            <div className="mb-3 col-md-10">
                                <label htmlFor="formFile" className="form-label">Upload image</label>
                                <input className="form-control" type="file" id="photo_file_name" name="photo_file_name" onChange={handleChange} ref={inputRef} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label" >Location ID<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-10">
                                <select className="form-select" id="location_id" name="location_id" onChange={handleChange} value={inputs.location_id || []} multiple required>
                                    <option key="" value="">--Select Location--</option>
                                    {location && location.length > 0 && location.map((locationObj) => (
                                        <option key={locationObj.id} value={locationObj.id} onClick={event => handleClick(event, locationObj.id)}>{locationObj.name}</option>

                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">First Name<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-10">
                                <input className="form-control" type="text" name="first_name" value={inputs.first_name || ''} onChange={handleChange} required />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Last Name<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-10">
                                <input className="form-control" type="text" name="last_name" value={inputs.last_name || ''} onChange={handleChange} required />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-email-input" className="col-md-2 col-form-label">Title<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-10">
                                <input className="form-control" type="text" name="title" value={inputs.title || ''} onChange={handleChange} required />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="form-label col-lg-2">Department</label>
                            <div className="col-md-10 ">
                                <select className="form-select" id="department" name="department" onChange={handleChange} value={inputs.department || ''}>
                                    <option key='' value="">--Select Department--</option>
                                    {departmentlist && departmentlist.length > 0 && departmentlist.map((departmentObj) => (
                                        <option key={departmentObj.department} value={departmentObj.department}>{departmentObj.department}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="form-label col-lg-2">Status<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-10 ">
                                <select className="form-select" id="is_active" name="is_active" onChange={handleChange} value={inputs.is_active} required>
                                    <option value="0">Inactive</option>
                                    <option value="1">Active</option>

                                </select>
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Email</label>
                            <div className="col-md-10">
                                <input className="form-control" type="email" pattern="[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$" name="email" value={inputs.email || ''} onChange={handleChange} onInvalid={e => e.target.setCustomValidity('Please enter a valid email address')} onInput={e => e.target.setCustomValidity('')} />
                            </div>
                        </div>

                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Phone</label>
                            <div className="col-md-10">
                                <input className="form-control" type="text" pattern="^(N\/A|\d{3}-\d{3}-\d{4}|\(\d{3}\)\s*\d{3}-\d{4}|\d{10}|\+?\d{1,2}\s?\d{3}[\s.-]?\d{3}[\s.-]?\d{4})(\s*(ext|x|ext\.|Ext|ext #|Ext #|x #|Ext\.)\s*\d{1,5})?$" name="phone" value={inputs.phone || ''} onChange={handleChange} onInvalid={e => e.target.setCustomValidity('Please enter a valid phone number')} onInput={e => e.target.setCustomValidity('')} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Text Message Number</label>
                            <div className="col-md-10">
                                <input className="form-control" type="text" pattern="^(N\/A|\d{11}|\+?\d{1,2}\s?)?(\d{3}|\(\d{3}\)|\d{4})[\s.-]?\d{3}[\s.-]?\d{4})?$" name="text_message_number" value={inputs.text_message_number || ''} onChange={handleChange} onInvalid={e => e.target.setCustomValidity('Please enter a valid text message number')} onInput={e => e.target.setCustomValidity('')} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Staff Order</label>
                            <div className="col-md-10">
                                <input className="form-control" defaultValue={inputs.order} type="number" readOnly />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                    </div>
                    <div className="mb-3 row">
                        <div className="col-md-3">&nbsp;</div>
                        <div className="col-md-7"> <input type="submit" className="btn btn-primary col-lg-10" value="Save" /></div>
                        <div className="col-md-2">&nbsp;</div>
                    </div>
                </div>
            </form>

        </div>
    );
};

export default EditStaff;