import React from "react";
import { useEffect, useState } from 'react';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SessionExpiredModal from '../SessionExpire/SessionExpiredModal';
import { errorHandler } from "../Utils/errorHandler";
import { appData } from "../../apis/AppsAPI";
import { locationData } from "../../apis/LocationAPI";
import { saveAutomatedPushMessage } from "../../apis/CommunicationAPI";
import { useLocation } from "react-router-dom";
import "react-datetime/css/react-datetime.css";
import AddUpdateLoading from "../Utils/AddUpdateLoading";


const AddAutomatedPushMessage = () => {
    const search = useLocation().search;
    const queryParams = new URLSearchParams(search);
    const app = queryParams.get('app_id') ? parseInt(queryParams.get('app_id')) : null;
    const isAutomatedPN = queryParams.get('automated') ? parseInt(queryParams.get('automated')) : 1;
    const msgType = queryParams.get('message_type') ? queryParams.get('message_type') : "";
    let rptEvent=1; // whether event will be repeated
    let rptEventGap=365;
    if(msgType==="LeaseRenewal" ||msgType==="ServiceThankYou" || msgType==="ScheduledServiceReminder"||msgType==="ServiceThankYou"||msgType==="SetUpProfile" ){
        rptEvent=0;
        rptEventGap="";
    } else { 
        rptEventGap=365;
        rptEvent=1;
}
    let commonString = "";
    const toastErrOptions = {
        autoClose: 1000,
        position: toast.POSITION.TOP_CENTER,
    };



    if (isAutomatedPN === 1) {
        commonString = "Automated ";
    }

    const [submitting, setSubmitting] = useState(false);
    const [applist, setApplist] = useState([]);
    const [location, setLocation] = useState([]);
    const [loc, setLoc] = useState(null);
    const [showToast, setShowToast] = useState(false);
    const [sessionModal, setSessionModal] = useState(false);
    const [backUrl] = useState("/communication/automatedpushmessages" + window.location.search);
    const [messageType, setMessageType] = useState('');
    const [file, setFile] = useState(null);
    const [apps, setApps] = useState(app);
    const [errorMsg, setErrorMsg] = useState("");
    const [inputs, setInputs] = useState({message_validity:1,message_type:msgType,is_automated:isAutomatedPN,is_published:1,is_repeative_event:rptEvent,repeat_event_gap:rptEventGap});


    // message types
    const [messageTypes, setMessageTypes] = useState(
        [
            { id: 1, name: "Birthday",title:"Birthday" },
            { id: 2, name: "LeaseRenewal",title:"Lease Renewal" },
            { id: 3, name: "PurchaseAnniversary",title:"Purchase Anniversary" },
            { id: 4, name: "ScheduledServiceReminder",title:"Scheduled Service Reminder" },
            { id: 5, name: "ServiceThankYou",title:"Service Thank You" },
            { id: 6, name: "SetUpProfile",title:"Set Up Profile" },
            { id: 7, name: "mDocsReminder",title:"mDocs Reminder" },
            { id: 8, name: "EmptyGarage",title:"Empty Garage" },
            { id: 9, name: "PriceChange",title:"Price Change" },
            { id: 10, name: "MaintenanceScheduleReminder",title:"Maintenance Schedule Reminder" },
        ]
    );


    // set app id from query params
    useEffect(() => {
        if (app) {
            setInputs(values => ({ ...values, app_id: app }));
        }
    }, [app]);


    // load all apps this for default 
    useEffect(() => {
        const apps = appData();
        if (apps.length > 0) {
            setApplist(apps);
        }
        else {
            setApplist([]);
        }
        const userData = JSON.parse(localStorage.getItem('user'));
        if (userData) {
            setInputs(values => ({ ...values, created_by: userData.id }));
        }

    }, []);


    console.log(window.location.search, 'window.location.search');
    // load all locations by app
    useEffect(() => {
        if (apps) {
            locationData(apps).then((response) => {
                if (response.status === 200) {
                    console.log(response.data);
                    let data = [];
                    response.data.map((loc, index) => {
                        return data.push({ value: loc.id, label: loc.name });
                    });
                    setLocation(data);
                } else {
                    setLocation([]);
                    // window.location = '/error';
                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        } else {
            setLocation([]);
        }
    }, [apps]);

    console.log(inputs, 'inputs global 1');

    // logs out a user
    const handleLogout = async () => {
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location = "/";
    };


    // handles toast closing
    const handleCloseToast = () => {
        setShowToast(false);
    };


    const validateSelectedFile = async (selectedFile, ftype) => {
        let message = "";
        let return_json = { "status": false };
        if (!selectedFile) {
            message = "No file Selected";
            setErrorMsg(message);
            return_json.msg = message;
            return return_json;
        }

        setErrorMsg("");

        const MIN_FILE_SIZE = 100; // 1MB
        let MAX_FILE_SIZE = 1024; // 5MB
        let fileName = selectedFile.name;
        let ext = fileName.substr(fileName.lastIndexOf('.') + 1);

        if (ftype === "document") {
            MAX_FILE_SIZE = 5024; // 5MB
            if (ext === "pdf" || ext === "doc" || ext === "xls" || ext === "xlsx" || ext === "docx" || ext === "doc") {
                console.log("ok " + ext)
            } else {
                message = "File type should be document";
                setErrorMsg(message);
                return_json.msg = message;
                return return_json;
            }
        }
        else if (ftype === "attachment") {
            MAX_FILE_SIZE = 5024; // 5MB
            if (ext === "pdf" || ext === "doc" || ext === "xls" || ext === "xlsx" || ext === "docx" || ext === "png" || ext === "jpg" || ext === "jpeg" || ext === "gif" || ext === "bmp" || ext === "ico" || ext === "txt") {
                console.log("ok " + ext)
            } else {
                message = "File type should be document or image";
                setErrorMsg(message);
                return_json.msg = message;
                return return_json;
            }
        }

        else if (ftype === "image") {

            if (ext === "png" || ext === "jpg" || ext === "jpeg" || ext === "gif" || ext === "bmp" || ext === "ico" || ext === "webp") {
                MAX_FILE_SIZE = 1024; // 5MB

            } else {
                message = "File type should be image";
                setErrorMsg(message);
                return_json.msg = message;
                return return_json;
            }
        }
        else if (ftype === "plist") {
            MAX_FILE_SIZE = 1024; // 5MB
            if (ext === "plist") {
                console.log("ok extension")
            } else {
                message = "File type should be .plist";
                setErrorMsg(message);
                return_json.msg = message;
                return return_json;
            }
        }
        else {
            message = "File type has unexpected issue";
            setErrorMsg(message);
            return_json.msg = message;
            return return_json;
        }

        const fileSizeKiloBytes = selectedFile.size / 1024
        const fileSizeBytes = selectedFile.size;

        if (fileSizeBytes < MIN_FILE_SIZE) {
            message = "File Size too small(" + fileSizeKiloBytes + "). Should be bigger than " + MIN_FILE_SIZE + " kb";

            setErrorMsg(message);
            return_json.msg = message;
            return return_json;
        }
        if (fileSizeKiloBytes > MAX_FILE_SIZE) {
            message = "File Size too big (" + fileSizeKiloBytes + "). Should be smaller than " + MAX_FILE_SIZE + " kb";
            setErrorMsg(message);
            return_json.msg = message;
            return return_json;
        }
        return_json = { "msg": "", "status": true }
        return return_json;

    };



    // handle user input
    const handleChange = (event) => {
        const name = event.target.name;
        let value;
        let filen;
        if (event.target.type === 'select') {
            value = document.getElementById(name).value;
            setInputs(values => ({ ...values, [name]: value }));
            if (name === "app_id") {
                setApps(value);
            } 
        }  
        
        else if (event.target.type === 'select-one') {
            console.log("hi2");

            value = document.getElementById(name).value;
            setInputs(values => ({ ...values, [name]: value }));
            const selectedIndex = event.target.selectedIndex;
            if (name === "app_id") {
                if (selectedIndex === 0) {
                    setLocation([]);
                }
                setApps(value);
            }
            else if (name === "message_type") {
                
                document.getElementById("interval_days").disabled = false;
                document.getElementById("interval_days_2").disabled = false;
                document.getElementById("interval_days_3").disabled = false;

                
                if (selectedIndex === 0) {
                    setMessageType(null);
                    setInputs(values => ({ ...values, [name]: null }));
                    setInputs(values => ({ ...values, is_repeative_event: 1 }));
                    setInputs(values => ({ ...values, repeat_event_gap: 365 }));
                    setInputs(values => ({ ...values, interval_days: "" }));
                    setInputs(values => ({ ...values, interval_days_2: "" }));
                    setInputs(values => ({ ...values, interval_days_3: "" }));
                }
                else {
                    setMessageType(value);
                    setInputs(values => ({ ...values, [name]: value }));
                    if(value==="LeaseRenewal"  ){

                        setInputs(values => ({ ...values, is_repeative_event: 0 }));
                        setInputs(values => ({ ...values, repeat_event_gap: "" }));
                        setInputs(values => ({ ...values, interval_days: 180 }));
                        setInputs(values => ({ ...values, interval_days_2: 90 }));
                        setInputs(values => ({ ...values, interval_days_3: 30 }));
                       
    
                    }
                    
                   else if(value==="ScheduledServiceReminder"){

                        setInputs(values => ({ ...values, is_repeative_event: 0 }));
                        setInputs(values => ({ ...values, repeat_event_gap: "" }));
                        setInputs(values => ({ ...values, interval_days: "" }));
                        setInputs(values => ({ ...values, interval_days_2: "" }));
                        setInputs(values => ({ ...values, interval_days_3: "" }));
                       
    
                    } 
                    else if(value==="ServiceThankYou"){

                        setInputs(values => ({ ...values, is_repeative_event: 0 }));
                        setInputs(values => ({ ...values, repeat_event_gap: "" }));
                        setInputs(values => ({ ...values, interval_days: "-1" }));
                        setInputs(values => ({ ...values, interval_days_2: "" }));
                        setInputs(values => ({ ...values, interval_days_3: "" }));
                        document.getElementById("interval_days_2").disabled = false;
                        document.getElementById("interval_days_3").disabled = false;
               
    
                    }
                    else if(value==="SetUpProfile" ){

                        setInputs(values => ({ ...values, is_repeative_event: 0 }));
                        setInputs(values => ({ ...values, repeat_event_gap: "" }));
                        setInputs(values => ({ ...values, interval_days: "-30" }));
                        setInputs(values => ({ ...values, interval_days_2: "" }));
                        setInputs(values => ({ ...values, interval_days_3: "" }));
                
                    } 
                    
                    
                    else if(value==="mDocsReminder") {
                    
                    setInputs(values => ({ ...values, is_repeative_event: 0 }));
                    setInputs(values => ({ ...values, repeat_event_gap: "" }));
                    setInputs(values => ({ ...values, interval_days: -60 }));
                    setInputs(values => ({ ...values, interval_days_2: "" }));
                    setInputs(values => ({ ...values, interval_days_3: "" }));
    
                  
                    }else if(value==="EmptyGarage") {
                    
                        setInputs(values => ({ ...values, is_repeative_event: 0 }));
                        setInputs(values => ({ ...values, repeat_event_gap: "" }));
                        setInputs(values => ({ ...values, interval_days: -30 }));
                        setInputs(values => ({ ...values, interval_days_2: "" }));
                        setInputs(values => ({ ...values, interval_days_3: "" }));
        
                      
                    }
                    else if(value==="PriceChange") {
                    
                            setInputs(values => ({ ...values, is_repeative_event: 0 }));
                            setInputs(values => ({ ...values, repeat_event_gap: "" }));
                            setInputs(values => ({ ...values, interval_days: -1 }));
                            setInputs(values => ({ ...values, interval_days_2: "" }));
                            setInputs(values => ({ ...values, interval_days_3: "" }));
            
                          
                    } else if(value==="MaintenanceScheduleReminder") {
                    
                                setInputs(values => ({ ...values, is_repeative_event: 0 }));
                                setInputs(values => ({ ...values, repeat_event_gap: "" }));
                                setInputs(values => ({ ...values, interval_days: -1 }));
                                setInputs(values => ({ ...values, interval_days_2: "" }));
                                setInputs(values => ({ ...values, interval_days_3: "" }));
                              
                    }
                    else if(value==="Birthday") {

                        setInputs(values => ({ ...values, is_repeative_event: 1 }));
                        setInputs(values => ({ ...values, repeat_event_gap: 365 }));
                        setInputs(values => ({ ...values, interval_days: "" }));
                        setInputs(values => ({ ...values, interval_days_2: "" }));
                        setInputs(values => ({ ...values, interval_days_3: "" }));
                        document.getElementById("interval_days").disabled = true;
                        document.getElementById("interval_days_2").disabled = true;
                        document.getElementById("interval_days_3").disabled = true;


                    }
                    else if(value==="PurchaseAnniversary") {

                        setInputs(values => ({ ...values, is_repeative_event: 1 }));
                        setInputs(values => ({ ...values, repeat_event_gap: 365 }));
                        setInputs(values => ({ ...values, interval_days: "" }));
                        setInputs(values => ({ ...values, interval_days_2: "" }));
                        setInputs(values => ({ ...values, interval_days_3: "" }));
                        document.getElementById("interval_days").disabled = true;
                        document.getElementById("interval_days_2").disabled = true;
                        document.getElementById("interval_days_3").disabled = true;


                    }
                    
                    else{
                        setInputs(values => ({ ...values, is_repeative_event: 1 }));
                        setInputs(values => ({ ...values, repeat_event_gap: 365 }));
                        setInputs(values => ({ ...values, interval_days: "" }));
                        setInputs(values => ({ ...values, interval_days_2: "" }));
                        setInputs(values => ({ ...values, interval_days_3: "" }));
                    }
                }
            }
            else if (name === "location_id") {
                setLoc(value);
                setInputs(values => ({ ...values, [name]: value }));
            }
        }
        else if (event.target.type === 'checkbox') {

            if (event.target.checked) {
                setInputs(values => ({ ...values, [name]: 1 }));
                console.log(inputs);
            } else {
                setInputs(values => ({ ...values, [name]: 0 }));
                console.log(inputs);
            }
        }
        else if (event.target.type === 'file') {

            filen = event.target.files[0];

            // if no file selected no validation required                
            if (!filen) {
                if (inputs[name]) {
                    setInputs(values => ({ ...values, [name]: null }));

                    if (name === "attachment_file") {
                        setInputs(values => ({ ...values, attachment_content_type: null }));
                        setInputs(values => ({ ...values, attachment_file_size: null }));
                        setFile(null);
                    }
                }
                return;
            }

            let file_type = "";


            if (name === "display_image_file") {
                file_type = "image";
            }
            else {
                file_type = "attachment";
            }

            let filevalidation = false;
            validateSelectedFile(filen, file_type).then((response) => {
                filevalidation = response;
                if (filevalidation.status) {

                    filen = event.target.files[0];
                    setInputs(values => ({ ...values, [name]: filen }));
                    if (name === "attachment_file") {
                        setInputs(values => ({ ...values, attachment_file_name: filen.name }));
                        setInputs(values => ({ ...values, attachment_content_type: filen.type }));
                        setInputs(values => ({ ...values, attachment_file_size: filen.size }));
                        setFile(event.target.files[0]);
                    }
                }
                else if (!filevalidation.status) {
                    document.getElementById(name).value = "";
                    if (inputs[name]) {
                        setInputs(values => ({ ...values, [name]: null }));
                    }
                    toast.error("Validation Error:" + filevalidation?.msg, toastErrOptions);
                } else {

                    // document.getElementById(name).value = "";
                    if (inputs[name]) {
                        setInputs(values => ({ ...values, [name]: null }));

                    }
                    toast.error("Unexpected Error:" + filevalidation?.msg, toastErrOptions);
                }
            }).catch(error => {
                setErrorMsg(error);
                toast.error(error, toastErrOptions);
                console.log(error);
                if (inputs[name]) {
                    setInputs(values => ({ ...values, [name]: null }));
                }

            });


        }
        else {

            console.log(value);
            value = event.target.value;
            setInputs(values => ({ ...values, [name]: value }));
        }
    
    
    }


    console.log(inputs);


    // handles form submit
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(inputs, 'inputs global');
        if (!apps) {
            toast.error("Please select an App", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
            });
            return false;
        }
        if (!inputs.message_type) {
            toast.error("Please select a Message Type", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
            });
            return false;
        }
        setSubmitting(true);
        saveAutomatedPushMessage(inputs).then((response) => {
            console.log(response.status, 'response')
            if (response.status === 201 || response.status === 200) {
                setSubmitting(false);
                toast.success("Push Message saved successfully", {
                    position: toast.POSITION.TOP_CENTER,
                    onClose: () => {
                        window.location.reload();
                    }
                });
            } else {
                setSubmitting(false);
                toast.error("Error saving Push Message", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000,
                });
            }
        }).catch(err => {
            errorHandler(err, setSessionModal, setShowToast, toast);
        });
    };

    // file types
    const getFileTypeIcon = (fileType) => {
        console.log(fileType);
        const fileIcons = {
            pdf: 'pdf-logo.png',
            docx: 'docx-logo.png',
            txt: 'txt-logo.png',
            xlsx: 'xlsx-logo.png',
            csv: 'csv-logo.png',
            pptx: 'pptx-logo.png',
            png: 'image-logo.png',
            jpg: 'image-logo.png',
            jpeg: 'image-logo.png',
            PNG: 'image-logo.png',
            // Add more mappings as needed
        };

        const fileName = fileIcons[fileType] || 'general-logo.png';

        return fileName;
    };

    console.log(file, 'file global');
    console.log(messageType, 'messageType global', typeof messageType)


    return (
        <div className="col-xl-12">
            {submitting &&
                <div>Submtting Form...</div>
            }
            {showToast && <div className="toast-overlay"></div>}
            <ToastContainer onClose={handleCloseToast} />
            <a href={backUrl} className="btn btn-sm rounded-pill btn-secondary">Back</a>  <a href="/communication" className="btn btn-sm rounded-pill btn-secondary">Communication Home</a>
            <SessionExpiredModal
                show={sessionModal}
                handleLogout={handleLogout}
            />
            {submitting ?
                <AddUpdateLoading show={submitting} /> :
                <form onSubmit={handleSubmit} >
                    <div className="card mb-4">
                        <h4 className="card-header">New {commonString}Push Message</h4>
                        <div className="card-body">
                            <h5>Message Info</h5>
                            <div className="mb-3 row">
                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Apps<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                <div className="col-md-10">
                                    <select className="form-select" id="app_id" name="app_id" onChange={handleChange} value={apps || ''} >
                                        <option key='' value="">--Select Apps--</option>
                                        {applist && applist.length > 0 && applist.map((appObj) => (
                                            <option key={appObj.id} value={appObj.id}>{appObj.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Location</label>
                                <div className="col-md-10">
                                    <select className="form-select" id="location_id" name="location_id" onChange={handleChange} value={loc || ''} >
                                        <option key='' value="">--Select Location--</option>
                                        {location && location.length > 0 && location.map((locObj) => (
                                            <option key={locObj.value} value={locObj.value}>{locObj.label}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Message Type<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                <div className="col-md-10">
                                    <select className="form-select" id="message_type" name="message_type" onChange={handleChange} value={messageType || ''} >
                                        <option key='' value="">--Select Message Type--</option>
                                        {messageTypes && messageTypes.length > 0 && messageTypes.map((msgObj) => (
                                            <option key={msgObj.id} value={msgObj.name}>{msgObj.title}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="mb-3 row" style={{ height: '15px' }}>
                            </div>
                            <h5>Message Details</h5>
                            <div className="mb-3 row">
                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Subject<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                <div className="col-md-10">
                                    <input className="form-control" type="text" id="subject" name="subject" onChange={handleChange} value={inputs.subject || ''} required />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Message<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                <div className="col-md-10">
                                    <textarea className="form-control" type="text" id="body" name="body" onChange={handleChange} value={inputs.body || ''} required />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Foot Note</label>
                                <div className="col-md-10">
                                    <textarea className="form-control" type="text" id="foot_note" name="foot_note" onChange={handleChange} value={inputs.foot_note || ''} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Number of Days Validity After Recieve<sup style={{ color: "red", fontSize: 12 }}></sup></label>
                                <div className="col-md-10">
                                    <input className="" type="number" size="3" name="message_validity" value={inputs.message_validity || ''} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label"> Days Number before/after Desired Date <sup style={{ color: "red", fontSize: 12 }}></sup></label>
                                <div className="col-md-3">
                                    
                                    <input className="" type="text" size="3" id="interval_days" name="interval_days" value={inputs.interval_days || ''} onChange={handleChange} placeholder="Interval 1"/>
                                </div>
                                <div className="col-md-3">
                                    <input className="" type="text" size="3" id="interval_days_2" name="interval_days_2" value={inputs.interval_days_2 || ''} onChange={handleChange} placeholder="Interval 2" />
                                </div>
                                <div className="col-md-3">
                                    <input className="" type="text" size="3" id="interval_days_3" name="interval_days_3" value={inputs.interval_days_3 || ''} onChange={handleChange} placeholder="Interval 3" />

                                
                                </div>
                            </div>

                           
                            <div className="mb-3 row">
                            <label htmlFor="opt_status" className="col-md-3 col-form-label"> Repeated Event</label>
                                <div className="col-md-5 ">
                                    <input type="checkbox" id="is_repeative_event" name="is_repeative_event" checked={inputs.is_repeative_event === 1} onChange={handleChange} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Repeat Event Interval<sup style={{ color: "red", fontSize: 12 }}></sup></label>
                                <div className="col-md-3">
                                    <input className="" type="text" size="3" name="repeat_event_gap" value={inputs.repeat_event_gap || ''} onChange={handleChange} placeholder="Repeat Frequency"/>
                                </div>
                            </div>
                           

                            <div className="mb-3 row">
                                <label className="col-md-2 col-form-label">Preview</label>
                                <div className="col-md-10">

                                    <span >
                                        <img src={inputs.display_image_file ? URL.createObjectURL(inputs.display_image_file) : "no-image-found.gif"} height="200" width="200" alt="display image" border="0" />
                                        <input className="form-control" type="file" id="display_image_file" name="display_image_file" onChange={handleChange} />


                                    </span>
                                </div>
                            </div>



                            <div className="mb-3 row">
                                <label htmlFor="html5-password-input" className="col-md-2 col-form-label">Attachment File</label>
                                <div className="mb-3 col-md-10">
                                    <label htmlFor="formFile" className="form-label">Upload File</label>
                                    <input className="form-control" type="file" id="attachment_file" name="attachment_file" onChange={handleChange} />
                                </div>
                            </div>

                            
                            <div className="mb-3 row">
                            <label htmlFor="opt_status" className="col-md-3 col-form-label"> Automated </label>
                                <div className="col-md-5 ">
                                    <input type="checkbox" id="is_automated" name="is_automated" checked={inputs.is_automated === 1} onChange={handleChange} />
                                </div>
                            </div>
                            
                            
                            <div className="mb-3 row">
                            <label htmlFor="opt_status" className="col-md-3 col-form-label"> Published</label>
                                <div className="col-md-5 ">
                                    <input type="checkbox" id="is_published" name="is_published" checked={inputs.is_published === 1} onChange={handleChange} />
                                </div>
                            </div>

                            <div className="mb-3 row" style={{ height: '15px' }}>
                            </div>
                            <div className="mb-3 row">
                                <div className="col-md-3">&nbsp;</div>
                                <div className="col-md-7"><input type="submit" className="btn btn-primary col-lg-10" value="Save" /></div>
                                <div className="col-md-2">&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </form>}
        </div>
    );
};


export default AddAutomatedPushMessage;
