import React from "react";
import { useState } from 'react';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SessionExpiredModal from '../SessionExpire/SessionExpiredModal';
import { addClient } from "../../apis/ClientAPI";


const AddClient = () => {

    // generate a api key
    let iteration = 0;
    let apkey = "";
    let randomNumber;
    let length = 20;
    let special = false;

    while (iteration < length) {
        randomNumber = (Math.floor((Math.random() * 100)) % 94) + 33;
        if (!special) {
            console.log(iteration);
            console.log(randomNumber);

            //ignore special charachters and exit iteration without increment
            if ((randomNumber >= 33) && (randomNumber <= 47)) { continue; }
            if ((randomNumber >= 58) && (randomNumber <= 64)) { continue; }
            if ((randomNumber >= 91) && (randomNumber <= 96)) { continue; }
            if ((randomNumber >= 123) && (randomNumber <= 126)) { continue; }

        }
        iteration++;

        apkey += String.fromCharCode(randomNumber);
    }




    const [inputs, setInputs] = useState({ 'is_active': 1, "api_key": apkey });
    const [submitting, setSubmitting] = useState(false);
    const [sessionModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [backUrl] = useState("/clients" + window.location.search);
    const [currentUrl] = useState(window.location.pathname.substring(1) + window.location.search);



    // handle user input
    const handleChange = (event) => {
        const name = event.target.name;
        let value;
        if (event.target.type === 'select') {

            value = document.getElementById(name).value;
            setInputs(values => ({ ...values, [name]: value }));
        }
        else {
            value = event.target.value;
            setInputs(values => ({ ...values, [name]: value }));
        }
    }


    // handle submit event
    const handleSubmit = event => {
        event.preventDefault();
        if (inputs.client_name === "") {
            setShowToast(true);
            toast.error("Validation Error: Client Name Required", { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });
        }
        else if (inputs.client_number === "") {
            setShowToast(true);
            toast.error("Validation Error: Client Number Required", { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });


        } else if (inputs.api_key === "") {
            setShowToast(true);
            toast.error("Validation Error: Client API KEY Required", { position: toast.POSITION.TOP_CENTER, autoClose: 2000 });

        }
        else {
            setSubmitting(true);
            console.log(inputs);
            addClient(inputs).then((res) => {
                setSubmitting(false);
                if (res.status === 201 && res.data.affectedRows > 0) {
                    setShowToast(true);
                    toast.success("Successfully Saved Clients Information ", {
                        position: toast.POSITION.TOP_CENTER, autoClose: 2000, hideProgressBar: false,
                        onClose: () => {
                            window.location.reload();
                        }
                    });
                } else {
                    setShowToast(true);
                    toast.error(`Failed to save Client!`, { position: toast.POSITION.TOP_CENTER, autoClose: false, hideProgressBar: true });
                };
            }).catch((err) => {
                setSubmitting(false);
                console.log(err);
                // errorHandler(err, setSessionModal, setShowToast, toast, "/addclient");
            });
        }
    }










    // logs out a user
    const handleLogout = async () => {
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location = "/";
    };


    // handles toast closing
    const handleCloseToast = () => {
        setShowToast(false);
    };

    return (
        <div className="col-xl-12">
            {submitting &&
                <div>Submtting Form...</div>
            }
            {showToast && <div className="toast-overlay"></div>}
            <ToastContainer onClose={handleCloseToast} />
            <a href="/clients" className="btn btn-sm rounded-pill btn-secondary">Cient Home</a>  <a href={currentUrl} className="btn btn-sm rounded-pill btn-secondary">Add New Client</a>
            <SessionExpiredModal
                show={sessionModal}
                handleLogout={handleLogout}
            />
            <form onSubmit={handleSubmit} >
                <div className="card mb-4">
                    <h5 className="card-header">Adding New Client</h5>
                    <div className="card-body">
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Client Name<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-10">
                                <input className="form-control" type="text" name="client_name" value={inputs.client_name || ''} onChange={handleChange} required />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Client Number<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-10">
                                <input className="form-control" type="text" name="client_number" value={inputs.client_number || ''} onChange={handleChange} required />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-email-input" className="col-md-2 col-form-label">API Key<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-10">
                                <input className="form-control" type="text" name="api_key" value={inputs.api_key || ''} onChange={handleChange} required readOnly />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="form-label col-lg-2">Status<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-10 ">
                                <select className="form-select" id="is_active" name="is_active" onChange={handleChange} value={inputs.is_active} required>
                                    <option value="1">Active</option>
                                    <option value="0">Inactive</option>

                                </select>
                            </div>
                        </div>



                    </div>
                    {/* <a href={"/addstaff"} type="button" className="btn btn-primary col-lg-12">Add New Staff</a> */}
                    <div className="mb-3 row">
                        <div className="col-md-3">&nbsp;</div>
                        <div className="col-md-7"><input type="submit" className="btn btn-primary col-lg-10" value="Save" /></div>
                        <div className="col-md-2">&nbsp;</div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default AddClient;