import React from "react";
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SessionExpiredModal from '../SessionExpire/SessionExpiredModal';
import { errorHandler } from "../Utils/errorHandler";
import { appData } from "../../apis/AppsAPI";
import { locationData } from "../../apis/LocationAPI";
import { updatePushMessage, singleGeoMessage, vehicleYearByTypeAndLocation, vehicleMakeByYearAndTypeAndLocation, vehicleModelByMakeAndYearAndTypeAndLocation, vehicleCountByLocation } from "../../apis/CommunicationAPI";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { MultiSelect } from 'react-multi-select-component';
import Geocode from "react-geocode";
import MapComponent from "./MapViewer";
import "react-datetime/css/react-datetime.css";
import Datetime from 'react-datetime';
import moment from 'moment';
import AddUpdateLoading from "../Utils/AddUpdateLoading";


const EditGeoPushMessage = () => {
    const { push_id } = useParams();
    const navigate = useNavigate();
    const search = useLocation().search;
    const queryParams = new URLSearchParams(search);
    const app = queryParams.get('app_id') ? parseInt(queryParams.get('app_id')) : null;

    const [inputs, setInputs] = useState([]);
    const [submitting, setSubmitting] = useState(false);
    const [applist, setApplist] = useState([]);
    const [location, setLocation] = useState([]);
    const [showToast, setShowToast] = useState(false);
    const [sessionModal, setSessionModal] = useState(false);
    const [backUrl] = useState("/communication/geofencepushmessages" + window.location.search);
    const [currentUrl] = useState("/communication/addgeopushmessage" + window.location.search);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [messageType, setMessageType] = useState('General');
    const [file, setFile] = useState(null);
    const [vehicleYear, setVehicleYear] = useState([]);
    const [vehicleMake, setVehicleMake] = useState([]);
    const [vehicleModel, setVehicleModel] = useState([]);
    const [selectedVehicleType, setSelectedVehicleType] = useState(null);
    const [selectedVehicleYear, setSelectedVehicleYear] = useState([]);
    const [selectedVehicleMake, setSelectedVehicleMake] = useState([]);
    const [selectedVehicleModel, setSelectedVehicleModel] = useState([]);
    const [hasInventory, setHasInventory] = useState(false);
    const [address, setAddress] = useState('');
    const [apps, setApps] = useState(app);
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');
    const [releaseDate, setReleaseDate] = useState(null);
    const [expiryDate, setExpiryDate] = useState(null);


    // message types
    const messageTypes = [
        { id: 1, name: 'General', alias: 'general' },
        { id: 2, name: 'Sales', alias: 'sales' },
        { id: 3, name: 'Service', alias: 'service' },
    ];


    // vehicle types
    const vehicleTypes = [
        { id: 1, name: 'All', alias: 'all' },
        { id: 2, name: 'New', alias: 'new' },
        { id: 3, name: 'Used', alias: 'used' }
    ];


    // load all apps
    useEffect(() => {
        const apps = appData();
        if (apps.length > 0) {
            setApplist(apps);
        }
        else {
            setApplist([]);
        }
    }, []);


    // load all locations by app
    useEffect(() => {
        if (apps) {
            locationData(apps).then((response) => {
                if (response.status === 200) {
                    console.log(response.data);
                    let data = [];
                    response.data.map((loc, index) => {
                        data.push({ value: loc.id, label: loc.name });
                    });
                    setLocation(data);
                } else {
                    setLocation([]);
                    // window.location = '/error';
                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        } else {
            setLocation([]);
        }
    }, [apps]);



    // load push message by id
    useEffect(() => {
        if (push_id) {
            singleGeoMessage(push_id).then((response) => {
                if (response.status === 200) {
                    console.log(response.data);
                    const pushMessage = response.data[0];
                    console.log(pushMessage, 'pushMessage');
                    if (pushMessage.app_id) {
                        setApps(pushMessage.app_id);
                        setInputs(values => ({ ...values, app_id: pushMessage.app_id }));
                    }
                    if (pushMessage.location_ids && pushMessage.location_names) {
                        const locIds = pushMessage.location_ids.split(',');
                        const locNames = pushMessage.location_names.split(',');
                        let data = [];
                        locIds.map((loc, index) => {
                            data.push({ value: loc, label: locNames[index] });
                        });
                        setSelectedLocations(data);
                        setInputs(values => ({ ...values, location_id: locIds }));
                    }
                    if (pushMessage.messageType) {
                        const mType = messageTypes.find(mt => mt.alias === pushMessage.messageType || mt.name === pushMessage.messageType);
                        setMessageType(mType.id);
                        setInputs(values => ({ ...values, message_type: mType.alias }));
                    }
                    // if (pushMessage.vehicle_type) {
                    //     console.log(pushMessage.vehicle_type, 'pushMessage.vehicle_type');
                    //     const vType = vehicleTypes.find(vt => vt.alias === pushMessage.vehicle_type || vt.name === pushMessage.vehicle_type);
                    //     console.log(vType, 'vType');
                    //     setSelectedVehicleType(vType.id);
                    //     setInputs(values => ({ ...values, vehicle_type: vType.alias }));
                    // }
                    // if (pushMessage.vehicle_year) {
                    //     console.log(pushMessage.vehicle_year, 'pushMessage.vehicle_year');
                    //     let data = [];
                    //     pushMessage.vehicle_year.map((year, index) => {
                    //         data.push({ value: index, label: year });
                    //     });
                    //     setSelectedVehicleYear(data);
                    //     setInputs(values => ({ ...values, vehicle_year: pushMessage.vehicle_year }));
                    // }
                    // if (pushMessage.vehicle_make) {
                    //     let data = [];
                    //     pushMessage.vehicle_make.map((make, index) => {
                    //         data.push({ value: index, label: make });
                    //     });
                    //     setSelectedVehicleMake(data);
                    //     setInputs(values => ({ ...values, vehicle_make: pushMessage.vehicle_make }));
                    // }
                    // if (pushMessage.vehicle_model) {
                    //     let data = [];
                    //     pushMessage.vehicle_model.map((model, index) => {
                    //         data.push({ value: index, label: model });
                    //     });
                    //     setSelectedVehicleModel(data);
                    //     setInputs(values => ({ ...values, vehicle_model: pushMessage.vehicle_model }));
                    // }
                    if (pushMessage.vehicle_type) {
                        console.log(pushMessage.vehicle_type, 'pushMessage.vehicle_type');
                        const vType = vehicleTypes.find(vt => vt.alias === pushMessage.vehicle_type || vt.name === pushMessage.vehicle_type);
                        console.log(vType, 'vType');
                        setSelectedVehicleType(vType.id);
                        setInputs(values => ({ ...values, vehicle_type: vType.alias }));
                    }
                    if (pushMessage.vehicle_year) {
                        const vYear = pushMessage.vehicle_year.split(',');
                        const vYears = vYear.map((vy, index) => ({ value: index, label: vy }));
                        setSelectedVehicleYear(vYears);
                        setInputs(values => ({ ...values, vehicle_year: vYear }));
                    }
                    if (pushMessage.vehicle_make) {
                        const vMake = pushMessage.vehicle_make.split(',');
                        const vMakes = vMake.map((vm, index) => ({ value: index, label: vm }));
                        setSelectedVehicleMake(vMakes);
                        setInputs(values => ({ ...values, vehicle_make: vMake }));
                    }
                    if (pushMessage.vehicle_model) {
                        const vModel = pushMessage.vehicle_model.split(',');
                        const vModels = vModel.map((vm, index) => ({ value: index, label: vm }));
                        setSelectedVehicleModel(vModels);
                        setInputs(values => ({ ...values, vehicle_model: vModel }));
                    }
                    if (pushMessage.latitude) {
                        setLatitude(pushMessage.latitude);
                        setInputs(values => ({ ...values, latitude: pushMessage.latitude }));
                    }
                    if (pushMessage.longitude) {
                        setLongitude(pushMessage.longitude);
                        setInputs(values => ({ ...values, longitude: pushMessage.longitude }));
                    }
                    if (pushMessage.release_date) {
                        setReleaseDate(moment(pushMessage.release_date));
                        setInputs(values => ({ ...values, release_date: pushMessage.release_date }));
                    }
                    if (pushMessage.expiry_date) {
                        setExpiryDate(moment(pushMessage.expiry_date));
                        setInputs(values => ({ ...values, expiry_date: pushMessage.expiry_date }));
                    }
                    if (pushMessage.display_image_path) {
                        setInputs(values => ({ ...values, display_image_path: pushMessage.display_image_path }));
                        setInputs(values => ({ ...values, old_image_path: pushMessage.display_image_path }));
                    }
                    if (pushMessage.attachment_file_path) {
                        setInputs(values => ({ ...values, attachment_file_path: pushMessage.attachment_file_path }));
                        setInputs(values => ({ ...values, old_attachment_path: pushMessage.attachment_file_path }));
                    }

                    setInputs(values => ({ ...values, id: pushMessage.id }));
                    setInputs(values => ({ ...values, image_id: pushMessage.image_id }));
                    setInputs(values => ({ ...values, attachment_id: pushMessage.attachment_id }));
                    setInputs(values => ({ ...values, subject: pushMessage.subject }));
                    setInputs(values => ({ ...values, message: pushMessage.message }));
                    setInputs(values => ({ ...values, terms_and_conditions: pushMessage.terms_and_conditions }));
                    setInputs(values => ({ ...values, release_date: pushMessage.release_date }));
                    setInputs(values => ({ ...values, release_time: pushMessage.release_time }));
                    setInputs(values => ({ ...values, expiry_date: pushMessage.expiry_date }));
                    setInputs(values => ({ ...values, range: pushMessage.range }));
                    setInputs(values => ({ ...values, label: pushMessage.label }));
                    setInputs(values => ({ ...values, coupon_code: pushMessage.coupon_code }));
                    setInputs(values => ({ ...values, vehicle_vins: pushMessage.vehicle_vins }));
                    setInputs(values => ({ ...values, vehicle_stock_numbers: pushMessage.vehicle_stock_numbers }));

                } else {
                    setInputs([]);
                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        } else {
            setInputs([]);
        }
    }, [push_id]);




    // check to see if has inventory
    useEffect(() => {
        if (selectedLocations && selectedLocations.length > 0) {
            const selectedLocs = selectedLocations.map(sl => sl.value);
            console.log(selectedLocs, 'selectedLocs');
            vehicleCountByLocation(selectedLocs).then((response) => {
                if (response.status === 200) {
                    setHasInventory(true);
                } else {
                    setHasInventory(false);
                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        } else {
            setHasInventory(false);
        }
    }, [selectedLocations]);


    console.log(selectedLocations, 'selectedLocations');
    // load all vehicles by type & location
    useEffect(() => {
        if (selectedLocations.length > 0 && selectedVehicleType) {
            const selectedLocs = selectedLocations.map(sl => sl.value);
            const vType = vehicleTypes.find(vt => vt.id === parseInt(selectedVehicleType));
            const vAlias = vType.alias;
            vehicleYearByTypeAndLocation(selectedLocs, vAlias).then((response) => {
                if (response.status === 200) {
                    let data = []
                    response.data.map((year, index) => {
                        data.push({ value: index, label: year.year });
                    });
                    setVehicleYear(data);
                } else {
                    setVehicleYear([]);
                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        } else {
            setVehicleYear([]);
        }
    }, [selectedLocations, selectedVehicleType]);



    // load all vehicles by type, year & location
    useEffect(() => {
        if (selectedLocations.length > 0 && selectedVehicleType && selectedVehicleYear.length > 0) {
            const selectedLocs = selectedLocations.map(sl => sl.value);
            const vType = vehicleTypes.find(vt => vt.id === parseInt(selectedVehicleType));
            const vAlias = vType.alias;
            const vYear = selectedVehicleYear.map(vy => vy.label);
            console.log(vYear, 'year');
            vehicleMakeByYearAndTypeAndLocation(selectedLocs, vAlias, vYear).then((response) => {
                if (response.status === 200) {
                    let data = []
                    response.data.map((make, index) => {
                        data.push({ value: index, label: make.make });
                    });
                    setVehicleMake(data);
                } else {
                    setVehicleMake([]);
                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        } else {
            setVehicleMake([]);
        }
    }, [selectedLocations, selectedVehicleType, selectedVehicleYear]);




    // load all vehicles by type, year, make & location
    useEffect(() => {
        if (selectedLocations.length > 0 && selectedVehicleType && selectedVehicleYear.length > 0 && selectedVehicleMake.length > 0) {
            const selectedLocs = selectedLocations.map(sl => sl.value);
            const vType = vehicleTypes.find(vt => vt.id === parseInt(selectedVehicleType));
            const vAlias = vType.alias;
            const vYear = selectedVehicleYear.map(vy => vy.label);
            const vMake = selectedVehicleMake.map(vm => vm.label);
            vehicleModelByMakeAndYearAndTypeAndLocation(selectedLocs, vAlias, vYear, vMake).then((response) => {
                if (response.status === 200) {
                    let data = []
                    response.data.map((model, index) => {
                        data.push({ value: index, label: model.model });
                    });
                    setVehicleModel(data);
                } else {
                    setVehicleModel([]);
                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        } else {
            setVehicleModel([]);
        }
    }, [selectedLocations, selectedVehicleType, selectedVehicleYear, selectedVehicleMake]);


    // logs out a user
    const handleLogout = async () => {
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location = "/";
    };


    // handles toast closing
    const handleCloseToast = () => {
        setShowToast(false);
    };

    // handle user input
    const handleChange = (event) => {
        const name = event.target.name;
        let value;
        let filen;
        if (event.target.type === 'select') {
            value = document.getElementById(name).value;
            setInputs(values => ({ ...values, [name]: value }));
            if (name === "app_id") {
                setApps(value);
            }
        } else if (event.target.type === 'select-one') {
            value = document.getElementById(name).value;
            setInputs(values => ({ ...values, [name]: value }));
            const selectedIndex = event.target.selectedIndex;
            if (name === "app_id") {
                if (selectedIndex === 0) {
                    setLocation([]);
                }
                setApps(value);
            }
            else if (name === "message_type") {
                if (selectedIndex === 0) {
                    setMessageType(null);
                }
                else {
                    const mType = messageTypes.find(mt => mt.id === parseInt(value));
                    setMessageType(parseInt(value));
                    setInputs(values => ({ ...values, [name]: mType.alias }));
                }
            }
            else if (name === "vehicle_type") {
                if (selectedIndex === 0) {
                    setSelectedVehicleType(null);
                }
                setSelectedVehicleType(value);
                const vType = vehicleTypes.find(vt => vt.id === parseInt(value));
                setInputs(values => ({ ...values, [name]: vType.alias }));
                console.log(vType.alias);
            }
        }
        else if (event.target.type === 'file') {
            if (name === "display_image_file") {
                filen = event.target.files[0];
                setInputs(values => ({ ...values, [name]: filen }));
                setInputs(values => ({ ...values, display_image_file_name: filen.name }));
                setInputs(values => ({ ...values, display_image_content_type: filen.type }));
                setInputs(values => ({ ...values, display_image_file_size: filen.size }));
            }
            else if (name === "attachment_file") {
                filen = event.target.files[0];
                setInputs(values => ({ ...values, [name]: filen }));
                setInputs(values => ({ ...values, attachment_file_name: filen.name }));
                setInputs(values => ({ ...values, attachment_content_type: filen.type }));
                setInputs(values => ({ ...values, attachment_file_size: filen.size }));
                setFile(event.target.files[0]);
            }
        }
        else if (event.target.type === 'text' || event.target.type === 'textarea') {
            value = event.target.value;
            setInputs(values => ({ ...values, [name]: value }));
            if (name === "address") {
                console.log(value, 'address');
                setAddress(value);
            }
            else if (name === "latitude") {
                setLatitude(value);
            }
            else if (name === "longitude") {
                setLongitude(value);
            }
        }
        else {
            value = event.target.value;
            setInputs(values => ({ ...values, [name]: value }));
        }
    }


    console.log(inputs);


    // on selecting location
    const onSelectedLocation = (data) => {
        setSelectedLocations(data);
        setInputs(values => ({ ...values, location_id: data.map(c => c.value) }));
    }


    // on selecting year
    const onSelectedYear = (data) => {
        setSelectedVehicleYear(data);
        setInputs(values => ({ ...values, vehicle_year: data }));
    }


    // on selecting make
    const onSelectedMake = (data) => {
        setSelectedVehicleMake(data);
        setInputs(values => ({ ...values, vehicle_make: data }));
    }

    // on selecting model
    const onSelectedModel = (data) => {
        setSelectedVehicleModel(data);
        setInputs(values => ({ ...values, vehicle_model: data }));
    }

    // set release date
    const setReleaseDateFunc = (releaseDate) => {
        console.log(releaseDate);
        setReleaseDate(releaseDate)
        setInputs(values => ({ ...values, release_date: releaseDate.format('YYYY-MM-DD') }))
        setInputs(values => ({ ...values, release_time: releaseDate.format('YYYY-MM-DD hh:mm:ss') }))
    };


    // set expiry date
    const setExpireDateFunc = (expiryDate) => {
        console.log(expiryDate);
        setExpiryDate(expiryDate)
        setInputs(values => ({ ...values, expiry_date: expiryDate.format('YYYY-MM-DD') }))
    };



    // handles form submit
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!inputs.app_id) {
            toast.error("Please select an App", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
            });
            return;
        }
        if (!inputs.location_id) {
            toast.error("Please select a Location", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
            });
            return;
        }
        if (!inputs.message_type) {
            toast.error("Please select a Message Type", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
            });
            return;
        }
        if (!inputs.label) {
            toast.error("Please select a Label", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
            });
            return;
        }
        if (!inputs.latitude) {
            toast.error("Please select a Latitude", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
            });
            return;
        }
        if (!inputs.longitude) {
            toast.error("Please select a Longitude", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 3000,
            });
            return;
        }
        setSubmitting(true);
        updatePushMessage(inputs, push_id).then((response) => {
            if (response.status === 201) {
                setSubmitting(false);
                toast.success("Push Message updated successfully", {
                    position: toast.POSITION.TOP_CENTER,
                    onClose: () => {
                        window.location.reload();
                    }
                });
            } else {
                setSubmitting(false);
                toast.error("Error updating Push Message", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 3000,
                });
            }
        }).catch(err => {
            errorHandler(err, setSessionModal, setShowToast, toast);
        });
    };



    // file types
    const getFileTypeIcon = (fileType) => {
        console.log(fileType);
        const fileIcons = {
            pdf: 'pdf-logo.png',
            docx: 'docx-logo.png',
            txt: 'txt-logo.png',
            xlsx: 'xlsx-logo.png',
            csv: 'csv-logo.png',
            pptx: 'pptx-logo.png',
            png: 'image-logo.png',
            jpg: 'image-logo.png',
            jpeg: 'image-logo.png',
            PNG: 'image-logo.png',
            // Add more mappings as needed
        };

        const fileName = fileIcons[fileType] || 'general-logo.png';

        const fileIcon = require('../../assets/img/' + fileName);

        return fileIcon;
    };



    useEffect(() => {
        const getLatLong = async (address) => {
            if (!address) return;

            Geocode.setApiKey("AIzaSyBpTxl8mi_iI0CnYz-Hg2mr6X7WA-9nsQU")
            Geocode.setLanguage('en')
            Geocode.setRegion('us')
            Geocode.setLocationType('ROOFTOP')
            Geocode.enableDebug()

            setTimeout(() => {
                Geocode.fromAddress(address).then(
                    response => {
                        const { lat, lng } = response.results[0].geometry.location
                        console.log(lat, lng);
                        setInputs(values => ({ ...values, latitude: lat }));
                        setInputs(values => ({ ...values, longitude: lng }));
                        setLatitude(lat);
                        setLongitude(lng);
                    }
                ).catch(err => {
                    console.log(err);
                })
            }, 2000);
        }

        getLatLong(address);
    }, [address]);


    console.log(inputs, 'inputs')


    return (
        <div className="col-xl-12">
            {submitting &&
                <div>Submtting Form...</div>
            }
            {showToast && <div className="toast-overlay"></div>}
            <ToastContainer onClose={handleCloseToast} />
            <a href={backUrl} className="btn btn-sm rounded-pill btn-secondary">Back</a>  <a href="/communication" className="btn btn-sm rounded-pill btn-secondary">Communication Home</a> <a href={currentUrl} className="btn btn-sm rounded-pill btn-secondary">Add Push Message</a>
            <SessionExpiredModal
                show={sessionModal}
                handleLogout={handleLogout}
            />
            {submitting && <AddUpdateLoading show={submitting} />}
            <form onSubmit={handleSubmit} >
                <div className="card mb-4">
                    <h4 className="card-header">Editing Push Message</h4>
                    <div className="card-body">
                        <h5>Message Info</h5>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Apps<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-10">
                                <select className="form-select" id="app_id" name="app_id" onChange={handleChange} value={apps || ''} >
                                    <option key='' value="">--Select Apps--</option>
                                    {applist && applist.length > 0 && applist.map((appObj) => (
                                        <option key={appObj.id} value={appObj.id}>{appObj.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Location<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-10">
                                <MultiSelect
                                    options={location}
                                    value={selectedLocations}
                                    onChange={onSelectedLocation}
                                    labelledBy={"Select Location"}
                                />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Message Type<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-10">
                                <select className="form-select" id="message_type" name="message_type" onChange={handleChange} value={messageType || ''} >
                                    <option key='' value="">--Select Message Type--</option>
                                    {messageTypes && messageTypes.length > 0 && messageTypes.map((msgObj) => (
                                        <option key={msgObj.id} value={msgObj.id}>{msgObj.name}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="mb-3 row" style={{ height: '15px' }}>
                        </div>
                        <h5>Message Details</h5>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Subject<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-10">
                                <input className="form-control" type="text" id="subject" name="subject" onChange={handleChange} value={inputs.subject || ''} required />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Message<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-10">
                                <textarea className="form-control" type="text" id="message" name="message" onChange={handleChange} value={inputs.message || ''} required />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Terms And Conditions</label>
                            <div className="col-md-10">
                                <textarea className="form-control" type="text" id="terms_and_conditions" name="terms_and_conditions" onChange={handleChange} value={inputs.terms_and_conditions || ''} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="col-md-2 col-form-label">Preview</label>
                            <div className="col-md-10">
                                <span >
                                    {inputs.image_id ?
                                        <img src={inputs.display_image_file ? URL.createObjectURL(inputs.display_image_file) : (inputs.display_image_path ? inputs.display_image_path : `http://cloud.dealerappvantage.com/api/Images/${inputs.image_id}`)} height="auto" width="150" alt='med1' />
                                        :
                                        <img src={inputs.display_image_file ? URL.createObjectURL(inputs.display_image_file) : inputs.display_image_path} height="auto" width="150" alt="med1" />
                                    }
                                </span>
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-password-input" className="col-md-2 col-form-label">Display Image</label>
                            <div className="mb-3 col-md-10">
                                <label htmlFor="formFile" className="form-label">Upload Image</label>
                                <input className="form-control" type="file" id="display_image_file" name="display_image_file" onChange={handleChange} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="col-md-2 col-form-label">Preview</label>
                            <div className="col-md-10">
                                <span>
                                    {inputs.attachment_id ?
                                        <img src={file ? getFileTypeIcon(file.name.split('.').pop()) : (inputs.attachment_file_path ? getFileTypeIcon(inputs.attachment_file_path.split('.').pop()) : getFileTypeIcon(`http://cloud.dealerappvantage.com/api/Files/${inputs.attachment_id}`.split('.').pop()))} height="auto" width="150" alt='attachment' />
                                        :
                                        <img src={file ? getFileTypeIcon(file.name.split('.').pop()) : (inputs.attachment_file_path ? getFileTypeIcon(inputs.attachment_file_path.split('.').pop()) : 'no-image-found.gif')} height="auto" width="150" alt="" />
                                    }
                                </span>
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-password-input" className="col-md-2 col-form-label">Attachment File</label>
                            <div className="mb-3 col-md-10">
                                <label htmlFor="formFile" className="form-label">Upload File</label>
                                <input className="form-control" type="file" id="attachment_file" name="attachment_file" onChange={handleChange} />
                            </div>
                        </div>
                        <div className="mb-3 row" style={{ height: '15px' }}>
                        </div>
                        <h5>Geo Fencing Options</h5>
                        <MapComponent
                            lat={latitude}
                            lng={longitude}
                            setLng={setLatitude}
                            setLat={setLongitude}
                        />
                        <div className="mb-3 row" style={{ paddingTop: '30px' }}>
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label" required>Label<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                            <div className="col-md-10">
                                <input className="form-control" type="text" id="label" name="label" onChange={handleChange} value={inputs.label || ''} placeholder="Add a message label" />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="col-md-2 col-form-label">Address</label>
                            <div className="col-md-10">
                                <input className="form-control" type="text" id="address" name="address" onChange={handleChange} value={inputs.address || ''} placeholder="Get Latitude and Longitude by Address" />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="col-md-2 col-form-label">Latitude</label>
                            <div className="col-md-10">
                                <input className="form-control" type="text" id="latitude" name="latitude" onChange={handleChange} value={inputs.latitude || ''} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="col-md-2 col-form-label">Longitude</label>
                            <div className="col-md-10">
                                <input className="form-control" type="text" id="longitude" name="longitude" onChange={handleChange} value={inputs.longitude || ''} />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label className="col-md-2 col-form-label">Range</label>
                            <div className="col-md-10">
                                <input className="form-control" type="text" id="range" name="range" onChange={handleChange} value={inputs.range || ''} />
                            </div>
                        </div>
                        <div className="mb-3 row" style={{ height: '15px' }}>
                        </div>
                        {messageType === 2 ?
                            <div>
                                <h5>Sales Message Options</h5>
                                {hasInventory ?
                                    <div>
                                        <div className="mb-3 row">
                                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Vehicle Type</label>
                                            <div className="col-md-10">
                                                <select className="form-select" id="vehicle_type" name="vehicle_type" onChange={handleChange} value={selectedVehicleType || ''} >
                                                    <option key='' value="">--Select Vehicle Type--</option>
                                                    {vehicleTypes && vehicleTypes.length > 0 && vehicleTypes.map((vehicleObj) => (
                                                        <option key={vehicleObj.id} value={vehicleObj.id}>{vehicleObj.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Vehicle Year</label>
                                            <div className="col-md-10">
                                                <MultiSelect
                                                    options={vehicleYear}
                                                    value={selectedVehicleYear}
                                                    onChange={onSelectedYear}
                                                    labelledBy={"Select Year"}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Vehicle Make</label>
                                            <div className="col-md-10">
                                                <MultiSelect
                                                    options={vehicleMake}
                                                    value={selectedVehicleMake}
                                                    onChange={onSelectedMake}
                                                    labelledBy={"Select Make"}
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Vehicle Model</label>
                                            <div className="col-md-10">
                                                <MultiSelect
                                                    options={vehicleModel}
                                                    value={selectedVehicleModel}
                                                    onChange={onSelectedModel}
                                                    labelledBy={"Select Model"}
                                                />
                                            </div>
                                        </div>
                                    </div> : null}
                                <div className="mb-3 row">
                                    <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Vehicle VINs</label>
                                    <div className="col-md-10">
                                        <input className="form-control" type="text" id="vehicle_vins" name="vehicle_vins" onChange={handleChange} value={inputs.vehicle_vins || ''} />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Vehicle Stock Numbers</label>
                                    <div className="col-md-10">
                                        <input className="form-control" type="text" id="vehicle_stock_numbers" name="vehicle_stock_numbers" onChange={handleChange} value={inputs.vehicle_stock_numbers || ''} />
                                    </div>
                                </div>
                            </div> : null}
                        <div className="mb-3 row" style={{ height: '15px' }}>
                        </div>
                        {messageType === 3 ?
                            <div>
                                <h5>Service Message Options</h5>
                                <div className="mb-3 row">
                                    <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Coupon Code</label>
                                    <div className="col-md-10">
                                        <input className="form-control" type="text" id="coupon_code" name="coupon_code" onChange={handleChange} value={inputs.coupon_code || ''} />
                                    </div>
                                </div>
                            </div> : null}
                        <div className="mb-3 row" style={{ height: '15px' }}>
                        </div>
                        <h5>Message Schedule</h5>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Release Date</label>
                            <div className="col-md-10">
                                <Datetime
                                    dateFormat="MM/DD/YYYY"
                                    timeFormat={true}
                                    onChange={setReleaseDateFunc}
                                    value={inputs.release_time ? moment(inputs.release_time) : ''}
                                    inputProps={{ placeholder: 'MM/DD/YYYY' }}
                                    name="release_date"
                                    id="release_date"
                                    required
                                />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Expire</label>
                            <div className="col-md-10">
                                <Datetime
                                    dateFormat="MM/DD/YYYY"
                                    timeFormat={true}
                                    onChange={setExpireDateFunc}
                                    value={inputs.expiryDate ? moment(inputs.expiryDate) : ''}
                                    inputProps={{ placeholder: 'MM/DD/YYYY' }}
                                    name="expiry_date"
                                    id="expiry_date"
                                    required
                                />
                            </div>
                        </div>
                        <div className="mb-3 row">
                            <div className="col-md-3">&nbsp;</div>
                            <div className="col-md-7"><input type="submit" className="btn btn-primary col-lg-10" value="Save" /></div>
                            <div className="col-md-2">&nbsp;</div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};


export default EditGeoPushMessage;