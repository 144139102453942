import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const SessionExpiredModal = ({ show, handleClose, handleLogout }) => {
  return (
    <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
      <Modal.Header>
        <Modal.Title>Session Expired</Modal.Title>
      </Modal.Header>
      <Modal.Body>Your session has expired. Please log in again.</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleLogout}>
          Log In
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SessionExpiredModal;
