import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from 'moment';
import { useEffect, useState } from 'react';

const DateTimeField = ({ startDate, setStartDate, endDate, setEndDate }) => {
  const [startDateKey, setStartDateKey] = useState(0);
  const [endDateKey, setEndDateKey] = useState(0);

  const handleStartDateChange = (startDate) => {
    setStartDate(startDate);
  };

  const handleEndDateChange = (endDate) => {
    let enDate = endDate;
    if (endDate === moment()) {
      enDate = moment().format('MM/DD/YYYY HH:mm:ss');
    }

    const currentDate = new Date();
    const selectedMoment = enDate.toDate();
    const updatedDate = new Date(
      selectedMoment.getFullYear(),
      selectedMoment.getMonth(),
      selectedMoment.getDate(),
      currentDate.getHours(),
      currentDate.getMinutes(),
      currentDate.getSeconds()
    );
    setEndDate(updatedDate);
  };

  const isValidStartDate = (current) => {
    const currentDate = moment();
    const isBeforeEndDate = current.isBefore(endDate) || !endDate;
    const isBeforeCurrentDate = current.isSameOrBefore(currentDate);
    return isBeforeCurrentDate && isBeforeEndDate;
  };

  const isValidEndDate = (current) => {
    const currentDate = moment();
    const isAfterStartDate = current.isAfter(startDate) || !startDate;
    const isBeforeCurrentDate = current.isSameOrBefore(currentDate);
    return isAfterStartDate && isBeforeCurrentDate;
  };

  useEffect(() => {
    setStartDateKey((prevKey) => prevKey + 1);
  }, [startDate]);

  useEffect(() => {
    setEndDateKey((prevKey) => prevKey + 1);
  }, [endDate]);

  return (
    <div className='row mb-3'>
      <div style={{ width: '150px', height: "40px" }}>
        <Datetime
          className="me-2"
          key={`startDate-${startDateKey}`}
          value={startDate || 'Start Date'}
          onChange={handleStartDateChange}
          closeOnSelect={true}
          isValidDate={isValidStartDate}
        />
      </div>
      <div style={{ width: '150px', height: "40px" }}>
        <Datetime
          className="me-2"
          key={`endDate-${endDateKey}`}
          value={endDate || 'End Date'}
          onChange={handleEndDateChange}
          closeOnSelect={true}
          isValidDate={isValidEndDate}
        />
      </div>
    </div>
  );
};

export default DateTimeField;
