import React from "react";

const LeftSidebar = () => {
    const user = JSON.parse(localStorage.getItem('user'));
    let admin = false;
    let isUser = false;
    let role;
    if (user) {
        role = user.role;

        admin = role === 'administrator' ? true : false;
        console.log(user);
        isUser = user !== null ? true : false;
    }

    // check if 693 is in the array
    let isRickCase = false;
    // if (applist.length > 0 && applist.some(item => item.id === 693)) {
    //     isRickCase = true;
    // }
    const rickCaseUsers = [1711, 1712, 1714, 1719, 1729, 1734, 1735, 1736, 1737, 1738, 1739, 1740, 1741, 1742, 1743, 1744, 1745, 1746, 1747, 1748, 1749, 1750, 1751, 1752, 1759];
    if (user?.id && rickCaseUsers.indexOf(user.id) > -1) {
        isRickCase = true;
    }

    console.log('isRickCase', isRickCase);

    return (
        <div>
            <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
                <div className="app-brand demo">
                    <a href={"/#"} className="app-brand-link">
                        <span className="app-brand-logo demo da-logo">
                            <img src={require("../../assets/img/logo.png")} alt='logo' />
                        </span>
                        <span className="app-brand-text demo menu-text fw-bolder ms-2">DA</span>
                    </a>

                    <a className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none" href="/#">
                        <i className="bx bx-chevron-left bx-sm align-middle"></i>
                    </a>
                </div>

                {isUser ?
                    <div>
                        <div className="menu-inner-shadow"></div>

                        <ul className="menu-inner py-1">

                            <li className="menu-item active">
                                <a href={"/"} className="menu-link">
                                    <i className="menu-icon tf-icons bx bx-home-circle"></i>
                                    <div data-i18n="Analytics">Dashboard</div>
                                </a >
                            </li >
                            {
                                (role === 'administrator' || role === 'client') &&
                                <li className="menu-item active">
                                    <a href={"/analytics"} className="menu-link">
                                        <i className="menu-icon tf-icons bx bxs-bar-chart-alt-2"></i>
                                        <div data-i18n="Analytics">Analytics</div>
                                    </a>
                                </li>
                            }
                            {
                                admin &&
                                <li className="menu-item active">
                                    <a href={"/app_management_center"} className="menu-link">
                                        <i className="menu-icon tf-icons bx bx-qr"></i>
                                        <div data-i18n="Apps">App Mgt. Center</div>
                                    </a>
                                </li>
                            }
                            {admin &&
                                <li className="menu-item active">
                                    <a href={"/clients"} className="menu-link">
                                        <i className="menu-icon tf-icons bx bx-qr"></i>
                                        <div data-i18n="Clients">Client Mgt. Center</div>
                                    </a>
                                </li>
                            }
                            {
                                (role === 'administrator' || role === 'client') &&
                                <li className="menu-item active">
                                    <a href={"/communication"} className="menu-link">
                                        <i className="menu-icon tf-icons bx bx-message-rounded-dots"></i>
                                        <div data-i18n="Analytics">Communication</div>
                                    </a>
                                </li>
                            }
                            {
                                (role === 'administrator' || role === 'client') &&
                                <li className="menu-item active">
                                    <a href={"/customer_profiles"} className="menu-link">
                                        <i className="menu-icon tf-icons bx bx-task"></i>
                                        <div data-i18n="Analytics">Customer Profiles</div>
                                    </a>
                                </li>
                            }
                            {
                                (role === 'administrator' || role === 'client') &&
                                <li className="menu-item active">
                                    <a href={"/departments"} className="menu-link">
                                        <i className="menu-icon tf-icons bx bx-layer"></i>
                                        <div data-i18n="Departments">Departments</div>
                                    </a>
                                </li>
                            }
                            {
                                admin &&
                                <li className="menu-item active">
                                    <a href={"/locations"} className="menu-link">
                                        <i className="menu-icon tf-icons bx bx-qr"></i>
                                        <div data-i18n="Locations">Locations</div>
                                    </a>
                                </li>
                            }
                            {
                                (role === 'administrator' || isRickCase || role === 'client') &&
                                <li className="menu-item active">
                                    <a href={"/rewards"} className="menu-link">
                                        <i className="menu-icon tf-icons bx bx-gift"></i>
                                        <div data-i18n="Analytics">Loyalty Rewards</div>
                                    </a>
                                </li>
                            }
                            {
                                (role === 'administrator' || role === 'client') &&
                                <li className="menu-item active">
                                    <a href={"/staffs"} className="menu-link">
                                        <i className="menu-icon tf-icons bx bx-user-circle"></i>
                                        <div data-i18n="Staff">Staff Management</div>
                                    </a>
                                </li>
                            }
                            {
                                role === 'administrator' &&
                                <li className="menu-item active">
                                    <a href="/customization" className="menu-link ">
                                        <i className="menu-icon tf-icons bx bx-shape-polygon"></i>
                                        <div data-i18n="Customization">Customization</div>
                                    </a>

                                </li>
                            }
                            {
                                role === 'administrator' &&
                                <li className="menu-item active">
                                    <a href={"/features"} className="menu-link">
                                        <i className="menu-icon tf-icons bx bxs-extension"></i>
                                        <div data-i18n="Analytics">Feature</div>
                                    </a>
                                </li>
                            }
                            {
                                (role === 'administrator' || role === 'client') &&
                                <li className="menu-item active">
                                    <a href={"/help"} className="menu-link">
                                        <i className="menu-icon tf-icons bx bxs-help-circle"></i>
                                        <div data-i18n="Analytics">Help</div>
                                    </a>
                                </li>
                            }
                            {
                                (role === 'administrator' || role === 'client') &&
                                <li className="menu-item active">
                                    <a href={"/marketing"} className="menu-link">
                                        <i className="menu-icon tf-icons bx bxs-crown"></i>
                                        <div data-i18n="Marketing">Marketing</div>
                                    </a>
                                </li>
                            }
                        </ul >
                    </div >
                    :
                    null
                }
            </aside >
        </div >
    );
}

export default LeftSidebar;
