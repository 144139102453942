import React from "react";
import {useState, useEffect} from "react";
import AddDepartmentModal from "./AddDepartmentModal";
import { toast, ToastContainer } from "react-toastify";
import SessionExpiredModal from "../SessionExpire/SessionExpiredModal";
import DeleteDepartmentModal from "./DeleteDepartmentModal";
import EditDepartmentModal from "./EditDepartmentModal";
import DragDropModal from "./DragDropModal";
import { errorHandler } from "../Utils/errorHandler";
import { getDepartmentCount, getDepartments } from "../../apis/DepartmentAPI";
import { locationData } from "../../apis/LocationAPI";
import { appData } from "../../apis/AppsAPI";
import AppsDropDownComponent from "../PageTopComponents/AppDropDown";
import LocationsDropDownComponent from "../PageTopComponents/LocationDropDown";
import PaginationComponent from "../Pagination/Pagination";
import SortDepartment from "./SortDepartment";
import Loading from "../Utils/Loading";
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';


const Department = () => {
    const [departments, setDepartments] = useState([]);
    const [applist, setApplist] = useState([]);
    const [location, setLocation] = useState([]);
    const [numberOfRows, setNumberOfRows] = useState(0);
    const [numberOfPages, setNumberOfPages] = useState(0);
    const [deptPerPage] = useState(30);
    const [isLoading, setIsLoading] = useState(true);
    const [sessionModal, setSessionModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteItem, setDeleteItem] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [editOrder, setEditOrder] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showOrderModal, setShowOrderModal] = useState(false);

    const navigate = useNavigate();
    const search = useLocation().search;
    const queryParams = new URLSearchParams(search);
    const app = queryParams.get('app_id') ? parseInt(queryParams.get('app_id')) : null;
    const locs = queryParams.get('loc_id') ? parseInt(queryParams.get('loc_id')) : null;
    const searchstr = queryParams.get('searchkey') ? queryParams.get('searchkey') : null;
    const currentP = queryParams.get('page') ? parseInt(queryParams.get('page')) : 1;
    const srt = queryParams.get('sort') ? parseInt(queryParams.get('sort')) : "3";

    const [apps, setApps] = useState(app);
    const [loc, setLoc] = useState(locs);
    const [searchkey, setSearchkey] = useState(searchstr);
    const [currentPage, setCurrentPage] = useState(currentP);
    const [sort, setSort] = useState(srt);

    // load all apps
    useEffect(() => {
        const apps = appData();
        if (apps.length === 1){
            setApps(apps[0]?.id);
            setApplist(apps);
        }
        else if(apps.length>1){
            setApplist(apps);
        }
        else{
            setApplist([]);
        };
    }, []);


    // load all locations by app
    useEffect(() => {    
        if(apps){
            locationData(apps).then((response) =>{ 
                if(response.status === 200){
                    setLocation(response.data);
                }else{
                    setLocation([]);
                    window.location = '/error';
                };
            }).catch(err => {
                    errorHandler(err, setSessionModal, setShowToast, toast);
            });
        }else{
            setLocation([]);
        };
    }, [apps]);


    // load departments count
    useEffect(() => {
        getDepartmentCount(apps, loc, searchkey, sort).then((response) => {
            if(response.status === 200){
                setNumberOfRows(response.data[0].department_count);
                setNumberOfPages(Math.ceil(response.data[0].department_count / deptPerPage));
                setIsLoading(false);
            }else{
                setNumberOfRows(0);
                setNumberOfPages(0);
                setIsLoading(false);
                window.location = '/error';
            };
        }).catch((error) => {
            errorHandler(error, setSessionModal, setShowToast, toast);
        });
    }, [apps, loc, searchkey, deptPerPage, sort]);


    // load departments
    useEffect(() => {
        console.log(currentPage)
        getDepartments(apps, loc, deptPerPage, searchkey, currentPage, sort).then((response) => {
            console.log(currentPage)
            if(response.status === 200){
                setDepartments(response.data);
                setIsLoading(false);
            }else{
                setDepartments([]);
                setIsLoading(false);
                window.location = '/error';
            };
        }).catch((error) => {
            errorHandler(error, setSessionModal, setShowToast, toast);
        });
    }, [apps, loc, deptPerPage, searchkey, currentPage, sort]);


    useEffect(() => {    
        // Check if currentPage is greater than numberOfPage
        if (numberOfPages > 0 && currentPage > numberOfPages) {
            setCurrentPage(1);
        }
        
        // Update the URL query params when the state of the hook changes
    
        const updateQueryParams = () => {
            const queryParams = new URLSearchParams();
            if (apps) queryParams.set('app_id', apps);
            if (loc) queryParams.set('loc_id', loc);
            if (searchkey) queryParams.set('searchkey', searchkey);
            if (currentPage) queryParams.set('page', currentPage);
            if (sort) queryParams.set('sort', sort)
            const queryString = queryParams.toString();
            const newUrl = `${window.location.pathname}?${queryString}`;
            window.history.pushState({ path: newUrl }, '', newUrl);
        };

        updateQueryParams();
    }, [apps, loc, searchkey, currentPage, numberOfPages, sort]);

    console.log(navigate)


    const handleChange = (event) => {
        setCurrentPage(1);

        const name = event.target.name;
        let value;

        if(event.target.type === "select-one"){
            let selectedIndex =event.target.selectedIndex;
            let tvalue= document.getElementById(name).value;

            // if no selected value
            if(selectedIndex === 0){
                if(name === "location_id"){
                    setLoc(null);
                }else if(name === "app_id"){
                    setApps(null);
                    setLoc(null);
                    setLocation([]);
                }
            }else{
                if(name === "location_id"){
                    value =+tvalue;
                    setLoc(value);
                }else if(name === "app_id"){
                    value =+tvalue;
                    setApps(value);
                    setLoc(null);
                }else if(name==='sort'){
                    value = +tvalue;
                    setSort(value);
                }
            };
        }else{
            if(name === 'search_inputs'){
                value = event.target.value;
                setSearchkey(value);
            }
        };
    };


    // close toast
    const handleCloseToast = () => {
        setShowToast(false);
    };

    // logs out a user
    const handleLogout = async () => {
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location = "/";
    };

    // handle delete department
    const handleDelete = (item) => {
        setShowDeleteModal(true);
        setDeleteItem(item);
    };

    // handle edit department
    const handleEdit = (item, order) => {
        setEditItem(item);
        setEditOrder(order);
        setShowEditModal(true);
    };

    // handle add department
    const handleAdd = () => {
        setShowAddModal(true);
    };

    // handle order department
    const handleOrder = () => {
        setShowOrderModal(true);
    };

    

    return (
        <div>
            <a href="/departments" className="btn btn-sm rounded-pill btn-secondary">Departments Home</a> 
            {showToast && <div className="toast-overlay"></div>}
            <ToastContainer onClose={handleCloseToast} />
            <DeleteDepartmentModal show={showDeleteModal} setShow={setShowDeleteModal} item={deleteItem} apps={apps} loc={loc} setDepartments = {setDepartments} />
            <EditDepartmentModal show={showEditModal} setShow={setShowEditModal} item={editItem} order={editOrder} apps={apps} loc={loc} setDepartments = {setDepartments} />
            <AddDepartmentModal show={showAddModal} setShow={setShowAddModal} appslist={applist} locs={loc} app = {apps} setDepartments = {setDepartments} />
            <DragDropModal show={showOrderModal} setShow={setShowOrderModal} appslist={applist} locs={loc} app = {apps} setDepartments = {setDepartments} searchkey={searchkey} sort={sort} />
            <div className="card">
                <h5 className="card-header">Department Management</h5>
                <div className="mb-3 row">
                    <div className="col-md-6">
                        <div style={{paddingLeft:"20px"}}>
                            <button className="btn btn-primary" onClick={handleAdd}>Add New Department</button>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <input className="form-control" type="search" id="search_input" name="search_inputs" value={searchkey || ''} onChange={handleChange} placeholder="search ..."/>
                    </div>
                </div>
                <div>
                    <SessionExpiredModal
                        show={sessionModal}
                        handleLogout={handleLogout}
                    />
                </div>
                <div className="mb-3 row" style={{paddingLeft:"20px"}}>
                    <div className="d-flex flex-wrap">
                        <AppsDropDownComponent
                            apps={apps}
                            handleChange={handleChange}
                            applist={applist}
                        />
                        <LocationsDropDownComponent
                            loc={loc}
                            handleChange={handleChange}
                            location={location}
                        />
                        <div className="col-md-3">
                            <button type="button" className="btn rounded-pill btn-primary me-1" style={{fontSize:12, marginLeft:50}} onClick={handleOrder}>
                                Order Departments
                            </button>
                        </div>
                    </div>
                </div>
                <div className="mb-3 row" style={{paddingLeft:"20px"}}>
                    <div className="d-flex flex-wrap">
                        <SortDepartment handleChange={handleChange} sort={sort} loc={loc}/>
                    </div>
                </div>
                <div className="alert alert-primary" role='alert'><strong>{numberOfRows} departments found</strong></div>
                {isLoading ? 
                <div>
                    <Loading/>
                </div>:<div>
                    {!apps && !loc && <div className="table-responsive text-nowrap" style={{paddingLeft:"100px", paddingTop:"20px", paddingBottom:'20px'}}>
                        <table className="table table-bordered text-center" style={{width: "800px"}}>
                            <thead style={{backgroundColor:'black', color:'white'}}>
                                <tr>
                                    <th style={{width: "50%"}}>Location</th>
                                    <th style={{width: "50%"}}>Department</th>
                                </tr>
                            </thead>
                            <tbody>
                                {departments && departments.length > 0 && departments.map((departmentObj, i) => (
                                    <tr key={i}>
                                        <td>{departmentObj.location_name}</td>
                                        <td>{departmentObj.department_name}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}
                    {apps && !loc && <div className="table-responsive text-nowrap" style={{paddingLeft:"100px", paddingTop:"20px", paddingBottom:'20px'}}>
                        <table className="table table-bordered text-center" style={{width: "800px"}}>
                            <thead style={{backgroundColor:'black', color:'white'}}>
                                <tr>
                                    <th style={{width: "50%"}}>Location</th>
                                    <th style={{width: "50%"}}>Department</th>
                                </tr>
                            </thead>
                            <tbody>
                                {departments && departments.length > 0 && departments.map((departmentObj, i) => (
                                    <tr key={i}>
                                        <td>{departmentObj.location_name}</td>
                                        <td>{departmentObj.department_name}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}
                    {apps && loc && <div className="table-responsive text-nowrap" style={{paddingLeft:"100px", paddingTop:"20px", paddingBottom:'20px'}}>
                        <table className="table table-bordered text-center" style={{width: "800px"}}>
                            <thead style={{backgroundColor:'black', color:'white'}}>
                                <tr>
                                    <th>Location</th>
                                    <th>Department</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {departments && departments.length > 0 && departments.map((departmentObj, i) => (
                                    <tr key={i}>
                                        <td>{departmentObj.location_name}</td>
                                        <td>{departmentObj.department_name}</td>
                                        <td><button style={{border:"none"}} onClick={() => handleEdit(departmentObj.department_name, departmentObj.department_order)} type="button" className="btn rounded-pill btn-outline-primary btn-sm me-2"><i className='bx bxs-edit'></i></button></td>
                                        <td><button style={{border:"none"}} onClick={() => handleDelete(departmentObj.department_name)} type="button" className="btn rounded-pill btn-outline-danger btn-sm me-2"><i className='bx bxs-trash'></i></button></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}
                </div>}
            </div>
            <div className="pagination-container" style={{ display: "flex", flexWrap: "wrap", paddingLeft:'100px'}}>
                <PaginationComponent
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    numberOfPage={numberOfPages}
                    setShowToast={setShowToast}
                    numVisiblePages={20}
                />
            </div>
        </div>
    );
}


export default Department;