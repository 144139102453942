import React from "react";
import Loading from "../Utils/Loading";


const AnalyticsSummaryView = ({analyticsDataSummary, summaryLoading}) => {
    console.log(analyticsDataSummary, "analyticsDataSummary");
    return (
        <div>
            {summaryLoading ? 
                <Loading />
                :
                <div className="card">
                    <div className="card-header">
                        <h3 style={{color:'#1E2D47', textAlign:'center'}}>Analytics Summary</h3>
                    </div>
                    <div className="card-body">
                        <div className="card-row" style={{display:'flex', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                            {analyticsDataSummary.length > 0 && analyticsDataSummary.map((item) => (
                                <div className="card-column" key={item.id} style={{flex: '1 1 calc(20% - 10px)', marginBottom: '5px', marginLeft: '5px'}}>
                                    <div className="card mb-4">
                                        <div className="card-header" style={{height:'100px'}}> 
                                            <h5 style={{textAlign:"center"}}>{item.name}</h5>
                                        </div>
                                        <div className="card-body">
                                            <h3 style={{ color: "teal", textAlign:"center" }}>{item.value}</h3>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};


export default AnalyticsSummaryView;