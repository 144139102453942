import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SessionExpiredModal from '../SessionExpire/SessionExpiredModal';
import { errorHandler } from "../Utils/errorHandler";
import { appDetailData, appDetailDataCount } from "../../apis/AppsAPI";
import { deleteStaff } from "../../apis/StaffAPI";
import PaginationComponent from "../Pagination/Pagination";
//import AppsDropDownComponent from "../PageTopComponents.jsx/AppDropDown";
// import AppsDropDownComponent from "../PageTopComponents/AppDropDown";
import Loading from "../Utils/Loading";
import { useLocation } from "react-router-dom";


const AppManagementCenter = () => {

    let aplist = [];

    // if user is not administrator he can view his apps



    const [numberOfPage, setNumberOfPage] = useState(0);
    const [del, setDel] = useState(false);
    const [appsPerPage] = useState(20);
    const [isLoading, setIsLoading] = useState(true);
    const [sessionModal, setSessionModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [firstLoad, setFirstLoad] = useState(1);
    const [addUrl, setaddUrl] = useState("/addApp");
    const [qryString, setQryString] = useState("");
    const search = useLocation().search;
    const queryParams = new URLSearchParams(search);
    const [timeoutid, setTimeoutid] = useState(null);





    const ap_id = queryParams.get('app_id');
    let app_id = null;
    if (ap_id) {
        app_id = parseInt(ap_id);
    }


    let searchstr = queryParams.get('searchkey');

    if (!searchstr) {
        searchstr = "";
    }




    const pag = queryParams.get('page');
    let pg = 1;

    if (pag) {
        pg = parseInt(pag);
    }

    const sortquery = queryParams.get('sort');
    let srt = 0;

    if (sortquery) {
        srt = parseInt(sortquery);

    }

    const [apps, setApps] = useState(app_id);
    const [searchkey, setSearchkey] = useState(searchstr);
    const [currentPage, setCurrentPage] = useState(pg);
    const [sort, setSort] = useState(srt);
    const [applist, setApplist] = useState(aplist);



    function intervalexecute() {
        if (timeoutid) {
            clearTimeout(timeoutid);
        }
        const timeout = setTimeout(() => {

            //only first time current page will be what ever page user address pressed     
            setIsLoading(true);

            appDetailData(currentPage, searchkey, appsPerPage, sort).then((response) => {
                console.log("commonn load data");
                if (response.status === 200) {
                    setApplist(response.data);
                    setIsLoading(false);
                } else {
                    setApplist([]);
                    setIsLoading(false);
                    errorHandler("search response" + response.status, setSessionModal, setShowToast, toast);

                }
            }).catch(err => {
                setIsLoading(false);
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
            // generate url in addressbar whenever any change take place
         
                updateQueryParams();
         
            appDetailDataCount(searchkey).then((responsex) => {
                if (responsex.status === 200) {

                    const rowNumberCount = responsex.data[0].number_of_rows;
                    const pgCount = Math.ceil(rowNumberCount / appsPerPage);
                    setNumberOfPage(pgCount);
                } else {
                    setNumberOfPage(0);
                    errorHandler("search count response" + responsex.status, setSessionModal, setShowToast, toast);

                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);

            });

        }, 2000);

        setTimeoutid(timeout);
    }

    // first loading use other variable to check
    useEffect(() => {
        if (firstLoad !== 1) { // first load not use search
            intervalexecute();
            console.log("use effect search");

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchkey]);

    useEffect(() => {

        console.log("use effect 1");

        setIsLoading(true);
        console.log(sort);
        appDetailData(currentPage, searchkey, appsPerPage, sort).then((response) => {
            console.log(response.data);
            if (response.status === 200) {
                setApplist(response.data);
            } else {
                errorHandler("response error" + response.status, setSessionModal, setShowToast, toast);

            }
            setIsLoading(false);

        }).catch(err => {
            setIsLoading(false);
            errorHandler(err, setSessionModal, setShowToast, toast);

        });

        appDetailDataCount(searchkey).then((responsex) => {
            console.log(responsex.data);
            if (responsex.status === 200) {
                const rowNumberCount = responsex.data[0].number_of_rows;
                const pgCount = Math.ceil(rowNumberCount / appsPerPage);
                setNumberOfPage(pgCount);
            } else {
                setNumberOfPage(0);

                errorHandler("response error" + responsex.status, setSessionModal, setShowToast, toast);
            }

        }).catch(err => {

            errorHandler(err, setSessionModal, setShowToast, toast);

        });



        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    // getting staff data
    useEffect(() => {

        //only first time current page will be what ever page user address pressed     
        if (firstLoad === 1) {
            // not first time load each search set current page to 1
            setFirstLoad(0);
            // as it is firstload no need to do param update
            return;
        }


        setIsLoading(true);

        appDetailData(currentPage, searchkey, appsPerPage, sort).then((response) => {
            if (response.status === 200) {
                setApplist(response.data);
            } else {
                console.log("status issue");


            }

            setIsLoading(false);

        }).catch(err => {
            setIsLoading(false);
            errorHandler(err, setSessionModal, setShowToast, toast);

        });

        updateQueryParams();


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sort, currentPage]);





    // Update the URL query params when the state of the hook changes

    const updateQueryParams = () => {
        console.log("addressbar update ..");
        const queryParams = new URLSearchParams();
        if (apps) queryParams.set('app_id', apps);

        if (searchkey) queryParams.set('searchkey', searchkey);

        if (sort) queryParams.set('sort', sort);
        if (currentPage) queryParams.set('page', currentPage);
        const queryString = queryParams.toString();
        const newUrl = `${window.location.pathname}?${queryString}`;
        window.history.pushState({ path: newUrl }, '', newUrl);
        // setCurrentUrl(newUrl);
        setaddUrl(`/addApp?${queryString}`);
        // query string will be used for edit purpose
        setQryString(`?${queryString}`);
    };


    // handle inputs and change events
    const handleChange = async (event) => {
        console.log("handle fired");
        if (currentPage !== 1) {
            setCurrentPage(1);
        }
        let search = window.location.search;
        let params = new URLSearchParams(search);
        console.log(params);
        const name = event.target.name;
        let value;

        if (event.target.type === 'select-one') {
            let selectedIndex = event.target.selectedIndex;
            let tvalue = document.getElementById(name).value;
            // incase no value selected 
            if (selectedIndex === 0) {
                if (name === 'app_id') {
                    setApps(null);
                }

            } else {
                // converting to number 

                if (name === 'app_id') {
                    value = +tvalue;
                    setApps(value);
                } else if (name === 'sort') {
                    value = +tvalue;
                    setSort(value);
                }
            }
        } else {
            if (name === 'search_inputs') {
                setSearchkey(event.target.value);
            }
        }
    };


    // deleting a staff
    const deleteClicked = (e, appObj) => {

        e.preventDefault();

        if (window.confirm("Delete the app?")) {

            deleteStaff(e, appObj, setDel).then((res) => {
                setDel(false);
                //    window.alert(res.status+" "+res.data.affectedRows );
                if (res.status === 201 && res.data.affectedRows > 0) {
                    setShowToast(true);
                    toast.success("Delete Successful", {
                        position: toast.POSITION.TOP_CENTER, hideProgressBar: false, autoClose: 1000,
                        onClose: () => {
                            window.location.reload();
                        }
                    });
                } else {
                    toast.error("Data not found!", {
                        position: toast.POSITION.TOP_CENTER, hideProgressBar: false, autoClose: 1000
                    });
                }
            }).catch((err) => {
                setDel(false);
                errorHandler(err, setSessionModal, setShowToast, toast);
            });


        } else {
            console.log("You canceled!");
        }



    };



    // logs out a user
    const handleLogout = async () => {
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location = "/";
    };

    // handle toast close
    const handleCloseToast = () => {
        setShowToast(false);
    };


    return (
        <div>
            <a href="/apps" className="btn btn-sm rounded-pill btn-secondary">App Home</a>
            {del &&
                <div>Deleting Item...</div>
            }
            {showToast && <div className="toast-overlay"></div>}
            <ToastContainer onClose={handleCloseToast} />
            <div className="card">
                <h5 className="card-header">App Management</h5>
                <div className="mb-3 row">
                    <div className="col-md-6">
                        <div style={{ paddingLeft: "10px" }}>
                            <a href={addUrl} type="button" className="btn btn-primary col-md-3">Add App</a>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <input className="form-control" type="search" id="search_input" name="search_inputs" value={searchkey || ''} onChange={handleChange} placeholder="search ..." />
                    </div>
                </div>
                <div>
                    <SessionExpiredModal
                        show={sessionModal}
                        handleLogout={handleLogout}
                    />
                </div>
                <div>



                </div>
                <div className="mb-4 row" style={{ paddingLeft: "20px" }}>
                    <div className="d-flex flex-wrap">



                    </div>
                    <label className="mx-2 col-form-label">Sort </label>
                    <div className="col-md-3">
                        <select className="form-select" id="sort" name="sort" onChange={handleChange} value={sort} >
                            <option value="">--Sort--</option>
                            <option value="0">By Name</option>
                            <option value="3">Most Recent Entry</option>
                            <option value="5">Most Recent Update</option>
                            <option value="1">By Name DESC</option>
                            <option value="2">Most Oldest Entry</option>
                            <option value="4">Most Oldest Update</option>


                        </select>
                    </div>
                </div>



            </div>

            {isLoading ?
                <div>
                    <Loading />
                </div> : <div>
                    <div className="table-responsive text-nowrap">
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th>Action</th>
                                    <th>Thumb</th>
                                    <th>Certificate</th>
                                    <th>Name</th>
                                    <th>Bundle Identifier</th>

                                    <th>Client <br />
                                        API Key</th>
                                    <th>Classification</th>
                                    <th>SKU</th>
                                    <th>Country</th>
                                    <th>700 </th>
                                </tr>
                            </thead>
                            <tbody className="table-border-bottom-0">
                                {applist && applist.length > 0 && applist.map((appObj) => (
                                    <tr key={appObj.id}>
                                        <td>
                                            <div className="dropdown">
                                                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="bx bx-dots-vertical-rounded"></i>
                                                </button>
                                                <div className="dropdown-menu" >
                                                    <a className="dropdown-item" href={"/app/edit/" + appObj.id + qryString} ><i className="bx bx-edit-alt me-1"></i> Edit</a>
                                                    <a href="/#" data-key={appObj.id} className="dropdown-item" onClick={(event) => deleteClicked(event, appObj)} ><i className="bx bx-trash me-1"></i> Delete</a>
                                                </div>
                                            </div>
                                            <div>
                                            </div>
                                        </td>
                                        <td>
                                            <ul className="list-unstyled users-list m-0 avatar-group d-flex align-items-center">
                                                <li key={appObj.id} data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="top" className="avatar avatar-xs pull-up" title="" data-bs-original-title="Lilian Fuller">


                                                    <div className={appObj.status === 1 ? "avatar avatar-online" : "avatar avatar-offline"} >
                                                        {appObj.app_icon_file_name ?
                                                            <a href={process.env.REACT_APP_ASSET_URL + "/assets/app_icons/" + appObj.id + "/original/Icon." + appObj.app_icon_file_name.split('.').pop()} target="_blank" rel="noreferrer">
                                                                <img src={process.env.REACT_APP_ASSET_URL + "/assets/app_icons/" + appObj.id + "/thumb/Icon." + appObj.app_icon_file_name.split('.').pop()} onError={(e) => { e.target.onerror = null; e.target.src = "app_file_not_exist.jpg" }} alt={appObj.name} className="rounded-circle" />
                                                            </a>
                                                            :
                                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                            <a>
                                                                <img src={"app_no_image.png"} alt={appObj.name} className="rounded-circle" />
                                                            </a>
                                                        }



                                                    </div>
                                                </li>
                                            </ul>
                                        </td>

                                        <td>

                                            <div >

                                                {appObj.apn_prod_cert_file_name ?
                                                    <a href={process.env.REACT_APP_ASSET_URL + "/apn_certs/" + appObj.bundle_identifier + "_prod.pem"} target="_blank" rel="noreferrer">

                                                        <img src={"cert_on_file_clip.png "} alt={appObj.apn_prod_cert_file_name} className="rounded-circle" />


                                                    </a>
                                                    :
                                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                                    <a>
                                                        -
                                                    </a>
                                                }


                                            </div>


                                        </td>
                                        <td>
                                            <i className="fab fa-angular fa-lg text-danger me-3"></i> <a href={"/app/edit/" + appObj.id + qryString} > <strong>{appObj.name ? appObj.name : ''}</strong></a>
                                        </td>
                                        <td><a href={"/app/edit/" + appObj.id + qryString} > {appObj.bundle_identifier ? appObj.bundle_identifier : ''} </a></td>

                                        <td>{appObj.client_name} <br />
                                            {appObj.api_key}</td>

                                        <td>
                                            <a href={"/app/edit/" + appObj.id + qryString} > <strong>{appObj.classification ? appObj.classification : ''}</strong></a>
                                        </td>
                                        <td><a href={appObj.id ? "/app/edit/" + appObj.id + qryString : ''} >{appObj.sku ? appObj.sku : ''}</a></td>
                                        <td> {appObj.country ? appObj.country : ''}</td>
                                        <td>{appObj.is_700_active === 1 ? 'Active' : 'Disable'}</td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="pagination-container" style={{ display: "flex", flexWrap: "wrap" }}>
                        <PaginationComponent
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            numberOfPage={numberOfPage}
                            setShowToast={setShowToast}
                            numVisiblePages={50}
                        />
                    </div>
                </div>}
        </div>
    );
};

export default AppManagementCenter;