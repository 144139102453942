import axiosInstance from "../services/AxiosInstance";



// get rewards-list
export const getRewardsList = async (apps, loc, cpage, searchkey, lowest, highest, applist, qr, redeem, expired, kind, customer, sort, rtPerPage, role) => {
    let url = '/v2/reward/list';
    const offset = (cpage - 1) * rtPerPage;
    url += '?offset=' + offset + '&limit=' + rtPerPage;

    if (apps && !loc) {
        url += '&app_id=' + apps;
    }
    else if (loc && apps) {
        url += '&location_id=' + loc;
    }
    else if (!apps && !loc) {
        if (role === 'administrator') {
            // url = url;
            console.log(role);
        }
        else if (applist.length === 1) {
            url += '&app_id=' + applist[0].id;
        }
        else {
            url += '&app_id=-1';
        }
    }


    if (searchkey) {
        url += '&searchkey=' + searchkey;
    }

    if (lowest) {
        url += '&lowest_point_range=' + lowest;
    }

    if (highest) {
        url += '&highest_point_range=' + highest;
    }

    if (qr) {
        url += '&qrcode =' + qr;
    }

    if (redeem) {
        url += '&is_redeem_once=' + redeem;
    }

    if (expired) {
        url += '&is_expired=' + expired;
    }

    if (kind) {
        url += '&kind=' + kind;
    }

    if (customer) {
        url += '&customer_id=' + customer;
    }

    if (sort) {
        url += '&sort=' + sort;
    }

    console.log(url);
    const response = await axiosInstance.get(url);
    return response;
}



// get reward-list-count
export const getRewardsListCount = async (apps, loc, searchkey, lowest, highest, applist, qr, redeem, expired, kind, customer, sort, role) => {
    let url = '/v2/reward/listcount';
    let questionadded = false;

    if (apps && !loc) {
        if (questionadded) {
            url += '&app_id=' + apps;
        }
        else {
            url += '?app_id=' + apps;
            questionadded = true;
        }
    }
    else if (loc && apps) {
        if (questionadded) {
            url += '&location_id=' + loc;
        }
        else {
            url += '?location_id=' + loc;
            questionadded = true;
        }
    }
    else if (!apps && !loc) {
        if (role === 'administrator') {
            // url = url;
            console.log(role);
        }
        else if (applist.length === 1) {
            if (questionadded) {
                url += '&app_id=' + applist[0].id;
            }
            else {
                url += '?app_id=' + applist[0].id;
                questionadded = true;
            }
        }
        else {
            if (questionadded) {
                url += '&app_id=-1';
            }
            else {
                url += '?app_id=-1';
                questionadded = true;
            }
        }
    }


    if (searchkey) {
        if (questionadded) {
            url += '&searchkey=' + searchkey;
        }
        else {
            url += '?searchkey=' + searchkey;
            questionadded = true;
        }
    }

    if (lowest) {
        if (questionadded) {
            url += '&lowest_point_range=' + lowest;
        }
        else {
            url += '?lowest_point_range=' + lowest;
            questionadded = true;
        }
    }

    if (highest) {
        if (questionadded) {
            url += '&highest_point_range=' + highest;
        }
        else {
            url += '?highest_point_range=' + highest;
            questionadded = true;
        }
    }

    if (qr) {
        if (questionadded) {
            url += '&qrcode =' + qr;
        }
        else {
            url += '?qrcode =' + qr;
            questionadded = true;
        }
    }

    if (redeem) {
        if (questionadded) {
            url += '&is_redeem_once=' + redeem;
        }
        else {
            url += '?is_redeem_once=' + redeem;
            questionadded = true;
        }
    }

    if (expired) {
        if (questionadded) {
            url += '&is_expired=' + expired;
        }
        else {
            url += '?is_expired=' + expired;
            questionadded = true;
        }
    }

    if (kind) {
        if (questionadded) {
            url += '&kind=' + kind;
        }
        else {
            url += '?kind=' + kind;
            questionadded = true;
        }
    }

    if (customer) {
        if (questionadded) {
            url += '&customer_id=' + customer;
        }
        else {
            url += '?customer_id=' + customer;
            questionadded = true;
        }
    }

    if (sort) {
        if (questionadded) {
            url += '&sort=' + sort;
        }
        else {
            url += '?sort=' + sort;
            questionadded = true;
        }
    }

    console.log(url);
    const response = await axiosInstance.get(url);
    return response;
}


// save reward
export const saveRewardList = async (data) => {
    const response = await axiosInstance.post('/v2/reward/list', data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response;
}


// get reward by id
export const getRewardById = async (id) => {
    const response = await axiosInstance.get('/v2/reward/list/' + id);
    return response;
}


// update reward by id
export const updateRewardById = async (id, data) => {
    const response = await axiosInstance.patch('/v2/reward/list/' + id, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
    return response;
}


// delete reward by id
export const deleteRewardById = async (id) => {
    const response = await axiosInstance.delete('/v2/reward/list/' + id);
    return response;
}

export const getAppRewardSettings = async (id) => {

    const user = JSON.parse(localStorage.getItem('user'));
    const role = user.role;
    const userid = user.id;
    const url = "v2/apps/get/" + id;
    console.log(url);

    const response = await axiosInstance.get(url, {
        headers: {
            'Content-Type': 'application/json',
            'userid': userid,
            'userrole': role
        }
    });
    return response;
}
