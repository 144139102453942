import React from "react";
import Modal from 'react-bootstrap/Modal';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { errorHandler } from "../Utils/errorHandler";
import { reorderDepartments } from "../../apis/DepartmentAPI";
import { locationData } from "../../apis/LocationAPI";
import { getDepartments } from "../../apis/DepartmentAPI";
import SessionExpiredModal from "../SessionExpire/SessionExpiredModal";
import AppsDropDownComponent from "../PageTopComponents/AppDropDown";
import LocationsDropDownComponent from "../PageTopComponents/LocationDropDown";

const DragDropModal = ({ show, setShow, appslist, locs, app, setDepartments, searchkey, sort }) => {
    console.log(locs, app, 'from dragdropmodal');
    const [apps, setApps] = useState(null);
    const [departlist, setDepartlist] = useState([]);
    const [loc, setLoc] = useState(null);
    const [locations, setLocations] = useState([]);
    const [deptPerPage] = useState(500);
    const [sessionModal, setSessionModal] = useState(false);
    const [showToast, setShowToast] = useState(false);

    const handleCloseModal = () => setShow(false);

    // keep app name if it was selected
    useEffect(() => {
        setApps(app);
    }, [app]);

    // keep location name if it was selected
    useEffect(() => {
        setLoc(locs);
    }, [locs]);

    // drag and drop function to update order
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        };
        const sourceIndex = result.source.index;
        const destinationIndex = result.destination.index;
        const reorderedItems = Array.from(departlist);
        const [removed] = reorderedItems.splice(sourceIndex, 1);
        reorderedItems.splice(destinationIndex, 0, removed);

        const updatedItems = reorderedItems.map((item, index) => ({
            ...item,
            department_order: index
        }));

        setDepartlist(updatedItems);
    };

    // submit order change
    const handleOrderSubmit = () => {
        reorderDepartments(loc, departlist).then((response) => {
            if (response.status === 201) {
                toast.success('Order Changed Successfully!', {
                    position: toast.POSITION.TOP_CENTER, hideProgressBar: false, autoClose: 2000,
                    onClose: () => {
                        setShow(false);
                        getDepartments(app, locs, 30, undefined, 1).then((response) => {
                            if (response.status === 200) {
                                setDepartments(response.data);
                                window.location.reload();
                            } else {
                                setDepartments([]);
                                window.location = '/error';
                            };
                        }).catch(err => {
                            errorHandler(err, setSessionModal, setShowToast, toast);
                        });
                    }
                });
            }
            else {
                toast.error('Something Went Wrong!', { position: toast.POSITION.TOP_CENTER, hideProgressBar: true, autoClose: false });
            };
        }).catch((err) => {
            errorHandler(err, setSessionModal, setShowToast, toast);
        });
    };


    // load all locations by app
    useEffect(() => {
        if (apps) {
            locationData(apps).then((response) => {
                if (response.status === 200) {
                    setLocations(response.data);
                } else {
                    setLocations([]);
                    window.location = '/error';
                };
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        } else {
            setLocations([]);
        };
    }, [apps]);


    // load departments
    useEffect(() => {
        getDepartments(apps, loc, deptPerPage, searchkey, 0, sort).then((response) => {
            if (response.status === 200) {
                setDepartlist(response.data);
            } else {
                setDepartlist([]);
                window.location = '/error';
            };
        }).catch((err) => {
            errorHandler(err, setSessionModal, setShowToast, toast);
        });
    }, [apps, loc, deptPerPage, searchkey, sort]);


    const handleChange = (e) => {
        e.preventDefault();
        if (e.target.type === 'select-one' && e.target.name === 'app_id') {
            setApps(e.target.value);
        } else if (e.target.type === 'select-one' && e.target.name === 'location_id') {
            setLoc(e.target.value);
        }
    };

    // logs out a user
    const handleLogout = async () => {
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location = "/";
    };



    return (
        <div>
            <Modal show={show} onHide={handleCloseModal} fullscreen={true}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3>Order Departments</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showToast && <div className="toast-overlay"></div>}
                    <SessionExpiredModal
                        show={sessionModal}
                        handleLogout={handleLogout}
                    />
                    <h5>Please select an app and a location first!</h5>
                    <div className="mb-3 row">
                        <div className="d-flex flex-wrap">
                            <AppsDropDownComponent
                                handleChange={handleChange}
                                apps={apps}
                                applist={appslist}
                            />
                            <LocationsDropDownComponent
                                handleChange={handleChange}
                                loc={loc}
                                location={locations}
                            />
                        </div>
                    </div>
                    {apps && loc && departlist.length > 0 &&
                        <div className="row g-2" style={{ marginTop: '20px' }}>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="modal-items">
                                    {(provided, snapshot) => (
                                        <ul
                                            className="list-group"
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={{
                                                overflowX: "scroll",
                                                backgroundColor: snapshot.isDraggingOver ? "#e6f7ff" : "white"
                                            }}
                                        >
                                            {departlist.map((item, index) => (
                                                <Draggable key={index} draggableId={item.department_name} index={index}>
                                                    {(provided, snapshot) => (
                                                        <li
                                                            className="list-group-item"
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            ref={provided.innerRef}
                                                            style={{
                                                                ...provided.draggableProps.style,
                                                                backgroundColor: snapshot.isDragging ? "#f5f5f5" : "white"
                                                            }}
                                                        >
                                                            {item.department_name}
                                                        </li>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </ul>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleCloseModal}>
                        Close
                    </button>
                    <button className="btn btn-primary" onClick={handleOrderSubmit}>
                        Save Changes
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};


export default DragDropModal;