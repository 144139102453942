import React, { useEffect } from "react";
import { XAxis, YAxis, Line, LineChart, Tooltip, CartesianGrid, ResponsiveContainer, Label } from 'recharts';
import Loading from "../Utils/Loading";

const AnalyticsDate = ({analyticsDataDetailsDates, metric, dates, setDates, dateLoading, locate, setLocate, analyticsDataDetailsLocs, locName, setLocName}) => {

    useEffect(() => {
        if(locate && locate !== ''){
            analyticsDataDetailsLocs.map((item) => (
                Object.keys(item).map((key) => (
                    key === metric ?
                        item[key].length > 0 && item[key][0].val ?
                            item[key].map((elem, index) => (
                                elem.location_id === locate ? setLocName(elem.name) : null
                            ))
                        : null
                    : null
                ))
            ))
        }
    }, [locate, analyticsDataDetailsLocs, metric, setLocName]);

    console.log(locName, 'locName');

    // handle dot click
    const handleDotClick = (event, payload) => {
        console.log(event, 'event');
        console.log(payload.payload.date, 'payload');
        setDates(payload.payload.date);
    };

    // custom tooltip for line chart
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            console.log('tooltip ', label, payload[0].payload)
            const { date, val } = payload[0].payload;
            console.log(date, val)
            return (
            <div className="custom-tooltip">
                {/* <p className="intro">{`Date: ${date}`}</p> */}
                <p className="label" style={{backgroundColor:'white', color:'black', marginRight:'3px', marginLeft:'3px'}}>{`${date} - ${val}`}</p>
            </div>
            );
        }

        return null;
    };


    return (
        <div>
            {dateLoading ?
                <Loading /> :
            <div className="row">
                {analyticsDataDetailsDates.map((item) => (
                    Object.keys(item).map((key) => (
                        key === metric ?
                            item[key].length > 0 && item[key][0].val ?
                                <div className="container" style={{ width: "100%", height: '100%', marginTop: '10px', marginBottom:'20px'}} key={key}>
                                    <div className="container">
                                            <h5 style={{textAlign:'center', color:'#000000FF'}}>{key} By Date  <span style={{color:'#2BAE66FF'}}>({locName? locName: 'All Locations'})</span></h5>
                                            {/* <button className="btn btn-sm btn-info" style={{color:'white', marginLeft:'5px', width:'100px'}} onClick={handleLocClear}><i className='bx bx-reset'></i>Reset</button> */}
                                            <ResponsiveContainer width="100%" height={400}>
                                                <LineChart data={item[key]} width={1000} height={500} key={key} margin={{top: 10, right: 30, left: 20, bottom: 5}}>
                                                    <CartesianGrid strokeDasharray="5 5" fill="#F1F4FFFF" padding={{top:5}}/>
                                                    <XAxis dataKey="date" angle={0} textAnchor="middle" height={50}
                                                        interval={item[key].length > 10 ? 'preserveStartEnd' : 0} >
                                                        <Label
                                                            value="Date"
                                                            position="insideBottom"
                                                            style={{ textAnchor: 'middle', fill:'#000000FF' }}
                                                        />
                                                    </XAxis>
                                                    <YAxis tick={false} type="number" domain={[Math.min(...item[key].map(data => data.val)) - 1, Math.max(...item[key].map(data => data.val)) + 1]}>
                                                        <Label
                                                            value={`Number of ${key}`}
                                                            position="insideLeft"
                                                            angle={270}
                                                            style={{ textAnchor: 'middle', fill:'#000000FF' }}
                                                        />
                                                    </YAxis>
                                                    <Line dataKey="val" type="monotone" stroke="#990011FF"  activeDot={{ onClick: (event, payload) => handleDotClick(event, payload), cursor:'hover' }}></Line>
                                                    <Tooltip content={CustomTooltip} cursor={false}/>
                                                </LineChart>
                                            </ResponsiveContainer>
                                        </div>
                                </div>
                                :
                            null :
                        null
                    ))
                ))}
            </div>}
        </div>
    );
};


export default AnalyticsDate;