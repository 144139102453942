import React from "react";


const AnalyticsDropDown = ({ analyticsDataSummary, analyticsDataDetailsLoc, analyticsDataDetailsDate, metric, handleChange }) => {
    return (
        <div className="card" style={{ backgroundColor: '#1E3F54' }}>
            <div className="card-header col-md-4">
                <select className="form-select" id="metric" name="metric" onChange={handleChange} value={metric || ''} >
                    <option key='' value="">--Select Metric--</option>
                    {analyticsDataSummary.length > 0 && analyticsDataDetailsLoc.length > 0 && analyticsDataDetailsDate.length > 0 && analyticsDataDetailsDate.map((item) => (
                        Object.keys(item).map((key) => (
                            <option key={key} value={key}>{key}</option>
                        ))
                    ))}
                </select>
            </div>
        </div>
    );
};


export default AnalyticsDropDown;