import React, { useEffect, useState } from "react";
import FileDownload from "./FileDownload";
import FileUploadModal from "./FileUploadModal";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { errorHandler } from "../Utils/errorHandler";
import { departmentData } from "../../apis/DepartmentAPI";
import { locationData } from "../../apis/LocationAPI";
import { deleteStaff, staffCount, staffData } from "../../apis/StaffAPI";
import PaginationComponent from "../Pagination/Pagination";
import Loading from "../Utils/Loading";
import { useLocation } from "react-router-dom";
import moment from "moment";
import ModuleHeader from "../Header/ModuleHeader";


const Staffs = () => {

    const userData = JSON.parse(localStorage.getItem('user'));
    let aplist = [];
    if (userData.apps?.length > 0) {
        aplist = userData.apps;

    }
    let endUserRole = userData?.role;



    const [numberOfPage, setNumberOfPage] = useState(0);
    const [numberOfRows, setNumberOfRows] = useState(0);
    const [del, setDel] = useState(false);
    const [location, setLocation] = useState([]);
    const [staffsPerPage] = useState(50);
    const [departmentlist, setDepartmentlist] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [sessionModal, setSessionModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [firstLoad, setFirstLoad] = useState(1);
    const [addUrl, setaddUrl] = useState("/addStaff");
    const [qryString, setQryString] = useState("");
    const search = useLocation().search;
    const queryParams = new URLSearchParams(search);
    const [timeoutid, setTimeoutid] = useState(null);
    const [userRole] = useState(endUserRole);





    const ap_id = queryParams.get('app_id');
    let app_id = null;
    if (ap_id) {
        app_id = parseInt(ap_id);
    } else {

        const clientUser = JSON.parse(localStorage.getItem('user'));
        if (clientUser?.apps?.length === 1) {
            app_id = parseInt(clientUser.apps[0]?.id);

        }

    }



    const locat_id = queryParams.get('loc_id');
    let loc_id = null;
    if (locat_id) {
        loc_id = parseInt(locat_id);
    }
    let searchstr = queryParams.get('searchkey');

    if (!searchstr) {
        searchstr = "";
    }
    const deprt = queryParams.get('dep');
    let depar = null;
    if (deprt) {
        depar = deprt;
    }
    const [depart, setDepart] = useState(depar);



    const pag = queryParams.get('page');
    let pg = 1;

    if (pag) {
        pg = parseInt(pag);
    }

    const sortquery = queryParams.get('sort');
    let srt = 0;

    if (sortquery) {
        srt = parseInt(sortquery);

    }

    const starDate = queryParams.get('start');
    console.log(starDate)
    let stDate = null;
    if (starDate) {
        stDate = moment(starDate);
    }


    const enDate = queryParams.get('end');

    let eDate = null;
    if (enDate) {
        eDate = moment(enDate);
    }
    let searchTitle = "Search for staff members by name";
    let buttonTitle = "Add New Staff Member";
    let buttonPath = `/addstaff`;
    let dateSearchtitle = "Search for staff members created within a date range";




    const [showModal, setShowModal] = useState(false);
    const [apps, setApps] = useState(app_id);
    const [loc, setLoc] = useState(loc_id);
    const [searchkey, setSearchkey] = useState(searchstr);
    const [currentPage, setCurrentPage] = useState(pg);
    const [sort, setSort] = useState(srt);
    const [startDate, setStartDate] = useState(stDate);
    const [endDate, setEndDate] = useState(eDate);
    const [staff, setStaff] = useState([]);
    const [applist] = useState(aplist);

    // watching search box
    function intervalexecute() {
        if (timeoutid) {
            clearTimeout(timeoutid);
        }
        const timeout = setTimeout(() => {

            //only first time current page will be what ever page user address pressed     
            let paramUpdate = true;
            setIsLoading(true);

            staffData(apps, loc, currentPage, searchkey, depart, startDate, endDate, applist, staffsPerPage, sort, userRole).then((response) => {
                console.log("commonn load data");
                if (response.status === 200) {
                    setStaff(response.data);
                    setIsLoading(false);
                } else {
                    setStaff([]);
                    setIsLoading(false);
                    errorHandler("staff fetch " + response.status, setSessionModal, setShowToast, toast);
                }
            }).catch(err => {
                setIsLoading(false);
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
            // generate url in addressbar whenever any change take place
            if (paramUpdate) {
                updateQueryParams();
            }
            staffCount(apps, loc, searchkey, depart, startDate, endDate, applist, userRole).then((responsex) => {
                if (responsex.status === 200) {
                    const rowNumberCount = responsex.data[0].number_of_rows;
                    const pgCount = Math.ceil(rowNumberCount / staffsPerPage);
                    setNumberOfPage(pgCount);
                    // handle error  
                    // if(currentPage>pgCount){
                    //     setCurrentPage(1);
                    // }
                    setNumberOfRows(rowNumberCount);
                } else {
                    setNumberOfPage(0);
                    setNumberOfRows(0);
                    errorHandler("staff count " + responsex.status, setSessionModal, setShowToast, toast);

                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });


        }, 2000);

        setTimeoutid(timeout);
    }

    useEffect(() => {
        // timer for seach facto will be used for all seach change except first page loading  
        if (firstLoad !== 1) {
            intervalexecute();
        } else {

            console.log("use effect searchkey");
        }

    }, [searchkey]);




    // load all locations by app
    useEffect(() => {
        if (apps) {
            // passing apps id to get location list
            locationData(apps).then((response) => {
                if (response.status === 200) {
                    setLocation(response.data);
                } else {
                    setLocation([]);
                    errorHandler("location " + response.status, setSessionModal, setShowToast, toast);

                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        } else {
            setLocation([]);
        }
    }, [apps]);


    //load all accessible departments
    useEffect(() => {
        departmentData(apps, loc, applist).then((response) => {
            if (response.status === 200) {

                setDepartmentlist(response.data);
                let temp_deptlist = response.data;
                let temp_dept_ok = 0;
                temp_deptlist.forEach((de) => {
                    if (de.department === depart) {
                        temp_dept_ok = 1;
                    }
                });


                if (temp_dept_ok === 0) {
                    setDepart(null);
                }

            } else {
                console.log(response.status);
                setDepart(null);
                setDepartmentlist([]);
            }
        }).catch(err => {
            errorHandler(err, setSessionModal, setShowToast, toast);
        });

    }, [applist, apps, loc]);


    // getting staff data count
    useEffect(() => {

        console.log("Staff count fired");
        staffCount(apps, loc, searchkey, depart, startDate, endDate, applist, userRole).then((responsex) => {
            if (responsex.status === 200) {
                const rowNumberCount = responsex.data[0].number_of_rows;
                const pgCount = Math.ceil(rowNumberCount / staffsPerPage);
                setNumberOfPage(pgCount);
                // handle error  
                // if(currentPage>pgCount){
                //     setCurrentPage(1);
                // }
                setNumberOfRows(rowNumberCount);
            } else {
                setNumberOfPage(0);
                setNumberOfRows(0);
                errorHandler("staff count " + responsex.status, setSessionModal, setShowToast, toast);
            }
        }).catch(err => {
            errorHandler(err, setSessionModal, setShowToast, toast);
        });

    }, [apps, loc, depart, startDate, endDate, applist, staffsPerPage]);

    // getting staff data
    useEffect(() => {

        //only first time current page will be what ever page user address pressed     
        if (firstLoad === 1) {
            // not first time load each search set current page to 1
            setFirstLoad(0);
            // as it is firstload no need to do param update
            console.log("first time load");
        }
        setIsLoading(true);

        staffData(apps, loc, currentPage, searchkey, depart, startDate, endDate, applist, staffsPerPage, sort, userRole).then((response) => {
            console.log("commonn load data");
            if (response.status === 200) {
                setStaff(response.data);
                setIsLoading(false);
            } else {
                setStaff([]);
                setIsLoading(false);
                errorHandler("staff fetch " + response.status, setSessionModal, setShowToast, toast);

            }
        }).catch(err => {
            setIsLoading(false);
            errorHandler(err, setSessionModal, setShowToast, toast);
        });
        // generate url in addressbar whenever any change take place
        updateQueryParams();

    }, [apps, loc, currentPage, depart, startDate, endDate, applist, staffsPerPage, sort]);






    // Update the URL query params when the state of the hook changes

    const updateQueryParams = () => {
        console.log("addressbar update ..");
        const queryParams = new URLSearchParams();
        if (apps) queryParams.set('app_id', apps);
        if (loc) queryParams.set('loc_id', loc);
        if (depart) queryParams.set('dep', depart);
        if (searchkey) queryParams.set('searchkey', searchkey);
        if (startDate) queryParams.set('start', startDate.toISOString());
        if (endDate) queryParams.set('end', endDate.toISOString());
        if (sort) queryParams.set('sort', sort);
        if (currentPage) queryParams.set('page', currentPage);
        const queryString = queryParams.toString();
        const newUrl = `${window.location.pathname}?${queryString}`;
        window.history.pushState({ path: newUrl }, '', newUrl);
        // setCurrentUrl(newUrl);
        setaddUrl(`/addStaff?${queryString}`);
        // query string will be used for edit purpose
        setQryString(`?${queryString}`);
    };


    // handle inputs and change events
    const handleChange = async (event) => {
        console.log("handle fired");
        if (currentPage !== 1) {
            setCurrentPage(1);
        }
        let search = window.location.search;
        let params = new URLSearchParams(search);
        console.log(params);
        const name = event.target.name;
        let value;

        if (event.target.type === 'select-one') {
            let selectedIndex = event.target.selectedIndex;
            let tvalue = document.getElementById(name).value;
            // incase no value selected 
            if (selectedIndex === 0) {
                if (name === 'location_id') {
                    setLoc(null);
                } else if (name === 'app_id') {
                    setApps(null);
                    setLoc(null);
                    setLocation([]);
                    departmentData(null); // null is app_id

                } else if (name === 'department') {
                    setDepart(null);
                }


            } else {
                // converting to number 

                if (name === 'location_id') {
                    value = +tvalue;
                    setLoc(value);
                } else if (name === 'app_id') {
                    value = +tvalue;
                    setApps(value);
                    locationData(value);
                    setLoc(null);
                    departmentData(value); // null is app_id

                } else if (name === 'department') {
                    console.log(tvalue)
                    setDepart(tvalue);
                } else if (name === 'sort') {
                    value = +tvalue;
                    setSort(value);
                }

            }
        } else {
            if (name === 'search_inputs') {
                setSearchkey(event.target.value);
            }
        }
    };


    // deleting a staff
    const deleteClicked = (e, staffObj) => {

        e.preventDefault();

        if (window.confirm("Delete the staff?")) {

            deleteStaff(e, staffObj, setDel).then((res) => {
                setDel(false);
                //    window.alert(res.status+" "+res.data.affectedRows );
                if (res.status === 201 && res.data.affectedRows > 0) {
                    setShowToast(true);
                    toast.success("Delete Successful", {
                        position: toast.POSITION.TOP_CENTER, hideProgressBar: false, autoClose: 1000,
                        onClose: () => {
                            window.location.reload();
                        }
                    });
                } else {
                    toast.error("Data not found!", { position: toast.POSITION.TOP_CENTER, hideProgressBar: false, autoClose: 1000 });
                }
            }).catch((err) => {
                setDel(false);
                errorHandler(err, setSessionModal, setShowToast, toast);
            });


        } else {
            console.log("You canceled!");
        }

    };



    // logs out a user
    const handleLogout = async () => {
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location = "/";
    };

    // handle toast close
    const handleCloseToast = () => {
        setShowToast(false);
    };


    return (
        <div>
            <a href="/staffs" className="btn btn-sm rounded-pill btn-secondary">Staff Home</a>
            {del &&
                <div>Deleting Item...</div>
            }
            {showToast && <div className="toast-overlay"></div>}
            <ToastContainer onClose={handleCloseToast} />
            <ModuleHeader
                searchKey={searchkey}
                searchTitle={searchTitle}
                buttonPath={buttonPath}
                buttonTitle={buttonTitle}
                dateSearchTitle={dateSearchtitle}
                handleChange={handleChange}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                sessionModal={sessionModal}
                handleLogout={handleLogout}
                apps={apps}
                applist={applist}
                loc={loc}
                location={location}
                depart={depart}
                departmentlist={departmentlist}
                showDeptDropdown={true}
                showCreateNewButton={true}
            />
            <div className="mb-4 row">
                <label className="mx-2 col-form-label">Sort </label>
                <div className="col-md-3">
                    <select className="form-select" id="sort" name="sort" onChange={handleChange} value={sort} >
                        <option value="">--Sort--</option>
                        <option value="6">By Department</option>
                        <option value="0">By Name</option>
                        <option value="3">Most Recent Entry</option>
                        <option value="5">Most Recent Update</option>
                        <option value="1">By Name DESC</option>
                        <option value="2">Most Oldest Entry</option>
                        <option value="4">Most Oldest Update</option>
                        <option value="7">Order</option>
                    </select>
                </div>
            </div>
            <div className="alert alert-primary" role='alert'><strong>{numberOfRows} staff members found</strong>
                <div style={{ float: "right" }}>
                    <FileDownload
                        allvalues={{ appId: apps, locationId: loc, dpt: depart, stDate: startDate, enDate: endDate, skey: searchkey }}
                    />
                </div>
                <FileUploadModal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                />
                <div style={{ float: "right" }}>

                    <button onClick={() => setShowModal(true)} className="btn rounded-pill btn-primary me-1" style={{ fontSize: 10, height: '30px' }}>
                        Import
                    </button>
                </div>

            </div>
            {isLoading ?
                <div>
                    <Loading />
                </div> : <div>
                    <div className="table-responsive text-nowrap">
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th>Action</th>
                                    <th>Thumb</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Title</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Department</th>
                                </tr>
                            </thead>
                            <tbody className="table-border-bottom-0">
                                {staff && staff.length > 0 && staff.map((staffObj) => (
                                    <tr key={staffObj.id}>
                                        <td>
                                            <div className="dropdown">
                                                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i className="bx bx-dots-vertical-rounded"></i>
                                                </button>
                                                <div className="dropdown-menu" >
                                                    <a className="dropdown-item" href={"/staff/edit/" + staffObj.id + qryString} ><i className="bx bx-edit-alt me-1"></i> Edit</a>
                                                    <a href="/#" data-key={staffObj.id} className="dropdown-item" onClick={(event) => deleteClicked(event, staffObj)} ><i className="bx bx-trash me-1"></i> Delete</a>
                                                </div>
                                            </div>
                                            <div>
                                            </div>
                                        </td>
                                        <td>
                                            <ul className="list-unstyled users-list m-0 avatar-group d-flex align-items-center">
                                                <li key={staffObj.id} data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="top" className="avatar avatar-xs pull-up" title="" data-bs-original-title="Lilian Fuller">
                                                    <div className={staffObj.is_active === 1 ? "avatar avatar-online" : "avatar avatar-offline"} >
                                                        {staffObj.image_id ?
                                                            <a href={staffObj.image_path ? staffObj.image_path : `http://cloud.dealerappvantage.com/api/Images/${staffObj.image_id}`} target="_blank" rel="noreferrer">
                                                                <img src={staffObj.image_path ? staffObj.image_path : `http://cloud.dealerappvantage.com/api/Images/${staffObj.image_id}`} alt={staffObj.first_name} className="rounded-circle" onError={(e) => { e.target.onerror = null; e.target.src = "app_file_not_exist.jpg" }} />
                                                            </a>
                                                            :
                                                            <a href={staffObj.image_path} target="_blank" rel="noreferrer">
                                                                <img src={staffObj.image_path ? staffObj.image_path : "user.webp"} alt={staffObj.first_name} className="rounded-circle" />
                                                            </a>
                                                        }
                                                    </div>
                                                </li>
                                            </ul>
                                        </td>
                                        <td>
                                            <i className="fab fa-angular fa-lg text-danger me-3"></i> <a href={"/staff/edit/" + staffObj.id + qryString} > <strong>{staffObj.first_name ? staffObj.first_name : ''}</strong></a>
                                        </td>
                                        <td><a href={"/staff/edit/" + staffObj.id + qryString} > {staffObj.last_name ? staffObj.last_name : ''} </a></td>
                                        <td><a href={staffObj.id ? "/staff/edit/" + staffObj.id + qryString : ''} >{staffObj.title ? staffObj.title : ''}</a></td>
                                        <td> <a href={staffObj.email ? "mailto:" + staffObj.email : ''}> {staffObj.email ? staffObj.email : ''}</a></td>
                                        <td><a href={staffObj.phone ? "tel:" + staffObj.phone : ''}>{staffObj.phone ? staffObj.phone : ''}</a></td>
                                        <td>{staffObj.department}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="pagination-container" style={{ display: "flex", flexWrap: "wrap" }}>
                        <PaginationComponent
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            numberOfPage={numberOfPage}
                            setShowToast={setShowToast}
                            numVisiblePages={50}
                        />
                    </div>
                </div>}
        </div>
    );
};

export default Staffs;