import React from "react";
import { getRandomColor, createImageFromInitials } from '../Utils/InitialsImage'
import axiosInstance from "../../services/AxiosInstance";
// import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const Header = () => {

    let name;
    let role;
    let user;
    let isLoggedIn = false;
    let p = localStorage.getItem('user');
    if (p) {
        user = JSON.parse(p);
        if (user) {
            name = user.first_name + " " + user.last_name;
            role = user.role;
            isLoggedIn = true;
        }
    }

    // handles user logout
    const logOut = async (event) => {
        event.preventDefault();
        const refreshToken = localStorage.getItem('refreshToken');
        console.log(refreshToken);
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        isLoggedIn = false;
        window.location = "/";
        await axiosInstance.post("/v2/auth/logout", { refreshToken }).then((response) => {
            console.log(response)
            if (response.status === 200) {
                console.log(response.data.message);
            }
        }).catch(err => {
            console.log(err.response)
            // toast.error(err.response.data.message, {
            //     position: toast.POSITION.TOP_CENTER,
            //     autoClose: false,
            //     hideProgressBar: true,
            //     onClose: () => {
            //         localStorage.removeItem("user");
            //         localStorage.removeItem("accessToken");
            //         localStorage.removeItem("refreshToken");
            //         window.location = "/";
            //         isLoggedIn = false;
            //     }   
            // });
        });
    };

    // handles click login button
    const logIn = (event) => {
        event.preventDefault();
        window.location = "/login";

    };


    if (isLoggedIn) {
        return (
            <nav
                className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme"
                id="layout-navbar">
                <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
                    <a className="nav-item nav-link px-0 me-xl-4" href="/#">
                        <i className="bx bx-menu bx-sm"></i>
                    </a>
                </div>
                <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                    <div className="navbar-nav align-items-center">
                        <div className="nav-item d-flex align-items-center">
                            {/* <i className="bx bx-search fs-4 lh-0"></i>
                            <input
                                type="text"
                                className="form-control border-0 shadow-none"
                                placeholder="Search..."
                                aria-label="Search..."
                            /> */}
                        </div>
                    </div>
                    {/* <div className="navbar-nav align-items-center">
                        <div className="nav-item d-flex align-items-center">
                            <i className="bx bx-search fs-4 lh-0"></i>
                            <input
                                type="text"
                                className="form-control border-0 shadow-none"
                                placeholder="Search..."
                                aria-label="Search..."
                            />
                        </div>
                    </div> */}

                    <ul className="navbar-nav flex-row align-items-center ms-auto">
                        <li className="nav-item navbar-dropdown dropdown-user dropdown">
                            <a className="nav-link dropdown-toggle hide-arrow" href="/#" data-bs-toggle="dropdown">
                                <div className="avatar avatar-online">
                                    {/* <img src={require("../../assets/img/avatars/1.png")} alt className="w-px-40 h-auto rounded-circle" /> */}
                                    <img src={createImageFromInitials(500, name, getRandomColor())} alt='' className="w-px-40 h-auto rounded-circle" />
                                </div></a>
                            <ul className="dropdown-menu dropdown-menu-end">
                                <li>
                                    <a className="dropdown-item" href="/#">
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                                <div className="avatar avatar-online">
                                                    {/* <img src={require("../../assets/img/avatars/1.png")} alt className="w-px-40 h-auto rounded-circle" /> */}
                                                    <img src={createImageFromInitials(500, name, getRandomColor())} alt='user' className="w-px-40 h-auto rounded-circle" />
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <span className="fw-semibold d-block">{name}</span>
                                                <small className="text-muted">{role}</small>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <div className="dropdown-divider"></div>
                                </li>
                                <li>
                                    <div className="dropdown-divider"></div>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <button style={{ border: "none", display: "flex", justifyContent: "center", alignItems: "center" }} onClick={logOut} type="button" className="btn rounded-pill btn-outline-danger btn-sm">
                                <i className='bx bx-power-off'></i>
                            </button>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
    else {
        return (
            <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-offline">
                <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                    <ul className="navbar-nav flex-row align-items-center ms-auto">
                        <button type="submit" className="btn rounded-pill" style={{ backgroundColor: "white" }} onClick={logIn}>Sign In</button>
                    </ul>
                </div>
            </nav>
        );
    };
}

export default Header;