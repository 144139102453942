import axios from 'axios';
import jwt_decode from 'jwt-decode';
// define axios 
let axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});
let isRefreshing = false;
let refreshSubscribers = [];

axiosInstance.interceptors.request.use(async (config) => {
  const currentDate = new Date();
  // @asif storing access token in localstorage is very easy to access
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  const decodedToken = jwt_decode(accessToken);

  if (decodedToken.exp * 1000 < currentDate.getTime()) {
    if (!isRefreshing) {
      isRefreshing = true;
      try {
        const response = await Promise.race([
          axios.post(process.env.REACT_APP_BACKEND_URL + '/v2/auth/login/refresh', {
            refreshToken: refreshToken
          }),
          new Promise((resolve, reject) => {
            setTimeout(() => reject(new Error('Refresh request timed out')), 5000);
          })
        ]);
        localStorage.setItem("accessToken", response.data.accessToken);
        localStorage.setItem("refreshToken", response.data.refreshToken);
        isRefreshing = false;
        refreshSubscribers.forEach((callback) => callback(null, response.data.accessToken));
        refreshSubscribers = [];
      } catch (err) {
        isRefreshing = false;
        refreshSubscribers.forEach((callback) => callback(err, null));
        refreshSubscribers = [];
        // localStorage.removeItem("user");
        // localStorage.removeItem("accessToken");
        // localStorage.removeItem("refreshToken");
        // window.location = "/login";
      }
    }

    return new Promise((resolve, reject) => {
      refreshSubscribers.push((err, token) => {
        if (err) {
          reject(err);
        } else {
          config.headers.Authorization = `Bearer ${token}`;
          resolve(config);
        }
      });
    });
  }

  config.headers.Authorization = `Bearer ${accessToken}`;

  return config;
});

export default axiosInstance;
