import React, { useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from "react";
import { toast } from "react-toastify";
import { useParams } from 'react-router-dom';
import { errorHandler } from "../Utils/errorHandler";
import { getDepartmentByLocation, getStaffByLocationAndDepartment, addStaffToCustomer } from "../../apis/ProfilesAPI";
import AddUpdateLoading from "../Utils/AddUpdateLoading";



const AddStaffModal = ({ show, setShow, locationId }) => {
    const { customer_id } = useParams();
    const [departments, setDepartments] = useState([]);
    const [department, setDepartment] = useState('');
    const [staffs, setStaffs] = useState([]);
    const [staff, setStaff] = useState('');
    const [sessionModal, setSessionModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [loading, setLoading] = useState(false);


    const handleClose = () => {
        setShow(false);
    };


    // get department list by location id
    useEffect(() => {
        if (locationId) {
            getDepartmentByLocation(locationId)
                .then((res) => {
                    // console.log(res.data, 'department list');
                    setDepartments(res.data);
                }).catch((err) => {
                    errorHandler(err, setSessionModal, setShowToast, toast);
                });
        }
    }, [locationId]);

    // console.log(departments, 'departments');

    // get staff list by location id and department
    useEffect(() => {
        if (department) {
            getStaffByLocationAndDepartment(locationId, department)
                .then((res) => {
                    setStaffs(res.data);
                }).catch((err) => {
                    errorHandler(err, setSessionModal, setShowToast, toast);
                });
        }
    }, [locationId, department]);


    // handle change
    const handleChange = (e) => {
        const name = e.target.name;
        let value;
        if (e.target.type === 'select-one') {
            value = document.getElementById(name).value;
            const selectedIndex = e.target.selectedIndex;

            if (name === 'department') {
                if (selectedIndex === 0) {
                    setStaffs([]);
                }
                else {
                    setDepartment(value);
                }
            }
            else if (name === 'staff') {
                if (selectedIndex === 0) {
                    setStaff('');
                }
                else {
                    setStaff(value);
                }
            }
        }
        else {
            value = e.target.value;
            if (name === 'department') {
                setDepartment(value);
            }
            else if (name === 'staff') {
                setStaff(value);
            }
        }
    };


    // console.log(staffs, 'staffs');
    // console.log(staff, 'staff');


    // handle submit
    const handleSubmit = (e) => {
        e.preventDefault();
        if (staff === '') {
            toast.error(`Staff member is required!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: false,
            });
            return;
        };

        setLoading(true);
        addStaffToCustomer(customer_id, staff).then((response) => {
            if (response.status === 201) {
                toast.success(`Staff added successfully!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    hideProgressBar: false,
                    onClose: () => {
                        setShow(false);
                        window.location.reload();
                    }
                });
            } else {
                window.location = '/error';
            };
        }).catch(err => {
            errorHandler(err, setSessionModal, setShowToast, toast);
        });
        setLoading(false);
    }


    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Add Staff</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? <AddUpdateLoading /> :
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="department">Department</label>
                            <select className="form-control" id="department" name="department" onChange={handleChange} value={department}>
                                <option value="">Select Department</option>
                                {departments.map((dept, index) => (
                                    <option key={dept.order} value={dept.department}>{dept.department}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="staff">Staff</label>
                            <select className="form-control" id="staff" name="staff" onChange={handleChange} value={staff}>
                                <option value="">Select Staff</option>
                                {staffs.map((staff, index) => (
                                    <option key={index} value={staff.id}>{staff.first_name} {staff.last_name}</option>
                                ))}
                            </select>
                        </div>
                    </form>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" type="submit" className="float-right" onClick={handleSubmit}>Add Staff</Button>
                <Button variant="secondary" onClick={handleClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}


export default AddStaffModal;
