import React from "react";
import { Navigate } from "react-router-dom";



const PrivateRoute = ({ children }) => {
    const userData = JSON.parse(localStorage.getItem('user'));
    const isLoggedIn = (userData !== null) ? true : false;

    return (
        isLoggedIn ? children : <Navigate to="/login" />
    );


}

export default PrivateRoute;