import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SessionExpiredModal from '../SessionExpire/SessionExpiredModal';
import { errorHandler } from "../Utils/errorHandler";
import { locationData } from "../../apis/LocationAPI";
import { getRewardsList, getRewardsListCount, deleteRewardById, getAppRewardSettings } from "../../apis/RewardsAPI";
import { customersByLocation } from "../../apis/CommunicationAPI";
import PaginationComponent from "../Pagination/Pagination";
import AppsDropDownComponent from "../PageTopComponents/AppDropDown";
import LocationsDropDownComponent from "../PageTopComponents/LocationDropDown";
import Loading from "../Utils/Loading";
import { useLocation } from "react-router-dom";
import moment from "moment";
import QRCodeGenerator from "./QRCode";
import ReadMore from "../Utils/ReadMore";
import QRCodeModal from "./QRCodeModal";
import SettingsModal from "./SettingsModal";

const Rewards = () => {
    const userData = JSON.parse(localStorage.getItem('user'));
    let aplist = [];
    if (userData.apps?.length > 0) {
        aplist = userData.apps;
    }
    const role = userData?.role;
    let isRickCase = false;
    // if (applist.length > 0 && applist.some(item => item.id === 693)) {
    //     isRickCase = true;
    // }
    const rickCaseUsers = [1711, 1712, 1714, 1719, 1729, 1734, 1735, 1736, 1737, 1738, 1739, 1740, 1741, 1742, 1743, 1744, 1745, 1746, 1747, 1748, 1749, 1750, 1751, 1752, 1759];
    if (userData?.id && rickCaseUsers.indexOf(userData.id) > -1) {
        isRickCase = true;
    }
    console.log("isRickCase", isRickCase);

    let endUserRole = userData?.role;

    const [numberOfPage, setNumberOfPage] = useState(0);
    const [numberOfRows, setNumberOfRows] = useState(0);
    const [location, setLocation] = useState([]);
    const [rewardsPerPage] = useState(10);
    const [isLoading, setIsLoading] = useState(true);
    const [sessionModal, setSessionModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [firstLoad, setFirstLoad] = useState(1);
    const [addUrl, setaddUrl] = useState("/rewards/add" + window.location.search);
    const [qryString, setQryString] = useState("");
    const search = useLocation().search;
    const queryParams = new URLSearchParams(search);
    console.log("query params", queryParams);
    const [timeoutid, setTimeoutid] = useState(null);
    const [userRole] = useState(endUserRole);

    const ap_id = queryParams.get('app_id');
    console.log("ap_id", ap_id);
    let app_id;
    if (ap_id) {
        app_id = parseInt(ap_id);
    }
    // } else {
    //     const clientUser = JSON.parse(localStorage.getItem('user'));
    //     if (clientUser?.apps?.length === 1) {
    //         app_id = parseInt(clientUser.apps[0]?.id);

    //     }
    // }

    const locat_id = queryParams.get('loc_id');
    console.log("locat_id", locat_id);
    let loc_id = null;
    if (locat_id) {
        loc_id = parseInt(locat_id);
    }
    let searchstr = queryParams.get('searchkey');

    if (!searchstr) {
        searchstr = "";
    }

    const pag = queryParams.get('page');
    let pg = 1;

    if (pag) {
        pg = parseInt(pag);
    }

    const sortquery = queryParams.get('sort');
    let srt;
    if (role === 'administrator') {
        srt = 3;
    } else {
        srt = 0;
    }

    if (sortquery) {
        srt = parseInt(sortquery);
    }

    const lowest_point_range = queryParams.get('lowest_point_range');
    let low = null;
    if (lowest_point_range) {
        low = parseInt(lowest_point_range);
    }

    const highest_point_range = queryParams.get('highest_point_range');
    let high = null;
    if (highest_point_range) {
        high = parseInt(highest_point_range);
    }

    const qr = queryParams.get('qr');
    let qrr = null;
    if (qr) {
        qrr = qr;
    }

    const redeemed = queryParams.get('redeemed');
    let red = null;
    if (redeemed) {
        red = redeemed;
    }

    const knd = queryParams.get('kind');
    let kndd = null;
    if (knd) {
        kndd = knd;
    }

    const exp = queryParams.get('expired');
    let expd = null;
    if (exp) {
        expd = parseInt(exp);
    }

    const cust = queryParams.get('customer');
    let custt = null;
    if (cust) {
        custt = parseInt(cust);
    }

    const [apps, setApps] = useState(app_id);
    const [programType, setProgramType] = useState(null);
    const [programTerms, setProgramTerms] = useState(null);
    const [loc, setLoc] = useState(loc_id);
    const [searchkey, setSearchkey] = useState(searchstr);
    const [currentPage, setCurrentPage] = useState(pg);
    const [sort, setSort] = useState(srt);
    const [lowest, setLowest] = useState(low);
    const [highest, setHighest] = useState(high);
    const [qrCode, setQrCode] = useState(qrr);
    const [redeem, setRedeem] = useState(red);
    const [kind, setKind] = useState(kndd);
    const [customer, setCustomer] = useState(custt);
    const [customers, setCustomers] = useState([]);
    const [expired, setExpired] = useState(expd);
    const [rewards, setRewards] = useState([]);
    const [applist] = useState(aplist);
    const [showQRCodeModal, setShowQRCodeModal] = useState(false);
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [qrCodeValue, setQRCodeValue] = useState(null);

    console.log("applis", applist);
    console.log("user role", userRole);

    // Update the URL query params when the state of the hook changes
    const updateQueryParams = () => {
        console.log("addressbar update ..");
        const queryParams = new URLSearchParams();
        if (apps) queryParams.set('app_id', apps);
        if (loc) queryParams.set('loc_id', loc);
        if (searchkey) queryParams.set('searchkey', searchkey);
        if (lowest) queryParams.set('lowest_point_range', lowest);
        if (highest) queryParams.set('highest_point_range', highest);
        if (qrCode) queryParams.set('qr', qrCode);
        if (redeem) queryParams.set('redeemed', redeem);
        if (expired) queryParams.set('expired', expired);
        if (kind) queryParams.set('kind', kind);
        if (customer && customer !== '') queryParams.set('customer', customer);
        // if (endDate) queryParams.set('end', endDate.toISOString());
        if (sort) queryParams.set('sort', sort);
        if (currentPage) queryParams.set('page', currentPage);
        const queryString = queryParams.toString();
        const newUrl = `${window.location.pathname}?${queryString}`;
        window.history.pushState({ path: newUrl }, '', newUrl);
        // setCurrentUrl(newUrl);
        setaddUrl(`/rewards/add?${queryString}`);
        // query string will be used for edit purpose
        setQryString(`?${queryString}`);
    };

    // load app if app list length is one
    useEffect(() => {
        if (applist.length === 1) {
            setApps(applist[0]?.id);
        }
    }, []);

    // watching search box
    const intervalexecute = () => {
        if (timeoutid) {
            clearTimeout(timeoutid);
        }
        const timeout = setTimeout(() => {
            //only first time current page will be what ever page user address pressed     
            let paramUpdate = true;
            setIsLoading(true);

            getRewardsList(apps, loc, currentPage, searchkey, lowest, highest, applist, qrCode, redeem, expired, kind, customer, sort, rewardsPerPage, userRole).then((response) => {
                console.log("commonn load data");
                if (response.status === 200) {
                    setRewards(response.data);
                    setIsLoading(false);
                } else {
                    setRewards([]);
                    setIsLoading(false);
                    errorHandler("staff fetch " + response.status, setSessionModal, setShowToast, toast);
                }
            }).catch(err => {
                setIsLoading(false);
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
            // generate url in addressbar whenever any change take place
            if (paramUpdate) {
                updateQueryParams();
            }
            getRewardsListCount(apps, loc, searchkey, lowest, highest, applist, qrCode, redeem, expired, kind, customer, sort, userRole).then((responsex) => {
                if (responsex.status === 200) {
                    const rowNumberCount = responsex.data[0].countrewardtask;
                    const pgCount = Math.ceil(rowNumberCount / rewardsPerPage);
                    setNumberOfPage(pgCount);
                    setNumberOfRows(rowNumberCount);
                } else {
                    setNumberOfPage(0);
                    setNumberOfRows(0);
                    errorHandler("staff count " + responsex.status, setSessionModal, setShowToast, toast);
                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        }, 2000);

        setTimeoutid(timeout);
    }

    useEffect(() => {
        if (firstLoad !== 1) { // first load not use search
            intervalexecute();
        } else {
            console.log("use effect searchkey");
        }
    }, [searchkey, lowest, highest, qrCode]);


    // load all locations by app
    useEffect(() => {
        if (apps) {
            // passing apps id to get location list
            locationData(apps).then((response) => {
                if (response.status === 200) {
                    setLocation(response.data);
                } else {
                    setLocation([]);
                    errorHandler("location " + response.status, setSessionModal, setShowToast, toast);
                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        } else {
            setLocation([]);
        }
    }, [apps]);

    useEffect(() => {
        if ((apps != undefined || apps != null) && userRole === 'administrator') {
            getAppRewardSettings(apps).then((response) => {
                if (response.status === 200) {
                    setProgramType(response.data[0].loyalty_rewards_program_type);
                    setProgramTerms(response.data[0].loyalty_rewards_terms_and_conditions);
                } else {
                    errorHandler("Reward Settings " + response.status, setSessionModal, setShowToast, toast);
                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        }
    }, [apps]);


    // getting reward data count
    useEffect(() => {
        console.log("Reward count fired");
        getRewardsListCount(apps, loc, searchkey, lowest, highest, applist, qrCode, redeem, expired, kind, customer, sort, userRole).then((responsex) => {
            if (responsex.status === 200) {
                const rowNumberCount = responsex.data[0].countrewardtask;
                const pgCount = Math.ceil(rowNumberCount / rewardsPerPage);
                setNumberOfPage(pgCount);
                setNumberOfRows(rowNumberCount);
            } else {
                setNumberOfPage(0);
                setNumberOfRows(0);
                errorHandler("staff count " + responsex.status, setSessionModal, setShowToast, toast);
            }
        }).catch(err => {
            errorHandler(err, setSessionModal, setShowToast, toast);
        });
    }, [apps, loc, applist, redeem, expired, kind, customer, sort, userRole]);

    // getting reward data
    useEffect(() => {
        //only first time current page will be what ever page user address pressed     
        if (firstLoad === 1) {
            // not first time load each search set current page to 1
            setFirstLoad(0);
            // as it is firstload no need to do param update
            console.log("first time load");
        }
        setIsLoading(true);
        getRewardsList(apps, loc, currentPage, searchkey, lowest, highest, applist, qrCode, redeem, expired, kind, customer, sort, rewardsPerPage, userRole).then((response) => {
            console.log("commonn load data");
            if (response.status === 200) {
                setRewards(response.data);
                setIsLoading(false);
            } else {
                setRewards([]);
                setIsLoading(false);
                errorHandler("staff fetch " + response.status, setSessionModal, setShowToast, toast);
            }
        }).catch(err => {
            setIsLoading(false);
            errorHandler(err, setSessionModal, setShowToast, toast);
        });
        // generate url in addressbar whenever any change take place
        updateQueryParams();
    }, [apps, loc, currentPage, applist, qrCode, redeem, expired, kind, customer, sort, rewardsPerPage, userRole]);

    // get customers by location
    useEffect(() => {
        if (loc && apps) {
            customersByLocation(loc).then((response) => {
                if (response.status === 200) {
                    let data = []
                    response.data.map((customer) => {
                        data.push({ id: customer.id, name: customer.Customers });
                    });
                    setCustomers(data);
                } else {
                    setCustomers([]);
                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        } else {
            setCustomers([]);
        }
    }, [loc, apps]);

    // handle inputs and change events
    const handleChange = async (event) => {
        console.log("handle fired");
        if (currentPage !== 1) {
            setCurrentPage(1);
        }
        let search = window.location.search;
        let params = new URLSearchParams(search);
        console.log(params);
        const name = event.target.name;
        let value;
        if (event.target.type === 'select-one') {
            let selectedIndex = event.target.selectedIndex;
            let tvalue = document.getElementById(name).value;
            // incase no value selected 
            if (selectedIndex === 0) {
                if (name === 'location_id') {
                    setLoc(null);
                } else if (name === 'app_id') {
                    setApps(null);
                    setLoc(null);
                    setLocation([]);
                    setKind(null);
                    setCustomer(null);
                    setLowest(null);
                    setHighest(null);
                    setRedeem(null);
                    setExpired(null);
                    setQrCode(null);
                } else if (name === 'kind') {
                    setKind(null);
                } else if (name === 'customer') {
                    setCustomer(null);
                } else if (name === 'redeemed') {
                    setRedeem(null);
                } else if (name === 'expired') {
                    setExpired(null);
                } else if (name === 'sort') {
                    setSort(null);
                }
            } else {
                // converting to number 
                if (name === 'location_id') {
                    value = +tvalue;
                    setLoc(value);
                } else if (name === 'app_id') {
                    value = +tvalue;
                    setApps(value);
                    locationData(value);
                    setLoc(null);
                } else if (name === 'kind') {
                    value = tvalue;
                    setKind(value);
                } else if (name === 'customer') {
                    value = +tvalue;
                    setCustomer(value);
                } else if (name === 'redeemed') {
                    value = +tvalue;
                    setRedeem(value);
                } else if (name === 'expired') {
                    value = +tvalue;
                    setExpired(parseInt(value));
                } else if (name === 'sort') {
                    value = +tvalue;
                    setSort(value);
                }
            }
        } else {
            if (name === 'search_inputs') {
                setSearchkey(event.target.value);
            } else if (name === 'qrcode') {
                setQrCode(event.target.value);
            } else if (name === 'lowest_point_range') {
                setLowest(event.target.value);
            }
            else if (name === 'highest_point_range') {
                setHighest(event.target.value);
            }
        }
    };


    // handle delete reward
    const handleDeleteReward = async (rewardId) => {
        const confirm = window.confirm("Are you sure you want to delete this entry?");

        if (!confirm) {
            return;
        }

        setIsLoading(true);
        deleteRewardById(rewardId).then((res) => {
            if (res.status === 201 && res.data.affectedRows > 0) {
                setShowToast(true);
                toast.success("Delete Successful", {
                    position: toast.POSITION.TOP_CENTER, hideProgressBar: false, autoClose: 1000,
                    onClose: () => {
                        window.location.reload();
                    }
                });
            } else {
                toast.error("Data not found!", { position: toast.POSITION.TOP_CENTER, hideProgressBar: false, autoClose: 1000 });
            }
        }).catch(err => {
            setIsLoading(false);
            errorHandler(err, setSessionModal, setShowToast, toast);
        });
    };


    // logs out a user
    const handleLogout = async () => {
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location = "/";
    };

    // handle toast close
    const handleCloseToast = () => {
        setShowToast(false);
    };


    console.log("customer", customer);
    console.log("rewards", rewards);


    // handle QR code modal
    const handleQRCodeModal = (qrCodeValue) => {
        console.log("qr code value", qrCodeValue);
        setShowQRCodeModal(true);
        setQRCodeValue(qrCodeValue);
    };


    // handle QR code modal
    const handleSettingsModal = () => {
        if (apps === null || apps === undefined) {
            alert('Please select an app first');
        } else {
            setShowSettingsModal(true);
        }
    }

    console.log("qr code value", qrCodeValue);
    console.log('query string', qryString);

    return (
        <>
            {(userRole === 'administrator' || userRole === 'client') || isRickCase ?
                <div>
                    <a href="/rewards" className="btn btn-sm btn-secondary">Reward and Task Home</a>
                    {/* {del &&
                <div>Deleting Item...</div>
            } */}
                    {showToast && <div className="toast-overlay"></div>}
                    <ToastContainer onClose={handleCloseToast} />
                    {showQRCodeModal && <QRCodeModal show={showQRCodeModal} handleClose={() => setShowQRCodeModal(false)} qrCodeValue={qrCodeValue} />}
                    {showSettingsModal && <SettingsModal show={showSettingsModal} appId={apps} programType={programType} programTerms={programTerms} handleClose={() => setShowSettingsModal(false)} />}
                    <div className="card">
                        <div className="mb-3 row">
                            <div className="col-md-6">
                                <h5 className="card-header">Rewards and Task Management</h5>
                            </div>
                            {userRole === 'administrator' &&
                                <div className="col-md-6 d-flex justify-content-end" style={{ paddingTop: "10px", paddingRight: "20px", paddingTop: "10px", paddingTop: "10px" }}>
                                    <button className="btn btn-primary" style={{ height: '40px', width: '100px' }} onClick={handleSettingsModal}>Settings</button>
                                </div>}
                        </div>
                        <div className="mb-3 row">
                            <div className="col-md-6">
                                <div style={{ paddingLeft: "10px" }}>
                                    <a href={addUrl} type="button" className="btn btn-primary col-md-6">Add Reward or Task</a>
                                </div>
                            </div>
                            <div className="col-md-5">
                                <input className="form-control" type="search" id="search_input" name="search_inputs" value={searchkey || ''} onChange={handleChange} placeholder="search ..." />
                            </div>
                        </div>
                        <div>
                            <SessionExpiredModal
                                show={sessionModal}
                                handleLogout={handleLogout}
                            />
                        </div>
                        {/* <div className="mb-4 row" style={{ paddingLeft: "20px" }}>
                            <div style={{ display: "flex", marginBottom: '1rem', marginLeft: '.1rem', paddingLeft: '10px' }} >
                                <label className="mx-2 col-form-label">Lowest Point Range </label>
                                <div className="col-md-3">
                                    <input className="form-control" type="number" id="lowest_point_range" name="lowest_point_range" value={lowest || ''} onChange={handleChange} placeholder="lowest point range ..." />
                                </div>
                                <label className="mx-2 col-form-label">Highest Point Range </label>
                                <div className="col-md-3">
                                    <input className="form-control" type="number" id="highest_point_range" name="highest_point_range" value={highest || ''} onChange={handleChange} placeholder="highest point range ..." />
                                </div>
                            </div>
                        </div> */}
                        <div className="mb-3 row" style={{ paddingLeft: '20px', paddingRight: '10px' }}>
                            <div className="col-md-3">
                                <label className="mx-2 col-form-label">Kind</label>
                                <select className="form-select" id="kind" name="kind" onChange={handleChange} value={kind} >
                                    <option value="">---Kind---</option>
                                    <option value="reward">Reward</option>
                                    <option value="task">Task</option>
                                </select>
                            </div>
                            <div className="col-md-3">
                                <label className="mx-2 col-form-label">Customer</label>
                                {apps && loc ?
                                    <select className="form-select" id="customer" name="customer" onChange={handleChange} value={customer} >
                                        <option value="">---Customer---</option>
                                        {customers && customers.length > 0 && customers.map((customerObj) => (
                                            <option key={customerObj.id} value={customerObj.id}>{customerObj.name}</option>
                                        ))}
                                    </select>
                                    :
                                    <select className="form-select" id="customer" name="customer" onChange={handleChange} value={customer} disabled>
                                        <option value="">Select App and Location to Activate Customer Selection</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </select>
                                }
                            </div>
                            <div className="col-md-3">
                                <label className="mx-2 col-form-label">QR Code</label>
                                <input className="form-control" type="text" id="qrcode" name="qrcode" value={qrCode || ''} onChange={handleChange} placeholder="qr code ..." />
                            </div>
                        </div>
                        <div className="mb-3 row" style={{ paddingLeft: '20px', paddingRight: '10px' }}>
                            <div className="col-md-3">
                                <label className="mx-2 col-form-label">One Time</label>
                                <select className="form-select" id="redeemed" name="redeemed" onChange={handleChange} value={redeem} >
                                    <option value="">---One Time---</option>
                                    <option value="1">Yes</option>
                                    <option value="0">No</option>
                                </select>
                            </div>
                            <div className="col-md-3">
                                <label className="mx-2 col-form-label">Expired</label>
                                <select className="form-select" id="expired" name="expired" onChange={handleChange} value={expired} >
                                    <option value="">---Expired---</option>
                                    <option value="0">Yes</option>
                                    <option value="1">No</option>
                                </select>
                            </div>
                            <div className="col-md-3">
                                <label className="mx-2 col-form-label">Sort</label>
                                <select className="form-select" id="sort" name="sort" onChange={handleChange} value={sort} >
                                    <option value="">--Sort--</option>
                                    <option value="0">Reward Name</option>
                                    <option value="1">Reward Points</option>
                                    <option value="2">Reward Status</option>
                                    <option value="3">Reward Date</option>
                                    <option value="4">Reward Expiration Date</option>
                                    <option value="5">Reward Task Status</option>
                                    <option value="6">Reward Task Date</option>
                                    <option value="7">Reward Task Expiry Date</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="mb-4 row" style={{ marginTop: "20px" }}>
                        <div className="d-flex flex-wrap">
                            <AppsDropDownComponent
                                apps={apps}
                                handleChange={handleChange}
                                applist={applist}
                                className="me-1" // Add me-1 class to add margin between components
                            />
                            <LocationsDropDownComponent
                                loc={loc}
                                handleChange={handleChange}
                                location={location}
                                className="me-1" // Add me-1 class to add margin between components
                            />
                        </div>
                    </div>
                    <div className="alert alert-primary" role='alert'><strong>{numberOfRows} reward and task items found</strong></div>
                    {isLoading ?
                        <div>
                            <Loading />
                        </div> : <div>
                            <table className="table table-sm table-bordered" style={{ paddingLeft: '30px', paddingRight: '20px' }}>
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Description</th>
                                        <th>Points</th>
                                        <th>One Time</th>
                                        <th>Image</th>
                                        <th>QR Code</th>
                                        <th>Kind</th>
                                        <th>Created</th>
                                        <th>Updated</th>
                                        <th>Expiration</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody className="table-border-bottom-0">
                                    {rewards && rewards.length > 0 && rewards.map((rObj) => (
                                        <tr key={rObj.id}>
                                            <td><ReadMore>{rObj.title.replace(/"/g, '')}</ReadMore></td>
                                            <td><ReadMore>{rObj.description.replace(/["]/g, '')}</ReadMore></td>
                                            <td>{rObj.points}</td>
                                            <td>{rObj.redeem_once == 1 ? 'Yes' : 'No'}</td>
                                            <td>
                                                {rObj.image_url === null ?
                                                    null :
                                                    <div className="col-md-3">
                                                        <img src={rObj.image_url} alt="reward" width="48" height="48" />
                                                    </div>
                                                }
                                            </td>
                                            <td style={{ 'cursor': 'pointer' }} onClick={() => handleQRCodeModal(rObj.qr_code_value)}><QRCodeGenerator value={rObj.qr_code_value} background={'#ffffff'} foreground={'#000000'} level={'M'} size={48} /></td>
                                            <td>{rObj.kind}</td>
                                            <td>{moment(rObj.created_at).isValid() ? moment(rObj.created_at).format('MM/DD/YYYY') : ''}</td>
                                            <td>{moment(rObj.updated_at).isValid() ? moment(rObj.updated_at).format('MM/DD/YYYY') : ''}</td>
                                            <td>{moment(rObj.expires_at).isValid() ? moment(rObj.expires_at).format('MM/DD/YYYY') : ''}</td>
                                            <td>
                                                <div className="btn-group">
                                                    <a href={`/rewards/edit/${rObj.id}${qryString}`}>
                                                        <button className="btn btn-sm btn-outline-secondary"><i className='bx bxs-edit'></i></button>
                                                    </a>
                                                    <button className="btn btn-sm btn-outline-secondary" onClick={() => handleDeleteReward(rObj.id)}><i className='bx bxs-trash'></i></button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="pagination-container" style={{ display: "flex", flexWrap: "wrap" }}>
                                <PaginationComponent
                                    currentPage={currentPage}
                                    setCurrentPage={setCurrentPage}
                                    numberOfPage={numberOfPage}
                                    setShowToast={setShowToast}
                                    numVisiblePages={50}
                                />
                            </div>
                        </div>}
                </div>
                : <h1>Rewards Module</h1>}
        </>
    );
};

export default Rewards;
