import React from "react";



const SortDepartment = ({handleChange, sort, loc}) => {
    return (
        <>
            {loc? 
                <>
                    <label htmlFor="html5-text-input" className="mx-2 col-form-label" style={{color:'black'}}>Sort By </label>
                    <div className="col-md-3"> 
                        <select className="form-select" id="sort" name="sort" onChange={handleChange} value={sort} >
                            <option value="">Select Sort Option</option>
                            <option value="1">Department Order</option>
                            <option value="2">Department Name</option>
                            <option value="4">Department Order DESC</option>
                            <option value="5">Department Name DESC</option>
                        </select>
                    </div>
                </>:<>
                    <label htmlFor="html5-text-input" className="mx-2 col-form-label" style={{color:'black'}}>Sort By </label>
                    <div className="col-md-3"> 
                        <select className="form-select" id="sort" name="sort" onChange={handleChange} value={sort} >
                            <option value="">Select Sort Option</option>
                            <option value="1">Department Order</option>
                            <option value="2">Department Name</option>
                            <option value="3">Location Name</option>
                            <option value="4">Department Order DESC</option>
                            <option value="5">Department Name DESC</option>
                            <option value="6">Location Name DESC</option>
                        </select>
                    </div>
                </>
            }
        </>
    )
};


export default SortDepartment;