import { Pagination } from "react-bootstrap";
import React from "react";
import { toast } from "react-toastify";


const PaginationComponent = ({ currentPage, setCurrentPage, numberOfPage, setShowToast, numVisiblePages  }) => {
    // pagination click function
    const handleClickNext =() =>{
        const cpage = currentPage+1;
        setCurrentPage(cpage);
        if(cpage>numberOfPage){
            setShowToast(true);
            toast.error(cpage+' out of range',{ position: toast.POSITION.TOP_CENTER, hideProgressBar: false, autoClose:2000 });
            setCurrentPage(numberOfPage);
        }
    };

    // pagination click function
    const handleClickPrevious =() =>{
        const cpage = currentPage-1;
        //fetchDataNext(currentPage-1);
        setCurrentPage(cpage);
        if(cpage<1){
            setShowToast(true);
            toast.error(cpage+' out of range',{ position: toast.POSITION.TOP_CENTER, hideProgressBar: false, autoClose:2000 });
            setCurrentPage(1);
        }
    };

    let items = [];
    const visiblePages = numVisiblePages;
    const startPage = Math.max(currentPage - Math.floor(visiblePages / 2), 1);
    const endPage = Math.min(startPage + visiblePages - 1, numberOfPage);

    if (numberOfPage > 0) {
        // First page button
        items.push(
            <Pagination.First
                key="first-page"
                disabled={currentPage === 1}
                onClick={() => setCurrentPage(1)}
            />
        );

        // Previous page button
        items.push(
            <Pagination.Prev
                key="prev-page"
                disabled={currentPage === 1}
                onClick={handleClickPrevious}
            />
        );

        // Pagination items
        for (let number = startPage; number <= endPage; number++) {
            items.push(
                <Pagination.Item
                    key={number}
                    active={number === currentPage}
                    onClick={(event) => paginationClicked(event)}
                >
                    {number}
                </Pagination.Item>
            );
        };

        // Next page button
        items.push(
            <Pagination.Next
                key="next-page"
                disabled={currentPage === numberOfPage}
                onClick={handleClickNext}
            />
        );

        // Last page button
        items.push(
            <Pagination.Last
                key="last-page"
                disabled={currentPage === numberOfPage}
                onClick={() => setCurrentPage(numberOfPage)}
            />
        );
        } else {
        // Show a message or placeholder instead of pagination buttons
        items.push(
            <div key="no-data-message"></div>
        );
    };

    const paginationClicked = (event) => {
        setCurrentPage(parseInt(event.target.text));
    };

    return (
        <div style={{height:'5px', width:'5px'}}>
            <Pagination size="sm">{items}</Pagination>
        </div>
    );
};

export default PaginationComponent;