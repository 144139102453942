import React from "react";


const DashboardBody = ({ children }) => {

    return (
        <div className="content-wrapper">
            <div className="container-xxl flex-grow-1 container-p-y">
                {/* Start  */}

                {children}

                {/* End     */}


            </div>
            <div className="content-backdrop fade"></div>
        </div>
    );

}


export default DashboardBody;