import React, { useState } from "react";
import { GoogleMap, InfoWindow, LoadScript, Marker } from "@react-google-maps/api";

const MapComponent = ({ lat, lng, setLat, setLng }) => {

    console.log(lat, lng)

    const lati = lat && lat !== '' ? parseFloat(lat) : 40.7127753;
    const long = lng && lng !== '' ? parseFloat(lng) : -74.0059728;


    console.log(lati, long)
    console.log(typeof (lati), typeof (long))

    const initialMarkers = [
        {
            position: {
                lat: 40.7127753,
                lng: -74.0059728
            },
            label: { color: "white", text: "P1" },
            draggable: true
        },
    ];

    const [activeInfoWindow, setActiveInfoWindow] = useState("");
    const [markers, setMarkers] = useState(initialMarkers);

    const containerStyle = {
        width: "100%",
        height: "400px",
        paddingBottom: "200px"
    }

    const center = {
        lat: lati ? lati : 40.7127753,
        lng: long ? long : -74.0059728,
    }

    const mapClicked = (event) => {
        console.log(event.latLng.lat(), event.latLng.lng())
        setLng(event.latLng.lng())
        setLat(event.latLng.lat())
    }

    const markerClicked = (marker, index) => {
        setActiveInfoWindow(index)
        console.log(marker, index)
    }

    const markerDragEnd = (event, index) => {
        console.log(event.latLng.lat())
        console.log(event.latLng.lng())
    }

    return (
        <LoadScript googleMapsApiKey='AIzaSyBpTxl8mi_iI0CnYz-Hg2mr6X7WA-9nsQU'>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={15}
                onClick={mapClicked}
            >
                {markers.map((marker, index) => (
                    <Marker
                        key={index}
                        position={marker.position}
                        label={marker.label}
                        draggable={marker.draggable}
                        onDragEnd={event => markerDragEnd(event, index)}
                        onClick={event => markerClicked(marker, index)}
                    >
                        {
                            (activeInfoWindow === index)
                            &&
                            <InfoWindow position={marker.position}>
                                <b>{marker.position.lat}, {marker.position.lng}</b>
                            </InfoWindow>
                        }
                    </Marker>
                ))}
            </GoogleMap>
        </LoadScript>
    );
};

export default MapComponent;
