import React from "react";


const Features = () =>{

    return(
        <div>
            I am Features
        </div>
    );
}

export default Features;