import axiosInstance from "../services/AxiosInstance";


// get departments for header dropdown
export const departmentData = (apps, loc, applist) => {
    let url = "";
    console.log(loc); 
    if(apps && loc){
        url = url + "/v2/department/allbylocation?location_id="+loc;
    }
    else if(apps && !loc){
        url = url + "/v2/department/all?app_id="+apps;
    }
    else {
        const userData = JSON.parse(localStorage.getItem('user'));
        const appsList = userData.apps;
        const role = userData.role;
        if(role==='administrator'){
            url = url + "/v2/department/all";
        }else if(applist && applist.length>0){
            if(appsList.length>0){
                const appIds = [];
                for(const item of appsList){
                    appIds.push(item.id);
                }
            url = url + "/v2/department/all-app?appId=["+appIds+"]";
            }
        } else{
            url = url + "/v2/department/all-app?appId=[-1]";
        }
    }
    console.log(url);   
    return axiosInstance.get(url);
};



// get all departments
export const getDepartments = (apps, loc, deptPerPage, searchkey, currentPage, sort) => {
    console.log('cpage', currentPage)
    let offset;
    if(currentPage){
        offset = (currentPage - 1) * deptPerPage;
    }else{
        offset = 0;
    };
    let url = "";
    let questionadded = false;
    
    if(apps && loc){
        url = url + "/v2/department/dept-location/"+offset+"/"+deptPerPage+"?locationId="+loc;
        questionadded = true;
    }else if(apps && !loc){
        url = url + "/v2/department/dept-single-app/"+offset+"/"+deptPerPage+"?appId="+apps;
        questionadded = true;
    }else{
        const user = JSON.parse(localStorage.getItem('user'));
        const appsList = user.apps;
        const role = user.role;

        if(role === "administrator"){
            url = url + "/v2/department/dept-all-apps/"+offset+"/"+deptPerPage;
        }else if(appsList && appsList.length > 0){
            let appids = [];
            for(let i=0; i<appsList.length; i++){
                appids.push(appsList[i].id);
            }
            url = url + "/v2/department/dept-all-apps/"+offset+"/"+deptPerPage+"?appId=["+appids+"]";
            questionadded = true;
        }else{
            url = url + "/v2/department/dept-all-apps/"+offset+"/"+deptPerPage+"?appId=[-1]";
            questionadded = true;
        }
    } 

    if(searchkey && searchkey !== ""){
        const skey = encodeURIComponent(searchkey);
        if(questionadded){
            url = url + "&searchkey="+skey;
        }else{
            url = url + "?searchkey="+skey;
            questionadded = true;
        };
    }

    if(sort){
        if (questionadded) {
            url = url + '&sort=' + sort;
        } else {
            url = url + '?sort=' + sort;
            questionadded = true;
        }

    }

    console.log(url)
    return axiosInstance.get(url);
};



// get department count 
export const getDepartmentCount = (apps, loc, searchkey) => {
    let url = "";
    let questionadded = false;

    if(apps && loc){
        url = url + "/v2/department/deptcount-location?locationId="+loc;
        questionadded = true;
    }else if(apps && !loc){
        url = url + "/v2/department/deptcount-single-app?appId="+apps;
        questionadded = true;
    }else{
        const user = JSON.parse(localStorage.getItem('user'));
        const appsList = user.apps;
        const role = user.role;
        
        if(role === "administrator"){
            url = url + "/v2/department/deptcount-all-apps";
        }else if(appsList && appsList.length > 0){
            let appids = [];
            for(let i=0; i<appsList.length; i++){
                appids.push(appsList[i].id);
            }
            url = url + "/v2/department/deptcount-all-apps?appId=["+appids+"]";
            questionadded = true;
        }else{
            url = url + "/v2/department/deptcount-all-apps?appId=[-1]";
            questionadded = true;
        }
    }

    if(searchkey && searchkey !== ""){
        const skey = encodeURIComponent(searchkey);
        if(questionadded){
            url = url + "&searchkey="+skey;
        }else{
            url = url + "?searchkey="+skey;
            questionadded = true;
        };
    }

    console.log(url);
    return axiosInstance.get(url);
};


// add department
export const addDepartment = (newDept, loc) => {
    return axiosInstance.post("/v2/department/add", {
        department:newDept,
        locationId:loc
    });
};


// update department by location
export const updateDepartmentByLoc = (loc, item, newDept) => {
    return axiosInstance.patch("/v2/department/update-by-loc", {
        locationId:loc,
        oldDepartment:item,
        newDepartment:newDept
    });
};


// inactive now
// update department by app
// export const updateDepartmentByApp = (apps, item, newDept) => {
//     return axiosInstance.patch("/v2/department/update-by-app", {
//         appId:apps,
//         oldDepartment:item,
//         newDepartment:newDept
//     });
// };


// update department name for staff
export const updateDepartmentName = (apps, loc, item, newDept) => {
    return axiosInstance.patch("/v2/staff/update-department-name", {
        appId:apps,
        locationId:loc,
        oldDepartment:item,
        newDepartment:newDept
    });
};


// reorder departments
export const reorderDepartments = (loc, departlist) => {
    return axiosInstance.patch("/v2/department/update-order", {
        locationId:loc,
        data:departlist
    });
};


// delete department by location
export const deleteDepartmentByLoc = (loc, item) => {
    return axiosInstance.delete("/v2/department/delete-by-loc", {
        data:{
            locationId:loc,
            department:item
        }
    });
};



// inactive now
// delete department by app
// export const deleteDepartmentByApp = (apps, item) => {
//     return axiosInstance.delete("/v2/department/delete-by-app", {
//         data:{
//             appId:apps,
//             department:item
//         }
//     });
// };