import React from 'react';

const DepartmentDropDownComponent = ({ handleChange, depart, departmentlist }) => {
    let currentDepartment = '';
    if (depart && depart !== 'null' && depart !== null && typeof depart === 'string') {
        console.log(depart + 'from departmentdropdowncomponent');
        const currenDepartment = departmentlist.filter((department) => department.department === depart);
        //console.log('hello from '+currenDepartment);
        if (currenDepartment && currenDepartment.length > 0) {
            currentDepartment = currenDepartment[0].department;
        }
        //console.log(currentDepartment);
    }

    return (

        <><label className="mx-2 col-form-label">Dept</label><div className="col-md-3">
            <select className="form-select" id="department" name="department" onChange={handleChange} value={depart || currentDepartment}>
                <option key='' value="">--Select Department--</option>
                {departmentlist && departmentlist.length > 0 && departmentlist.map((departmentObj) => (
                    <option key={departmentObj.department} value={departmentObj.department}>{departmentObj.department}</option>
                ))}
            </select>
        </div></>

    );
};

export default DepartmentDropDownComponent;
