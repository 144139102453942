// return location information
import axiosInstance from "../services/AxiosInstance";


// get all locations for header dropdown
export const locationData = (apps) => {
    if (apps) {
        const url = "/v2/location/apps/" + apps;
        console.log(url)
        return axiosInstance.get(url);
    }
};

// get location count
export const getLocationCount = async () => {
    const url = `/v2/location/searchcount?app_status=1`;
    try {
        const response = await axiosInstance.get(url);
        console.log(url);
        return response;
    } catch (error) {
        // Handle error here
        console.error('Error fetching location count:', error);
        throw error; // Rethrow the error for the caller to handle
    }
};


// get all locations for admin
export const getAllLocations = async (locationsPerPage) => {
    const url = `/v2/location/search?offset=0&limit=${locationsPerPage}&sort=0&app_status=1`;
    try {
        const response = await axiosInstance.get(url);
        console.log(url);
        console.log(response.data.length);
        return response;
    } catch (error) {
        // Handle error here
        console.error('Error fetching locations:', error);
        throw error; // Rethrow the error for the caller to handle
    }
};