import React from "react";
import { useEffect, useState } from 'react';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SessionExpiredModal from '../SessionExpire/SessionExpiredModal';
import { errorHandler } from "../Utils/errorHandler";
import { getRewardById, updateRewardById } from "../../apis/RewardsAPI";
import moment from 'moment';
import AddUpdateLoading from "../Utils/AddUpdateLoading";
import { useParams } from 'react-router-dom';
import Loading from "../Utils/Loading";


const EditRewards = () => {
    const { reward_id } = useParams();
    const [submitting, setSubmitting] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [sessionModal, setSessionModal] = useState(false);
    const [backUrl] = useState("/rewards" + window.location.search);
    const [loading, setLoading] = useState(false);
    const [role, setRole] = useState(null);
    const [inputs, setInputs] = useState([]);
    const [showUpdateLoading, setShowUpdateLoading] = useState(false);


    console.log(reward_id, 'id');

    // load reward by id
    useEffect(() => {
        if (reward_id) {
            setLoading(true);
            getRewardById(reward_id).then((response) => {
                if (response.status === 200) {
                    const reward = response.data[0];
                    console.log(reward, 'reward');
                    setInputs(inputs => ({ ...inputs, location_id: reward.location_id }));
                    setInputs(inputs => ({ ...inputs, kind: reward.kind }));
                    setInputs(inputs => ({ ...inputs, title: reward.title }));
                    setInputs(inputs => ({ ...inputs, description: reward.description }));
                    setInputs(inputs => ({ ...inputs, points: reward.points }));
                    setInputs(inputs => ({ ...inputs, can_only_redeem_once: reward.can_only_redeem_once }));
                    setInputs(inputs => ({ ...inputs, dms_point_type: reward.dms_point_type }));
                    setInputs(inputs => ({ ...inputs, activate_dms_mapping: reward.activate_dms_mapping }));
                    setInputs(inputs => ({ ...inputs, expires_at: moment(reward.expires_at).format('YYYY-MM-DD HH:mm:ss') }));
                    setInputs(inputs => ({ ...inputs, image_url: reward.image_url }));
                    setInputs(inputs => ({ ...inputs, expired_number_of_date: reward.expired_number_of_date }));
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            }).catch(err => {
                setLoading(false);
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        }
    }, [reward_id]);


    // handle user input
    const handleChange = (event) => {
        const name = event.target.name;
        let value;
        let filen;
        if (event.target.type === 'select-one') {
            value = document.getElementById(name).value;
            const selectedIndex = event.target.selectedIndex;

            if (name === "kind") {
                if (selectedIndex === 0) {
                    setInputs(inputs => ({ ...inputs, kind: null }));
                }
                setInputs(inputs => ({ ...inputs, kind: value }));
            }
            if (name === "can_only_redeem_once") {
                if (selectedIndex === 0) {
                    setInputs(inputs => ({ ...inputs, can_only_redeem_once: null }));
                }
                setInputs(inputs => ({ ...inputs, can_only_redeem_once: parseInt(value) }));
            }
            if (name === "dms_point_type") {
                if (selectedIndex === 0) {
                    setInputs(inputs => ({ ...inputs, dms_point_type: null }));
                }
                setInputs(inputs => ({ ...inputs, dms_point_type: value }));
                if (value === '2' || parseInt(value) === 2) {
                    setInputs(inputs => ({ ...inputs, points: null }));
                }
            }
            if (name === "activate_dms_mapping") {
                if (selectedIndex === 0) {
                    setInputs(inputs => ({ ...inputs, activate_dms_mapping: null }));
                }
                setInputs(inputs => ({ ...inputs, activate_dms_mapping: parseInt(value) }));
                if (value === '0' || parseInt(value) === 0) {
                    setInputs(inputs => ({ ...inputs, expired_number_of_date: null }));
                }
            }
        }
        else if (event.target.type === 'file') {
            filen = event.target.files[0];
            const maxSize = 1024 * 1024;
          
            const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp','svg','svgz','tif','tiff','heif','cr3','arw','orf','pef','ref','sr2','dng'];

            console.log("File Size"+filen.size);
            console.log("Max Size"+maxSize);

            if (filen.size > maxSize) {
                setShowToast(true);
                toast.error(`File size must be less than 1MB `, { position: toast.POSITION.TOP_CENTER, autoClose: false, hideProgressBar: true });
                return false;
            } else if (!allowedExtensions.includes(filen.name.split('.').pop().toLowerCase())){

                setShowToast(true);
                toast.error(`file type should be jpg, jpeg, png, gif, bmp, webp `, { position: toast.POSITION.TOP_CENTER, autoClose: false, hideProgressBar: true });
                return false;
            }
            else {
                setInputs(inputs => ({ ...inputs, reward_input_file: filen }));
                setInputs(inputs => ({ ...inputs, old_image_url: inputs.image_url }));
                setInputs(inputs => ({ ...inputs, delete_image: 1 }));
            }
        }
        else {
            value = event.target.value;
            if (name === "title") {
                setInputs(inputs => ({ ...inputs, title: value }));
            }
            if (name === "description") {
                setInputs(inputs => ({ ...inputs, description: value }));
            }
            if (name === "points") {
                if (inputs.dms_point_type === '2' || inputs.dms_point_type === 2) {
                    setInputs(inputs => ({ ...inputs, points: null }));
                } else {
                    setInputs(inputs => ({ ...inputs, points: value }));
                }
            }
            if (name === "expires_at") {
                setInputs(inputs => ({ ...inputs, expires_at: value }));
            }
            if (name === "expired_number_of_date") {
                if (inputs.activate_dms_mapping === '0' || inputs.activate_dms_mapping === 0) {
                    setInputs(inputs => ({ ...inputs, expired_number_of_date: null }));
                } else {
                    if (value === '') {
                        setInputs(inputs => ({ ...inputs, expired_number_of_date: null }));
                    } else if (parseInt(value) < 0) {
                        setInputs(inputs => ({ ...inputs, expired_number_of_date: null }));
                    } else if (typeof parseInt(value) !== 'number') {
                        setInputs(inputs => ({ ...inputs, expired_number_of_date: null }));
                    } else {
                        setInputs(inputs => ({ ...inputs, expired_number_of_date: value }));
                    }
                }
            }
        }
    };

    // get user role
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            setRole(user.role);
        }
    }, []);


    // handles form submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);
        setShowUpdateLoading(true);
        const formData = new FormData();
        formData.append('location_id', inputs.location_id);
        formData.append('kind', inputs.kind);
        formData.append('title', inputs.title);
        formData.append('description', inputs.description);
        formData.append('points', inputs.points);
        formData.append('can_only_redeem_once', inputs.can_only_redeem_once);
        formData.append('dms_point_type', inputs.dms_point_type);
        formData.append('activate_dms_mapping', inputs.activate_dms_mapping);
        formData.append('expires_at', inputs.expires_at);
        formData.append('reward_input_file', inputs.reward_input_file);
        formData.append('expired_number_of_date', inputs.expired_number_of_date);

        if (inputs.old_image_url) {
            formData.append('old_image_url', inputs.old_image_url);
            formData.append('delete_image', inputs.delete_image);
        }

        await updateRewardById(reward_id, formData).then((res) => {
            if (res.status === 201 && res.data.affectedRows > 0) {
                setShowToast(true);
                toast.success(`Successfully Saved ${inputs.kind}`, {
                    position: toast.POSITION.TOP_CENTER, autoClose: 3000, hideProgressBar: false,
                    onClose: () => {
                        window.location.reload();
                    }
                });
            } else {
                setShowToast(true);
                toast.error(`Failed to save!`, { position: toast.POSITION.TOP_CENTER, autoClose: false, hideProgressBar: true });
            }
        }).catch(err => {
            errorHandler(err, setSessionModal, setShowToast, toast);
        });
        setSubmitting(false);
        setShowUpdateLoading(false);
    }


    // logs out a user
    const handleLogout = async () => {
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location = "/";
    };

    // handle toast close
    const handleCloseToast = () => {
        setShowToast(false);
    };


    console.log(inputs, 'inputs');


    return (
        <div className="col-xl-12">
            {submitting &&
                <div>Submtting Form...</div>
            }
            {showToast && <div className="toast-overlay"></div>}
            <ToastContainer onClose={handleCloseToast} />
            <a href={backUrl} className="btn btn-sm rounded-pill btn-secondary">Back</a>  <a href={backUrl} className="btn btn-sm rounded-pill btn-secondary">Rewards Home</a> <a href="/rewards/add" className="btn btn-sm rounded-pill btn-secondary">Add Reward</a>
            <SessionExpiredModal
                show={sessionModal}
                handleLogout={handleLogout}
            />
            {loading ? <Loading /> :
                <form onSubmit={handleSubmit} >
                    <div className="card mb-4">
                        <h5 className="card-header">Editing {inputs.kind} - {reward_id}</h5>
                        {submitting ? <AddUpdateLoading show={showUpdateLoading} /> :
                            <div className="card-body">
                                <div className="mb-3 row">
                                    <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Kind<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                    <div className="col-md-10">
                                        <select className="form-select" id="kind" name="kind" onChange={handleChange} value={inputs.kind || ''} required>
                                            <option key="" value="">--Select Kind--</option>
                                            <option key="reward" value="reward">Reward</option>
                                            <option key="task" value="task">Task</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Title<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                    <div className="col-md-10">
                                        <input className="form-control" type="text" name="title" value={inputs.title || ''} onChange={handleChange} required />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Description<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                    <div className="col-md-10">
                                        <input className="form-control" type="text" name="description" value={inputs.description || ''} onChange={handleChange} required />
                                    </div>
                                </div>
                                {inputs.dms_point_type === '2' || inputs.dms_point_type === 2 ?
                                    <div className="mb-3 row">
                                        <label htmlFor="html5-email-input" className="col-md-2 col-form-label">Points<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                        <div className="col-md-10">
                                            <input className="form-control" type="number" name="points" value={inputs.points || ''} onChange={handleChange} disabled />
                                        </div>
                                    </div>
                                    :
                                    <div className="mb-3 row">
                                        <label htmlFor="html5-email-input" className="col-md-2 col-form-label">Points<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                        <div className="col-md-10">
                                            <input className="form-control" type="number" name="points" value={inputs.points || ''} onChange={handleChange} />
                                        </div>
                                    </div>
                                }
                                <div className="mb-3 row">
                                    <label htmlFor="html5-email-input" className="col-md-2 col-form-label">One Time<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                    <div className="col-md-10">
                                        <select className="form-select" id="can_only_redeem_once" name="can_only_redeem_once" onChange={handleChange} value={String(inputs.can_only_redeem_once) || ''} required>
                                            <option key="" value="">--Select One Time--</option>
                                            <option key="yes" value="1">Yes</option>
                                            <option key="no" value="0">No</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label className="col-md-2 col-form-label">Preview</label>
                                    <div className="col-md-10">
                                        <span >
                                            <img
                                                src={inputs.reward_input_file ? URL.createObjectURL(inputs.reward_input_file) : (inputs.image_url ? inputs.image_url : "no-image-found.gif")}
                                                height="200"
                                                width="200"
                                                alt="Reward/Task Image"
                                            />
                                        </span>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label htmlFor="html5-password-input" className="col-md-2 col-form-label">Image</label>
                                    <div className="mb-3 col-md-10">
                                        <label htmlFor="formFile" className="form-label">Upload image</label>
                                        <input className="form-control" type="file" id="reward_input_file" name="reward_input_file" onChange={handleChange} />
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Expiration Date<sup style={{ color: "red", fontSize: 12 }}>*</sup></label>
                                    <div className="col-md-10">
                                        <input className="form-control" type="datetime-local" name="expires_at" value={inputs.expires_at || ''} onChange={handleChange} />
                                    </div>
                                </div>
                                {role === 'administrator' ?
                                    <>
                                        <div className="mb-3 row">
                                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">DMS Point Type</label>
                                            <div className="col-md-10">
                                                <select className="form-select" id="dms_point_type" name="dms_point_type" onChange={handleChange} value={String(inputs.dms_point_type) || ''}>
                                                    <option key="" value="">--Select DMS Point Type--</option>
                                                    <option key="vehicle" value="1">Automated New Vehicle Points </option>
                                                    <option key="service" value="2">Automated Service and Parts points</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Activate DMS Mapping</label>
                                            <div className="col-md-10">
                                                <select className="form-select" id="activate_dms_mapping" name="activate_dms_mapping" onChange={handleChange} value={String(inputs.activate_dms_mapping) || ''}>
                                                    <option key="" value="">--Activate DMS Mapping--</option>
                                                    <option key="active" value="1">Active</option>
                                                    <option key="inactive" value="0">Inactive</option>
                                                </select>
                                            </div>
                                        </div>
                                        {
                                            inputs.activate_dms_mapping === '1' || inputs.activate_dms_mapping === 1 ?
                                                <div className="mb-3 row">
                                                    <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Expired Number of Days</label>
                                                    <div className="col-md-10">
                                                        <input className="form-control" type="number" name="expired_number_of_date" value={inputs.expired_number_of_date || ''} onChange={handleChange} />
                                                    </div>
                                                </div>
                                                : null
                                        }
                                    </>
                                    : null}
                            </div>
                        }
                        <div className="mb-3 row">
                            <div className="col-md-3">&nbsp;</div>
                            <div className="col-md-7"><input type="submit" className="btn btn-primary col-lg-10" value="Save" /></div>
                            <div className="col-md-2">&nbsp;</div>
                        </div>
                    </div>
                </form>
            }
        </div>
    );
}



export default EditRewards;
