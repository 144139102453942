import React from "react";


const Customization = () =>{

    return(
        <div>
            I am customization.
        </div>
    );
}

export default Customization;