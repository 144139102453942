import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SessionExpiredModal from '../SessionExpire/SessionExpiredModal';
import { errorHandler } from "../Utils/errorHandler";
import PaginationComponent from "../Pagination/Pagination";
import AppsDropDownComponent from "../PageTopComponents/AppDropDown";
import LocationsDropDownComponent from "../PageTopComponents/LocationDropDown";
import Loading from "../Utils/Loading";
import { locationData } from "../../apis/LocationAPI";
import { useLocation } from "react-router-dom";
import { profileCount, profileData, updateCustomerVIP } from "../../apis/ProfilesAPI";
import RewardModal from "./RewardModal";
import moment from "moment";


const CustomerProfiles = () => {

    // Load all apps
    const userData = JSON.parse(localStorage.getItem('user'));
    let applist = [];

    // getting user apps from browser storage memory
    if (userData.apps?.length > 0) {
        applist = userData.apps;
    }
    let endUserRole = userData?.role;

    const search = useLocation().search;
    const queryParams = new URLSearchParams(search);
    const [location, setLocation] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [timeoutid, setTimeoutid] = useState(null);

    // App ID
    const ap_id = queryParams.get('app_id');
    let app_id = null;
    if (ap_id) {
        app_id = parseInt(ap_id);
    } else {
        const clientUser = JSON.parse(localStorage.getItem('user'));
        if (clientUser?.apps?.length === 1) {
            app_id = parseInt(clientUser.apps[0]?.id);
        }
    }
    const [appSelected, setAppSelected] = useState(app_id);

    // Location ID
    const locat_id = queryParams.get('loc_id');
    let loc_id = null;
    if (locat_id) {
        loc_id = parseInt(locat_id);
    }
    let searchstr = queryParams.get('searchkey');

    // Search String
    if (!searchstr) {
        searchstr = "";
    }

    // Current PAge
    const pag = queryParams.get('page');
    let pg = 1;
    if (pag) {
        pg = parseInt(pag);
    }

    const [numberOfPage, setNumberOfPage] = useState(0);
    const [numberOfRows, setNumberOfRows] = useState(0);
    const [profilesPerPage] = useState(50);
    const [isLoading, setIsLoading] = useState(false);
    const [isCustomerCountLoading, setisCustomerCountLoading] = useState(false);
    const [sessionModal, setSessionModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [qryString, setQryString] = useState("");
    const [searchkey, setSearchkey] = useState(searchstr);
    const [currentPage, setCurrentPage] = useState(pg);
    const [firstLoad, setFirstLoad] = useState(1);
    const [locationSelected, setLocationSelected] = useState(loc_id);
    const [showModal, setShowModal] = useState(false);

    const [custId, setCustId] = useState(null);
    const [locId, setLocId] = useState(null);
    const [isVip, setIsVip] = useState(false);
    const [isVipEnabled, setIsVipEnabled] = useState(false);
    const [vipMultiplier, setVipMultiplier] = useState(1);

    function intervalexecute() {
        if (timeoutid) {
            console.log("need to clear timeoutid");
            clearTimeout(timeoutid);
        }
        const timeout = setTimeout(() => {

            //only first time current page will be what ever page user address pressed     
            let paramUpdate = true;
            setIsLoading(true);

            profileData(applist, appSelected, locationSelected, currentPage, searchkey, profilesPerPage, endUserRole).then((response) => {
                if (response.status === 200) {
                    setCustomerList(response.data);
                }
                setIsLoading(false);
            }).catch(err => {
                setIsLoading(false);
                errorHandler(err, setSessionModal, setShowToast, toast, "/customer_profiles");
            });

            profileCount(applist, appSelected, locationSelected, searchkey, endUserRole).then((response) => {
                if (response.status === 200) {
                    console.log(response.data);
                    const count = response.data[0].count;
                    console.log('Count is... ' + count);
                    const pageCount = Math.ceil(count / profilesPerPage);
                    setNumberOfRows(count);
                    setNumberOfPage(pageCount);
                    setisCustomerCountLoading(false);
                } else {
                    setNumberOfRows(0);
                    setNumberOfPage(0);
                    setisCustomerCountLoading(false);
                    errorHandler("Customer Count " + response.status, setSessionModal, setShowToast, toast);
                }
            }).catch(err => {
                setisCustomerCountLoading(false);
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
            // generate url in addressbar whenever any change take place
            if (paramUpdate) {
                updateQueryParams();
            }
        }, 2000);

        setTimeoutid(timeout);
    }

    useEffect(() => {
        if (firstLoad !== 1) { // first load not use search
            intervalexecute();
        }
    }, [searchkey]);

    // load all locations by app
    useEffect(() => {
        if (appSelected) {

            // passing apps id to get location list
            locationData(appSelected).then((response) => {
                if (response.status === 200) {
                    setLocation(response.data);
                } else {
                    setLocation([]);
                    // window.location = '/error';
                }
            }).catch(err => {
                errorHandler(err, setSessionModal, setShowToast, toast, "/customer_profiles");
            });
        }

        setIsLoading(true);
        setisCustomerCountLoading(true);
        if (firstLoad === 1) {
            // not first time load each search set current page to 1
            setFirstLoad(0);
            // as it is firstload no need to do param update
            console.log("first time load");
        }


        profileCount(applist, appSelected, locationSelected, searchkey, endUserRole).then((response) => {

            if (response.status === 200) {
                let count = response.data[0].count;
                console.log('Count is... ' + count);
                let pageCount = Math.ceil(count / profilesPerPage);
                setNumberOfRows(count);
                setNumberOfPage(pageCount);
                setisCustomerCountLoading(false);
            } else {
                setNumberOfRows(0);
                setNumberOfPage(0);
                setisCustomerCountLoading(false);
                errorHandler("Customer Count " + response.status, setSessionModal, setShowToast, toast);
            }
        }).catch(err => {
            setisCustomerCountLoading(false);
            errorHandler(err, setSessionModal, setShowToast, toast);
        });

        profileData(applist, appSelected, locationSelected, currentPage, searchkey, profilesPerPage, endUserRole).then((response) => {
            if (response.status === 200) {
                setCustomerList(response.data);
                setIsLoading(false);
            }
        }).catch(err => {
            setIsLoading(false);
            errorHandler(err, setSessionModal, setShowToast, toast, "/customer_profiles");
        });

        updateQueryParams();
    }, [appSelected, locationSelected, currentPage]);

    // Update the URL query params when the state of the hook changes
    const updateQueryParams = () => {
        console.log("address bar update ..");
        const queryParams = new URLSearchParams();
        if (appSelected) queryParams.set('app_id', appSelected);
        if (locationSelected) queryParams.set('loc_id', locationSelected);
        if (searchkey) queryParams.set('searchkey', searchkey);
        if (currentPage) queryParams.set('page', currentPage);
        const queryString = queryParams.toString();
        const newUrl = `${window.location.pathname}?${queryString}`;
        window.history.pushState({ path: newUrl }, '', newUrl);
        // setCurrentUrl(newUrl);
        // setaddUrl(`/addStaff?${queryString}`);
        // query string will be used for edit purpose
        setQryString(`?${queryString}`);
    };

    // handle inputs and change events
    const handleChange = async (event) => {
        console.log("handle fired");
        if (currentPage !== 1) {
            setCurrentPage(1);
        }
        let search = window.location.search;
        let params = new URLSearchParams(search);
        console.log(params);
        const name = event.target.name;
        let value;

        if (event.target.type === 'select-one') {
            let selectedIndex = event.target.selectedIndex;
            let tvalue = document.getElementById(name).value;
            // incase no value selected 
            if (selectedIndex === 0) {
                if (name === 'location_id') {
                    setLocationSelected(null);
                } else if (name === 'app_id') {
                    setAppSelected(null);
                    setLocationSelected(null);
                    setLocation([]);

                    // when unselect an app search string need to be empty
                    setSearchkey("");


                }
            } else {
                // converting to number 
                if (name === 'location_id') {
                    value = +tvalue;
                    setLocationSelected(value);
                } else if (name === 'app_id') {
                    value = +tvalue;
                    setAppSelected(value);
                    locationData(value);
                    setLocationSelected(null);
                }
            }
        } else {
            if (name === 'search_inputs') {
                setSearchkey(event.target.value);
            }
        }
    };


    // logs out a user
    const handleLogout = async () => {
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location = "/";
    };

    // handle toast close
    const handleCloseToast = () => {
        setShowToast(false);
    };

    // update vip status 
    const handleVIPClick = (event, param) => {
        console.log(param);

        updateCustomerVIP(param.id).then((response) => {

            if (response.status === 201 && response.data.affectedRows > 0) {
                setShowToast(true);
                toast.success(`Updated Successfully`, {
                    position: toast.POSITION.TOP_CENTER, autoClose: 500, hideProgressBar: false, onClose: () => {
                        window.location.reload();
                    }
                });
                // window.alert("success");
            }
            else {
                errorHandler("VIP Status update error " + response.status, setSessionModal, setShowToast, toast);
            }
        }).catch(err => {
            errorHandler(err, setSessionModal, setShowToast, toast);
        });
    };


    // handle reward modal
    const handleRewardModal = (cust_id, loc_id, is_vip, is_vip_enabled, vip_times) => {
        setCustId(cust_id);
        setLocId(loc_id);
        if (is_vip === 1) {
            setIsVip(true);
        } else {
            setIsVip(false);
        }
        if (is_vip_enabled === 1) {
            setIsVipEnabled(true);
        } else {
            setIsVipEnabled(false);
        }
        setVipMultiplier(vip_times);
        setShowModal(true);
    };

    return (
        <>
            <div>
                {showToast && <div className="toast-overlay"></div>}
                <ToastContainer onClose={handleCloseToast} />
                <div className="card">
                    <h5 className="card-header">Customer Profiles</h5>
                    <div className="mb-3 row">
                        <div style={{ display: "flex", justifyContent: "right", paddingInlineEnd: "30px", paddingBottom: "50px" }}>
                            <div className="col-md-5">


                                {!appSelected ?
                                    <input className="form-control" type="search" id="search_input" name="search_inputs" value={searchkey || ''} onChange={handleChange} disabled placeholder="Please Select an APP for enable search" />
                                    : <input className="form-control" type="search" id="search_input" name="search_inputs" value={searchkey || ''} onChange={handleChange} placeholder="Search customer name..." />


                                }
                            </div>
                        </div>
                    </div>
                    <div>
                        <SessionExpiredModal
                            show={sessionModal}
                            handleLogout={handleLogout}
                        />
                    </div>
                    <div className="mb-4 row" style={{ paddingLeft: "20px" }}>
                        <div className="d-flex flex-wrap">
                            <AppsDropDownComponent
                                apps={appSelected}
                                handleChange={handleChange}
                                applist={applist}
                                className="me-1" // Add me-1 class to add margin between components
                            />
                            <LocationsDropDownComponent
                                loc={locationSelected}
                                handleChange={handleChange}
                                location={location}
                                className="me-1" // Add me-1 class to add margin between components
                            />
                        </div>
                    </div>

                    <div>
                        <RewardModal
                            show={showModal}
                            setShow={setShowModal}
                            locationId={locId}
                            customerId={custId}
                            isVip={isVip}
                            isVipEnabled={isVipEnabled}
                            vipMultiplier={vipMultiplier}
                        />
                    </div>
                </div>
                {isCustomerCountLoading ?
                    <div className="alert alert-primary" role='alert'><strong>Calculating Customer Count...</strong>
                        <div style={{ float: "right" }}>
                        </div></div> :
                    <div className="alert alert-primary" role='alert'><strong>{numberOfRows} Customers found</strong>
                        <div style={{ float: "right" }}>
                        </div>
                    </div>}
                {isLoading ?
                    <div>
                        <Loading />
                    </div> : <div>
                        <div className="table-responsive text-nowrap">
                            <table className="table table-sm">
                                <thead>
                                    <tr>
                                        <th>Action</th>
                                        <th>VIP</th>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        {/* <th>Title</th> */}
                                        <th>Email</th>
                                        <th>Phone</th>
                                        {/* <th>Platform</th> */}
                                        <th>App</th>
                                        <th>Location</th>
                                        <th>Loyalty User</th>
                                         <th>Deleted</th> 
                                    </tr>
                                </thead>
                                <tbody className="table-border-bottom-0">



                                    {customerList && customerList.length > 0 && customerList.map((customerObj) => (
                                        <tr key={customerObj.id}>
                                            <td>
                                                <div className="dropdown">
                                                    <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="bx bx-dots-vertical-rounded"></i>
                                                    </button>
                                                    <div className="dropdown-menu" >
                                                        <a className="dropdown-item" href={"/customer/edit/" + customerObj.id + qryString} ><i className="bx bx-edit-alt me-1"></i> Edit</a>
                                                        <a href="/#" data-key={customerObj.id} className="dropdown-item"  ><i className="bx bx-trash me-1"></i> Delete</a>
                                                        {/* <a className="dropdown-item" onClick={() => { handleRewardModal(customerObj.id, customerObj.loc_id, customerObj.is_vip, customerObj.is_vip_enabled, customerObj.vip_times) }} style={{ cursor: 'pointer' }} ><i className="bx bx-award me-1"></i>
                                                            Add Reward
                                                        </a> */}
                                                    </div>
                                                </div>
                                               
                                                <i className={customerObj.deleted_at ? "bx bxs-trash" : ""} ></i>
                                            </td>
                                            <td>

                                                <label class="toggle" >
                                                    {customerObj.is_vip === 1 ? <input class='toggle-checkbox' type='checkbox' checked onClick={event => handleVIPClick(event, customerObj)} /> : <input class='toggle-checkbox' type='checkbox' onClick={event => handleVIPClick(event, customerObj)} />}

                                                    <div class="toggle-switch"></div>
                                                </label>




                                            </td>
                                            <td>
                                           <a href={"/customer/edit/" + customerObj.id + qryString} >                                                    
                                                <strong>{customerObj.first_name ? customerObj.first_name : ''}</strong></a>
                                            </td>
                                            <td><a href={"/customer/edit/" + customerObj.id + qryString} > {customerObj.last_name ? customerObj.last_name : ''} </a></td>

                                            <td> <a href={customerObj.email ? "mailto:" + customerObj.email : ''}> {customerObj.email ? customerObj.email : ''}</a></td>
                                            <td><a href={customerObj.phone ? "tel:" + customerObj.phone : ''}>{customerObj.phone ? customerObj.phone : ''}</a></td>
                                            {/* <td>{customerObj.platform ? customerObj.platform : ''}</td> */}
                                            <td>{customerObj.app ? customerObj.app : ''}</td>
                                            <td>{customerObj.location ? customerObj.location : ''}</td>
                                            <td>{customerObj.is_loyalty_rewards_user ? 'Yes' : 'No'}</td>
                                            <td>{customerObj.deleted_at ? moment(customerObj.deleted_at).format('MM/DD/YYYY')  : ''}</td>
                                        
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="pagination-container" style={{ display: "flex", flexWrap: "wrap" }}>
                            <PaginationComponent
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                numberOfPage={numberOfPage}
                                setShowToast={setShowToast}
                                numVisiblePages={50}
                            />
                        </div>
                    </div>}
            </div>
        </>
    );
};


export default CustomerProfiles;