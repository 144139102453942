import React from "react";
import { toast } from "react-toastify";
import axiosInstance from "../../services/AxiosInstance";
import Modal from 'react-bootstrap/Modal';


const AnalyticsEmail = ({emails, setEmails, apps, singleEmail, setSingleEmail, showEmail, setShowEmail}) => {
    // set single email
    const handleSingleEmail = (event) => {
        setSingleEmail(event.target.value);
    };

    // get email input
    const handleEmailInput = (event) => {
        if (event.key === 'Enter' || event.key === ',' || event.key === ' ') {
            event.preventDefault(); // prevent the comma from being entered into the input field
            const newEmail = event.target.value.trim().replace(',', ''); // remove any commas from the email
            setSingleEmail('');
            if (newEmail) {
                setEmails([...emails, newEmail]);
                event.target.value = ''; // reset the input field to an empty string
            }
        }
    };

    console.log(emails, singleEmail, 'emails');
    console.log(showEmail, 'showEmail');


    // edit email from list
    const handleEditEmail = (index, newEmail) => {
        if (newEmail !== null) {
            setEmails(emails.map((email, i) => (i === index ? newEmail : email)));
        }
    }

    // remove email from list
    const handleRemoveEmail = (index) => {
        setEmails(emails.filter((_, i) => i !== index));
    }


    // save email input
    const handleEmailSave = (event) => {
        // send to API
        if(apps){
            let data;
            if(emails.length){
                const email = emails.join(',');
                console.log(email);
                data = {
                    email: email,
                    appId: apps,
                };
                axiosInstance.post('/v2/analytics/save-analytics-report-email', data).then((response) => {
                    if(response.status === 201){
                        toast.success('Emails saved successfully', { position: toast.POSITION.TOP_CENTER, hideProgressBar: false, autoClose:3000,
                            onclose: () => {
                                setSingleEmail('');
                                setEmails([]);
                                setShowEmail(false);
                            }
                        });
                        setEmails([]);
                        setSingleEmail('');
                    }else{
                        toast.error("Email save failed!",{ position: toast.POSITION.TOP_CENTER, hideProgressBar: false, autoClose:3000});
                    };
                }).catch(err => {
                        toast.error(err, { position: toast.POSITION.TOP_CENTER, hideProgressBar: false, autoClose:3000});
                        // errorHandler(err, setSessionModal, setShowToast, toast, "/analytics");
                });
            }
            else if(singleEmail.length){
                data = {
                    email: singleEmail,
                    appId: apps,
                };
                axiosInstance.post('/v2/analytics/save-analytics-report-email', data).then((response) => {
                    if(response.status === 201){
                        toast.success('Emails saved successfully', { position: toast.POSITION.TOP_CENTER, hideProgressBar: false, autoClose:3000,
                            onClose: () => {
                                setSingleEmail('');
                                setEmails([]);
                                setShowEmail(false);
                            }
                        });
                        setEmails([]);
                        setSingleEmail('');
                        // setShowEmail(false);
                    }else{
                        toast.error("Email save failed!",{ position: toast.POSITION.TOP_CENTER, hideProgressBar: false, autoClose:3000});
                    };
                }).catch(err => {
                        toast.error(err, { position: toast.POSITION.TOP_CENTER, hideProgressBar: false, autoClose:3000});
                        // errorHandler(err, setSessionModal, setShowToast, toast, "/analytics");
                });
            }
            else{
                toast.error("Please enter email first!",{ position: toast.POSITION.TOP_CENTER, hideProgressBar: false, autoClose:3000});
            };
        } else{
            toast.error("Please choose an app first!",{ position: toast.POSITION.TOP_CENTER, hideProgressBar: false, autoClose:3000});
        };
    };

    // close collapse
    const handleClose = () => {
        setShowEmail(false);
    };


    return (
        <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showEmail}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Monthly Analytics Report Email
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="card-body">
                        <div className="row" style={{display:'flex', justifyContent:'space-between'}}>
                            <div>
                                <h5>Add email(s) to receive monthly analytics report</h5>
                                <p style={{ color: '#2D87E2' }}>To add multiple emails: Press enter / press spacebar / add (,) after each email.</p>
                            </div>
                        </div>
                        <div className="col mb-12" style={{ display: 'flex' }}>
                            <label htmlFor="email-input" style={{ marginRight: '15px' }}><strong>Email(s):</strong></label>
                            <input style={{ width: '300px' }} type="text" className="form-control" id="emails" name="emails" onChange={handleSingleEmail} onKeyDown={handleEmailInput} placeholder="Email(s)" value={singleEmail} />
                        </div>
                        <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                            {emails.map((email, index) => (
                                <div key={index} className="email-wrapper">
                                    <span>{email}</span>
                                    <button onClick={() => handleEditEmail(index, prompt('Edit email', email))}><i className='bx bxs-edit' /></button>
                                    <button onClick={() => handleRemoveEmail(index)}>x</button>
                                </div>
                            ))}
                        </div>
                    </div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={handleClose}>Close</button>
                <button className="btn btn-primary" onClick={handleEmailSave}>Save</button>
            </Modal.Footer>
        </Modal>
    );
};

export default AnalyticsEmail;