import React, { useEffect } from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useState } from "react";
import { toast } from "react-toastify";
import { errorHandler } from "../Utils/errorHandler";
import { addRewardToCustomer, getRewardsByLocation } from "../../apis/ProfilesAPI";
import AddUpdateLoading from "../Utils/AddUpdateLoading";


const RewardModal = ({ show, setShow, locationId, customerId, isVip, isVipEnabled, vipMultiplier }) => {
    const [inputs, setInputs] = useState([]);
    const [rewards, setRewards] = useState([]);
    const [selectedReward, setSelectedReward] = useState(null);
    const [sessionModal, setSessionModal] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [loading, setLoading] = useState(false);


    const handleClose = () => {
        setShow(false);
    }


    // append customer id to inputs
    useEffect(() => {
        setInputs(inputs => ({ ...inputs, customer_id: customerId }));
    }, [customerId]);


    // append location id to inputs
    useEffect(() => {
        setInputs(inputs => ({ ...inputs, location_id: locationId }));
    }, [locationId]);


    // handle change
    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    console.log(inputs, 'inputs from reward modal');

    // add reward to customer
    const handleAddReward = (e) => {
        e.preventDefault();
        if (!inputs.title || !inputs.description || !inputs.points) {
            toast.error(`Please fill all fields!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                hideProgressBar: false,
            });
            return;
        }
        setLoading(true);
        addRewardToCustomer(inputs)
            .then((res) => {
                if (res.status === 201) {
                    toast.success(`Reward added successfully!`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        hideProgressBar: false,
                        onClose: () => {
                            setShow(false);
                            window.location.reload();
                        }
                    });
                }
            }).catch((err) => {
                errorHandler(err, setSessionModal, setShowToast, toast);
            });
        setLoading(false);
    }


    // get rewards by location
    useEffect(() => {
        getRewardsByLocation(locationId).then((res) => {
            if (res.status === 200) {
                setRewards(res.data);
            }
        }).catch((err) => {
            errorHandler(err, setSessionModal, setShowToast, toast);
        });
    }, [locationId]);


    // handle reward
    const handleReward = (e) => {
        if (rewards.length > 0) {
            const reward = rewards.find(reward => reward.id === parseInt(e.target.value));
            setSelectedReward(reward);
            if (reward.kind === 'task') {
                setInputs(inputs => ({ ...inputs, title: reward.title, description: reward.description, loyalty_reward_or_task_id: reward.id, points: reward.points * vipMultiplier }));
            } else {
                setInputs(inputs => ({ ...inputs, title: reward.title, description: reward.description, points: reward.points, loyalty_reward_or_task_id: reward.id }));
            }
        }
    }


    console.log(inputs, 'inputs');
    console.log(isVip, 'isVip');
    console.log(isVipEnabled, 'isVipEnabled');
    console.log(vipMultiplier, 'vipMultiplier');


    return (
        <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="lg"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title>Add Reward or Task Manually</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? <AddUpdateLoading /> :
                    <>
                        <div className="row">
                            <div className="col-md-12">
                                <h5>Add Reward or Task from List</h5>
                                <div>
                                    <div className="form-group">
                                        {/* <label htmlFor="title">Reward</label> */}
                                        <select className="form-control" id="title" name="title" onChange={handleReward} required>
                                            <option value="">Select Reward or Task</option>
                                            {rewards.map((reward, index) => (
                                                <option key={index} value={reward.id}>{reward.title}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                {/* <h5>Add Reward Manually</h5> */}
                                <div>
                                    <form onSubmit={handleAddReward}>
                                        <div className="form-group">
                                            <label htmlFor="title">Title</label>
                                            <input type="text" className="form-control" id="title" name="title" value={inputs.title || ''} onChange={handleChange} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="description">Description</label>
                                            <input type="text" className="form-control" id="description" name="description" value={inputs.description || ''} onChange={handleChange} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="points">Points</label>
                                            <input type="number" className="form-control" id="points" name="points" value={inputs.points || ''} onChange={handleChange} />
                                        </div>
                                        <>
                                            {isVipEnabled && isVip && selectedReward && selectedReward.kind === 'task' ?
                                                <p className="alert alert-success">Multiplying points by {vipMultiplier}x for VIP</p>
                                                : null
                                            }
                                        </>
                                        {isVipEnabled && isVip ?
                                            <p className="alert alert-primary">NB: VIP will recieve {vipMultiplier}x points for Tasks if selected from list</p>
                                            : null
                                        }
                                    </form>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Close</Button>
                <Button variant="primary" onClick={handleAddReward}>Add</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default RewardModal;
