import moment from "moment";
import axiosInstance from "../services/AxiosInstance";


export const standardMessages = async (apps, loc, cpage, searchkey, stDate, enDate, applist, pmPerPage, role) => {
    let offset = (cpage - 1) * pmPerPage;
    let url = "";
    let questionadded = false;

    if (apps && !loc) {
        url = `/v2/communication/get-standard-push-messages-app/${offset}/${pmPerPage}?appId=${apps}`;
        questionadded = true;
    }
    else if (apps && loc) {
        url = `/v2/communication/get-standard-push-messages-location/${offset}/${pmPerPage}?locationId=${loc}`;
        questionadded = true;
    }
    else if (!apps && !loc) {
        if (role === 'administrator') {
            url = `/v2/communication/get-standard-push-messages/${offset}/${pmPerPage}`;
        }
        else if (applist.length === 1) {
            url = `/v2/communication/get-standard-push-messages/${offset}/${pmPerPage}?appId=${applist[0].id}`;
            questionadded = true;
        }
        else if (applist.length > 1) {
            let appIds = [];
            for (let item of applist) {
                appIds.push(item.id);
            }
            let apid = encodeURIComponent(appIds);
            url = `/v2/communication/get-standard-push-messages-multiapps/${offset}/${pmPerPage}?appIds=${apid}`;
            questionadded = true;
        }
        else {
            url = `/v2/communication/get-standard-push-messages-app/${offset}/${pmPerPage}?appId=-1`;
            questionadded = true;
        }
    }

    if (searchkey && searchkey !== '') {
        let searchkeyencoded = encodeURIComponent(searchkey);
        if (questionadded) {
            url = url + '&searchKey=' + searchkeyencoded;
        }
        else {
            url = url + '?searchKey=' + searchkeyencoded;
            questionadded = true;
        }
    }

    if (stDate && enDate) {
        if (questionadded) {
            url = url + '&startDate=' + stDate + '&endDate=' + enDate;
        }
        else {
            url = url + '?startDate=' + stDate + '&endDate=' + enDate;
            questionadded = true;
        }
    }

    console.log(url);
    const response = await axiosInstance.get(url);
    return response;
}




export const standardMessagesCount = async (apps, loc, searchkey, stDate, enDate, applist, role) => {
    let url = "";
    let questionadded = false;

    if (apps && !loc) {
        url = `/v2/communication/get-standard-push-messages-count-app?appId=${apps}`;
        questionadded = true;
    }
    else if (apps && loc) {
        url = `/v2/communication/get-standard-push-messages-count-location?locationId=${loc}`;
        questionadded = true;
    }
    else if (!apps && !loc) {
        if (role === 'administrator') {
            url = `/v2/communication/get-standard-push-messages-count`;
        }
        else if (applist.length === 1 && role !== 'administrator') {
            url = `/v2/communication/get-standard-push-messages-count-app?appId=${applist[0].id}`;
            questionadded = true;
        }
        else if (applist.length > 1 && role !== 'administrator') {
            let appIds = [];
            for (const item of applist) {
                appIds.push(item.id);
            }
            let apid = encodeURIComponent(appIds);
            url = `/v2/communication/get-standard-push-messages-count-multiapps?appIds=${apid}`;
            questionadded = true;
        }
        else {
            url = `/v2/communication/get-standard-push-messages-count-app?appId=-1`;
            questionadded = true;
        }
    }

    if (searchkey && searchkey !== '') {
        let searchkeyencoded = encodeURIComponent(searchkey);
        if (questionadded) {
            url = url + '&searchKey=' + searchkeyencoded;
        }
        else {
            url = url + '?searchKey=' + searchkeyencoded;
            questionadded = true;
        }
    }

    if (stDate && enDate) {
        if (questionadded) {
            url = url + '&startDate=' + stDate + '&endDate=' + enDate;
        }
        else {
            url = url + '?startDate=' + stDate + '&endDate=' + enDate;
            questionadded = true;
        }
    }

    console.log(url);
    const response = await axiosInstance.get(url);
    return response;
}




// get all geo messages
export const geoMessages = async (apps, loc, cpage, searchkey, stDate, enDate, applist, pmPerPage, role) => {
    let offset = (cpage - 1) * pmPerPage;
    let url = "";
    let questionadded = false;

    if (apps && !loc) {
        url = `/v2/communication/get-geo-fence-push-messages-app/${offset}/${pmPerPage}?appId=${apps}`;
        questionadded = true;
    }
    else if (apps && loc) {
        url = `/v2/communication/get-geo-fence-push-messages-location/${offset}/${pmPerPage}?locationId=${loc}`;
        questionadded = true;
    }
    else if (!apps && !loc) {
        if (role === 'administrator') {
            url = `/v2/communication/get-geo-fence-push-messages/${offset}/${pmPerPage}`;
        }
        else if (applist.length === 1 && role !== 'administrator') {
            url = `/v2/communication/get-geo-fence-push-messages/${offset}/${pmPerPage}?appId=${applist[0].id}`;
            questionadded = true;
        }
        else if (applist.length > 1 && role !== 'administrator') {
            let appIds = [];
            for (const item of applist) {
                appIds.push(item.id);
            }
            let apid = encodeURIComponent(appIds);
            url = `/v2/communication/get-geo-fence-push-messages-multiapps/${offset}/${pmPerPage}?appIds=${apid}`;
            questionadded = true;
        }
        else {
            url = `/v2/communication/get-geo-fence-push-messages-app/${offset}/${pmPerPage}?appId=-1`;
            questionadded = true;
        }
    }

    if (searchkey && searchkey !== '') {
        let searchkeyencoded = encodeURIComponent(searchkey);
        if (questionadded) {
            url = url + '&searchKey=' + searchkeyencoded;
        }
        else {
            url = url + '?searchKey=' + searchkeyencoded;
            questionadded = true;
        }
    }

    if (stDate && enDate) {
        if (questionadded) {
            url = url + '&startDate=' + stDate + '&endDate=' + enDate;
        }
        else {
            url = url + '?startDate=' + stDate + '&endDate=' + enDate;
            questionadded = true;
        }
    }

    console.log(url);
    const response = await axiosInstance.get(url);
    return response;
}




// get all geo messages count
export const geoMessagesCount = async (apps, loc, searchkey, stDate, enDate, applist, role) => {
    let url = "";
    let questionadded = false;

    if (apps && !loc) {
        url = `/v2/communication/get-geo-fence-push-messages-count-app?appId=${apps}`;
        questionadded = true;
    }
    else if (apps && loc) {
        url = `/v2/communication/get-geo-fence-push-messages-count-location?locationId=${loc}`;
        questionadded = true;
    }
    else if (!apps && !loc) {
        if (role === 'administrator') {
            url = `/v2/communication/get-geo-fence-push-messages-count`;
        }
        else if (applist.length === 1 && role !== 'administrator') {
            url = `/v2/communication/get-geo-fence-push-messages-count-app?appId=${applist[0].id}`;
            questionadded = true;
        }
        else if (applist.length > 1 && role !== 'administrator') {
            let appIds = [];
            for (const item of applist) {
                appIds.push(item.id);
            }
            let apid = encodeURIComponent(appIds);
            url = `/v2/communication/get-geo-fence-push-messages-count-multiapps?appIds=${apid}`;
            questionadded = true;
        }
        else {
            url = `/v2/communication/get-geo-fence-push-messages-count-app?appId=-1`;
            questionadded = true;
        }
    }

    if (searchkey && searchkey !== '') {
        let searchkeyencoded = encodeURIComponent(searchkey);
        if (questionadded) {
            url = url + '&searchKey=' + searchkeyencoded;
        }
        else {
            url = url + '?searchKey=' + searchkeyencoded;
            questionadded = true;
        }
    }

    if (stDate && enDate) {
        if (questionadded) {
            url = url + '&startDate=' + stDate + '&endDate=' + enDate;
        }
        else {
            url = url + '?startDate=' + stDate + '&endDate=' + enDate;
            questionadded = true;
        }
    }

    console.log(url);
    const response = await axiosInstance.get(url);
    return response;
}




// get all scheduled messages
export const scheduledMessages = async (apps, loc, cpage, searchkey, stDate, enDate, applist, pmPerPage, role) => {
    let offset = (cpage - 1) * pmPerPage;
    let url = "";
    let questionadded = false;

    if (apps && !loc) {
        url = `/v2/communication/get-scheduled-push-messages-app/${offset}/${pmPerPage}?appId=${apps}`;
        questionadded = true;
    }
    else if (apps && loc) {
        url = `/v2/communication/get-scheduled-push-messages-location/${offset}/${pmPerPage}?locationId=${loc}`;
        questionadded = true;
    }
    else if (!apps && !loc) {
        if (role === 'administrator') {
            url = `/v2/communication/get-scheduled-push-messages/${offset}/${pmPerPage}`;
        }
        else if (applist.length === 1 && role !== 'administrator') {
            url = `/v2/communication/get-scheduled-push-messages/${offset}/${pmPerPage}?appId=${applist[0].id}`;
            questionadded = true;
        }
        else if (applist.length > 1 && role !== 'administrator') {
            let appIds = [];
            for (const item of applist) {
                appIds.push(item.id);
            }
            let apid = encodeURIComponent(appIds);
            url = `/v2/communication/get-scheduled-push-messages-multiapps/${offset}/${pmPerPage}?appIds=${apid}`;
            questionadded = true;
        }
        else {
            url = `/v2/communication/get-scheduled-push-messages-app/${offset}/${pmPerPage}?appId=-1`;
            questionadded = true;
        }
    }

    if (searchkey && searchkey !== '') {
        let searchkeyencoded = encodeURIComponent(searchkey);
        if (questionadded) {
            url = url + '&searchKey=' + searchkeyencoded;
        }
        else {
            url = url + '?searchKey=' + searchkeyencoded;
            questionadded = true;
        }
    }

    if (stDate && enDate) {
        if (questionadded) {
            url = url + '&startDate=' + stDate + '&endDate=' + enDate;
        }
        else {
            url = url + '?startDate=' + stDate + '&endDate=' + enDate;
            questionadded = true;
        }
    }

    console.log(url);
    const response = await axiosInstance.get(url);
    return response;
}




// get all scheduled messages count
export const scheduledMessagesCount = async (apps, loc, searchkey, stDate, enDate, applist, role) => {
    let url = "";
    let questionadded = false;

    if (apps && !loc) {
        url = `/v2/communication/get-scheduled-push-messages-count-app?appId=${apps}`;
        questionadded = true;
    }
    else if (apps && loc) {
        url = `/v2/communication/get-scheduled-push-messages-count-location?locationId=${loc}`;
        questionadded = true;
    }
    else if (!apps && !loc) {
        if (role === 'administrator') {
            url = `/v2/communication/get-scheduled-push-messages-count`;
        }
        else if (applist.length === 1 && role !== 'administrator') {
            url = `/v2/communication/get-scheduled-push-messages-count-app?appId=${applist[0].id}`;
            questionadded = true;
        }
        else if (applist.length > 1 && role !== 'administrator') {
            let appIds = [];
            for (const item of applist) {
                appIds.push(item.id);
            }
            let apid = encodeURIComponent(appIds);
            url = `/v2/communication/get-scheduled-push-messages-count-multiapps?appIds=${apid}`;
            questionadded = true;
        }
        else {
            url = `/v2/communication/get-scheduled-push-messages-count-app?appId=-1`;
            questionadded = true;
        }
    }

    if (searchkey && searchkey !== '') {
        let searchkeyencoded = encodeURIComponent(searchkey);
        if (questionadded) {
            url = url + '&searchKey=' + searchkeyencoded;
        }
        else {
            url = url + '?searchKey=' + searchkeyencoded;
            questionadded = true;
        }
    }

    if (stDate && enDate) {
        if (questionadded) {
            url = url + '&startDate=' + stDate + '&endDate=' + enDate;
        }
        else {
            url = url + '?startDate=' + stDate + '&endDate=' + enDate;
            questionadded = true;
        }
    }

    console.log(url);
    const response = await axiosInstance.get(url);
    return response;
}




// get all expired messages
export const expiredMessages = async (apps, loc, cpage, searchkey, stDate, enDate, applist, pmPerPage, role) => {
    let offset = (cpage - 1) * pmPerPage;
    let url = "";
    let questionadded = false;

    if (apps && !loc) {
        url = `/v2/communication/get-expired-push-messages-app/${offset}/${pmPerPage}?appId=${apps}`;
        questionadded = true;
    }
    else if (apps && loc) {
        url = `/v2/communication/get-expired-push-messages-location/${offset}/${pmPerPage}?locationId=${loc}`;
        questionadded = true;
    }
    else if (!apps && !loc) {
        if (role === 'administrator') {
            url = `/v2/communication/get-expired-push-messages/${offset}/${pmPerPage}`;
        }
        else if (applist.length === 1 && role !== 'administrator') {
            url = `/v2/communication/get-expired-push-messages/${offset}/${pmPerPage}?appId=${applist[0].id}`;
            questionadded = true;
        }
        else if (applist.length > 1 && role !== 'administrator') {
            let appIds = [];
            for (const item of applist) {
                appIds.push(item.id);
            }
            let apid = encodeURIComponent(appIds);
            url = `/v2/communication/get-expired-push-messages-multiapps/${offset}/${pmPerPage}?appIds=${apid}`;
            questionadded = true;
        }
        else {
            url = `/v2/communication/get-expired-push-messages-app/${offset}/${pmPerPage}?appId=-1`;
            questionadded = true;
        }
    }

    if (searchkey && searchkey !== '') {
        let searchkeyencoded = encodeURIComponent(searchkey);
        if (questionadded) {
            url = url + '&searchKey=' + searchkeyencoded;
        }
        else {
            url = url + '?searchKey=' + searchkeyencoded;
            questionadded = true;
        }
    }

    if (stDate && enDate) {
        if (questionadded) {
            url = url + '&startDate=' + stDate + '&endDate=' + enDate;
        }
        else {
            url = url + '?startDate=' + stDate + '&endDate=' + enDate;
            questionadded = true;
        }
    }

    console.log(url);
    const response = await axiosInstance.get(url);
    return response;
}




// get all expired messages count
export const expiredMessagesCount = async (apps, loc, searchkey, stDate, enDate, applist, role) => {
    let url = "";
    let questionadded = false;

    if (apps && !loc) {
        url = `/v2/communication/get-expired-push-messages-count-app?appId=${apps}`;
        questionadded = true;
    }
    else if (apps && loc) {
        url = `/v2/communication/get-expired-push-messages-count-location?locationId=${loc}`;
        questionadded = true;
    }
    else if (!apps && !loc) {
        if (role === 'administrator') {
            url = `/v2/communication/get-expired-push-messages-count`;
        }
        else if (applist.length === 1 && role !== 'administrator') {
            url = `/v2/communication/get-expired-push-messages-count-app?appId=${applist[0].id}`;
            questionadded = true;
        }
        else if (applist.length > 1 && role !== 'administrator') {
            let appIds = [];
            for (const item of applist) {
                appIds.push(item.id);
            }
            let apid = encodeURIComponent(appIds);
            url = `/v2/communication/get-expired-push-messages-count-multiapps?appIds=${apid}`;
            questionadded = true;
        }
        else {
            url = `/v2/communication/get-expired-push-messages-count-app?appId=-1`;
            questionadded = true;
        }
    }

    if (searchkey && searchkey !== '') {
        let searchkeyencoded = encodeURIComponent(searchkey);
        if (questionadded) {
            url = url + '&searchKey=' + searchkeyencoded;
        }
        else {
            url = url + '?searchKey=' + searchkeyencoded;
            questionadded = true;
        }
    }

    if (stDate && enDate) {
        if (questionadded) {
            url = url + '&startDate=' + stDate + '&endDate=' + enDate;
        }
        else {
            url = url + '?startDate=' + stDate + '&endDate=' + enDate;
            questionadded = true;
        }
    }

    console.log(url);
    const response = await axiosInstance.get(url);
    return response;
}




// get all targeted messages
export const targetedMessages = async (apps, loc, cpage, searchkey, stDate, enDate, applist, pmPerPage, role) => {
    let offset = (cpage - 1) * pmPerPage;
    let url = "";
    let questionadded = false;


    if (apps && !loc) {
        url = `/v2/communication/get-targeted-push-messages-app/${offset}/${pmPerPage}?appId=${apps}`;
        questionadded = true;
    }
    else if (apps && loc) {
        url = `/v2/communication/get-targeted-push-messages-location/${offset}/${pmPerPage}?locationId=${loc}`;
        questionadded = true;
    }
    else if (!apps && !loc) {
        if (role === 'administrator') {
            url = `/v2/communication/get-targeted-push-messages/${offset}/${pmPerPage}`;
        }
        else if (applist.length === 1 && role !== 'administrator') {
            url = `/v2/communication/get-targeted-push-messages/${offset}/${pmPerPage}?appId=${applist[0].id}`;
            questionadded = true;
        }
        else if (applist.length > 1 && role !== 'administrator') {
            let appIds = [];
            for (const item of applist) {
                appIds.push(item.id);
            }
            let apid = encodeURIComponent(appIds);
            url = `/v2/communication/get-targeted-push-messages-multiapps/${offset}/${pmPerPage}?appIds=${apid}`;
            questionadded = true;
        }
        else {
            url = `/v2/communication/get-targeted-push-messages-app/${offset}/${pmPerPage}?appId=-1`;
            questionadded = true;
        }
    }


    if (searchkey && searchkey !== '') {
        let searchkeyencoded = encodeURIComponent(searchkey);
        if (questionadded) {
            url = url + '&searchKey=' + searchkeyencoded;
        }
        else {
            url = url + '?searchKey=' + searchkeyencoded;
            questionadded = true;
        }
    }


    if (stDate && enDate) {
        if (questionadded) {
            url = url + '&startDate=' + stDate + '&endDate=' + enDate;
        }
        else {
            url = url + '?startDate=' + stDate + '&endDate=' + enDate;
            questionadded = true;
        }
    }


    console.log(url);
    const response = await axiosInstance.get(url);
    return response;
}




// get all targeted messages count
export const targetedMessagesCount = async (apps, loc, searchkey, stDate, enDate, applist, role) => {
    let url = "";
    let questionadded = false;


    if (apps && !loc) {
        url = `/v2/communication/get-targeted-push-messages-count-app?appId=${apps}`;
        questionadded = true;
    }
    else if (apps && loc) {
        url = `/v2/communication/get-targeted-push-messages-count-location?locationId=${loc}`;
        questionadded = true;
    }
    else if (!apps && !loc) {
        if (role === 'administrator') {
            url = `/v2/communication/get-targeted-push-messages-count`;
        }
        else if (applist.length === 1 && role !== 'administrator') {
            url = `/v2/communication/get-targeted-push-messages-count-app?appId=${applist[0].id}`;
            questionadded = true;
        }
        else if (applist.length > 1 && role !== 'administrator') {
            let appIds = [];
            for (const item of applist) {
                appIds.push(item.id);
            }
            let apid = encodeURIComponent(appIds);
            url = `/v2/communication/get-targeted-push-messages-count-multiapps?appIds=${apid}`;
            questionadded = true;
        }
        else {
            url = `/v2/communication/get-targeted-push-messages-count-app?appId=-1`;
            questionadded = true;
        }
    }


    if (searchkey && searchkey !== '') {
        let searchkeyencoded = encodeURIComponent(searchkey);
        if (questionadded) {
            url = url + '&searchKey=' + searchkeyencoded;
        }
        else {
            url = url + '?searchKey=' + searchkeyencoded;
            questionadded = true;
        }
    }


    if (stDate && enDate) {
        if (questionadded) {
            url = url + '&startDate=' + stDate + '&endDate=' + enDate;
        }
        else {
            url = url + '?startDate=' + stDate + '&endDate=' + enDate;
            questionadded = true;
        }
    }


    console.log(url);
    const response = await axiosInstance.get(url);
    return response;
}



// get single message (standard, scheduled, expired)
export const singleMessage = (id) => {
    let url = '/v2/communication/get-push-message/' + id;
    return axiosInstance.get(url);
};


// get single message (geo)
export const singleGeoMessage = (id) => {
    let url = '/v2/communication/get-push-message-geofence/' + id;
    return axiosInstance.get(url);
};


// get single message (targeted)
export const singleTargetedMessage = (id) => {
    let url = '/v2/communication/get-push-message-targeted/' + id;
    console.log(url);
    return axiosInstance.get(url);
};



// get customers by location
export const customersByLocation = (loc, type) => {
    let url = '/v2/communication/get-customers-location/' + loc;
    if (type !== '' && type) {
        url = url + '?isReward=' + type;
    }
    return axiosInstance.get(url);
};



// get count of vehicles in location
export const vehicleCountByLocation = (loc) => {
    let url = '/v2/communication/get-inventory-counts/';
    url = url + '?locationIds=' + loc;
    return axiosInstance.get(url);
};



// get vehicle year by type and location
export const vehicleYearByTypeAndLocation = (loc, type) => {
    let url = '/v2/communication/get-vehicles-year-by-type-location/';
    url = url + '?locationIds=' + loc + '&vehicleType=' + type;
    return axiosInstance.get(url);
};


// get vehicle make by year and type and location
export const vehicleMakeByYearAndTypeAndLocation = (loc, type, year) => {
    let url = '/v2/communication/get-vehicles-make-by-year-type-location/';
    url = url + '?locationIds=' + loc + '&vehicleType=' + type + '&vehicleYear=' + year;
    return axiosInstance.get(url);
};


// get vehicle model by make and year and type and location
export const vehicleModelByMakeAndYearAndTypeAndLocation = (loc, type, year, make) => {
    let url = '/v2/communication/get-vehicles-model-by-make-year-type-location/';
    url = url + '?locationIds=' + loc + '&vehicleType=' + type + '&vehicleYear=' + year + '&vehicleMake=' + make;
    return axiosInstance.get(url);
};


// save push message (standard, geo, scheduled, expired)
export const savePushMessage = (data) => {
    let url = '/v2/communication/save-push-message';
    return axiosInstance.post(url, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

// update push message (standard, geo, scheduled, expired)
export const updatePushMessage = (data, push_id) => {
    console.log(data, 'data');
    let url = '/v2/communication/update-push-message/' + push_id;
    return axiosInstance.patch(url, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};


// delete push message (standard, geo, scheduled, expired)
export const deletePushMessage = (id) => {
    let url = '/v2/communication/delete-push-message/' + id;
    return axiosInstance.delete(url);
};


// save push message (targeted)
export const saveTargetedPushMessage = (data) => {
    let url = '/v2/communication/save-targeted-push-message';
    return axiosInstance.post(url, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};


// update push message (targeted)
export const updateTargetedPushMessage = (data, push_id) => {
    let url = '/v2/communication/update-targeted-push-message/' + push_id;
    return axiosInstance.patch(url, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};



// delete push message (targeted)
export const deleteTargetedPushMessage = (id) => {
    let url = '/v2/communication/delete-targeted-push-message/' + id;
    return axiosInstance.delete(url);
};


// Automated Push Messages

// get all automated messages
export const automatedMessages = async (apps, loc, cpage, message_type, searchkey, stDate, enDate, applist, pmPerPage, role,automatedPN) => {
    let offset = (cpage - 1) * pmPerPage;
    let url = "";
    let questionadded = false;

    console.log('apps from api', apps);
    console.log('loc from api', loc);
    console.log('message_type from api', message_type);
    console.log('searchkey from api', searchkey);
    console.log('stDate from api', stDate);
    console.log('enDate from api', enDate);
    console.log('applist from api', applist);
    console.log('role from api', role);
    console.log('offset from api', offset);

    if (apps && !loc) {
        url = `/v2/communication/get-automated-push-messages-app/${offset}/${pmPerPage}?appId=${apps}`;
        questionadded = true;
    }
    else if (apps && loc) {
        url = `/v2/communication/get-automated-push-messages-location/${offset}/${pmPerPage}?locationId=${loc}`;
        questionadded = true;
    }
    else if (!apps && !loc) {
        if (role === 'administrator') {
            console.log('hitting admin')
            url = `/v2/communication/get-automated-push-messages/${offset}/${pmPerPage}`;
        }
        else if (applist.length === 1 && role !== 'administrator') {
            url = `/v2/communication/get-automated-push-messages-app/${offset}/${pmPerPage}?appId=${applist[0].id}`;
            questionadded = true;
        }
        else if (applist.length > 1 && role !== 'administrator') {
            let appIds = [];
            for (const item of applist) {
                appIds.push(item.id);
            }
            let apid = encodeURIComponent(appIds);
            url = `/v2/communication/get-automated-push-messages-multiapps/${offset}/${pmPerPage}?appIds=${apid}`;
            questionadded = true;
        }
        else {
            url = `/v2/communication/get-automated-push-messages-app/${offset}/${pmPerPage}?appId=-1`;
            questionadded = true;
        }
    }
    if(!isNaN(automatedPN)){
        if (questionadded) {
            url = url + '&automated=' +automatedPN ;
        } else {
            url = url + '?automated=' +automatedPN ;
            questionadded = true;
        }

    }



    if (message_type && message_type !== '') {
        if (questionadded) {
            url = url + '&messageType=' + message_type;
        }
        else {
            url = url + '?messageType=' + message_type;
            questionadded = true;
        }
    }


    if (searchkey && searchkey !== '') {
        let searchkeyencoded = encodeURIComponent(searchkey);
        if (questionadded) {
            url = url + '&searchKey=' + searchkeyencoded;
        }
        else {
            url = url + '?searchKey=' + searchkeyencoded;
            questionadded = true;
        }
    }

    if (stDate && !enDate) {
        stDate = moment(stDate).format('YYYY-MM-DD');
        enDate = moment().format('YYYY-MM-DD');
        if (questionadded) {
            url = url + '&startDate=' + stDate + '&endDate=' + enDate;
        }
        else {
            url = url + '?startDate=' + stDate + '&endDate=' + enDate;
            questionadded = true;
        }
    } else if (!stDate && enDate) {
        stDate = moment('2020-01-01').format('YYYY-MM-DD');
        enDate = moment(enDate).format('YYYY-MM-DD');
        if (questionadded) {
            url = url + '&startDate=' + stDate + '&endDate=' + enDate;
        }
        else {
            url = url + '?startDate=' + stDate + '&endDate=' + enDate;
            questionadded = true;
        }
    } else if (stDate && enDate) {
        stDate = moment(stDate).format('YYYY-MM-DD');
        enDate = moment(enDate).format('YYYY-MM-DD');
        if (questionadded) {
            url = url + '&startDate=' + stDate + '&endDate=' + enDate;
        }
        else {
            url = url + '?startDate=' + stDate + '&endDate=' + enDate;
            questionadded = true;
        }
    }

    console.log(url);
    const response = await axiosInstance.get(url);
    return response;
}


// get all automated messages count
export const automatedMessagesCount = async (apps, loc, message_type, searchkey, stDate, enDate, applist, role,automatedPN) => {
    let url = "";
    let questionadded = false;

    if (apps && !loc) {
        url = `/v2/communication/get-automated-push-messages-count-app?appId=${apps}`;
        questionadded = true;
    }
    else if (apps && loc) {
        url = `/v2/communication/get-automated-push-messages-count-location?locationId=${loc}`;
        questionadded = true;
    }
    else if (!apps && !loc) {
        if (role === 'administrator') {
            url = `/v2/communication/get-automated-push-messages-count`;
            
        }
        else if (applist.length === 1 && role !== 'administrator') {
            url = `/v2/communication/get-automated-push-messages-count-app?appId=${applist[0].id}`;
            questionadded = true;
        }
        else if (applist.length > 1 && role !== 'administrator') {
            let appIds = [];
            for (const item of applist) {
                appIds.push(item.id);
            }
            let apid = encodeURIComponent(appIds);
            url = `/v2/communication/get-automated-push-messages-count-multiapps?appIds=${apid}`;
            questionadded = true;
        }
        else {
            url = `/v2/communication/get-automated-push-messages-count-app?appId=-1`;
            questionadded = true;
        }
    }

    if(!isNaN(automatedPN)){
        if (questionadded) {
            url = url + '&automated=' +automatedPN ;
        } else {
            url = url + '?automated=' +automatedPN ;
            questionadded = true;
        }

    }
    
    
    if (message_type && message_type !== '') {
        if (questionadded) {
            url = url + '&messageType=' + message_type;
        }
        else {
            url = url + '?messageType=' + message_type;
            questionadded = true;
        }
    }

    if (searchkey && searchkey !== '') {
        let searchkeyencoded = encodeURIComponent(searchkey);
        if (questionadded) {
            url = url + '&searchKey=' + searchkeyencoded;
        }
        else {
            url = url + '?searchKey=' + searchkeyencoded;
            questionadded = true;
        }
    }

    if (stDate && !enDate) {
        stDate = moment(stDate).format('YYYY-MM-DD');
        enDate = moment().format('YYYY-MM-DD');
        if (questionadded) {
            url = url + '&startDate=' + stDate + '&endDate=' + enDate;
        }
        else {
            url = url + '?startDate=' + stDate + '&endDate=' + enDate;
            questionadded = true;
        }
    } else if (!stDate && enDate) {
        stDate = moment('2020-01-01').format('YYYY-MM-DD');
        enDate = moment(enDate).format('YYYY-MM-DD');
        if (questionadded) {
            url = url + '&startDate=' + stDate + '&endDate=' + enDate;
        }
        else {
            url = url + '?startDate=' + stDate + '&endDate=' + enDate;
            questionadded = true;
        }
    } else if (stDate && enDate) {
        stDate = moment(stDate).format('YYYY-MM-DD');
        enDate = moment(enDate).format('YYYY-MM-DD');
        if (questionadded) {
            url = url + '&startDate=' + stDate + '&endDate=' + enDate;
        }
        else {
            url = url + '?startDate=' + stDate + '&endDate=' + enDate;
            questionadded = true;
        }
    }

    console.log(url);
    const response = await axiosInstance.get(url);
    return response;
}


// get single automated message
export const singleAutomatedMessage = (id) => {
    let url = '/v2/communication/get-automated-push-message/' + id;
    return axiosInstance.get(url);
};


// save automated push message
export const saveAutomatedPushMessage = (data) => {
    console.log(data);
    let url = '/v2/communication/save-automated-push-message';
    return axiosInstance.post(url, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};


// update automated push message
export const updateAutomatedPushMessage = (data, push_id) => {
    let url = '/v2/communication/update-automated-push-message/' + push_id;
    return axiosInstance.patch(url, data, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};


// delete automated push message
export const deleteAutomatedPushMessage = (id) => {
    let url = '/v2/communication/delete-automated-push-message/' + id;
    return axiosInstance.delete(url);
};
