import React from "react";

import DashboardBody from "../DashboardBody/DashboardBody";
import Header from "../Header/Header";
import LeftSidebar from "../LeftSidebar/LeftSidebar";


const Dashboard = ({ children }) => {
    let user;
    let p = localStorage.getItem('user');
    if (p) {
        user = JSON.parse(p);
    }
    return (
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <LeftSidebar />
                <div className="layout-page">
                    {user &&
                        <Header />}
                    <DashboardBody>
                        {children}
                    </DashboardBody>
                </div>
            </div>
            <div className="layout-overlay layout-menu-toggle"></div>
        </div>
    );
}

export default Dashboard;

