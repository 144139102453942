import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Loading from '../Utils/Loading';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { errorHandler } from "../Utils/errorHandler";
import { updateApp } from "../../apis/AppsAPI";

const SettingsModal = ({ show, appId, programType, programTerms, handleClose, onSave }) => {
    const [updatedProgramType, setUpdatedProgramType] = useState(programType);
    const [updatedProgramTerms, setUpdatedProgramTerms] = useState(programTerms);
    const [submitting, setSubmitting] = useState(false);
    const [sessionModal, setSessionModal] = useState(false);
    const [showToast, setShowToast] = useState(false);

    const handleDropdownChange = (event) => {
        setUpdatedProgramType(event.target.value);
    };

    const handleTextareaChange = (event) => {
        setUpdatedProgramTerms(event.target.value);
    };

    const handleSubmit = async () => {
        setSubmitting(true);
        const inputs = {
            id: appId,
            loyalty_rewards_program_type: updatedProgramType,
            loyalty_rewards_terms_and_conditions: updatedProgramTerms
        };
        updateApp(inputs).then((res) => {
            setSubmitting(false);
            console.log(res.data);
            if (res.status === 201 && res.data.affectedRows > 0) {
                toast.success("Successfully Updated Program Settings ", {
                    position: toast.POSITION.TOP_CENTER, autoClose: 1000, hideProgressBar: false,
                    onClose: () => {
                        window.location.reload();
                    }

                });
            } else {
                setShowToast(true);
                toast.error(`Failed to update!`, { position: toast.POSITION.TOP_CENTER, autoClose: false, hideProgressBar: true });
            }
        }).catch((err) => {
            setSubmitting(false);
            console.log(err);
            errorHandler(err, setSessionModal, setShowToast, toast);
        });
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                {submitting ? <Loading /> :
                    <div className="form-group">
                        <label htmlFor="myDropdown">Set Rewards Program Type</label>
                        <select
                            className="form-select"
                            id="programType"
                            name="programType"
                            value={updatedProgramType}
                            onChange={handleDropdownChange}
                        >
                            <option value="location">Location</option>
                            <option value="group">Group</option>
                        </select>
                        <label htmlFor="myInput" className="mt-3">Rewards Terms and Conditions</label>
                        <textarea
                            className="form-control form-control-lg"
                            id="myInput"
                            name="programTerms"
                            value={updatedProgramTerms}
                            onChange={handleTextareaChange}
                            rows={4}
                        ></textarea>
                        <button
                            className="btn btn-primary"
                            style={{ marginTop: '30px' }}
                            onClick={handleSubmit}
                        >
                            Submit
                        </button>
                    </div>}
            </Modal.Body>
        </Modal>
    );
};

export default SettingsModal;
