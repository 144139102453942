import React from "react";
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import SessionExpiredModal from '../SessionExpire/SessionExpiredModal';
import { errorHandler } from "../Utils/errorHandler";
import moment from "moment";
import ReadMore from "../Utils/ReadMore";
import PaginationComponent from "../Pagination/Pagination";
import { getCustomerInfoBasicById, getRewardsByCustomerId, getRewardsCountByCustomerId, getRewardsSumCustomerId, getCustomerInfoVehicleById, getCustomerInfoVehicleByIdCount, getCustomerInfoStaffById, getCustomerInfoStaffByIdCount, getCustomerInfoPushMessagesById, getCustomerInfoPushMessagesByIdCount, getCustomerInfoDocumentById, getCustomerInfoDocumentByIdCount, getCustomerRewardBalanceById, deleteCustomerVehicle, deleteStaffFromCustomer, deleteDocumentFromCustomer, deleteRewardForCustomer, updateCustomerInfo } from "../../apis/ProfilesAPI";
import { getLicenseByCustomerId, deleteCustomerLicense } from "../../apis/LicenseAPI";
import Loading from "../Utils/Loading";
import AddVehicleModal from "./AddVehicleModal";
import AddStaffModal from "./AddStaffModal";
import AddDocumentModal from "./AddDocumentModal";
import AddRewardModal from "./AddRewardModal";
import EditRewardModal from "./EditRewardModal";
import AddLicenseModal from "./AddLicenseModal";


const EditCustomer = () => {
    // customer id from url
    const { customer_id } = useParams();
    const [isOpen, setIsOpen] = useState(false);
    const [inputs, setInputs] = useState({});

    const openNewLicenseModal = () => {
        setIsLicenseUpdate(false);
        setLicenseInfo({ "customerId": customer_id });
        setIsOpen(true);
        setShowAddLicenseModal(true);

    };
    const editLicenseModal = (item) => {
        setIsLicenseUpdate(true);
        setLicenseInfo(item);
        setIsOpen(true);
        setShowAddLicenseModal(true);
    }



    const closeModal = () => {
        setIsOpen(false);
    };


    // vip status
    const [isVip, setIsVip] = useState(false);
    const [isVipEnabled, setIsVipEnabled] = useState(false);
    const [vipMultiplier, setVipMultiplier] = useState(1);
    // set customer info in state with count
    const [customerBasicInfo, setCustomerBasicInfo] = useState([]);
    const [customerRewardInfo, setCustomerRewardInfo] = useState([]);
    const [customerRewardInfoCount, setCustomerRewardInfoCount] = useState(null);
    let [customerLicenseInfo, setCustomerLicenseInfo] = useState(null);

    const [customerVehicleInfo, setCustomerVehicleInfo] = useState([]);
    const [customerVehicleInfoCount, setCustomerVehicleInfoCount] = useState(null);
    const [customerStaffInfo, setCustomerStaffInfo] = useState([]);
    const [customerStaffInfoCount, setCustomerStaffInfoCount] = useState(null);
    const [customerPushMessageInfo, setCustomerPushMessageInfo] = useState([]);
    const [customerPushMessageInfoCount, setCustomerPushMessageInfoCount] = useState(null);
    const [customerDocumentInfo, setCustomerDocumentInfo] = useState([]);
    const [customerDocumentInfoCount, setCustomerDocumentInfoCount] = useState(null);
    // set page count and current page in separate variable
    const [currentPageReward, setCurrentPageReward] = useState(1);
    const [currentPageVehicle, setCurrentPageVehicle] = useState(1);
    const [currentPageStaff, setCurrentPageStaff] = useState(1);
    const [currentPagePushMessage, setCurrentPagePushMessage] = useState(1);
    const [currentPageDocument, setCurrentPageDocument] = useState(1);
    const [numberOfPageReward, setNumberOfPageReward] = useState(0);
    const [numberOfPageVehicle, setNumberOfPageVehicle] = useState(0);
    const [numberOfPageStaff, setNumberOfPageStaff] = useState(0);
    const [numberOfPagePushMessage, setNumberOfPagePushMessage] = useState(0);
    const [numberOfPageDocument, setNumberOfPageDocument] = useState(0);
    // set modal and toast
    const [sessionModal, setSessionModal] = useState(false);
    let [showToast, setShowToast] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    // set customer name
    const [customerName, setCustomerName] = useState("");
    // define back page url
    const [backUrl] = useState("/customer_profiles" + window.location.search);
    const [addUrl] = useState("/addcustomer" + window.location.search);
    const [loc, setLoc] = useState(null);
    // set items per page
    const [rewardsPerPage] = useState(5);
    const [totalPoints, setTotalPoints] = useState(0);
    const [vehiclePerPage] = useState(5);
    const [staffPerPage] = useState(5);
    const [pushMessagePerPage] = useState(5);
    const [documentPerPage] = useState(5);
    // set search key and sort
    const [searchKeyReward, setSearchKeyReward] = useState("");
    const [searchKeyVehicle, setSearchKeyVehicle] = useState("");
    let [searchKeyLicense, setSearchKeyLicense] = useState("");

    const [searchKeyStaff, setSearchKeyStaff] = useState("");
    const [searchKeyPushMessage, setSearchKeyPushMessage] = useState("");
    const [searchKeyDocument, setSearchKeyDocument] = useState("");
    const [rewardSort, setRewardSort] = useState(null);
    const [rewardKind, setRewardKind] = useState(null);
    const [basicLoading, setBasicLoading] = useState(false);
    const [licenseLoading, setLicenseLoading] = useState(false);


    const [rewardLoading, setRewardLoading] = useState(false);
    const [vehicleLoading, setVehicleLoading] = useState(false);
    const [staffLoading, setStaffLoading] = useState(false);
    const [pushMessageLoading, setPushMessageLoading] = useState(false);
    const [documentLoading, setDocumentLoading] = useState(false);
    // modals for add
    const [showAddVehicleModal, setShowAddVehicleModal] = useState(false);
    const [showAddLicenseModal, setShowAddLicenseModal] = useState(false);


    const [showAddStaffModal, setShowAddStaffModal] = useState(false);
    const [showAddDocumentModal, setShowAddDocumentModal] = useState(false);
    const [showAddRewardModal, setShowAddRewardModal] = useState(false);
    const [showEditRewardModal, setShowEditRewardModal] = useState(false);
    // search helpers
    const [firstLoad, setFirstLoad] = useState(1);
    const [timeoutid, setTimeoutid] = useState(null);
    const [messagetimeoutid, setMessagetimeoutid] = useState(null);

    // edit modal id
    const [editRewardModalId, setEditRewardModalId] = useState(null);
    // reward related states
    const [isLoyaltyRewardUser, setIsLoyaltyRewardUser] = useState(false);
    const [isRewardEnabled, setIsRewardEnabled] = useState(false);
    const [rewardStatus, setRewardStatus] = useState(null);

    const [licenseInfo, setLicenseInfo] = useState({});
    const [isLicenseUpdate, setIsLicenseUpdate] = useState(false);

    // handle toast close
    const handleCloseToast = () => {
        setShowToast(false);
    };

    function intervalexecute() {
        if (timeoutid) {
            clearTimeout(timeoutid);
        }

        const timeout = setTimeout(() => {
            setBasicLoading(true);
            setRewardLoading(true);
            setVehicleLoading(true);
            setStaffLoading(true);
            setDocumentLoading(true);
            // getCustomerInfoBasicById(customer_id).then((response) => {
            //     if (response.data) {
            //         setCustomerBasicInfo(response.data);
            //         setInputs(response.data[0]);
            //         setCustomerName(response.data[0].customer_first_name + " " + response.data[0].customer_last_name);
            //         if (parseInt(response.data[0].is_vip) === 1) {
            //             setIsVip(true);
            //         } else {
            //             setIsVip(false);
            //         }
            //         if (parseInt(response.data[0].is_vip_enabled) === 1) {
            //             setIsVipEnabled(true);
            //         } else {
            //             setIsVipEnabled(false);
            //         }
            //         if (response.data[0].is_loyalty_rewards_user === 1) {
            //             setIsLoyaltyRewardUser(true);
            //         } else {
            //             setIsLoyaltyRewardUser(false);
            //         }
            //         if (parseInt(response.data[0].is_reward_enabled) === 1) {
            //             setIsRewardEnabled(true);
            //         }
            //         else {
            //             setIsRewardEnabled(false);
            //         }
            //         setVipMultiplier(response.data[0].vip_times);
            //         setLoc(response.data[0].location_id);
            //         setBasicLoading(false);
            //     }
            // }).catch((error) => {
            //     if (error.response) {
            //         if (error.response.status === 401) {
            //             setSessionModal(true);
            //         } else {
            //             toast.error(errorHandler(error));
            //         }
            //     } else {
            //         toast.error("Something went wrong!");
            //     }
            //     setBasicLoading(false);


            // });



            getRewardsByCustomerId(customer_id, currentPageReward, searchKeyReward, rewardSort, rewardKind, rewardStatus).then((response) => {
                //console.log(response.data, 'rewards');
                if (response.data.length > 0) {
                    setCustomerRewardInfo(response.data);


                } else {
                    setCustomerRewardInfo([]);
                }

                setRewardLoading(false);
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        setSessionModal(true);
                    } else {
                        toast.error(errorHandler(error));
                    }
                } else {
                    toast.error("Something went wrong!");
                }

                setRewardLoading(false);

            });

            getRewardsCountByCustomerId(customer_id, searchKeyReward, rewardKind, rewardStatus).then((response) => {
                //console.log(response.data[0]);

                if (response.data.length > 0) {
                    setCustomerRewardInfoCount(response.data[0].countreward);
                    setNumberOfPageReward(Math.ceil(response.data[0].countreward / rewardsPerPage));

                } else {
                    setCustomerRewardInfoCount(0);
                    setNumberOfPageReward(0);
                }

                setRewardLoading(false);
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        setSessionModal(true);
                    } else {
                        toast.error(errorHandler(error));
                    }
                } else {
                    toast.error("Something went wrong!");
                }

                setRewardLoading(false);

            });

            getRewardsSumCustomerId(customer_id, searchKeyReward, rewardKind, rewardStatus).then((response) => {
                //console.log(response.data[0]);
                let points = 0;
                if (response.data.length > 0) {
                    setTotalPoints(response.data[0].totalpoints);
                } else {
                    setTotalPoints(points);
                }
                setRewardLoading(false);


            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        setSessionModal(true);
                    } else {
                        toast.error(errorHandler(error));
                    }
                } else {
                    toast.error("Something went wrong for reward sum!");
                }
                setRewardLoading(false);



            });

            getCustomerInfoVehicleById(customer_id, currentPageVehicle, vehiclePerPage, searchKeyVehicle).then((response) => {
                if (response.data.length > 0) {
                    setCustomerVehicleInfo(response.data);
                } else {
                    setCustomerVehicleInfo([]);
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        setSessionModal(true);
                    } else {
                        toast.error(errorHandler(error));
                    }
                } else {
                    toast.error("Something went wrong!");
                }

                setVehicleLoading(false);

            });

            getCustomerInfoVehicleByIdCount(customer_id, searchKeyVehicle).then((response) => {
                if (response.data.length > 0) {
                    setCustomerVehicleInfoCount(response.data[0].countvehicle);
                    setNumberOfPageVehicle(Math.ceil(response.data[0].countvehicle / vehiclePerPage));
                } else {
                    setCustomerVehicleInfoCount(0);
                    setNumberOfPageVehicle(0);
                }
                setVehicleLoading(false);


            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        setSessionModal(true);
                    } else {
                        toast.error(errorHandler(error));
                    }
                } else {
                    toast.error("Something went wrong!");
                }
                setVehicleLoading(false);


            });

            getCustomerInfoStaffById(customer_id, currentPageStaff, staffPerPage, searchKeyStaff).then((response) => {
                if (response.data.length > 0) {
                    setCustomerStaffInfo(response.data);
                } else {
                    setCustomerStaffInfo([]);
                }
                setStaffLoading(false);

            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        setSessionModal(true);
                    } else {
                        toast.error(errorHandler(error));
                    }
                } else {
                    toast.error("Something went wrong!");
                }
                setStaffLoading(false);



            });

            getCustomerInfoStaffByIdCount(customer_id, searchKeyStaff).then((response) => {
                if (response.data.length > 0) {
                    setCustomerStaffInfoCount(response.data[0].countstaff);
                    setNumberOfPageStaff(Math.ceil(response.data[0].countstaff / staffPerPage));
                } else {
                    setCustomerStaffInfoCount(0);
                    setNumberOfPageStaff(0);
                }
                setStaffLoading(false);


            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        setSessionModal(true);
                    } else {
                        toast.error(errorHandler(error));
                    }
                } else {
                    toast.error("Something went wrong!");
                }
                setStaffLoading(false);



            });




            getCustomerInfoDocumentById(customer_id, currentPageDocument, documentPerPage, searchKeyDocument).then((response) => {
                if (response.data.length > 0) {
                    //console.log(response.data);
                    setCustomerDocumentInfo(response.data);

                } else {
                    setCustomerDocumentInfo([]);
                }

                setDocumentLoading(false);

            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        setSessionModal(true);
                    } else {
                        toast.error(errorHandler(error));
                    }
                } else {
                    toast.error("Something went wrong!");
                }
                setDocumentLoading(false);



            });

            getCustomerInfoDocumentByIdCount(customer_id, searchKeyDocument).then((response) => {
                if (response.data.length > 0) {
                    setCustomerDocumentInfoCount(response.data[0].countdocument);
                    setNumberOfPageDocument(Math.ceil(response.data[0].countdocument / documentPerPage));
                } else {
                    setCustomerDocumentInfoCount(0);
                    setNumberOfPageDocument(0);
                }
                setDocumentLoading(false);

            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        setSessionModal(true);
                    } else {
                        toast.error(errorHandler(error));
                    }
                } else {
                    toast.error("Something went wrong!");
                }
                setDocumentLoading(false);

            });

            getCustomerRewardBalanceById(customer_id).then((response) => {
                if (response.data.length > 0) {
                    setTotalPoints(response.data[0].totalpoints);
                } else {
                    setTotalPoints(0);
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        setSessionModal(true);
                    }
                }
            });



        }, 2000); // fired after 2 seconds

        setTimeoutid(timeout);
    };




    function intervalexecuteMessage() {

        // messagetimeoutid is a variable 
        if (messagetimeoutid) {

            clearTimeout(messagetimeoutid);
        }

        const timeoutMessage = setTimeout(() => {
            setPushMessageLoading(true);

            getCustomerInfoPushMessagesById(customer_id, currentPagePushMessage, pushMessagePerPage, searchKeyPushMessage).then((response) => {
                console.log("push messgae interval");

                if (response.data.length > 0) {
                    setCustomerPushMessageInfo(response.data);
                } else {
                    setCustomerPushMessageInfo([]);
                }
                setPushMessageLoading(false);

            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        setSessionModal(true);
                    } else {

                        console.log(error);
                        toast.error(errorHandler(error));
                    }
                } else {
                    toast.error("Something went wrong!");
                }
                setCustomerPushMessageInfo([]);
                setPushMessageLoading(false);


            });

            getCustomerInfoPushMessagesByIdCount(customer_id, searchKeyPushMessage).then((response) => {
                if (response.data.length > 0) {
                    setCustomerPushMessageInfoCount(response.data[0].countpush);
                    setNumberOfPagePushMessage(Math.ceil(response.data[0].countpush / pushMessagePerPage));
                } else {
                    setCustomerPushMessageInfoCount(0);
                    setNumberOfPagePushMessage(0);
                }


            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        setSessionModal(true);
                    } else {
                        toast.error(errorHandler(error));
                    }
                } else {
                    toast.error("Something went wrong!");
                }

                setCustomerPushMessageInfoCount(0);

            });



        }, 2000); // fired after 2 seconds

        setMessagetimeoutid(timeoutMessage);

    };



    /*Get license details on basis search*/

    useEffect(() => {
        // get license data of a customer
        getLicenseByCustomerId(customer_id, searchKeyLicense).then((response) => {

            if (response.data.length > 0) {
                setCustomerLicenseInfo(response.data);
                // console.log(response.data);
            } else {
                setCustomerLicenseInfo([]);
            }
            setLicenseLoading(false);

        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 401) {
                    console.log(error.response.status);

                } else {
                    toast.error(errorHandler(error));
                    console.log(errorHandler(error));

                }
            } else {
                console.log("erro response dont exist");
            }

            setLicenseLoading(false);
        });
    }, [searchKeyLicense]);

    // if it is not first time any key change will fire intervalexecute method
    useEffect(() => {
        // only first time search string change will not work and first load is still 0  
        if (firstLoad !== 1) {
            intervalexecute();
        }
    }, [searchKeyReward, searchKeyVehicle, searchKeyStaff, searchKeyDocument]);

    useEffect(() => {
        // only first time search string change will not work and first load is still 0  
        if (firstLoad !== 1) {
            intervalexecuteMessage();
        }
    }, [searchKeyPushMessage]);
    // get customer basic info
    useEffect(() => {
        //only first time current page will be what ever page user address pressed     
        if (firstLoad === 1) {
            setFirstLoad(0);

        }
        setBasicLoading(true);
        getCustomerInfoBasicById(customer_id).then((response) => {
            if (response.data) {
                // console.log(response.data);
                setCustomerBasicInfo(response.data);
                setInputs(response.data[0]);
                console.log("customer data");
                console.log(response.data[0]);
                setCustomerName(response.data[0].customer_first_name + " " + response.data[0].customer_last_name);
                if (response.data[0].is_vip === 1) {
                    setIsVip(true);
                } else {
                    setIsVip(false);
                }
                if (parseInt(response.data[0].is_vip_enabled) === 1) {
                    setIsVipEnabled(true);
                } else {
                    setIsVipEnabled(false);
                }
                if (response.data[0].is_loyalty_rewards_user === 1) {
                    setIsLoyaltyRewardUser(true);
                } else {
                    setIsLoyaltyRewardUser(false);
                }
                if (parseInt(response.data[0].is_reward_enabled) === 1) {
                    setIsRewardEnabled(true);
                }
                else {
                    setIsRewardEnabled(false);
                }
                setVipMultiplier(response.data[0].vip_times);
                setLoc(response.data[0].location_id);
                setBasicLoading(false);

            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 401) {
                    setSessionModal(true);
                    setBasicLoading(false);
                } else {
                    toast.error(errorHandler(error));
                    setBasicLoading(false);
                }
            } else {
                toast.error("Something went wrong!");
            }
        });


    }, [customer_id]);


    // get rewards by customer id
    useEffect(() => {
        //only first time current page will be what ever page user address pressed     
        if (firstLoad === 1) {
            // not first time load each search set current page to 1
            setFirstLoad(0);
            // as it is firstload no need to do param update
            //console.log("first time load");
        }
        setRewardLoading(true);
        getRewardsByCustomerId(customer_id, currentPageReward, searchKeyReward, rewardSort, rewardKind, rewardStatus).then((response) => {
            //console.log(response.data, 'rewards');
            if (response.data.length > 0) {
                setCustomerRewardInfo(response.data);
                setRewardLoading(false);
            } else {
                setCustomerRewardInfo([]);
                setRewardLoading(false);
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 401) {
                    setSessionModal(true);
                    setRewardLoading(false);
                } else {
                    toast.error(errorHandler(error));
                    setRewardLoading(false);
                }
            } else {
                toast.error("Something went wrong!");
            }
        });
    }, [customer_id, currentPageReward, rewardSort, rewardKind, rewardStatus]);



    // get rewards count by customer id
    useEffect(() => {
        //only first time current page will be what ever page user address pressed     
        if (firstLoad === 1) {
            // not first time load each search set current page to 1
            setFirstLoad(0);
            // as it is firstload no need to do param update
            //console.log("first time load");
        }
        setRewardLoading(true);
        getRewardsCountByCustomerId(customer_id, searchKeyReward, rewardKind, rewardStatus).then((response) => {
            //console.log(response.data[0]);

            if (response.data.length > 0) {
                setCustomerRewardInfoCount(response.data[0].countreward);
                setNumberOfPageReward(Math.ceil(response.data[0].countreward / rewardsPerPage));
                setRewardLoading(false);
            } else {
                setCustomerRewardInfoCount(0);
                setNumberOfPageReward(0);
                setRewardLoading(false);
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 401) {
                    setSessionModal(true);
                } else {
                    toast.error(errorHandler(error));
                }
            } else {
                toast.error("Something went wrong!");
            }
        });


        getRewardsSumCustomerId(customer_id, searchKeyReward, rewardKind, rewardStatus).then((response) => {
            //console.log(response.data[0]);
            let points = 0;
            if (response.data.length > 0) {
                setTotalPoints(response.data[0].totalpoints);
            } else {
                setTotalPoints(points);
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 401) {
                    setSessionModal(true);
                } else {
                    toast.error(errorHandler(error));
                }
            } else {
                toast.error("Something went wrong!");
            }
        });
    }, [customer_id, rewardKind, rewardStatus]);



    // get vehicles by customer id
    useEffect(() => {
        //only first time current page will be what ever page user address pressed     
        if (firstLoad === 1) {
            // not first time load each search set current page to 1
            setFirstLoad(0);
            // as it is firstload no need to do param update
            //console.log("first time load");
        }
        setVehicleLoading(true);
        getCustomerInfoVehicleById(customer_id, currentPageVehicle, vehiclePerPage, searchKeyVehicle).then((response) => {
            if (response.data.length > 0) {
                setCustomerVehicleInfo(response.data);
                setVehicleLoading(false);
            } else {
                setCustomerVehicleInfo([]);
                setVehicleLoading(false);
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 401) {
                    setSessionModal(true);
                } else {
                    toast.error(errorHandler(error));
                }
            } else {
                toast.error("Something went wrong!");
            }
        });
    }, [customer_id, currentPageVehicle, vehiclePerPage]);


    // get vehicles count by customer id
    useEffect(() => {
        //only first time current page will be what ever page user address pressed     
        if (firstLoad === 1) {
            setFirstLoad(0);

        }
        setVehicleLoading(true);
        getCustomerInfoVehicleByIdCount(customer_id, searchKeyVehicle).then((response) => {
            if (response.data.length > 0) {
                setCustomerVehicleInfoCount(response.data[0].countvehicle);
                setNumberOfPageVehicle(Math.ceil(response.data[0].countvehicle / vehiclePerPage));
                setVehicleLoading(false);


            } else {
                setCustomerVehicleInfoCount(0);
                setNumberOfPageVehicle(0);
                setVehicleLoading(false);
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 401) {
                    setSessionModal(true);
                } else {
                    toast.error(errorHandler(error));
                }
            } else {
                toast.error("Something went wrong!");
            }
        });
    }, [customer_id, searchKeyVehicle]);



    // get staffs by customer id
    useEffect(() => {
        //only first time current page will be what ever page user address pressed     
        if (firstLoad === 1) {
            // not first time load each search set current page to 1
            setFirstLoad(0);
            // as it is firstload no need to do param update
            //console.log("first time load");
        }
        setStaffLoading(true);
        getCustomerInfoStaffById(customer_id, currentPageStaff, staffPerPage, searchKeyStaff).then((response) => {
            if (response.data.length > 0) {
                setCustomerStaffInfo(response.data);
                setStaffLoading(false);
            } else {
                setCustomerStaffInfo([]);
                setStaffLoading(false);
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 401) {
                    setSessionModal(true);
                } else {
                    toast.error(errorHandler(error));
                }
            } else {
                toast.error("Something went wrong!");
            }
        });
    }, [customer_id, currentPageStaff, staffPerPage]);


    // get staffs count by customer id
    useEffect(() => {
        //only first time current page will be what ever page user address pressed     
        if (firstLoad === 1) {
            // not first time load each search set current page to 1
            setFirstLoad(0);
            // as it is firstload no need to do param update
            //console.log("first time load");
        }
        setStaffLoading(true);
        getCustomerInfoStaffByIdCount(customer_id, searchKeyStaff).then((response) => {
            if (response.data.length > 0) {
                setCustomerStaffInfoCount(response.data[0].countstaff);
                setNumberOfPageStaff(Math.ceil(response.data[0].countstaff / staffPerPage));
                setStaffLoading(false);
            } else {
                setCustomerStaffInfoCount(0);
                setNumberOfPageStaff(0);
                setStaffLoading(false);
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 401) {
                    setSessionModal(true);
                } else {
                    toast.error(errorHandler(error));
                }
            } else {
                toast.error("Something went wrong!");
            }
        });
    }, [customer_id]);


    // get push messages by customer id
    useEffect(() => {
        //only first time current page will be what ever page user address pressed     
        if (firstLoad === 1) {
            // not first time load each search set current page to 1
            setFirstLoad(0);

        }
        setPushMessageLoading(true);
        getCustomerInfoPushMessagesById(customer_id, currentPagePushMessage, pushMessagePerPage, searchKeyPushMessage).then((response) => {
            console.log("Push message api");
            console.log(response.data);
            if (response.data.length > 0) {
                setCustomerPushMessageInfo(response.data);
                setPushMessageLoading(false);


            } else {
                setCustomerPushMessageInfo([]);
                setPushMessageLoading(false);
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 401) {
                    setSessionModal(true);
                } else {
                    toast.error(errorHandler(error));
                }
            } else {
                toast.error("Something went wrong!");
            }
        });
    }, [customer_id, currentPagePushMessage, pushMessagePerPage]);



    // get push messages count by customer id
    useEffect(() => {
        //only first time current page will be what ever page user address pressed     
        if (firstLoad === 1) {
            // not first time load each search set current page to 1
            setFirstLoad(0);
            // as it is firstload no need to do param update
            //console.log("first time load");
        }
        setPushMessageLoading(true);
        getCustomerInfoPushMessagesByIdCount(customer_id, searchKeyPushMessage).then((response) => {
            if (response.data.length > 0) {
                setCustomerPushMessageInfoCount(response.data[0].countpush);
                setNumberOfPagePushMessage(Math.ceil(response.data[0].countpush / pushMessagePerPage));
                setPushMessageLoading(false);
            } else {
                setCustomerPushMessageInfoCount(0);
                setNumberOfPagePushMessage(0);
                setPushMessageLoading(false);
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 401) {
                    setSessionModal(true);
                } else {
                    toast.error(errorHandler(error));
                }
            } else {
                toast.error("Something went wrong!");
            }
        });
    }, [customer_id]);



    // get documents by customer id
    useEffect(() => {
        //only first time current page will be what ever page user address pressed     
        if (firstLoad === 1) {
            // not first time load each search set current page to 1
            setFirstLoad(0);
            // as it is firstload no need to do param update
            //console.log("first time load");
        }
        setDocumentLoading(true);
        getCustomerInfoDocumentById(customer_id, currentPageDocument, documentPerPage, searchKeyDocument).then((response) => {
            if (response.data.length > 0) {
                //console.log(response.data);
                setCustomerDocumentInfo(response.data);
                setDocumentLoading(false);
            } else {
                setCustomerDocumentInfo([]);
                setDocumentLoading(false);
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 401) {
                    setSessionModal(true);
                } else {
                    toast.error(errorHandler(error));
                }
            } else {
                toast.error("Something went wrong!");
            }
        });
    }, [customer_id, currentPageDocument, documentPerPage]);


    // get documents count by customer id   
    useEffect(() => {
        //only first time current page will be what ever page user address pressed     
        if (firstLoad === 1) {
            // not first time load each search set current page to 1
            setFirstLoad(0);
            // as it is firstload no need to do param update
            //console.log("first time load");
        }
        setDocumentLoading(true);
        getCustomerInfoDocumentByIdCount(customer_id, searchKeyDocument).then((response) => {
            if (response.data.length > 0) {
                setCustomerDocumentInfoCount(response.data[0].countdocument);
                setNumberOfPageDocument(Math.ceil(response.data[0].countdocument / documentPerPage));
                setDocumentLoading(false);
            } else {
                setCustomerDocumentInfoCount(0);
                setNumberOfPageDocument(0);
                setDocumentLoading(false);
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 401) {
                    setSessionModal(true);
                    setDocumentLoading(false);
                } else {
                    toast.error(errorHandler(error));
                    setDocumentLoading(false);
                }
            } else {
                toast.error("Something went wrong!");
            }
        });
    }, [customer_id]);


    // get total reward points
    useEffect(() => {
        //only first time current page will be what ever page user address pressed     
        if (firstLoad === 1) {
            // not first time load each search set current page to 1
            setFirstLoad(0);
            // as it is firstload no need to do param update
            //console.log("first time load");
        }
        getCustomerRewardBalanceById(customer_id).then((response) => {
            if (response.data.length > 0) {
                setTotalPoints(response.data[0].totalpoints);
            } else {
                setTotalPoints(0);
            }
        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 401) {
                    setSessionModal(true);
                }
            }
        });
    }, [customer_id]);


    //console.log(loc, 'loc');



    // console.log(customerBasicInfo, 'customerBasicInfo');
    // console.log(customerRewardInfo, 'customerRewardInfo');
    // console.log(customerRewardInfoCount, 'customerRewardInfoCount');
    // console.log(customerVehicleInfo, 'customerVehicleInfo');
    // console.log(customerVehicleInfoCount, 'customerVehicleInfoCount');
    // console.log(customerStaffInfo, 'customerStaffInfo');
    // console.log(customerStaffInfoCount, 'customerStaffInfoCount');
    // console.log(customerPushMessageInfo, 'customerPushMessageInfo');
    // console.log(customerPushMessageInfoCount, 'customerPushMessageInfoCount');
    // console.log(customerDocumentInfo, 'customerDocumentInfo');
    // console.log(customerDocumentInfoCount, 'customerDocumentInfoCount');




    // handle logout
    const handleLogout = async () => {
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location = "/";
    };


    // handle basic info change
    const handleBasicChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setInputs(values => ({ ...values, [name]: value }));
        console.log(name);
        console.log(value);
        console.log(inputs);
    };
    const reloadBasicCustomerInfo = () => {

        getCustomerInfoBasicById(customer_id).then((response) => {
            if (response.data) {
                // console.log(response.data);
                setCustomerBasicInfo(response.data);
                setInputs(response.data[0]);

                setCustomerName(response.data[0].customer_first_name + " " + response.data[0].customer_last_name);
                if (response.data[0].is_vip === 1) {
                    setIsVip(true);
                } else {
                    setIsVip(false);
                }
                if (parseInt(response.data[0].is_vip_enabled) === 1) {
                    setIsVipEnabled(true);
                } else {
                    setIsVipEnabled(false);
                }
                if (response.data[0].is_loyalty_rewards_user === 1) {
                    setIsLoyaltyRewardUser(true);
                } else {
                    setIsLoyaltyRewardUser(false);
                }
                if (parseInt(response.data[0].is_reward_enabled) === 1) {
                    setIsRewardEnabled(true);
                }
                else {
                    setIsRewardEnabled(false);
                }
                setVipMultiplier(response.data[0].vip_times);
                setLoc(response.data[0].location_id);

            }

            setBasicLoading(false);

        }).catch((error) => {
            if (error.response) {
                if (error.response.status === 401) {
                    setSessionModal(true);
                    setBasicLoading(false);
                } else {
                    toast.error(errorHandler(error));
                    setBasicLoading(false);
                }
            } else {
                toast.error("Something went wrong!");
            }
        });




    }
    const updateBasicInfo = () => {

        setBasicLoading(true);

        updateCustomerInfo(inputs).then((res) => {
            if (res.status === 201 && res.data.affectedRows > 0) {
                setShowToast(false);
                toast.success(`Updated Successfully`, {
                    position: toast.POSITION.TOP_CENTER, autoClose: 3000, hideProgressBar: false, onClose: () => {

                    }
                });
                reloadBasicCustomerInfo();
                setBasicLoading(false);
            } else {
                setShowToast(true);
                toast.error(`Could not update customer!`, { position: toast.POSITION.TOP_CENTER, autoClose: false, hideProgressBar: true });
                setBasicLoading(true);
            }
        }).catch((err) => {
            errorHandler(err, setSessionModal, setShowToast, toast);
            setBasicLoading(false);
        });

    };
    // handle reward changes
    const handleRewardChange = async (event) => {
        const name = event.target.name;
        let value;

        if (event.target.type === 'select-one') {
            let selectedIndex = event.target.selectedIndex;
            let tvalue = document.getElementById(name).value;
            // incase no value selected 
            if (selectedIndex === 0) {
                if (name === 'sort') {
                    setRewardSort(null);
                    setCurrentPageReward(1);
                } else if (name === 'kind') {
                    setRewardKind(null);
                    setCurrentPageReward(1);
                } else if (name === 'status') {
                    setRewardStatus(null);
                    setCurrentPageReward(1);
                }
            } else {
                // converting to number 
                if (name === 'sort') {
                    value = +tvalue;
                    setRewardSort(value);
                    setCurrentPageReward(1);
                } else if (name === 'kind') {
                    value = tvalue;
                    setRewardKind(value);
                    setCurrentPageReward(1);
                } else if (name === 'status') {
                    value = +tvalue;
                    setRewardStatus(value);
                    setCurrentPageReward(1);
                }
            }
        }
        else {
            value = event.target.value;
            if (name === 'search_customerInfoReward') {
                setSearchKeyReward(value);
            }
        }
    };


    // handle vehicle changes
    const handleVehicleChange = (event) => {
        // console.log("handle fired");
        if (currentPageVehicle !== 1) {
            setCurrentPageVehicle(1);
        }

        const name = event.target.name;
        let value;
        value = event.target.value;
        if (name === 'search_customerInfoVehicle') {
            setSearchKeyVehicle(value);
        }
    };


    // handle License changes
    const handleLicenseChange = (event) => {


        const name = event.target.name;
        let value;
        value = event.target.value;
        setSearchKeyLicense(value);
    };




    // handle staff changes
    const handleStaffChange = (event) => {
        // console.log("handle fired");
        if (currentPageStaff !== 1) {
            setCurrentPageStaff(1);
        }

        const name = event.target.name;
        let value;
        value = event.target.value;
        if (name === 'search_customerInfoStaff') {
            setSearchKeyStaff(value);
        }
    };


    // handle push message changes
    const handlePushMessageChange = (event) => {
        // console.log("handle fired");
        if (currentPagePushMessage !== 1) {
            setCurrentPagePushMessage(1);
        }

        const name = event.target.name;
        let value;
        value = event.target.value;
        if (name === 'search_customerInfoPushMessage') {
            setSearchKeyPushMessage(value);
        }
    }


    // handle document changes
    const handleDocumentChange = (event) => {
        // console.log("handle fired");
        if (currentPageDocument !== 1) {
            setCurrentPageDocument(1);
        }

        const name = event.target.name;
        let value;
        value = event.target.value;
        if (name === 'search_customerInfoDocument') {
            setSearchKeyDocument(value);
        }
    };


    // handle delete
    const handleDelete = (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this entry?");

        if (confirmDelete) {
            deleteCustomerVehicle(id).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    toast.success(`Vehicle deleted successfully!`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        hideProgressBar: false,
                        onClose: () => {
                            window.location.reload();
                        }
                    });
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        setSessionModal(true);
                    }
                }
            });
        }
        else {
            // User clicked cancel or closed the confirmation dialog
            alert("Deletion canceled.");
        }
    };

    const handleDeleteLicense = (id, customerId) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this license?");

        if (confirmDelete) {
            deleteCustomerLicense(id, customer_id).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    toast.success(`License deleted successfully!`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        hideProgressBar: false,
                        onClose: () => {
                            window.location.reload();
                        }
                    });
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        setSessionModal(true);
                    }
                }
            });
        }
        else {
            // User clicked cancel or closed the confirmation dialog
            alert("Deletion canceled.");
        }
    };



    // handle delete staff
    const handleDeleteStaff = (customer_id, staff_id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this entry?");

        if (confirmDelete) {
            deleteStaffFromCustomer(customer_id, staff_id).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    toast.success(`Staff deleted successfully!`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        hideProgressBar: false,
                        onClose: () => {
                            window.location.reload();
                        }
                    });
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        setSessionModal(true);
                    }
                }
            });
        }
        else {
            // User clicked cancel or closed the confirmation dialog
            alert("Deletion canceled.");
        }
    };


    // handle delete document
    const handleDeleteDocument = (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this entry?");

        if (confirmDelete) {
            deleteDocumentFromCustomer(id).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    toast.success(`Document deleted successfully!`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        hideProgressBar: false,
                        onClose: () => {
                            window.location.reload();
                        }
                    });
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                        setSessionModal(true);
                    }
                }
            });
        } else {
            // User clicked cancel or closed the confirmation dialog
            alert("Deletion canceled.");
        }
    };


    // handle delete reward
    const handleDeleteReward = (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this entry?");

        if (confirmDelete) {
            deleteRewardForCustomer(id)
                .then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        toast.success(`Reward deleted successfully!`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            hideProgressBar: false,
                            onClose: () => {
                                window.location.reload();
                            }
                        });
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        if (error.response.status === 401) {
                            setSessionModal(true);
                        }
                    }
                });
        } else {
            // User clicked cancel or closed the confirmation dialog
            alert("Deletion canceled.");
        }
    };


    // handle edit modal
    const handleEditModal = (id) => {
        setShowEditRewardModal(true);
        setEditRewardModalId(id);
    };


    //console.log(customerDocumentInfo, 'customerDocumentInfo');

    return (
        <>
            <div className="col-xl-12">
                {submitting &&
                    <div>Submtting Form...</div>
                }
                {showToast && <div className="toast-overlay"></div>}
                <ToastContainer onClose={handleCloseToast} />
                <SessionExpiredModal
                    show={sessionModal}
                    handleLogout={handleLogout}
                />
                <a href={backUrl} type="button" className="btn btn-sm rounded-pill btn-secondary">Back</a>  <a href="/customer_profiles" type="button" className="btn btn-sm rounded-pill btn-secondary">Customer Home</a>
                <AddVehicleModal show={showAddVehicleModal} setShow={setShowAddVehicleModal} locationId={loc} />

                <AddStaffModal show={showAddStaffModal} setShow={setShowAddStaffModal} locationId={loc} />
                <AddDocumentModal show={showAddDocumentModal} setShow={setShowAddDocumentModal} locationId={loc} />
                <AddRewardModal show={showAddRewardModal} setShow={setShowAddRewardModal} locationId={loc} isVip={isVip} isVipEnabled={isVipEnabled} vipMultiplier={vipMultiplier} />
                <EditRewardModal show={showEditRewardModal} setShow={setShowEditRewardModal} locationId={loc} rewardId={editRewardModalId} rewardData={customerRewardInfo} />
                <div className="card mb-4">
                    <h5 className="card-header"> ID  {customer_id} - {customerName} {isVip ? <span className="badge bg-danger">VIP</span> : ''}</h5>
                    {basicLoading ?
                        <Loading />
                        :

                        <div className="card-body">
                            <AddLicenseModal show={showAddLicenseModal} setShow={setShowAddLicenseModal} customerInfo={customerBasicInfo} customerId={customer_id} licenseInfo={licenseInfo} supdate={isLicenseUpdate} />

                            <div className="mb-3 row">
                                <label className="col-md-2 col-form-label">Apps</label>
                                <div className="col-md-10">

                                    {customerBasicInfo.length > 0 ? inputs.app_name : ''}
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label className="col-md-2 col-form-label">Location</label>
                                <div className="col-md-10">
                                    {customerBasicInfo.length > 0 ? inputs.location : ''} {isRewardEnabled ? <span className="badge bg-success">Reward Enabled</span> : ''}
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">First Name</label>
                                <div className="col-md-10">
                                    <input className="form-control" type="text" id="customer_first_name" name="customer_first_name" value={inputs.customer_first_name || ''} onChange={handleBasicChange} />


                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Last Name</label>
                                <div className="col-md-10">
                                    <input className="form-control" type="text" id="customer_last_name" name="customer_last_name" value={inputs.customer_last_name || ''} onChange={handleBasicChange} />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Email</label>
                                <div className="col-md-10">
                                    <input className="form-control" type="email" id="customer_email" pattern="[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$" name="customer_email" value={inputs.customer_email || ''} onChange={handleBasicChange} onInvalid={e => e.target.setCustomValidity('Please enter a valid email address')} onInput={e => e.target.setCustomValidity('')} readOnly />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Email Verified</label>
                                <div className="col-md-10">
                                    <input className="form-control" type="text" name="email_verified" value={customerBasicInfo.length > 0 && inputs.email_has_been_verified && inputs.email_has_been_verified.data[0] === 1 ? 'Yes' : 'No'} onChange={handleBasicChange} readOnly />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Phone</label>
                                <div className="col-md-2">
                                    <input className="form-control" type="text" name="phone_country_code" id="phone_country_code" value={inputs.phone_country_code || ''} onChange={handleBasicChange} readOnly />
                                </div>
                                <div className="col-md-8">

                                    <input className="form-control" type="text" pattern="^(N\/A|\d{3}-\d{3}-\d{4}|\(\d{3}\)\s*\d{3}-\d{4}|\d{10}|\+?\d{1,2}\s?\d{3}[\s.-]?\d{3}[\s.-]?\d{4})(\s*(ext|x|ext\.|Ext|ext #|Ext #|x #|Ext\.)\s*\d{1,5})?$" name="customer_phone" id="customer_phone" value={inputs.customer_phone || ''} onChange={handleBasicChange} onInvalid={e => e.target.setCustomValidity('Please enter a valid phone number')} onInput={e => e.target.setCustomValidity('')} readOnly />
                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Contact Preference</label>
                                <div className="col-md-10">

                                    <input className="form-control" type="text" name="contact_pref" id="contact_pref" value={inputs.contact_pref || ''} onChange={handleBasicChange} />

                                </div>
                            </div>
                            <div className="mb-3 row">
                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Zip Code </label>
                                <div className="col-md-10">

                                    <input className="form-control" type="text" name="zip_code" id="zip_code" value={inputs.zip_code || ''} onChange={handleBasicChange} />

                                </div>
                            </div>


                            <div className="mb-3 row">
                                <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Loyalty Reward User</label>
                                <div className="col-md-10">
                                    <input className="form-control" type="text" name="loyalty_reward_user" value={customerBasicInfo.length > 0 && inputs.is_loyalty_rewards_user && inputs.is_loyalty_rewards_user.data[0] === 0 | null ? 'No' : 'Yes'} onChange={handleBasicChange} readOnly />
                                </div>
                            </div>
                            {isVipEnabled && isRewardEnabled ?
                                <div className="mb-3 row">
                                    <label htmlFor="html5-text-input" className="col-md-2 col-form-label">Loyalty Reward Multiplier</label>
                                    <div className="col-md-10">
                                        <input className="form-control" type="text" name="loyalty_reward_multiplier" value={customerBasicInfo.length > 0 ? inputs.vip_times : ''} onChange={handleBasicChange} readOnly />
                                    </div>
                                </div>
                                : null
                            }
                            <input type="button" className="btn btn-secondary col-md-1" value="Save" onClick={updateBasicInfo} />

                        </div>

                    }
                </div>
                <div className="card mb-4" style={{ paddingTop: '20px' }}>
                    <h5 className="card-header">Driver's License</h5>
                    <div className="mb-3 row">
                        <div className="col-md-6">
                            <div style={{ paddingLeft: "20px" }}>
                                <button type="button" className="btn btn-primary col-md-3" style={{ fontSize: '12px' }} onClick={() => openNewLicenseModal()}>Add License</button>

                            </div>
                        </div>
                        <div className="col-md-5">
                            <input className="form-control" type="search" id="search_license_input" name="search_customerLicense" value={searchKeyLicense || ''} onChange={handleLicenseChange} placeholder="search ..." />
                        </div>
                    </div>
                    {licenseLoading ?
                        <Loading />
                        :
                        <table className="table table-sm table-bordered" style={{ paddingLeft: '30px', paddingRight: '20px' }}>
                            <thead>
                                <tr>
                                    <th>Number</th>
                                    <th>Name</th>
                                    <th>Address</th>
                                    <th>Birth Date</th>
                                    <th>Document Type</th>
                                    <th>Issue Date</th>
                                    <th>Expiry Date</th>
                                    <th>Gender</th>

                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody className="table-border-bottom-0">
                                {customerLicenseInfo && customerLicenseInfo.map((item, index) => (
                                    <tr key={index}>
                                        <td title={item.id}>{item.license_number}</td>
                                        <td>{item.first_name} {item.middle_name}  {item.last_name}</td>
                                        <td>{item.street} {item.city} {item.state} {item.country}</td>
                                        <td>{moment(item.birth_date).format('YYYY-MM-DD')}</td>
                                        <td>{item.document_type}</td>
                                        <td>{moment(item.issue_date).format('YYYY-MM-DD')} </td>
                                        <td>
                                            {moment(item.expiry_date).format('YYYY-MM-DD')}
                                        </td>
                                        <td>
                                            {item.gender === 1 ? "Male" : "Female"}


                                        </td>

                                        <td>

                                            <button type="button" className="btn btn-sm rounded-pill btn-secondary" onClick={() => { editLicenseModal(item) }}><i className='bx bxs-edit'></i></button>

                                            <button type="button" className="btn btn-sm rounded-pill btn-secondary" onClick={() => handleDeleteLicense(item.id, customer_id)}><i className='bx bxs-trash'></i></button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    }
                </div>


                {isRewardEnabled ?
                    <>
                        <div className="card mb-4">
                            <h5 className="card-header">Loyalty Rewards</h5>
                            <div className="mb-3 row">
                                {isRewardEnabled ?
                                    <div className="col-md-6">
                                        <div style={{ paddingLeft: "20px" }}>
                                            <button type="button" className="btn btn-primary col-md-3" style={{ fontSize: '12px' }} onClick={() => setShowAddRewardModal(true)}>Add Reward or Task</button>
                                        </div>
                                    </div>
                                    : ''
                                }
                            </div>
                            <div className="mb-3 row" style={{ paddingLeft: '20px', paddingRight: '10px' }}>
                                <div className="col-md-6" style={{ width: '200px' }}>
                                    <label className="col-form-label">Kind</label>
                                    <select className="form-select" id="kind" name="kind" onChange={handleRewardChange} value={rewardKind} >
                                        <option value="">---Kind---</option>
                                        <option value="reward">Reward</option>
                                        <option value="task">Task</option>
                                    </select>
                                </div>
                                <div className="col-md-6" style={{ width: '200px' }}>
                                    <label className="col-form-label">Sort By</label>
                                    <select className="form-select" id="sort" name="sort" onChange={handleRewardChange} value={rewardSort} >
                                        <option value="">--Sort--</option>
                                        <option value="0">Reward Name</option>
                                        <option value="1">Reward Points</option>
                                        <option value="2">Reward Status</option>
                                        <option value="3">Reward Date</option>
                                        <option value="4">Reward Expiry Date</option>
                                        <option value="5">Reward Task Status</option>
                                        <option value="6">Reward Task Date</option>
                                        <option value="7">Reward Task Expiry Date</option>
                                    </select>
                                </div>
                                <div className="col-md-6" style={{ width: '200px' }}>
                                    <label className="col-form-label">Status</label>
                                    <select className="form-select" id="status" name="status" onChange={handleRewardChange} value={rewardStatus} >
                                        <option value="">--Status--</option>
                                        <option value="1">Active</option>
                                        <option value="0">Expired</option>
                                    </select>
                                </div>
                                <div className="col-md-4" style={{ paddingTop: '35px', width: '50%', paddingLeft: '50px' }}>
                                    <input className="form-control" type="search" id="search_input" name="search_customerInfoReward" value={searchKeyReward || ''} onChange={handleRewardChange} placeholder="search ..." />
                                </div>
                            </div>
                            <div className="mb-3 row" style={{ paddingLeft: '30px', paddingRight: '20px' }}><strong>{customerRewardInfoCount} reward/ task entries found</strong>   </div>

                            <div className="alert alert-primary" role='alert' style={{ paddingLeft: '30px', paddingRight: '20px' }}><strong>Total Balance: {totalPoints ? totalPoints : 0} Points</strong></div>
                            {rewardLoading ?
                                <Loading />
                                :
                                <table className="table table-sm table-bordered" style={{ paddingLeft: '30px', paddingRight: '20px' }}>
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th>Description</th>
                                            <th>Points</th>
                                            <th>Auto Entry</th>
                                            <th>Created</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="table-border-bottom-0">
                                        {customerRewardInfo && customerRewardInfo.map((item, index) => (
                                            <tr key={index}>
                                                <td title={item.id}>{item.title}</td>
                                                <td><ReadMore>{item.description}</ReadMore></td>
                                                <td style={{ color: parseInt(item.points) > 0 ? '#00ab41' : '#FF3632' }} >{item.points}</td>
                                                <td>{item.auto_entry_dms === 0 ? 'No' : 'Yes'}</td>
                                                <td>{item.created_at}</td>
                                                <td>
                                                    <button type="button" className="btn btn-sm rounded-pill btn-secondary" onClick={() => { handleEditModal(item.id) }}><i className='bx bxs-edit'></i></button>
                                                    <button type="button" className="btn btn-sm rounded-pill btn-secondary" onClick={() => handleDeleteReward(item.id)}><i className='bx bxs-trash'></i></button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            }
                        </div>
                        <div>
                            <div className="pagination-container" style={{ paddingBottom: '30px', marginTop: '-10px' }}>
                                <PaginationComponent
                                    currentPage={currentPageReward}
                                    setCurrentPage={setCurrentPageReward}
                                    numberOfPage={numberOfPageReward}
                                    numVisiblePages={50}
                                />
                            </div>
                        </div>
                    </>
                    :
                    <div className="alert alert-primary" role='alert' style={{ paddingLeft: '30px', paddingRight: '20px' }}><strong>Not a rewards user</strong></div>
                }



                <div className="card mb-4" style={{ paddingTop: '20px' }}>
                    <h5 className="card-header">Vehicles</h5>
                    <div className="mb-3 row">
                        <div className="col-md-6">
                            <div style={{ paddingLeft: "20px" }}>
                                <button type="button" className="btn btn-primary col-md-3" style={{ fontSize: '12px' }} onClick={() => setShowAddVehicleModal(true)}>Add Vehicle</button>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <input className="form-control" type="search" id="search_input" name="search_customerInfoVehicle" value={searchKeyVehicle || ''} onChange={handleVehicleChange} placeholder="search ..." />
                        </div>
                    </div>
                    <div className="mb-3 row" style={{ paddingLeft: '30px', paddingRight: '20px' }}><strong>{customerVehicleInfoCount} vehicles found</strong> </div>

                    {vehicleLoading ?
                        <Loading />
                        :
                        <table className="table table-sm table-bordered" style={{ paddingLeft: '30px', paddingRight: '20px' }}>
                            <thead>
                                <tr>
                                    <th>VIN</th>
                                    <th>Acode</th>
                                    <th>Year</th>
                                    <th>Make</th>
                                    <th>Model</th>
                                    <th>Odometer</th>
                                    <th>Created</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody className="table-border-bottom-0">
                                {customerVehicleInfo && customerVehicleInfo.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.vehicle_vin}</td>
                                        <td>{item.vehicle_acode}</td>
                                        <td>{item.vehicle_year}</td>
                                        <td>{item.vehicle_make}</td>
                                        <td>{item.vehicle_model}</td>
                                        <td>{item.vehicle_odometer}</td>
                                        <td>{moment(item.vehicles_created_at).format('YYYY-MM-DD')}</td>
                                        <td>
                                            <button type="button" className="btn btn-sm rounded-pill btn-secondary" onClick={() => handleDelete(item.vehicle_id)}><i className='bx bxs-trash'></i></button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    }
                </div>
                <div>
                    <div className="pagination-container" style={{ paddingBottom: '30px', marginTop: '-10px' }}>
                        <PaginationComponent
                            currentPage={currentPageVehicle}
                            setCurrentPage={setCurrentPageVehicle}
                            numberOfPage={numberOfPageVehicle}
                            numVisiblePages={50}
                        />
                    </div>
                </div>
                <div className="card mb-4" style={{ paddingTop: '20px' }}>
                    <h5 className="card-header">Favorited Staff</h5>
                    <div className="mb-3 row">
                        <div className="col-md-6">
                            <div style={{ paddingLeft: "20px" }}>
                                <button type="button" className="btn btn-primary col-md-3" style={{ fontSize: '12px' }} onClick={() => setShowAddStaffModal(true)}>Add Staff</button>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <input className="form-control" type="search" id="search_input" name="search_customerInfoStaff" value={searchKeyStaff || ''} onChange={handleStaffChange} placeholder="search ..." />
                        </div>
                    </div>

                    <div className="mb-3 row" style={{ paddingLeft: '30px', paddingRight: '20px' }}><strong>{customerStaffInfoCount} staff favorited</strong>   </div>



                    {staffLoading ?
                        <Loading />
                        :
                        <table className="table table-sm table-bordered" style={{ paddingLeft: '30px', paddingRight: '20px' }}>
                            <thead>
                                <tr>
                                    <th>Department</th>
                                    <th>First Name</th>
                                    <th>Last Name</th>
                                    <th>Title</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Created</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody className="table-border-bottom-0">
                                {customerStaffInfo && customerStaffInfo.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.staff_department}</td>
                                        <td title={item.staff_id}><a href={process.env.REACT_APP_BASE_URL + "/staff/edit/" + item.staff_id} target="_blank">{item.staff_first_name}</a></td>
                                        <td title={item.staff_id}><a href={process.env.REACT_APP_BASE_URL + "/staff/edit/" + item.staff_id} target="_blank">{item.staff_last_name}</a></td>
                                        <td>{item.staff_title}</td>
                                        <td>{item.staff_email}</td>
                                        <td>{item.staff_phone}</td>
                                        <td>{moment(item.created_at).format('YYYY-MM-DD')}</td>
                                        <td>
                                            <button type="button" className="btn btn-sm rounded-pill btn-secondary" onClick={() => handleDeleteStaff(customer_id, item.staff_id)}><i className='bx bxs-trash'></i></button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    }
                </div>
                <div>
                    <div className="pagination-container" style={{ paddingBottom: '30px', marginTop: '-10px' }}>
                        <PaginationComponent
                            currentPage={currentPageStaff}
                            setCurrentPage={setCurrentPageStaff}
                            numberOfPage={numberOfPageStaff}
                            numVisiblePages={50}
                        />
                    </div>
                </div>
                <div className="card mb-4" style={{ paddingTop: '20px' }}>
                    <h5 className="card-header">Documents</h5>
                    <div className="mb-3 row">
                        <div className="col-md-6">
                            <div style={{ paddingLeft: "20px" }}>
                                <button type="button" className="btn btn-primary col-md-3" style={{ fontSize: '12px' }} onClick={() => setShowAddDocumentModal(true)}>Add Document</button>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <input className="form-control" type="search" id="search_input" name="search_customerInfoDocument" value={searchKeyDocument || ''} onChange={handleDocumentChange} placeholder="search ..." />
                        </div>
                    </div>
                    <div className="mb-3 row" style={{ paddingLeft: '30px', paddingRight: '20px' }}><strong>{customerDocumentInfoCount} Documents Found</strong>   </div>


                    {documentLoading ?
                        <Loading />
                        :
                        <table className="table table-sm table-bordered" style={{ paddingLeft: '30px', paddingRight: '20px' }}>
                            <thead>
                                <tr>
                                    <th>Document</th>
                                    <th> URL</th>

                                    <th>Created</th>
                                    <th>Uploaded Via</th>
                                    <th> VIN</th>

                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody className="table-border-bottom-0">
                                {customerDocumentInfo && customerDocumentInfo.map((item, index) => (
                                    <tr key={index}>
                                        <td title={item.document_id}>{item.documents_name}</td>
                                        <td><a href={item.file_url}>Click to Open</a></td>

                                        <td>{moment(item.document_created).format('YYYY-MM-DD')}</td>
                                        <td>{item.document_upload_via ? (item.document_upload_via === 2 ? 'web' : 'mobile') : ''}</td>
                                        <td>{item.document_vin}</td>
                                        <td>
                                            <button type="button" className="btn btn-sm rounded-pill btn-secondary" onClick={() => handleDeleteDocument(item.document_id)}><i className='bx bxs-trash'></i></button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    }
                </div>
                <div>
                    <div className="pagination-container" style={{ display: "flex", flexWrap: "wrap" }}>
                        <PaginationComponent
                            currentPage={currentPageDocument}
                            setCurrentPage={setCurrentPageDocument}
                            numberOfPage={numberOfPageDocument}
                            numVisiblePages={50}
                        />
                    </div>
                </div>
                <div className="card mb-4" style={{ paddingTop: '20px' }}>
                    <h5 className="card-header">Messages</h5>
                    <div className="mb-3 row">
                        <div className="col-md-6">
                            {/* <div style={{ paddingLeft: "20px" }}>
                                    <a href={'#'} type="button" className="btn btn-primary col-md-3">Add Messages</a>
                                </div> */}
                        </div>
                        <div className="col-md-5" style={{ justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                            <input className="form-control" type="search" id="search_input" name="search_customerInfoPushMessage" value={searchKeyPushMessage || ''} onChange={handlePushMessageChange} placeholder="search ..." />
                        </div>
                    </div>
                    <div className="mb-3 row" style={{ paddingLeft: '30px', paddingRight: '20px' }}><strong>{customerPushMessageInfoCount} targeted messages found</strong> </div>

                    {pushMessageLoading ?
                        <Loading />
                        :
                        <table className="table table-sm table-bordered" style={{ paddingLeft: '30px', paddingRight: '20px' }}>
                            <thead>
                                <tr>
                                    <th>Subject</th>
                                    <th>Message</th>
                                    <th>Type</th>

                                    <th>Created</th>
                                </tr>
                            </thead>
                            <tbody className="table-border-bottom-0">
                                {customerPushMessageInfo && customerPushMessageInfo.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.message_subject}</td>
                                        <td>{item.message_body}</td>
                                        <td>{item.message_type}</td>
                                        <td>{moment(item.push_message_created).format('YYYY-MM-DD')}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    }
                </div>
                <div>
                    <div className="pagination-container" style={{ display: "flex", flexWrap: "wrap" }}>
                        <PaginationComponent
                            currentPage={currentPagePushMessage}
                            setCurrentPage={setCurrentPagePushMessage}
                            numberOfPage={numberOfPagePushMessage}
                            numVisiblePages={50}
                        />
                    </div>
                </div>

            </div>
        </>
    );
};


export default EditCustomer;
