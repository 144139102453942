import axiosInstance from "../services/AxiosInstance";
import moment from "moment";


// utility function for format datetime
const formatDateTime = (date) => {
    if (date) {
        return moment(date).format('YYYY-MM-DD HH:mm:ss');
    }
};

// get all staffs
export const staffData = (apps, loc, cpage, searchkey, depart, stDate, enDate, applist, staffsPerPage, sort, role) => {
    console.log(applist);
    // let userx = JSON.parse(localStorage.getItem('user'));

    let offset = (cpage - 1) * staffsPerPage;
    let url = "";
    let questionadded = false;
    if (apps !== null && loc === null) {
        url = url + '/v2/staff/all/' + offset + '/' + staffsPerPage + '?appId=' + apps;
        questionadded = true;
    }
    else if (apps !== null && loc !== null) {
        url = url + "/v2/staff/" + loc + "/manylocation?offset=" + offset + "&limit=" + staffsPerPage;
        questionadded = true;
    }
    else if (apps === null && loc === null) {
        // let appsList = userx.apps;
        // let role = userx.role;

        if (role === 'administrator') {
            url = url + '/v2/staff/all/' + offset + '/' + staffsPerPage;
        } else if (applist && applist.length === 1) {
            url = url + '/v2/staff/all/' + offset + '/' + staffsPerPage + '?appId=' + applist[0].id;
            questionadded = true;

        }
        else if (applist && applist.length > 0) {
            let appIds = [];
            for (let item of applist) {
                appIds.push(item.id);
            }


            let apid = encodeURIComponent(appIds);
            // url = url + '/v2/staff/all-data/' + offset + '/' + staffsPerPage + '?appId=[' + appIds + ']';
            url = url + '/v2/staff/all-data/' + offset + '/' + staffsPerPage + '?appId=' + apid;
            questionadded = true;

        } else {
            url = url + '/v2/staff/all/' + offset + '/' + staffsPerPage + '?appId=-1';
            questionadded = true;

        }


    }
    if (searchkey !== '' && searchkey) {
        let searchkeyencoded = encodeURIComponent(searchkey);
        if (questionadded) {
            url = url + '&searchkey=' + searchkeyencoded;
        } else {
            url = url + '?searchkey=' + searchkeyencoded;
            questionadded = true;

        }

    }

    if (depart && depart !== '' && depart !== null) {
        let departmentencoded = encodeURIComponent(depart);
        if (questionadded) {
            url = url + '&department=' + departmentencoded;
        } else {
            url = url + '?department=' + departmentencoded;
            questionadded = true;
        }
    }
    if (sort) {
        if (questionadded) {
            url = url + '&sort=' + sort;
        } else {
            url = url + '?sort=' + sort;
            questionadded = true;
        }

    }
    let startDates, endDates;
    if (stDate !== null && enDate !== null) {
        startDates = formatDateTime(stDate);
        endDates = formatDateTime(enDate);
    } else if (stDate !== null && enDate === null) {
        startDates = formatDateTime(stDate);
        endDates = formatDateTime(moment());
    } else if (stDate === null && enDate !== null) {
        startDates = formatDateTime(moment(`2000-01-01:00:00:00`, `YYYY-MM-DD HH:mm:ss`));
        endDates = formatDateTime(enDate);
    }

    if (startDates && endDates) {
        const startDateEncoded = encodeURIComponent(startDates);
        const endDateEncoded = encodeURIComponent(endDates);
        if (questionadded) {
            url = url + '&startDate=' + startDateEncoded + '&endDate=' + endDateEncoded;
        } else {
            url = url + '?startDate=' + startDateEncoded + '&endDate=' + endDateEncoded;
            questionadded = true;
        }
    }
    console.log(url);
    return axiosInstance.get(url);
};


// get all staffcount

export const staffCount = (apps, loc, searchkey, depart, stDate, enDate, applist, role) => {
    console.log(applist);
    let url = '';
    let questionadded = false;
    if (apps !== null && loc !== null) {
        url = url + "/v2/staff/" + loc + "/manylocationcount";
    } else if (apps !== null && loc === null) {
        url = url + "/v2/staff/allcount?appId=" + apps;
        questionadded = true;
    } else if (apps === null && loc === null) {
        // const user = JSON.parse(localStorage.getItem('user'));
        // const appsList = user.apps;
        if (role === 'administrator') {
            url = url + "/v2/staff/allcount";
        } else if (applist && applist.length === 1) {
            url = url + "/v2/staff/allcount?appId=" + applist[0].id;
            questionadded = true;

        }
        else if (applist && applist.length > 0) {
            let appIds = [];
            for (const item of applist) {
                appIds.push(item.id);
            }
            let apid = encodeURIComponent(appIds);
            // url = url + "/v2/staff/alldatacount?appId=[" + appIds + "]";
            url = url + "/v2/staff/alldatacount?appId=" + apid;
            questionadded = true;


        } else {
            url = url + "/v2/staff/allcount?appId=-1";
            questionadded = true;

        }

    }

    if (searchkey && searchkey !== "") {
        let searchkeyencoded = encodeURIComponent(searchkey);
        if (questionadded) {
            url = url + "&searchkey=" + searchkeyencoded;
        } else {
            questionadded = true;
            url = url + "?searchkey=" + searchkeyencoded;
        }
    }
    if (depart && depart !== "") {
        let departmentencoded = encodeURIComponent(depart);
        if (questionadded) {
            url = url + "&department=" + departmentencoded;
        } else {
            questionadded = true;
            url = url + "?department=" + departmentencoded;
        }
    }

    let startDates, endDates;
    if (stDate !== null && enDate !== null) {
        startDates = formatDateTime(stDate);
        endDates = formatDateTime(enDate);
    } else if (stDate !== null && enDate === null) {
        startDates = formatDateTime(stDate);
        endDates = moment().format(`YYYY-MM-DD HH:mm:ss`);
    } else if (stDate === null && enDate !== null) {
        startDates = moment(`2000-01-01:00:00:00`, `YYYY-MM-DD HH:mm:ss`);
        endDates = formatDateTime(enDate);
    }


    if (startDates && endDates) {
        const startDateEncoded = encodeURIComponent(startDates);
        const endDateEncoded = encodeURIComponent(endDates);
        if (questionadded) {
            url = url + '&startDate=' + startDateEncoded + '&endDate=' + endDateEncoded;
        } else {
            url = url + '?startDate=' + startDateEncoded + '&endDate=' + endDateEncoded;
            questionadded = true;
        }
    }

    return axiosInstance.get(url);
};


// get staff by id
export const getStaffById = (id) => {
    let url = "/v2/staff/get/" + id;
    return axiosInstance.get(url);
};



// add staff
export const addStaff = (inputs) => {
    let userx = JSON.parse(localStorage.getItem('user'));

    const formData = new FormData();
    for (var i = 0; i < inputs.location_id.length; i++) {
        formData.append("location_id[]", inputs.location_id[i]);
    };

    formData.append("first_name", inputs.first_name);
    formData.append("last_name", inputs.last_name);
    formData.append("department", inputs.department);
    formData.append("title", inputs.title);
    formData.append("phone", inputs.phone);
    formData.append("email", inputs.email);
    formData.append("text_message_number", inputs.text_message_number);
    formData.append("photo_file_name", inputs.photo_file_name);
    formData.append("created_by", userx.id);

    return axiosInstance.post("/v2/staff/add", formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }
    );
};


// update staff
export const updateStaff = (inputs, locationChanged, mainlocation) => {
    const formData = new FormData();
    let userx = JSON.parse(localStorage.getItem('user'));

    console.log(inputs.id);
    formData.append("id", inputs.id);
    formData.append("first_name", inputs.first_name);
    formData.append("last_name", inputs.last_name);
    formData.append("department", inputs.department);
    formData.append("title", inputs.title);
    formData.append("phone", inputs.phone);
    formData.append("email", inputs.email);
    formData.append("image_path", inputs.image_path);
    formData.append("old_image_path", inputs.old_image_path);
    formData.append("is_active", inputs.is_active);
    formData.append("mainlocation", mainlocation);
    formData.append("updated_by", userx.id);
    for (let i = 0; i < inputs.location_id.length; i++) {
        formData.append("location_id[]", inputs.location_id[i]);
    };


    formData.append("text_message_number", inputs.text_message_number);
    formData.append("order", inputs.order);
    formData.append('location_changed', locationChanged);
    formData.append('image_id', inputs.image_id);

    if (inputs.photo_file_name) {
        formData.append("photo_file_name", inputs.photo_file_name);
    }

    return axiosInstance.patch("/v2/staff/update", formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }
    );
};


// delete staff
export const deleteStaff = (e, staffObj, setDel) => {
    // e.preventDefault();
    let userx = JSON.parse(localStorage.getItem('user'));

    const id = e.target.getAttribute("data-key");
    const imagePath = staffObj.image_path;
    setDel(true);
    return axiosInstance.delete("/v2/staff/del", { data: { id: id, image_path: imagePath, updated_by: userx.id } });
};



