import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import { toast } from "react-toastify";
import axiosInstance from "../../services/AxiosInstance";
import { errorHandler } from "../Utils/errorHandler";
import SessionExpiredModal from "../SessionExpire/SessionExpiredModal";
import { deleteDepartmentByLoc, getDepartments } from "../../apis/DepartmentAPI";


const DeleteDepartmentModal = ({ show, setShow, item, apps, loc, setDepartments }) => {
    const [sessionModal, setSessionModal] = useState(false);
    const [showToast, setShowToast] = useState(false);

    const handleClose = () => setShow(false);

    // handle warnings and backend call
    const handleWarning = async (e) => {
        e.preventDefault();
        if (apps && loc) {
            const counts = await axiosInstance.get(`/v2/department/staffcount-loc?locationId=${loc}&department=${item}`);
            const staffCount = counts.data[0].count;

            if (staffCount > 0) {
                toast.error("This department has staffs associated with it. Please remove the staffs or migrate them to another department before removing!", {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: false,
                    hideProgressBar: true,
                    onClose: () => {
                        setShow(false);
                    }
                });
            }
            else {
                deleteDepartmentByLoc(loc, item).then((res) => {
                    if (res.status === 201) {
                        toast.success("Department deleted successfully!", {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            hideProgressBar: false,
                            onClose: () => {
                                setShow(false);
                                getDepartments(apps, loc, 30, undefined, 1).then((response) => {
                                    if (response.status === 200) {
                                        setDepartments(response.data);
                                        window.location.reload();
                                    } else {
                                        setDepartments([]);
                                        window.location = '/error';
                                    };
                                }).catch(err => {
                                    errorHandler(err, setSessionModal, setShowToast, toast);
                                });
                            }
                        });
                    } else {
                        toast.error("Error deleting department!", {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: false,
                            hideProgressBar: true
                        });
                    }
                }).catch((err) => {
                    errorHandler(err, setSessionModal, setShowToast, toast);
                }
                );
            };
        }
    };


    // logs out a user
    const handleLogout = async () => {
        localStorage.removeItem("user");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        window.location = "/";
    };


    return (
        <div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showToast && <div className="toast-overlay"></div>}
                    <SessionExpiredModal
                        show={sessionModal}
                        handleLogout={handleLogout}
                    />
                    {apps && loc && <p>Are you sure you want to delete the department <b>{item}</b> from this location?</p>}
                    {apps && !loc && <p>Are you sure you want to delete the department <b>{item}</b> from this app?
                        This will delete the department from all locations.</p>}
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleClose}>Close</button>
                    <button className="btn btn-danger" onClick={handleWarning}>Delete</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default DeleteDepartmentModal;